import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainContainer: {
        backgroundColor: '#FFFFFF',
        height: '100%',
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        '& img': {
            cursor: 'pointer'
        }
    },
    titleContainer: {
        display: 'flex',
        padding: '0.2rem 1rem',
        // paddingTop: '0.2rem',
        // paddingBottom: '1.4rem',
        borderBottom: '1px solid rgb(221, 224, 226, 0.5)',
        // position: 'sticky',
        // backgroundColor: '#fff',
        // zIndex: 999,
        // top: '3.4rem',
    },
    column: {
        display: 'inline-block',
        width: '50%',
        '& > i': {
            // position: 'absolute',
            // right: '0.7rem',
            // top: '0.5rem',
            color: '#f4511e'
        },
    },
    titleColumn1: {
        display: 'flex',
        // border :'1px solid',
        width: '94%'
    },
    titleColumn2: {
        // border :'1px solid red',
        width: '6%'
    },
    iconsBox: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    icon: {
        paddingLeft: '1.3rem'
    },
    categoryNameBox: {
        margin: '0.8rem 0',
        padding: '0.8rem',
        backgroundColor: '#F9F9F9',
        display: 'flex',
        position: 'relative',
        gap: '0.8rem',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.6s',
        '& > i': {
            color: '#f4511e',
            transition: '0.5s',
        },
        '& > .fa-circle-arrow-up': {
            position: 'absolute',
            right: '4.6rem',
            fontSize: '1.1rem',
            '&:hover': {
                scale: '1.2'
            }
        },
        '& > .fa-circle-arrow-down': {
            position: 'absolute',
            right: '2.8rem',
            fontSize: '1.1rem',
            '&:hover': {
                scale: '1.2'
            }
        },
        '& > img': {
            position: 'absolute',
            right: '1rem',
            transition: 'scale 0.5s',
            '&:hover': {
                scale: '1.2'
            }
        },
        '& > h5': {
            fontSize: '0.8rem',
            fontFamily: 'Montserrat',
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            '& > input': {
                outline: 'none',
                fontSize: '0.8rem',
                fontFamily: 'Montserrat',
                border: 'none',
                backgroundColor: '#F9F9F9',
                fontWeight: 700,
                maxWidth: '8.5rem',
                // minWidth: 'fit-content',
                // width: '8rem',
                // '&::selection': {
                //     background: '#b3d4fc'
                //   },
                '&:focus':{
                    caretColor: '#f4511e',
                    // borderBottom: '2px solid #f4511e',
                }
            },
            '& > i': {
                color: '#f4511e',
                fontSize: '1rem',
                scale: '1.2',
                transition: 'scale 0.5s',
                '&:hover': {
                    scale: '1.4'
                }
            },
        }
    },
    sequenceCtaBtnBox: {
        // border: '1px solid',
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        height: 38,
        width: 298,
        gap: '1rem',
        '& > .MuiButtonBase-root': {
            // maxHeight: '100%',
            // width: '50%',
            height: 35,
            width: 140,
            padding: 0,
            borderRadius: '4px',
            fontSize: '10px !important',
            fontWeight: '800 !important'

        },
        '& .MuiButton-filled': {
            color: '#fff !important',
            '&:hover': {
                borderColor: '#f4511e',
                backgroundColor: '#f4511e',
            }

        },
        '& .MuiButton-outlined': {
            color: '#f4511e !important',
        }

    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding:"1rem"
    },
    listItem: {
        // border: '1px solid',
    },
    highlighted: {
        '-webkit-box-shadow': '0px 1px 5px 2px rgba(110, 186, 0, 0.6) !important',
        '-moz-box-shadow': '0px 1px 5px 2px rgba(110, 186, 0, 0.6) !important',
        boxShadow: '0px 1px 5px 2px rgba(110, 186, 0, 0.6) !important',
    },
    editIcon: {
        // marginLeft: '1.2rem',
        height: '0.8rem',
        transition: 'scale 0.5s',
        '&:hover': {
            scale: '1.2',
            filter: 'invert(36%) sepia(83%) saturate(1278%) hue-rotate(347deg) brightness(96%) contrast(100%)'
        }
    },
    deleteIcon: {
        height: '1rem',
        width: '1rem',
        "&:hover": {
            filter: 'invert(42%) sepia(30%) saturate(7472%) hue-rotate(352deg) brightness(100%) contrast(92%)'
        }
    },
    cardsContainer: {
        padding: '1rem 0.2rem 0.2rem 1rem',
        display: 'flex',
        gap: '1rem',
        width: '100%',
        flexWrap: 'wrap',
        containerType: 'inline-size',
        '& > div:first-child': {
            // width: '24cqi', //doesn't support on safari version < 16 mac-OS
            width: '24%',
            aspectRatio: 0.80,
        },


        '& > div': {
            // border : '2px solid',
            borderRadius: '10px',
            // width: '24cqi',//doesn't support on safari version < 16 mac-OS
            width: '24%',
            aspectRatio: 0.80,
            overflow: 'hidden'
        }

    },
    createCard: {
        cursor: 'pointer',
        width: '23%',
        aspectRatio: 0.80,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'repeating-linear-gradient(180deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(270deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(0deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(90deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px)',
        backgroundSize: '2px calc(100% + 30px), calc(100% + 30px) 2px, 2px calc(100% + 30px) , calc(100% + 30px) 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
        animation: '$borderAnimation 4s infinite linear reverse',
        '& img': {
            // height: '3.5rem',
            // width: '3.5rem',
            // marginBottom: '1.5rem',
            height: '4.5cqi',
            width: '4.5cqi',
            marginBottom: '2cqi',
            alignSelf: 'center',
            cursor: 'pointer'
        },
        '& h5': {
            color: '#F4511E',
            // fontSize: '0.8rem',
            fontSize: '1cqi',
            fontWeight: 'Bold',
            fontFamily: 'Open Sans'
        }
    },
    '@keyframes borderAnimation': {
        from: { backgroundPosition: '0 0, -30px 0, 100% -30px, 0 100%' },
        to: { backgroundPosition: '0 -30px, 0 0, 100% 0, -30px 100%' }
    },
    addCategoryBox: {
        position: 'relative',
        margin: '1rem',
        height: '1.7rem',
        '& .MuiButtonBase-root': {
            padding: 0,
            color: '#2B2B2B',
            fontSize: '0.8rem',
            fontFamily: 'Montserrat',
            fontWeight: 700,
            '& .MuiButton-startIcon': {
                color: '#f4511e',
            }
        }
    },
    dropdownBox: {
        // border: '1px solid',
        width: '30%',
        display: "block",
        margin: "auto",
        '& > .MuiFormControl-root': {
            height: '1.9rem',
            marginLeft: '0.5rem',
            '& > div': {
                height: '1.9rem',
                '& > .MuiInputBase-root': {
                    height: '1.9rem',

                }
            }
        }
    },
    searchBarBox: {
        // border: '1px solid',
        position: 'relative',
        width: '53rem',
        height: '2.5rem',
        margin: '1rem',
        '& .MuiPaper-root': {
            width: '100%',
            height: '100%'
        }
    },
}))

export default useStyles;
