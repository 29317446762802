import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

//components
import CustomButton from '../../../custom-styled/button';
import SearchBox from '../../../search-box';
import MultipleFilter from '../group-multiple-filter';
import commonUtil from '../../../../utils/commonUtil';

const defaultFilterGroupData = [
    {
        id: "language",
        name: 'Language Groups',
        subMenu: [],
        selected: [],
        total: 0,
        singleSelect: true,
    },
    {
        id: "other",
        name: 'Other Groups',
        subMenu: [],
        selected: [],
        total: 0,
        singleSelect: false,
    }
]

const ManageGroupDialog = ({
    manageGroupDialogOpen = false,
    setManageGroupDialogOpen,
    microskillName = 'Making a great sales pitch',
    handleAddGroup,
    msGroupsData,
    allGroups,
    handleRemoveMsFromGroup,
    handleSave,
    isSaving = false
}) => {
    console.log('msGroupsData = ', msGroupsData);

    const classes = useStyles();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [chips, setChips] = useState([]);
    const [filterGroupData, setFilterGroupData] = useState(defaultFilterGroupData);
    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '75%',
        bgcolor: 'background.paper',
        // border: '2px solid red',
        boxShadow: 24,
        // border: 'none',
        borderRadius: '5px',
        outline: 'none',

    }

    useEffect(() => {
        let tempSelectedGroups = [];
        let tempChips = [];
        msGroupsData.forEach(ele => {
            tempSelectedGroups.push(ele.Group.id);
            tempChips.push({ ...ele.Group, microskillId: ele.microskillId });
        });
        setChips(tempChips);
        setSelectedGroups(tempSelectedGroups);
        updateToDefaultFilterGroupData()
    }, [msGroupsData])

    const updateToDefaultFilterGroupData = () => {
        let languageGroups = [];
        let selectedLanguageGroups = [];
        let otherGroups = [];
        let selectedOtherGroups = [];
        allGroups.forEach(element => {
            if (element.type === 'LANGUAGE') languageGroups.push(element);
            else otherGroups.push(element);
        });
        msGroupsData.forEach(element => {
            if (element.Group.type === 'LANGUAGE') selectedLanguageGroups.push(element.Group.id);
            else selectedOtherGroups.push(element.Group.id);
        });
        let tempData = [
            {
                id: "language",
                name: 'Language Groups',
                subMenu: languageGroups,
                selected: selectedLanguageGroups,
                total: languageGroups.length,
                singleSelect: true,
            },
            {
                id: "other",
                name: 'Other Groups',
                subMenu: otherGroups,
                selected: selectedOtherGroups,
                total: otherGroups.length,
                singleSelect: false,
            }
        ];
        setFilterGroupData(tempData)
    }



    // const handleSelectGroup = (item) => {
    //     if (selectedGroups.includes(item.id)) {
    //         let filtered = selectedGroups.filter(ele => ele !== item.id);
    //         setSelectedGroups(filtered);
    //     } else {
    //         setSelectedGroups([...selectedGroups, item.id])
    //     }
    // };

    const handleSaveFilter = (updatedFilterList) => {
        const allGroupsObject = commonUtil.objectify(allGroups, "id");
        setFilterGroupData(updatedFilterList);
        let newSelectedGroups = [...updatedFilterList[0].selected, ...updatedFilterList[1].selected]
        setSelectedGroups(newSelectedGroups);
        let newChips = [];
        let microskillId = msGroupsData[0]?.microskillId ?? null;
        updatedFilterList[0].selected.forEach(id => newChips.push({ id: id, name: allGroupsObject[id].name, microskillId }));//language
        updatedFilterList[1].selected.forEach(id => newChips.push({ id: id, name: allGroupsObject[id].name, microskillId }));//other
        setChips(newChips)
    }

    return (
        <Modal
            open={manageGroupDialogOpen}
            onClose={() => {
                return
                setManageGroupDialogOpen(false);
            }}>
            <Box sx={boxStyle}>
                <div className={classes.header}>
                    <Typography variant="body1" component="div"> {'Groups Containing:'} </Typography>
                    <Typography variant="body2" component="div"> {commonUtil.truncateString(microskillName, 35)} </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setManageGroupDialogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#f4511e',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.body}>
                    <MultipleFilter
                        label="Groups Filters"
                        title="Select Groups"
                        filterList={filterGroupData}
                        setFilterList={setFilterGroupData}
                        handleSaveFilter={handleSaveFilter}
                    />

                    {
                        chips.map((ele, i) => (
                            <span className={classes.chip} key={i}>
                                <h6>
                                    {ele.name}
                                </h6>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => handleRemoveMsFromGroup(ele)}
                                    sx={{
                                        position: 'absolute',
                                        right: '0.7rem',
                                        // top: '0.2rem',
                                        color: '#ffffff',
                                        heighgt: '0.8rem',
                                        width: '0.8rem',
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        ))
                    }
                </div>
                <div className={classes.footer}>
                    {isSaving ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Saving...
                        </LoadingButton>
                        : <CustomButton
                            handleClick={() => handleSave(selectedGroups)}
                            variant='contained'
                            textColor='#fff'
                            bgcolor='#f4511e'
                            fs="0.6rem"
                            fw={800}
                            ls={1}
                            btnText='SAVE'
                        />}
                    <CustomButton
                        handleClick={handleAddGroup}
                        variant='outlined'
                        textColor='#f4511e'
                        borderColor='#f4511e'
                        bgcolor='#fff'
                        fs="0.6rem"
                        fw={800}
                        ls={1}
                        btnText='ADD A GROUP'
                    />
                </div>
            </Box>
        </Modal >
    )
}

export default ManageGroupDialog;

const useStyles = makeStyles((theme) => ({
    header: {
        // border: '1px solid',
        height: '10%',
        borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body1': {
            marginLeft: '2rem',
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "1.3rem",
            fontWeight: 500,
        },
        '& .MuiTypography-body2': {
            marginLeft: '1rem',
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "1.4rem",
            fontWeight: 700,
        },
    },
    // scrollableDiv: {
    //     maxHeight: 200,
    //     overflowY: 'auto',
    //     padding: theme.spacing(2),
    //   },
    body: {
        position: 'relative',
        padding: '1rem 1.5rem',
        display: 'flex',
        gap: '0.9rem',
        flexWrap: 'wrap',
        maxHeight: '28rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        // padding: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '0.6em', // Width of the scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#f4511e', // Color of the scrollbar thumb
            borderRadius: '4px', // Rounded corners of the thumb
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#fff', // Color of the scrollbar track
            borderRadius: '4px', // Rounded corners of the track
        },
        // flexDirection: 'column'
    },
    chip: {
        position: 'relative',
        border: '1px solid #f4511e',
        minWidth: 'fit-content',
        // width: 'fit-content',
        height: '2.6rem',
        padding: '0.5rem 1rem',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f4511e',
        '& > h6': {
            marginRight: '1.2rem !important',
            color: '#ffffff',
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 700,

        }
    },
    chip2: {
        position: 'relative',
        border: '1px solid #f4511e',
        minWidth: 'fit-content',
        // width: 'fit-content',
        height: '2.6rem',
        padding: '0.5rem 1rem',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '& > h6': {
            marginRight: '1.2rem !important',
            color: '#f4511e',
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 700,

        },
        '&:hover': {
            backgroundColor: 'rgb(235, 235, 242, 0.3)'
        }
    },
    selectBox: {
        position: 'relative',
        '& .MuiButtonBase-root': {
            '&:hover': {
                backgroundColor: '#fff'
            }
        }
    },
    footer: {
        // border: '1px solid green',
        height: '10%',
        display: 'flex',
        gap: '2rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 2rem',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        '& .MuiButtonBase-root': {
            width: '20%',
            borderRadius: '4px'
        },
        '& .MuiButton-contained:hover': {
            backgroundColor: '#f4511e',
            borderColor: '#f4511e',
            color: '#fff',
        },
        '& .MuiLoadingButton-root': {
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 700,
            padding: '0.52rem 0'
        }
    }
}));
