import React, { useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//constants
import constants from '../../../constants';


const CustomInput = ({
    id = '',
    withLimitWarning = true,
    value = '',
    placeholder = '',
    isDisabled = false,
    charLimit = 2000,
    handleChange,
    exceedText = '',
    isExceeded = false,
    isEmpty = false,
    type = 'text',
    width = '100%',
    height = '2.3rem',
    m = 0,
    wrapperWidth = 'auto',
    wrapperHeight = 'auto',
    isNotificationDescription = false,

}) => {
    const classes = useStyles();
    const inputStyle = {
        width: isExceeded ? '86%' : width,
        height: height,
        outline: 'none',
        border: 'none',
    };

    const textAreaStyle = {
        width: isExceeded ? '86%' : width,
        height: "",
        outline: 'none',
        border: 'none',
        // minWidth: '100%',
        minHeight: '5rem',
        resize: 'none',
        padding: '0.5rem'
    }

    return (
        <>
            <div className={`${classes.customInputBox} ${isExceeded || isEmpty ? classes.error : ''}`}
                style={{
                    width: wrapperWidth, height: wrapperHeight,
                    borderWidth: isEmpty ? '2px' : '1.5px',
                    margin: m,
                    minWidth: wrapperWidth

                }}>


                {!isNotificationDescription ? <input
                    id={id}
                    style={inputStyle}
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                    maxLength={charLimit}
                    type={type}
                    disabled={isDisabled}
                /> :

                    <textarea
                        className="scrollbar"
                        id={id}
                        style={textAreaStyle}
                        onChange={handleChange}
                        value={value}
                        placeholder={placeholder}
                        maxLength={charLimit}
                        type={type}
                        disabled={isDisabled}
                        rows="5"
                    />

                }

                {
                    withLimitWarning ?
                        <span style={{
                            backgroundColor: isDisabled ? "rgba(239, 239, 239, 0.3)" : "#fffff",
                            alignItems: isNotificationDescription ? "flex-end" : "center",
                            paddingBottom: isNotificationDescription ? "0.5rem" : "auto",




                        }}>
                            {exceedText}
                        </span> : null
                }

            </div>
        </>

    )
}

export default CustomInput;


const useStyles = makeStyles(() => ({

    customInputBox: {
        outline: '1.5px solid rgb(184, 184, 184, 0.5)',
        borderRadius: '3px',
        display: 'flex',
        fontSize: "0.7rem",
        fontWeight: "500",
        '& input': {
            color: 'rgb(112, 112, 112, 0.9)',
            paddingLeft: '0.5rem',
            // padding: '0.28rem 0.5rem',
            // width: '86%',
            border: 'none',
            '&:focus': {
                outline: 'none',
            },
            // '&::placeholder': {
            //     color: isEmpty ? 'red' : 'rgb(112, 112, 112, 0.9)',
            //     // color : 'red',
            //     // opacity: 1 /* Firefox */
            // },
        },

        '&:focus-within': {
            outline: '2px solid #4F0060',
        },
        '&:hover': {
            outline: '2px solid #4F0060',
        },

        '& span': {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.28rem 0.5rem',
            width: '14%',
            minWidth: 'fit-content',
            color: "#EB3917",
            fontFamily: "Montserrat",
            fontSize: "0.54rem",
            fontWeight: "700",
        },
        // What corrective majors should you take in order to avoid conflict?
    },
    error: {
        outline: '2px solid #EB3917 !important',
        '&:hover': {
            outline: '2px solid #EB3917 !important',
        },
    },

}));
