import React, { useState, useEffect, useRef, useContext } from 'react'
import useRouter from '../../utils/useRouter'


//  components
import CustomInput from "./components/custom-input";
import TypeQuestion from './components/typeQuestion';
import ResponseQuestions from './components/ResponseQuestions';
import RatingResponses from './components/ratingQuestionsResponses';

//  mui
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//  styles
import useStyles from './index.styles';

//  services
import formServices from "../../services/form";
import FailureDialog from '../failure-dialog';
import SuccessDialog from '../success-dialog';
import useQuery from '../../utils/useQuery';
import LoadingContainer from '../loading-container';
import { Divider } from '@mui/material';

//validations
import formsValidations from '../../validations/forms-validations';
import DeleteDialog from '../delete-dialog';

import { GlobalStates } from '../../App';

const FormsQuestion = () => {
    const router = useRouter();
    const query = useQuery();
    const formId = query.get('formId');
    const companyId = query.get('c_id');
    const microSkillId = query.get('ms_id')
    const sequence = query.get('seq')
    const styles = useStyles();
    const [globalStates] = useContext(GlobalStates);
    const [maxScore, setMaxScore] = useState(0);
    const questionCharLimit = 110;
    const responseCharLimit = 85;

    let finalData = {
        formId: "",
        companyId: "",
        questions: []
    };

    let QuestionData = {
        id: 0,
        questionId: 0,
        question: "",
        isQuestionEmpty: false,
        status: 'error',
        validations: [],
        options: [],
        type: "SINGLE_CHOICE_QUESTION",
        mandatory: false,
        sequence: 1,
        active: true,
        scorePerResponse: "0",
        isScore: false,
        answers: []
    };
    let answersData = {
        id: 0,
        answerId: 0,
        option: "",
        value: false,
        type: "",
        sequence: 1,
        isAnswerEmpty: false,
        active: true
    };

    const [formQuestion, setFormQuestion] = useState([]);

    let responses = Array(2).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responseCharLimit} Characters` }))
    let questions = Array(1).fill(null).map((item, index) => ({ exceeded: false, limitText: `${questionCharLimit} Characters`, answers: [...responses] }))

    const [questionValidations, setQuestionValidations] = useState(questions);
    const [failureAddQuestion, setFailureAddQuestion] = useState(false);
    const [successAddQuestion, setSuccessAddQuestion] = useState(false);
    const [successResponseText, setSuccessResponseText] = useState('');
    const [failedResponseText, setFailedResponseText] = useState('');
    const [deleteDialogText, setDeleteDialogText] = useState('Question');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteParameters, setDeleteParameters] = useState({});
    const [collapseList, setCollapseList] = useState([0]);
    const [minScore, setMinScore] = useState(0);
    const [minQuestionAttempt, setMinQuestionAttempt] = useState(0);

    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [isAssessMent, setIsAssesment] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [timeLimit, setTimeLimit] = useState(60);
    const [randomizeQuestions, setRandomizeQuestions] = useState(false);
    const [randomizeAnswers, setRandomizeAnswers] = useState(false);

    const [loadingData, setLoadingData] = useState(true);
    const maxScoreRef = useRef(0);

    useEffect(async () => {
        await fetchSpecificForm();
    }, []);




    const newFormValidations = (questionValidation) => {
        let tempQuestionValidation = [...questionValidation];
        formQuestion.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${value.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: true, limitText: `${responseCharLimit - value.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
        setLoadingData(false);
    }

    const oldFormsValidations = (questionForm) => {
        let tempQuestionValidtions = [];
        questionForm.forEach((element, index) => {
            const ans = element.answers;
            let tempAnswersValidation = [];
            if (ans.length > 0) {
                ans.forEach((tempans, index) => {
                    var tempAnswer = {};
                    const optionValue = tempans.option;
                    if (optionValue === '') {
                        tempAnswer = { exceeded: false, limitText: '*Required' }
                    }
                    // if (optionValue.length > responseCharLimit) {
                    //     tempAnswer = { exceeded: true, limitText: `${optionValue.length - responseCharLimit} Characters Exceeded` }
                    // } else if (optionValue === '') {
                    //     tempAnswer = { exceeded: false, limitText: '*Required' }
                    // } else {
                    //     tempAnswer = { exceeded: false, limitText: `${responseCharLimit - optionValue.length} Characters` }
                    // }
                    tempAnswersValidation.push(tempAnswer);
                })
            }

            var tempQuestion = {};
            const ques = element.question;
            if (ques === '') {
                tempQuestion = { exceeded: false, limitText: '*Required', answers: [...tempAnswersValidation] };
            }
            // if (ques.length > questionCharLimit) {
            //     tempQuestion = { exceeded: true, limitText: `${ques.length - questionCharLimit} Characters Exceeded`, answers: [...tempAnswersValidation] };
            // } else if (ques === '') {
            //     tempQuestion = { exceeded: false, limitText: '*Required', answers: [...tempAnswersValidation] };
            // }
            // else {
            //     tempQuestion = { exceeded: false, limitText: `${questionCharLimit - ques.length} Characters`, answers: [...tempAnswersValidation] };
            // }
            tempQuestionValidtions.push(tempQuestion);
        })
        setQuestionValidations(tempQuestionValidtions);
        setLoadingData(false);
    }

    const fetchSpecificForm = async () => {
        const respData = await formServices.fetchSpecificForm({ formId });
        if (respData.status === 200 || respData.status === 201) {
            const data = respData.data.data;
            setFormName(data.name);
            setFormDescription(data.description);
            setShowScore(data.showScore);
            setIsAssesment(data.assessment);
            setTimeLimit(data.timeLimit)

            if (data.settings !== null && data.settings !== undefined && data.settings !== '') {
                setRandomizeQuestions(data.settings.randomizeQuestions);
                setRandomizeAnswers(data.settings.randomizeAnswers);
            }

            if (respData.data.data.Questions.length === 0) {
                setFormQuestion([QuestionData]);
                setQuestionValidations(questions);
                newFormValidations(questions);
                setSuccessResponseText('Question Added in Form Successfully');
                setFailedResponseText('Error in adding Question in Form, Please Try again');
            }
            else {
                const formData = [...respData.data.data.Questions];
                const tempFormData = [];
                setSuccessResponseText('Form Updated Successfully');
                setFailedResponseText('Error in updating the form, Please Try again');

                formData.forEach((data, idx) => {
                    const tempData = {};
                    tempData.id = idx;
                    tempData.question = data.question;
                    tempData.isQuestionEmpty = false;
                    tempData.status = 'done';
                    tempData.validations = [];
                    tempData.options = data.options === null ? [] : data.options;
                    tempData.type = data.type;
                    tempData.mandatory = data.mandatory;
                    tempData.sequence = data.sequence;
                    tempData.active = data.active;
                    tempData.questionId = data.id;
                    tempData.scorePerResponse = data.scorePerResponse.toString() || "0";
                    tempData.isScore = data.scorePerResponse > 0 ? true : false;
                    const tempDataAnswers = [];
                    data.answers.forEach((ans, idx) => {
                        const tempAns = {};
                        tempAns.option = ans.answer;
                        tempAns.value = ans.isCorrect;
                        tempAns.type = ans.answerType;
                        tempAns.id = idx;
                        tempAns.answerId = ans.id;
                        tempAns.active = ans.active;
                        tempAns.sequence = ans.sequence;
                        tempDataAnswers.push(tempAns);
                    })
                    tempData.answers = tempDataAnswers;
                    tempFormData.push(tempData);
                })
                oldFormsValidations(tempFormData);
                setFormQuestion([...tempFormData]);
                     setMinQuestionAttempt(tempFormData.length)   // For Setting the MinQuestion attempt intially
                     console.log(tempFormData);
            }
        }
    }

    const addQuestion = async (payload) => {
        const resp = await formServices.updateForm(payload);

        globalStates.handleProgressDialog(false);
        if (resp.status === 200 || resp.status === 201) {
            // console.log(resp.data);
            setFormQuestion([QuestionData]);
            setQuestionValidations(questions);
            setSuccessAddQuestion(!successAddQuestion);
        }
        else {
            setFailureAddQuestion(!failureAddQuestion)
        }
    }

    const handleInputQuestion = (e, id) => {
        const tempObj = [...formQuestion];
        tempObj[id].question = e.target.value;
        tempObj[id].isQuestionEmpty = false;
        setFormQuestion(tempObj);

        //  Validations
        let tempQuestionValidation = [...formQuestion];
        formQuestion.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - value.length} Characters`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required", ...ele }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
    }

    const handleAddQuestion = () => {
        const tempObj = [...formQuestion];
        tempObj.push({ ...QuestionData, id: tempObj.length });

        tempObj.map((obj, idx) => {
            obj.id = idx;
            obj.sequence = idx + 1;
        })

        setFormQuestion(tempObj);
      setMinQuestionAttempt(tempObj.length)  // For Setting the min Question attempt by the length of added questions 

        let tempQuestionValidation = [...formQuestion, QuestionData];
        tempObj.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - value.length} Characters`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
        handleCollapse(tempObj.length - 1)
    }

    const handleDeleteQuestion = async (idx) => {

        let tempObj = [...formQuestion];

        let deleteQuestion = tempObj.find((item, i) => idx === i);
        if (typeof (deleteQuestion.questionId) === 'string') {
            const questionId = deleteQuestion.questionId;
            const resp = await formServices.deleteQuestion({ formId, companyId, questionId });
            if (resp.status !== 200 && resp.status !== 201) {
                console.log("It is Not able to delete");
                // e.stopPropagation();
                return;
            }
        }

        let deleteQuestionArr = tempObj.filter((item, i) => idx !== i);

        deleteQuestionArr.map((item, idx) => {
            item.id = idx;
            item.sequence = idx + 1;
            var answerObj = item.answers;
            answerObj.map((answer, i) => {
                answer.id = i;
                answer.sequence = i + 1;
            })
        })

        setFormQuestion(deleteQuestionArr);
        setMinQuestionAttempt(deleteQuestionArr.length)

        let tempQuestionValidation = [...deleteQuestionArr];
        deleteQuestionArr.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - value.length} Characters`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required", ...ele }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })
        setQuestionValidations(tempQuestionValidation);
        // e.stopPropagation();
    }

    const handleDeleteAnswer = async (questionIdx, idx) => {
        // console.log(questionIdx, idx);
        let tempObj = [...formQuestion];

        var deleteAnswer = tempObj[questionIdx].answers;
        deleteAnswer = deleteAnswer.filter((ans) => ans.id === idx);

        if (typeof (deleteAnswer.answerId) === 'string') {

        }

        const remainAnswerOfQuestion = tempObj[questionIdx].answers.filter((ans) => ans.id !== idx);


        tempObj[questionIdx].answers = [...remainAnswerOfQuestion];

    }

    const handleDelete = async (i) => {
        setShowDeleteDialog(false);
        if (i.type === 'Question') {
            handleDeleteQuestion(i.questionIdx);
        }
        else if (i.type === 'Answer') {
            handleDeleteAnswer(i.questionIdx, i.answerIdx);
        }
    }

    const handleQuestionType = (e, id) => {
        let value = e.target.value;
        let tempObj = [...formQuestion];
        if (value === "Single") {
            tempObj[id].type = "SINGLE_CHOICE_QUESTION";

            tempObj[id].answers.map((element, idx) => {
                tempObj[id].answers[idx].value = false;
            })
        }
        else if (value === "Multiple") {
            tempObj[id].type = "MULTI_CHOICE_QUESTION";
        }
        else if (value === "Open ended") {
            tempObj[id].type = "OPEN_ENDED";
            tempObj[id].answers = [];
        }
        else if (value === "Rating") {
            // By Defalut in Rating, Star is Selected
            tempObj[id].type = "STAR_RATING";
            tempObj[id].answers = [];
            tempObj.options = {
                maxLimit: 5,
                minLimit: 0
            }
        }
        else if (value === "Date") {
            tempObj[id].type = "DATE";
        }
        else if (value === "Custom") {
            tempObj[id].type = "CUSTOM";
        }

        setFormQuestion(tempObj)
    }

    const handleInputAnswer = (e, questionIdx, answerIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].answers[answerIdx].option = e.target.value;
        tempObj[questionIdx].answers[answerIdx].isAnswerEmpty = false;
        // console.log(tempObj);
        setFormQuestion(tempObj)

        //  Validations
        let tempQuestionValidation = [...formQuestion];
        formQuestion.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - value.length} Characters`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required", ...ele }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
    }

    const handleCorrectIncorrectOption = (questionIdx, answerIdx) => {
        console.log("MaxSCore is Updated");
        let tempObj = [...formQuestion];
        if (tempObj[questionIdx].type === "SINGLE_CHOICE_QUESTION") {
            tempObj[questionIdx].answers.map((element, idx) => {
                if (tempObj[questionIdx].answers[idx].value) {
                    tempObj[questionIdx].answers[idx].value = false;
                    setMaxScore(maxScore - 10);
                    maxScoreRef.current -= 10;
                }
            })
        }

        if (tempObj[questionIdx].answers[answerIdx].value === false) {
            maxScoreRef.current += 10;
            setMaxScore(maxScore + 10);
            tempObj[questionIdx].answers[answerIdx].value = true;
        }
        else {
            maxScoreRef.current -= 10;
            setMaxScore(maxScore - 10)
            tempObj[questionIdx].answers[answerIdx].value = false;
        }
        setFormQuestion(tempObj);
    }

    const handleAddAnswer = (questionIdx, typeAddAnswer = "") => {
        let tempObj = [...formQuestion];
        let tempAnsObj = [...tempObj[questionIdx].answers];

        // console.log("tempAnsObj", tempAnsObj);
        let answerTemp = answersData;
        answerTemp.type = "FIXED"
        tempAnsObj = [...tempObj[questionIdx].answers, answersData];
        tempObj[questionIdx].answers = tempAnsObj;

        // console.log("tempAnsObj", tempAnsObj);

        tempObj.map((obj) => {
            var tempAns = obj.answers;
            tempAns.map((ans, idx) => {
                ans.id = idx;
                ans.sequence = idx + 1;
            })
            obj.answers = tempAns;
        })

        // console.log(tempObj);

        setFormQuestion(tempObj);

        let tempQuestionValidation = [...tempObj];
        tempObj.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }
            // if (value.length > questionCharLimit) {
            //     tempQuestionValidation[idx] = { exceeded: true, limitText: `${value.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
            // }
            // else {
            //     if (value === '') {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            //     }
            //     else {
            //         tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - value.length} Characters`, answers: [...responses] }
            //     }
            // }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required", ...ele }
                }
                // if (ansValue.length > responseCharLimit) {
                //     tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
                // }
                // else {
                //     if (ansValue === '') {
                //         tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                //     }
                //     else {
                //         tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
                //     }
                // }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
    }

    const handleMandatory = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].mandatory = !tempObj[questionIdx].mandatory;
        setFormQuestion(tempObj)
    }

    const handleScoreToBeAddedInQuestion = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].isScore = !tempObj[questionIdx].isScore;
        setFormQuestion(tempObj);
    }

    const handleScorePerResponse = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].scorePerResponse = (e.target.value);
        setFormQuestion(tempObj);
    }

    const updateFormButton = () => {
        let tempObj = [...formQuestion];
        let validatedList = formsValidations.validateList(formQuestion, isAssessMent);
        // console.log('updateFormButton validatedList = ', validatedList);
        let { isValid, idx } = formsValidations.isEachListitemValid(validatedList);
        let tempCollapse = []
        setFormQuestion(validatedList);
        setCollapseList(tempCollapse)
        if (!isValid) {
            setFailedResponseText('Invalid Question');
            setFailureAddQuestion(true);
            return
        }
          
        if(tempObj.length===0){
            setFailedResponseText("Please add questions")
             setFailureAddQuestion(true)
             return
        }
        
        if(minQuestionAttempt<1){
            setFailedResponseText("Minimum question attempt should be greater than 0");
            setFailureAddQuestion(true)
            return
        }
        globalStates.handleProgressDialog(true, 'Please wait...');

        tempObj.map((obj) => {
            if (obj.isScore === false) obj.scorePerResponse = 0;
            obj.scorePerResponse = parseInt(obj.scorePerResponse);
        });

        var score = 0;
        for (const question of tempObj) {
            if (question.type === 'SINGLE_CHOICE_QUESTION') {
                let tempAnswer = question.answers;
                tempAnswer = tempAnswer.filter((ques) => ques.value === true);
                score += tempAnswer.length * question.scorePerResponse;
            } else if (question.type === 'MULTI_CHOICE_QUESTION') {
                let tempAnswer = question.answers;
                tempAnswer = tempAnswer.filter((ques) => ques.value === true);
                score += tempAnswer.length * question.scorePerResponse;
            } else if (question.type === 'OPEN_ENDED') {
                score += question.scorePerResponse;
            } else if (question.type === 'STAR_RATING') {
                score += question.scorePerResponse;
            } else if (question.type === 'NUMBER_RATING') {
                score += question.scorePerResponse;
            } else if (question.type === 'DATE') {
                score += question.scorePerResponse;
            } else if (question.type === 'CUSTOM') {
                score += question.scorePerResponse;
            }
        }

        if (isAssessMent && score < minScore) {
            setFailedResponseText('Minimum Score should be less than Total Score');
            setFailureAddQuestion(true);
        }

        let submitData = finalData;
        submitData.formId = formId;
        submitData.companyId = companyId;
        submitData.name = formName;
        submitData.assessment = isAssessMent;
        submitData.timeLimit = parseInt(timeLimit);
        submitData.showScore = showScore;
        submitData.description = formDescription;
        submitData.maxScore = score;
        submitData.questions = [...tempObj];
        submitData.settings = { randomizeQuestions, randomizeAnswers, minScore, minQuestionAttempt };
        addQuestion(submitData)
    }

    const handleMinScore = (e) => {
        setMinScore(e.target.value);
    }

    const handleMinQuestionAttempt = (e) => {
        setMinQuestionAttempt(e.target.value);
    }

    const handleFailureClose = () => {
        setFailureAddQuestion(!failureAddQuestion);
    }

    const handleSuccessClose = () => {
        setSuccessAddQuestion(!successAddQuestion);
        if (microSkillId) {
            router.history.push(`/microskill-studio/form-based-challenge?c_id=${companyId}&&ms_id=${microSkillId}&&seq=${sequence}&&formId=${formId}`)
        }
        else
            router.history.push(`/add-forms?c_id=${companyId}`);
    }

    const handleChangeFormName = (e) => {
        setFormName(e.target.value);
    }

    const handleFormDescription = (e) => {
        setFormDescription(e.target.value);
    }

    const handleAssementChange = () => {
        if (isAssessMent === false) {
            const tempObj = [...formQuestion];

            tempObj.map((ques) => {
                if (ques.type !== 'SINGLE_CHOICE_QUESTION' && ques.type !== 'MULTI_CHOICE_QUESTION') {
                    ques.type = 'SINGLE_CHOICE_QUESTION';
                    ques.options = [];
                }
            })
        }

        setIsAssesment(!isAssessMent);
    }

    const handleShowScore = () => {
        setShowScore(!showScore);
    }

    const handleRandomizeQuestions = () => {
        setRandomizeQuestions(!randomizeQuestions);
    }

    const handleRandomizeAnswers = () => {
        setRandomizeAnswers(!randomizeAnswers);
    }

    const handleTimeLimit = (e) => {
        setTimeLimit(e.target.value);
    }

    const handleIncrementTime = () => {
        setTimeLimit(timeLimit + 1);
    }

    const handleDecrementTime = () => {
        if (timeLimit > 0) {
            setTimeLimit(timeLimit - 1);
        }
    }

    const handleCollapse = (idx) => {
        let tempCollapseList = [...collapseList];
        if (tempCollapseList.includes(idx)) {
            let filtered = tempCollapseList.filter(ele => ele !== idx);
            setCollapseList(filtered);
            return;
        }
        tempCollapseList.push(idx);
        setCollapseList(tempCollapseList);
    }

    return (
        loadingData ? <LoadingContainer /> : (
            <div className={styles.MainContainer}>
                <div style={{
                    width: '30%', padding: '0rem 1rem', display: 'flex',
                    flexDirection: "column", justifyContent: "space-between", rowGap: '2rem'
                }}>
                    <div>
                        <label htmlFor="FormName" className={styles.Label}>
                            Name:
                        </label>
                        <input
                            className={styles.Input_Box}
                            id="FormName"
                            name="FormName"
                            value={formName}
                            onChange={(e) => handleChangeFormName(e)}
                        />
                    </div>
                    <div>
                        <label htmlFor="FormDescription" className={styles.Label}>
                            Description:
                        </label>
                        <textarea
                            className={styles.Input_Box}
                            id="FormDescription"
                            name="FormDescription"
                            style={{ minHeight: '10rem', maxHeight: '10rem' }}
                            value={formDescription}
                            onChange={(e) => handleFormDescription(e)}
                        />
                    </div>

                    <div className={`${styles.centerFlex} ${styles.formOptionContainer}`}>
                        <div className={`${styles.centerFlex}`} onClick={handleAssementChange} style={{ cursor: 'pointer' }}>
                            Assessment
                            <Checkbox checked={isAssessMent} style={{ color: '#F4511E' }} />
                        </div>
                        <div className={`${styles.centerFlex}`} onClick={handleShowScore} style={{ cursor: 'pointer' }}>
                            Show Score to Learners
                            <Checkbox checked={showScore} style={{ color: '#F4511E' }} />
                        </div>
                        <div className={`${styles.centerFlex}`} onClick={handleRandomizeQuestions} style={{ cursor: 'pointer' }}>
                            Randomize Questions
                            <Checkbox checked={randomizeQuestions} style={{ color: '#F4511E' }} />
                        </div>
                        <div className={`${styles.centerFlex}`} onClick={handleRandomizeAnswers} style={{ cursor: 'pointer' }}>
                            Randomize Answers
                            <Checkbox checked={randomizeAnswers} style={{ color: '#F4511E' }} />
                        </div>
                    </div>

                    <div>
                        <label htmlFor='TimeLimit' className={styles.Label}>
                            Time Limit:
                        </label>
                        <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                            <div style={{ width: "50%" }}>
                                <CustomInput
                                    defaultValue={timeLimit}
                                    handleChange={(e) => handleTimeLimit(e)}
                                    withLabel={false}
                                />
                            </div>
                            <div className={`${styles.centerFlex} ${styles.timeLimitArrowContainer}`}>
                                <div className={`${styles.centerFlex} ${styles.timeLimitArrows}`}>
                                    <KeyboardArrowUpIcon onClick={handleIncrementTime} style={{ fontSize: "1rem" }} />
                                    <KeyboardArrowDownIcon onClick={handleDecrementTime} style={{ fontSize: "1rem" }} />
                                </div>
                                <div>Seconds</div>
                            </div>
                        </div>
                    </div>

                    {isAssessMent && (
                        <>
                            <div>
                                <label htmlFor='TimeLimit' className={styles.Label}>
                                    Min Score:
                                </label>
                                <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                                    <CustomInput
                                        defaultValue={minScore}
                                        handleChange={(e) => handleMinScore(e)}
                                        withLabel={false}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div>
                        <label htmlFor='TimeLimit' className={styles.Label}>
                            Minimum Questions to Attempt:
                        </label>
                        <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                            <CustomInput
                                defaultValue={minQuestionAttempt}
                                handleChange={(e) => handleMinQuestionAttempt(e)}
                                withLabel={false}
                            />
                        </div>
                    </div>

                    <div className={styles.btnBox}>
                        <Button key={"next"} variant="contained" className={styles.saveBtn} onClick={updateFormButton}>
                            NEXT
                        </Button>
                        <Button
                            key={"clear"}
                            title='Clear all'
                            className={`${styles.btn} ${styles.clearBtn}`}>
                            CLEAR & DELETE
                        </Button>
                    </div>

                </div>
                <div className={styles.FormQuestionContainer}>
                    {formQuestion.map((item, i) => (

                        <>
                            <div key={item.id} style={{ width: '95%', marginBottom: '1rem' }}>
                                <div className={styles.FormQuestionHeading} onClick={() => handleCollapse(i)}>
                                    <span>
                                        <i className={`fa-solid ${collapseList.includes(i) ? 'fa-angle-up' : 'fa-angle-down'} ${styles.dropDownIcon}`} />
                                        {!collapseList.includes(i) && <i className={`fa-solid ${item.validations.length > 0 ? "fa-circle-exclamation" : "fa-circle-check"} ${item.validations.length ? styles.unValidatedQuestionIcon : styles.validatedQuestionIcon}`} />}
                                        Question {i + 1}:
                                    </span>
                                    <div className={`${styles.centerFlex} ${styles.errorContainer}`}>
                                        {item.validations.length !== 0 && (
                                            <div className={`${styles.errorText}`} dataHover={`${item.validations}`}>
                                                <i className={`fa-solid fa-circle-exclamation ${styles.unValidatedQuestionIcon}`} />
                                                {item.validations.length === 1 ? item.validations[0] : `${item.validations.length} error occured`}
                                            </div>
                                        )}
                                        <DeleteIcon
                                            key={i}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                setShowDeleteDialog(true);
                                                setDeleteParameters({ questionIdx: i, answerIdx: undefined, type: 'Question' })
                                            }} />
                                    </div>
                                </div>
                                {collapseList.includes(i) ? <>
                                    <CustomInput
                                        id={i.toString()}
                                        defaultValue={item.question}
                                        handleChange={(e) => handleInputQuestion(e, i)}
                                        // limitText={questionValidations[i].limitText}
                                        // isExceeded={questionValidations[i].exceeded}
                                        isDisabledTextLimitContainer={true}
                                        isEmpty={item.isQuestionEmpty}
                                    />
                                    <div style={{ display: "flex", width: "100%", flexDirection: 'column', rowGap: '1rem' }}>
                                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                                            <FormControlLabel
                                                onChange={(e) => handleMandatory(e, i)}
                                                control={
                                                    <Checkbox checked={item.mandatory} style={{ padding: "0.5rem", color: "#F4511E" }} />
                                                }
                                                label={<Typography style={{
                                                    color: "#484848",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: "bold"
                                                }}>Mandatory</Typography>}
                                            />
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap: '0.5rem' }}>
                                            <Checkbox checked={item.isScore} onClick={(e) => handleScoreToBeAddedInQuestion(e, i)} style={{ color: "#F4511E", padding: '0' }} />
                                            <Typography style={{ color: "#484848", fontFamily: "Montserrat", fontWeight: "bold" }}>Score:</Typography>
                                            <CustomInput
                                                defaultValue={item.scorePerResponse}
                                                handleChange={(e) => handleScorePerResponse(e, i)}
                                                isDisabledTextLimitContainer={true}
                                                isDisabled={item.isScore ? false : true}
                                                withLabel={false}
                                            />
                                        </div>
                                    </div>

                                    <TypeQuestion
                                        id={i}
                                        handleQuestionType={handleQuestionType}
                                        QuestionType={item.type}
                                        isAssessment={isAssessMent}
                                    />

                                    {item.type === 'SINGLE_CHOICE_QUESTION' || item.type === 'MULTI_CHOICE_QUESTION' ?
                                        <ResponseQuestions
                                            questionIdx={i}
                                            answers={item.answers}
                                            typeQuestion={item.type}
                                            handleInputAnswer={handleInputAnswer}
                                            // validation={questionValidations[i].answers}
                                            handleCorrectIncorrectOption={handleCorrectIncorrectOption}
                                            handleAddAnswer={handleAddAnswer}
                                            setShowDeleteDialog={setShowDeleteDialog}
                                            setDeleteParameters={setDeleteParameters}
                                        /> : null}

                                    {item.type === 'STAR_RATING' || item.type === 'NUMBER_RATING' ?
                                        <RatingResponses
                                            questionIdx={i}
                                            formQuestion={formQuestion}
                                            setFormQuestion={setFormQuestion}
                                        /> : null}
                                </> : null}



                            </div>

                            <Divider style={{ margin: '1rem 0', width: '95%' }} />
                        </>
                    ))}

                    <Button
                        variant='contained'
                        startIcon={<AddIcon />}
                        style={{ background: "#F4511E", color: "#FFF" }}
                        disableElevation
                        onClick={handleAddQuestion}
                    >
                        Add Questions
                    </Button>

                    {
                        failureAddQuestion === true &&
                        <FailureDialog
                            setErrorDialogOpen={setFailureAddQuestion}
                            errorDialogOpen={failureAddQuestion}
                            dialogText={failedResponseText}
                            handleClick={handleFailureClose}
                        />}

                    {
                        successAddQuestion === true &&
                        <SuccessDialog
                            successDialogOpen={successAddQuestion}
                            setSuccessDialogOpen={setSuccessAddQuestion}
                            dialogText={successResponseText}
                            handleClick={handleSuccessClose}
                        />
                    }

                    {
                        showDeleteDialog === true &&
                        <DeleteDialog
                            id={deleteParameters}
                            dialogText={deleteDialogText}
                            confirmDeleteDialog={showDeleteDialog}
                            setConfirmDeleteDialog={setShowDeleteDialog}
                            handleDelete={handleDelete}
                        />
                    }
                </div>
                <div className={styles.SecondConatiner}>
                    <div className={`${styles.centerFlex}`} style={{ height: '100%' }}>
                        <img src="/images/form-assests/form-mobile.png" alt="Mobile-Preview-Form" />
                    </div>
                </div>
            </div>
        )
    )
}

export default FormsQuestion;