import React, { useState, useContext } from "react";

// mui
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

//components
import CustomButton from "../../../custom-styled/button";
import CustomInput from "../../../custom-styled/custom-input";
import Label from "../../../custom-styled/label";
import Title from "../../../title";
import Loader from "../../../custom-styled/loader";

//services
import azureServices from "../../../../services/microsoft-azure";

// //global-states
// import { GlobalStates } from '../../App';
import draglistData from "../../draglist-data";


// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     maxWidth: 1000,
//     height: 600,
//     width: '90%',
//     bgcolor: 'background.paper',
//     // border: '2px solid #000',
//     boxShadow: 24,
//     border: 'none',
//     p: 3,
//     borderRadius: '5px',
//     '& .MuiTypography-root': {
//         fontFamily: 'montserrat'
//     }
// }

const style = {
    position: 'absolute !important',
    top: 0,
    right: 0,
    // transform: 'translate(-50%, -50%)',
    minWidth: 600,
    maxWidth: 600,
    height: '100vh',
    // width: '90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    border: 'none',
    p: 3,
    borderRadius: '5px',
    '& .MuiTypography-root': {
        fontFamily: 'montserrat'
    }
}

const GenerateContentModal = ({
    open = false,
    setOpen,
    skills = [],
    selectedGame,
    list,
    setList,
    topic, setTopic,
    questions, setQuestions,
}) => {
    console.log('skills = ', skills);
    console.log('selectedGame = ', selectedGame);
    const classes = useStyles();
    // const [topic, setTopic] = useState('');
    // const [questions, setQuestions] = useState([]);
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numberOfQuestions, setNumberOfQuestions] = useState(5);



    const handleClose = () => {
        return
    }

    let prompt = `Please generate ${numberOfQuestions} multiple-choice questions based on the topic ${topic}. Ensure that the correct answer is marked as 'true' in the answers array. Provide the questions in an array of json following this format: [{"id": 0,"question": "Here goes the question 1","answers": [{"id": 0,"option": "Here goes the answer 1 for question 1","value": true},{"id": 1,"option": "Here goes the answer 2 for question 1","value": false},{"id": 2,"option": "Here goes the answer 3 for question 1","value": false},{"id": 3, "option": "Here goes the answer 4 for question 1", "value": false}] } ]`


    const handleGenerate = async (prompt) => {
        // addMatchingSkillTags(question);
        const isValid = validate();
        if (!isValid) return
        console.log('Content generation started...');
        // let template = `${topic}`
        const messages = [
            { role: "user", content: prompt },
        ];
        setLoading(true);
        let response = await azureServices.azureOpenAi(messages);
        let content = response?.choices[0]?.message?.content;
        console.log('content  = ', content);
        console.log('typeof content  = ', typeof content);
        let cleanedString = content.replace(/\n/g, '');
        console.log('cleanedString  = ', cleanedString);
        let parsed = JSON.parse(cleanedString);
        setResult(response.choices)
        let questions = [...parsed];
        for (let i = 0; i < questions.length; i++) {
            let question = questions[i].question;
            const skillTag = await addMatchingSkillTags(question);
            questions[i].skillTag = skillTag;
        }
        console.log('questions  = ', questions);
        setQuestions(questions);
        setLoading(false);
        
        let tempList = [...list]
        let stringified = JSON.stringify(draglistData[selectedGame.type][0]);
        let parsedData = JSON.parse(stringified);
        console.log('parsedData  = ', parsedData);
        for (let i = 0; i < questions.length; i++) {
            let tempObj = {
                ...parsedData,
                id: tempList.length,
                questionId: tempList.length,
                question: questions[i].question,
                sequence: tempList.length + 1,
                skillId: getSkillId(questions[i].skillTag)
            }
            let tempAnswers = [...questions[i].answers];
            for (let j = tempAnswers.length; j < 4; j++) {
                tempAnswers.push({
                    id: j,
                    option: "",
                    value: true,
                    sequence: j + 1
                })
            }
            tempObj.answers = tempAnswers;
            tempList.push(tempObj)
        }
        setList(tempList);

    }

    const getSkillId = (skill) => {
        if (skill === 'null' || !skill || !skill.length) return ''
        let data = skills.find(s => s.skill == skill)
        console.log('data = ', data);
        return data?.id ? data?.id : ''
    }

    const validate = () => {
        let isValid = true;
        if (!topic.length) isValid = false;
        return isValid;
    }

    const handleTopicChange = (e) => {
        let value = e.target.value;
        setTopic(value);
    }

    const addMatchingSkillTags = async (question = '') => {
        let skillList = skills.map(ele => ele.skill);
        let skillPrompt = `Provide the skill tag from the array ${skillList} that best corresponds to the question '${question}' If none of the skills match the question, return null. Your response should only include the skill tag from the array or null.`
        const messages = [
            { role: "user", content: skillPrompt },
        ];
        let response = await azureServices.azureOpenAi(messages);
        let content = response?.choices[0]?.message?.content;
        console.log('content  = ', content);
        return content;
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style} className={classes.box}>
                <Title titleText={"CREATE CONTENT USING AI - MCQ"} fs="1rem" padding="0" />
                <CloseIcon sx={{ position: 'absolute', top: 12, right: 12, fontSize: '2rem', cursor: 'pointer', color: '#f4511e' }} onClick={() => setOpen(false)} />
                <hr />
                {/* <Label labelText={"Topic:"} /> */}
                <div className={classes.inputBtnWrapper}>
                    <CustomInput
                        value={topic}
                        handleChange={handleTopicChange}
                        height="2.5rem"
                        width="48rem"
                    />
                    <div className={classes.btnSelectWrapper}>
                        <select defaultValue={5} className={classes.selectMenu} onChange={(e) => setNumberOfQuestions(e.target.value)}>
                            <option value={'default'} disabled>Select number of questions</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                        <CustomButton
                            fs="0.8rem"
                            fw={700}
                            btnText="GENERATE"
                            handleClick={() => handleGenerate(prompt)}
                            width={"10rem"}
                            isDisabled={loading}
                        />
                    </div>


                </div>
                <div className={classes.contentWrapper}>
                    {
                        loading ?
                            <div className={classes.loaderBox}>
                                <Loader />
                            </div> :
                            questions.map(ques => (
                                <div className={classes.questionWrapper}>
                                    <h3 key={ques?.id}>{ques?.question}</h3>
                                    <h4>Skill: {ques.skillTag === 'null' ? 'No matching skill found' : ques.skillTag}</h4>
                                    {
                                        ques?.answers?.map(ans => (
                                            <div>
                                                <p key={ans?.id} className={classes.answerWrapper}>
                                                    <span>{ans?.id + 1}:</span>
                                                    <span>{ans?.option}</span>
                                                    <span style={{ fontWeight: 800, fontFamily: 'monospace' }}>{ans?.value.toString()}</span>
                                                </p>
                                            </div>
                                        ))
                                    }

                                </div>
                            ))
                    }
                </div>

            </Box>
        </Modal>
    )
}

export default GenerateContentModal;

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    inputBtnWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column'
    },
    contentWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        margin: '1rem',
        overflowY: 'auto'
    },
    loaderBox: {
        minHeight: '20vh',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
    },
    questionWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        '& *': {
            fontFamily: 'monospace'
        }
    },
    answerWrapper: {
        display: 'flex',
        gap: '1rem',
    },
    btnSelectWrapper: {
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center'
    },
    selectMenu: {
        height: '2.5rem',
        width: '5rem',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        backgroundColor: '#fff',
        color: '#000'
    },
}));