import React, { useEffect, useRef, useState } from 'react';

// mui
import { Button } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

//styles
import useStyles from './index.styles';


const LinkCard = ({
    link,
    setLink,
}) => {

    const classes = useStyles();
    const capture = useRef();
    const iframeRef = useRef(null);

    const handleRemoveImage = () => {
        setLink('');
    }

    return (
        <div className={`${classes.card} ${classes.aspect1}`} 
            style={{
                padding: link.length > 1 ? '0' : '1rem',
            }}
            ref={capture}
        >
            <div className={classes.linkBox} >
                { link.length > 0 &&
                    <span className={classes.deleteIcon}>
                        <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Image" onClick={handleRemoveImage} />
                    </span>
                }
                {
                    link 
                    ? <iframe
                        height="100%"
                        width="100%"
                        src={link} 
                        className={classes.iframe}
                        ref={iframeRef}
                    />
                    : <Button 
                        disabled={true}
                        variant='outlined' 
                        component="label"
                        className={classes.uploadContainer}
                    >
                        <LinkIcon sx={{fontSize:"5rem"}}/>
                    </Button>
                }
            </div>
        </div>
    );
};

export default LinkCard;