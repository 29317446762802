import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import dayjs from 'dayjs';

//components
import Title from '../../../title'
import Label from '../../../custom-styled/label';
import MultipleFilter from '../multiple-filter';
import EmailReports from '../email-report';
import DatePicker from '../date-picker';

//utils
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';

//services
import reportsServices from '../../../../services/reports';

//global-states
import { GlobalStates } from '../../../../App';

import useStyles from '../../index.styles';
import { DEMO_COMP_ID } from '../../../../constants';

const LicenceReport = ({
    setBackdrop,
    _resetDateRange,
    active = false,
    tabIdx,
    handleSelectTab }) => {
    const commonStyles = useStyles({ active });
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const today = dayjs();
    const [payload, setPayload] = useState({ companyId: [companyId] });
    const [dateRange, setDateRange] = useState([null, null]);
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    let minDate = dayjs(new Date('2022-01-01'));
    let admin=JSON.parse( localStorage.getItem("admin"));
    let adminUsername=admin.username;
    let isInternalAdmin = adminUsername.includes('internal') || companyId === DEMO_COMP_ID

    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            let newPayload = {
                ...payload,
                companyId: companyId,
                flag: 'filter',
                fromDate: dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'),
                toDate: dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS')
            }
            setPayload(newPayload);
        }

        setDateRange(newValue);
    }

    const handleResetDateRange = () => {
        let { newPayload } = _resetDateRange(payload);
        setPayload(newPayload);
        setDateRange([null, null]);
    }

    const handleDownloadLicenceReport = async () => {
        if (!active) return
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: [companyId],
            flag: "excel",
        };

        const response = await reportsServices.fetchLicenceReport(downloadAsExcelPayload, { startIndex: 0 });
        if (response.status === 200) {
            let fileUrl = response?.data?.data?.file?.Location;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }



    const handleEmailLicenceReport = async () => {
        // console.log('email id = ', emailId);
        // console.log('is valid emailId = ', commonUtil.isValidEmail(emailId));
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        let emailReportPayload = {
            ...payload,
            companyId: companyId,
            flag: "email",
            emailId: emailId
        };
        // console.log('emailReportPayload = ', emailReportPayload);
        const response = await reportsServices.fetchLicenceReport(emailReportPayload, { startIndex: 0 });
        console.log('emailReport response = ', response);
        if (response.status === 200 || isEmpty(response)) {
            let message = `Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        }else if(response.status === 400){
            globalStates.handleToast(true, response.data.errors[0].message, "error");
            setSendingEmail(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }

    }

    return (
        <div id={`#${tabIdx}-mis-reports`} className={`${commonStyles.card} ${!active && commonStyles.inActiveCard}`} onClick={(e) => handleSelectTab(e, tabIdx)}>
            <Title titleText={"Licence Report"} padding='0rem' />

            <div className={commonStyles.dateRangeWrapper} style={{ minHeight: '2.2rem' }}>
                {/* <Label labelText={'Date Range:'} />
                <DatePicker
                    value={dateRange}
                    maxDate={today}
                    minDate={minDate}
                    handleDateChange={handleDateChange}
                    handleReset={handleResetDateRange}
                    selectedShortcut={selectedShortcut}
                    setSelectedShortcut={setSelectedShortcut}
                    disabled={!active}
                /> */}
            </div>

            <div className={commonStyles.filtersContainer} style={{ minHeight: '2.2rem' }}>
                {/* <MultipleFilter
                    label={"Demographic Filters"}
                    title={"Select Demographics"}
                    filterList={demographicFilter}
                    setFilterList={setDemographicFilter}
                    handleSaveFilter={handleApplyDemographicFilter}
                    handleResetFilter={handleResetDemographicFilter}
                    customSelectAll={_selectAllSubmenuDF}
                    customhandleClick={_handleSubmenuItemDF}
                    handleSearch={handleDemographicSearch}
                    disabled={!active}
                />
                <MultipleFilter
                    label={"Content Filters"}
                    title={"Select Content"}
                    filterList={contentFilter}
                    setFilterList={setContentFilter}
                    handleSaveFilter={handleApplyContentFilter}
                    handleResetFilter={handleResetContentFilter}
                    handleSearch={_handleContentSearch}
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    customSelectAll={_selectAllSubmenuCF}
                    customhandleClick={_handleSubmenuItemCF}
                    disabled={!active}
                /> */}

            </div>



            <div className={commonStyles.actionIcons}>
                {isInternalAdmin ?
                    <EmailReports
                        open={emailDialog}
                        setOpen={setEmailDialog}
                        loading={sendingEmail}
                        handleChange={(e) => setEmailId(e.target.value)}
                        handleClick={handleEmailLicenceReport} />
                    :
                    <img src='/images/analytics/download-icon.svg' alt='' title='Download Licence Report' onClick={handleDownloadLicenceReport} />
                }
            </div>

        </div>
    )

}

export default LicenceReport;

