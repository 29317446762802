import React, { useState, useEffect, useContext } from "react";

//mui
import { Button, Checkbox, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ButtonBase from '@mui/material/ButtonBase';
import { Close } from "@mui/icons-material";

//services
import skillServices from "../../services/skills";

//utils
import useQuery from "../../utils/useQuery";

//styles
import useStyles from "./index.styles";

//contexts
import { GlobalStates } from "../../App";



const TagsInput = ({ microskillTags, setMicroskillTags }) => {
  const styles = useStyles();

  const query = useQuery();
  const c_id = query.get('c_id')
  const ms_id = query.get('ms_id');

  const [globalStates] = useContext(GlobalStates);
  const [manage, showManage] = useState(false);
  const [showOption, setShowOption] = useState(true);

  const [tagsList, setTagsList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let inputField = document.getElementById("#input-field");
    if (inputField) inputField.focus();
  }, [manage])

  const fetchSkills = async () => {
    const respData = await skillServices.fetchSkills(c_id);
    if (respData.status === 200 || respData.status === 201) {
      const temp = respData.data.data.filter(tag => tag.active !== false);
      const tempSkill = temp.map(tag => ({
        ...tag, type: 'default'
      }))

      setTagsList(tempSkill);
    }
  }

  const checkIfExactSkillExists = (value, skills = []) => {
    const filtered = skills.filter(skill => skill.skill.toLowerCase() === value.toLowerCase());
    return filtered.length;
  }

  const fetchSearchSkill = async (value) => {
    const respData = await skillServices.fetchSkills(c_id, value);
    if (respData.status === 200 || respData.status === 201) {
      if (respData.data.data.length > 0) {
        const temp = respData.data.data.filter(tag => tag.active === true) || [];
        const tempSkill = [];
        temp.forEach(tag => {
          tempSkill.push({ ...tag, type: 'default' })
        })

        if (!checkIfExactSkillExists(value, tempSkill)) {
          tempSkill.push({ id: tempSkill.length + 1, skill: value, type: 'new' });
        }
        setTagsList(tempSkill);
      }
      else {
        setTagsList([{ id: 1, skill: value, type: 'new' }])
      }
    }
  }

  const createSkill = async (value) => {
    const respData = await skillServices.createSkill({ companyId: c_id, name: value });
    if (respData.status === 200 || respData.status === 201) {
      setMicroskillTags([...microskillTags, respData.data.data]);
      addMicroskillSkill(respData.data.data.id);
      setInputValue("");
      let inputField = document.getElementById("#input-field");
      if (inputField) inputField.focus();

      fetchSkills();
    }
  }

  const deleteSkill = async (id) => {
    let payload = {
      companyId: c_id,
      microskillId: ms_id,
      skillId: id
    }
    const respData = await skillServices.removeMicroskillSkill(payload);
    if (respData.status === 200 || respData.status === 201) {
      fetchSkills();
    }
  }

  const addMicroskillSkill = async (id) => {
    let payload = {
      companyId: c_id,
      microskillId: ms_id,
      skillId: id
    }

    const respData = await skillServices.addMicroskillSkill(payload);
    if (respData.status === 200 || respData.status === 201) {

      return;
    }
  }

  const fetchMicroskillSkill = async () => {
    if (!ms_id) return
    const respData = await skillServices.getMicroskillSkill(ms_id);
    if (respData.status === 200 || respData.status === 201) {
      const temp = respData.data.data.map(item => { return item.Skill });
      console.log(temp);
      setMicroskillTags(temp)
    }
  }

  useEffect(() => {
    if (manage) {
      fetchSkills();
    }
  }, [manage])

  useEffect(() => {
    fetchMicroskillSkill();
  }, [])

  const handleSelectSkill = (e, tag) => {
    if (tag.type === 'new') {
      createSkill(tag.skill);
      return;
    }

    let filterTag = microskillTags.filter(item => item.id === tag.id);
    if (filterTag.length > 0) {
      filterTag = microskillTags.filter(item => item.id !== tag.id);
      setMicroskillTags([...filterTag]);
      deleteSkill(tag.id)
    } else {
      setMicroskillTags([...microskillTags, tag]);
      addMicroskillSkill(tag.id);
    }
  }

  const handleFilterSkills = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === '') {
      fetchSkills();
      return;
    }

    fetchSearchSkill(value);
  }

  const handleDeleteTag = (e, id) => {
    const tempTag = microskillTags.filter(tag => tag.id != id);
    setMicroskillTags(tempTag);
    deleteSkill(id);
  }

  const handleManageTags = () => {
    console.log('ms_id = ', ms_id);
    if (!ms_id) {
      globalStates.handleToast(true, 'Please create the Microskill first to add skill tags to it.', 'error');
      return
    }
    showManage(true)
  }

  return (
    <div style={{ width: '100%' }}>

      <div className={`${styles.centerFlex} ${styles.tagsContainer}`}>
        {!manage && (
          <ButtonBase
            id="basic-button"
            className={`${styles.chip}`}
            onClick={handleManageTags}>
            <h6> {microskillTags.length ? "Manage Skill Tags" : "Add Skill Tags"} </h6>
            <IconButton
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '0.7rem',
                color: '#ffffff',
                padding: 0,
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }}
            >
              <AddIcon />
            </IconButton>

          </ButtonBase>
        )}
        {microskillTags.map(skill => (
          <div
            key={skill.id}
            className={`${styles.centerFlex} ${styles.selectedTag}`}
          >
            <div style={{
              fontSize: '0.7rem',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              userSelect: 'none'
            }}>{skill.skill}</div>
            <Close style={{
              marginLeft: "0.4rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
              onClick={(e) => handleDeleteTag(e, skill.id)}
            />
          </div>
        ))}
      </div>



      {manage && (
        <div style={{ width: '50%', position: 'relative', }}
        >
          <Close style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            border: '1px solid #f4511e',
            borderRadius: '50%',
            cursor: 'pointer',
            fontSize: '1.6rem',
            color: '#f4511e',
            backgroundColor: '#fff',
            zIndex: 1
          }}
            onClick={() => showManage(false)}
          />
          <TextField
            id="#input-field"
            value={inputValue}
            sx={{ width: '100%' }}
            placeholder="Search Skills"
            onChange={handleFilterSkills}
          />

          {showOption && (
            <div className={`${styles.optionContainer}`}>
              {tagsList.length === 0 && <div
                className={`${styles.centerFlex} ${styles.options}`}
              >
                No Skills Found
              </div>}
              {tagsList.map(tag => (
                <div
                  key={tag.id}
                  className={`${styles.centerFlex} ${styles.options}`}
                  onClick={(e) => handleSelectSkill(e, tag)}
                >
                  {tag.type === 'default' && <Checkbox checked={microskillTags.length > 0 && microskillTags.find(item => item.id === tag.id)} style={{ color: '#F4511E' }} />}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
                    <div>{tag.skill}</div>
                    {tag.type === 'new' && <Button style={{ backgroundColor: '#F4511E', color: '#FFF' }}>Add Skill</Button>}
                  </div>
                </div>
              ))}
            </div>
          )}

        </div>
      )}

    </div>
  )
}

export default TagsInput;