import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Label from '../../../custom-styled/label';
import isEmpty from '../../../../utils/isEmpty';

const useStyles = makeStyles((theme) => ({
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
    frequencyButton: {
        margin: "5px 10px",
        color: 'rgba(112, 112, 112, 0.9)',
        textTransform: "capitalize",
        "&.selected": {
            backgroundColor: "#ff6e40",
            color: "white",
        },
    },
    dialogModal: {
        justifyContent: "center",
    },
}));

const SetReportScheduleModal = ({ open, templateName, setTemplateName, onClose, emailArray, setEmailArray, startDate, setStartDate, endDate, setEndDate, scheduleTime, setScheduleTime, frequency, setFrequency, reportPeriod, setReportPeriod, handleSchedule, selectedTemplate, isReportHistory = false, isScheduleUpdate = false }) => {
    const classes = useStyles();
    const [frequencyArray, setFrequencyArray] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];


    useEffect(() => {
        if (!isEmpty(startDate) && !isEmpty(endDate)) {
            const dateDifference = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));

            if (dateDifference >= 90) {
                setFrequencyArray(["Daily", "Weekly", "Fortnightly", "Monthly", "Quarterly"]);
            } else if (dateDifference >= 30) {
                setFrequencyArray(["Daily", "Weekly", "Fortnightly", "Monthly"]);
            } else if (dateDifference >= 15) {
                setFrequencyArray(["Daily", "Weekly", "Fortnightly"]);
            } else if (dateDifference >= 7) {
                setFrequencyArray(["Daily", "Weekly"]);
            } else if (dateDifference >= 1) {
                setFrequencyArray(["Daily"]);
            } else {
                setFrequencyArray([]);
            }
        }
    }, [startDate, endDate]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <div className={classes.modalHeader}>
                    <h2>Set Report Schedule</h2>
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon style={{ color: '#ff5722' }} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={12}>
                        {(isEmpty(selectedTemplate) && !isReportHistory) && <TextField
                            label="Template Name"
                            fullWidth
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: '#ff5722',
                                    '&.Mui-focused': {
                                        color: '#ff5722',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                },
                            }}
                        />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            fullWidth
                            value={emailArray}
                            onChange={(e) => setEmailArray(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: '#ff5722',
                                    '&.Mui-focused': {
                                        color: '#ff5722',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                },
                            }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Set Date From"
                            type="date"
                            fullWidth
                            value={startDate || ""}
                            onChange={(e) => { setStartDate(e.target.value); setEndDate(null) }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: currentDate, // Pass max as part of inputProps
                                max: endDate && endDate,
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: '#ff5722',
                                    '&.Mui-focused': {
                                        color: '#ff5722',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#000',
                                    '&:valid': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit-fields-wrapper': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit-month-field, &::-webkit-datetime-edit-day-field, &::-webkit-datetime-edit-year-field': {
                                        color: '#000',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="To"
                            type="date"
                            fullWidth
                            value={endDate || ""}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: startDate ? startDate : currentDate,   // Pass min as part of inputProps
                            }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: '#ff5722',
                                    '&.Mui-focused': {
                                        color: '#ff5722',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#000',
                                    '&:valid': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit-fields-wrapper': {
                                        color: '#000',
                                    },
                                    '&::-webkit-datetime-edit-month-field, &::-webkit-datetime-edit-day-field, &::-webkit-datetime-edit-year-field': {
                                        color: '#000',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Set Time"
                            type="time"
                            fullWidth
                            value={scheduleTime}
                            onChange={(e) => setScheduleTime(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: '#ff5722',
                                    '&.Mui-focused': {
                                        color: '#ff5722',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff5722',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            !isEmpty(frequencyArray) && (
                                <>
                                    <label style={{ fontSize: '1rem', fontWeight: '600' }}>Set Frequency</label>
                                    <RadioGroup row style={{ marginTop: '5px' }}>
                                        {frequencyArray.map(
                                            (freq) => (
                                                <Button
                                                    key={freq}
                                                    variant={frequency === freq ? "contained" : "outlined"}
                                                    className={`${classes.frequencyButton} ${frequency === freq ? "selected" : ""}`}
                                                    onClick={() => setFrequency(freq)}
                                                    style={{ borderColor: '#ff5722' }}
                                                >
                                                    {freq}
                                                </Button>
                                            )
                                        )}
                                    </RadioGroup>
                                </>
                            )
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <label style={{ fontSize: '1rem', fontWeight: '600' }}>Set Data Period</label>
                        <RadioGroup row style={{ marginTop: '5px' }}>
                            {["Current Month", "Last Month", "Three Months", "Six Months"].map(
                                (reportP) => (
                                    <Button
                                        key={reportP}
                                        variant={reportP === reportPeriod ? "contained" : "outlined"}
                                        className={`${classes.frequencyButton} ${reportP === reportPeriod ? "selected" : ""}`}
                                        onClick={() => setReportPeriod(reportP)}
                                        style={{ borderColor: '#ff5722' }}
                                    >
                                        {reportP}
                                    </Button>
                                )
                            )}
                        </RadioGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogModal}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSchedule()}
                    style={{
                        background: isEmpty(emailArray) || isEmpty(startDate) || isEmpty(endDate) || isEmpty(scheduleTime) || isEmpty(frequency) ? "#d3d3d3" : "#ff5722",
                        color: isEmpty(emailArray) || isEmpty(startDate) || isEmpty(endDate) || isEmpty(scheduleTime) || isEmpty(frequency) ? "#8a8a8a" : "#fff",
                        textTransform: 'capitalize',
                    }}
                    disabled={isEmpty(emailArray) || isEmpty(startDate) || isEmpty(endDate) || isEmpty(scheduleTime) || isEmpty(frequency)}
                    sx={{
                        "&.Mui-disabled": {
                            backgroundColor: "#e0e0e0", // Gray background for disabled state
                            color: "#9e9e9e", // Dimmed text color
                        },
                    }}
                >
                    {isScheduleUpdate ? "Update Schedule" : "Set Schedule"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SetReportScheduleModal;