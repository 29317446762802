import React, { useState, useEffect, useContext } from 'react';

//mui
import AddIcon from '@mui/icons-material/Add';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';


// import ViaDemographic from '../../../zw-user-launch/components/via-demographic';
import CustomSelect from '../../../custom-styled/custom-select';

//services
import reportsServices from '../../../../services/reports';
import isEmpty from '../../../../utils/isEmpty';

import { GlobalStates } from '../../../../App';




import {
    Box,
} from '@mui/material';
import useStyles from './index.styles';
import Label from '../../../custom-styled/label';




const RowHeadersUI = ({ demographicFilter, setDemographicFilter, mapping, setMapping, setAllDemographicData, allDemographicData, selectedDemographicColumns, setSelectedDemographicColumns, selectedTemplate, isTemplateEdited, setIsTemplateEdited, setBackdrop, setDefaultDemographicData, }) => {
    const query = useQuery();
    const companyId = query.get('c_id');
    // const [demographicFilter, setDemographicFilter] = useState([]);
    const [allDemographicObjects, setAllDemographicObjects] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    let mappingObject = commonUtil.objectify(mapping, "name");
    // const [selectedDemographicColumns, setSelectedDemographicColumns] = useState([]);
    const classes = useStyles();

    const columnsDataObject = commonUtil.objectify(columnsData, 'id');
    const getMappingDetails = (value) => mappingObject[value];
    const [globalStates, setGlobalStates] = useContext(GlobalStates);

    useEffect(() => {
        if (isEmpty(allDemographicData)) {
            fetchDemographics(companyId);
        }
    }, [])


    const fetchDemographics = async (companyId) => {
        const response = await reportsServices.getDemographics(companyId);
        if (response && response.status === 200) {
            let distinctColumnValues = response.data.data.distinctColumnValues;
            setAllDemographicData(distinctColumnValues);
            updateToDefaultDF(distinctColumnValues);
            let resData = response.data.data;
            if (resData?.mapping && !mapping.length) {
                let mapping = resData?.mapping;
                mapping.forEach(element => {
                    element.id = element.table_column;
                    element.name = element.excel_column;
                });
                setMapping(mapping);
            }
        }
    }
    const updateToDefaultDF = (distinctColumnValues) => {
        if (distinctColumnValues.length) {
            let tempColumnsData = []
            let tempDemographicFilters = [];
            distinctColumnValues.forEach(ele => {
                let tempSubmenu = [];
                ele.values.forEach(item => {
                    if (item) tempSubmenu.push({ id: item, name: item })
                });
                let tempObj = {
                    id: ele.columnName,
                    name: ele.columnName,
                    subMenu: tempSubmenu,
                    selected: [],
                    total: tempSubmenu.length
                };
                tempDemographicFilters.push(tempObj);
                tempColumnsData.push({
                    id: ele.columnName,
                    name: ele.columnName,
                });

            })
            setColumnsData(tempColumnsData);
            setDemographicFilter(tempDemographicFilters);
            setDefaultDemographicData(tempDemographicFilters);
        }
    }

    const selectDemographicColumn = (item) => {
        if (!isTemplateEdited && !isEmpty(selectedTemplate)) {
            setIsTemplateEdited(true)
        }
        if (selectedDemographicColumns.includes(item.id)) {
            let filtered = selectedDemographicColumns.filter(ele => ele !== item.id);
            setSelectedDemographicColumns(filtered);
            const newDemographicFilter = demographicFilter.map(curr => {
                let temp = { ...curr };
                if (temp.id === item.id) temp.selected = [];
                return temp
            })
            setDemographicFilter(newDemographicFilter);
        } else {
            setSelectedDemographicColumns([...selectedDemographicColumns, item.id])
        }
    }

    const _selectAllSubmenuDF = (newSelectedFilter, filterList) => {
        let templist = [...filterList];
        let isSelectAll = newSelectedFilter.selected.length === newSelectedFilter.subMenu.length;

        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === newSelectedFilter.id) {
                let newSelected = []
                if (!isSelectAll) {
                    newSelected = newSelectedFilter.subMenu.map(ele => ele.id)
                }
                let newObj = { ...templist[i], selected: newSelected };
                templist[i] = newObj;
                break;
            }

        }
        let selectedDemographics = getSelectedDemographics(templist);
        fetchFilteredDemographics(companyId, { withBackdrop: true, selectedDemographics: selectedDemographics, newDemographicFilter: templist });
    }

    const getSelectedDemographics = (demographicFilter) => {
        let selectedFilters = [];
        for (let i = 0; i < demographicFilter.length; i++) {
            if (demographicFilter[i].selected.length) {
                const columnName = getMappingDetails(demographicFilter[i].id)["table_column"];
                const excelColumn = getMappingDetails(demographicFilter[i].id)["excel_column"];
                let filterObj = { columnName: columnName, values: demographicFilter[i].selected, excelColumn: excelColumn }
                selectedFilters.push(filterObj);
            }
        }
        return selectedFilters;
    }
    const _handleSubmenuItemDF = (item, selectedfilter, filterList) => {
        let templist = [...filterList];
        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === selectedfilter.id) {
                let newObj = { ...templist[i] };
                let selected = templist[i].selected;
                if (selected.includes(item.id)) {
                    let filtered = selected.filter(ele => ele !== item.id);
                    newObj.selected = filtered;
                    templist[i] = newObj;
                    break;
                } else {
                    newObj.selected = [...selected, item.id];
                    templist[i] = newObj;
                    break;
                }
            }

        }
        let selectedDemographics = getSelectedDemographics(templist);
        fetchFilteredDemographics(companyId, { withBackdrop: true, selectedDemographics: selectedDemographics, newDemographicFilter: templist });
    }

    const fetchFilteredDemographics = async (companyId, { withBackdrop = false, selectedDemographics = [], newDemographicFilter = [] }) => {
        let parsedSelectedFilterCopy;
        if (globalStates?.selectedFilterCopy !== undefined) {
            parsedSelectedFilterCopy = JSON.parse(globalStates.selectedFilterCopy);
        }

        if (withBackdrop) setBackdrop(true);

        if (!selectedDemographics.length) {
            await fetchDemographics(companyId, { withLoader: false });
            if (withBackdrop) setBackdrop(false);
            return
        } else {
            let payload = {
                companyId: companyId,
                demographics: selectedDemographics
            }
            const response = await reportsServices.getFilteredDemographics(payload);
            if (response && response.status === 200) {
                let distinctColumnValues = response.data.data.distinctColumnValues;
                let tempDemographicFilters = [];
                distinctColumnValues.forEach(ele => {
                    let tempSubmenu = [];
                    ele.values.forEach(item => {
                        if (item) tempSubmenu.push({ id: item, name: item })
                    });
                    let tempObj = { id: ele.columnName, name: ele.columnName, subMenu: tempSubmenu, selected: [], total: tempSubmenu.length };
                    tempDemographicFilters.push(tempObj);
                })
                for (let i = 0; i < tempDemographicFilters.length; i++) {
                    let ele = { ...tempDemographicFilters[i] };
                    let selectedOnes = newDemographicFilter.filter(item => item.name === ele.name && item.selected.length);
                    if (selectedOnes.length) {
                        tempDemographicFilters[i] = { ...ele, selected: selectedOnes[0].selected, subMenu: selectedOnes[0].subMenu, total: selectedOnes[0].total };
                    }
                }

                setDemographicFilter(tempDemographicFilters);
                let demographicObjects = commonUtil.objectify(tempDemographicFilters, "id");
                if (!isEmpty(parsedSelectedFilterCopy) && demographicObjects.hasOwnProperty(parsedSelectedFilterCopy?.id)) {
                    demographicObjects[parsedSelectedFilterCopy.id] = { ...demographicObjects[parsedSelectedFilterCopy.id], subMenu: parsedSelectedFilterCopy.subMenu }
                }
                setAllDemographicObjects(JSON.stringify(demographicObjects));
            }

        }
        if (withBackdrop) setBackdrop(false);
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {
                selectedDemographicColumns.length && !isEmpty(demographicFilter) ?
                    <div className={classes.selectMenusWrapper}>
                        {
                            selectedDemographicColumns.map((ele, i) => {
                                const selectedfilterIndex = demographicFilter.findIndex((data) => data.id === ele);
                                const selectedfilterData = demographicFilter[selectedfilterIndex]
                                return (
                                    <>
                                        <Label labelText={selectedfilterData.name} fs='0.8rem' />
                                        <CustomSelect
                                            defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: `Select ${selectedfilterData.name}`, selected: selectedfilterData.selected, dataObject: commonUtil.objectify(selectedfilterData.subMenu, 'id') })}
                                            autoClose={false}
                                            withSearch={true}
                                            withSelectAllOption={true}
                                            listData={selectedfilterData.subMenu}
                                            selected={selectedfilterData.selected}
                                            handleSelect={(item) => _handleSubmenuItemDF(item, selectedfilterData, demographicFilter)}
                                            customSelectAll={true}
                                            _selectAll={() => _selectAllSubmenuDF(selectedfilterData, demographicFilter)}
                                            width="230px"
                                        />
                                    </>
                                )
                            })
                        }
                    </div>
                    : null
            }

            <div className={classes.addContentBtn}>
                <AddIcon /> Add Demographic
                <CustomSelect
                    hideBaseBtn={true}
                    defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Demographic', selected: selectedDemographicColumns, dataObject: columnsDataObject })}
                    withSelectAllOption={false}
                    listData={columnsData}
                    selected={selectedDemographicColumns}
                    handleSelect={selectDemographicColumn}
                />
            </div>
        </Box>
    );
};

export default RowHeadersUI;