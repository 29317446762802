import React, { useEffect, useState } from 'react'

//mui
import MuiCheckbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';

//components
import Title from '../../../title';
import CustomButton from '../../../custom-styled/button';

//styles
import useStyles from "./index.styles";
import isEmpty from '../../../../utils/isEmpty';
import { useRef } from 'react';

import { GlobalStates } from '../../../../App';
import { useContext } from 'react';

let defaultMessage = 'No Data Available.'



const MultipleFilter = ({
    isFilterOpen = false,
    setIsFilterOpen = () => { },
    label = "Content Filters",
    title = "Select Content",
    withSearch = true,
    // filterData = [],
    filterList = [],
    setFilterList,
    customhandleClick = null,
    customSelectAll = null,
    withSelectAll = true,
    emptyListMessage = defaultMessage,
    handleSaveFilter,
    handleResetFilter,
    handleSearch,
    column1Checkbox = false,
    disabled = false
}) => {
    // console.log('filterList = ', filterList );
    const styles = useStyles();
    const [globalStates, setGlobalStates] = useContext(GlobalStates)
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [filterList, setFilterList] = useState([]);
    // const defaultSelectedFilter = filterList.length ? filterList[0] : { id: null,name: "", subMenu:[], selected: [] }
    // const [searchValue, setSearchValue] = useState('');
    const [selectedfilter, setSelectedFilter] = useState({ id: null, name: "", subMenu: [], selected: [], total:0 });
    const [message, setMessage] = useState(emptyListMessage);

    const searchInputRef = useRef();

    useEffect(() => {
        if (filterList?.length && selectedfilter.id) {
            for (let i = 0; i < filterList.length; i++) {
                if (filterList[i].id === selectedfilter.id) {
                    setSelectedFilter({ ...selectedfilter, subMenu: filterList[i].subMenu })
                    break
                }
            }
        }


    }, [filterList])

    useEffect(() => {
        if (open) {
            setSelectedFilter(filterList[0])
        }
    }, [open])


    const handleLocalSearch = (e) => {
        let value = e.target.value;
        handleSearch(value, selectedfilter);
    }

    const handleSelectAll = () => {
        let isSelectAll = selectedfilter.subMenu.length === selectedfilter.selected.length;
        let templist = [...filterList];
        if (customSelectAll !== null) {
            let newSelectedFilter;
            // for (let i = 0; i < templist.length; i++) {
            //     if (templist[i].id === selectedfilter.id) {
            //         if (isSelectAll) {
            //             newSelectedFilter = { ...selectedfilter, selected: [] };
            //             setSelectedFilter({ ...selectedfilter, selected: [] });
            //             break
            //         } else {
            //             let all = selectedfilter.subMenu.map(ele => ele.id);
            //             console.log('all = ', all);
            //             newSelectedFilter = { ...selectedfilter, selected: all };
            //             setSelectedFilter({ ...selectedfilter, selected: all });
            //             break
            //         }
            //     }
            // }
            // console.log('isSelectAll = ', isSelectAll);
            if (isSelectAll) {
                newSelectedFilter = { ...selectedfilter, selected: [] };
                setSelectedFilter({ ...selectedfilter, selected: [] });

            } else {
                let all = selectedfilter.subMenu.map(ele => ele.id);
                // console.log('all = ', all);
                newSelectedFilter = { ...selectedfilter, selected: all };
                setSelectedFilter({ ...selectedfilter, selected: all });

            }
            customSelectAll(newSelectedFilter, filterList);
            return
        }
        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === selectedfilter.id) {
                if (isSelectAll) {
                    // setSelected([]);
                    templist[i].selected = [];
                    setFilterList(templist);
                    setSelectedFilter({ ...selectedfilter, selected: [] });
                    break
                } else {
                    let all = selectedfilter.subMenu.map(ele => ele.id);
                    // console.log('all = ', all);
                    templist[i].selected = all;
                    setFilterList(templist);
                    setSelectedFilter({ ...selectedfilter, selected: all });
                    break
                }
            }
        }


    };

    const _selectFilter = (item) => {
        // console.log('_selectFilter item = ', item);
        // console.log('prev selectedfilter = ', selectedfilter);
        // console.log('searchInputRef = ', searchInputRef.current.value);
        if (searchInputRef?.current?.value?.length) {
            searchInputRef.current.value = "";
            handleSearch("", selectedfilter);
        }
        setSelectedFilter(item);
        const subMenuDiv = document.getElementById('sub-menu');
        subMenuDiv.scrollTop = 0;
    };

    const handleClick = (item) => {
        // console.log('handleClick item = ', item);
        let templist = [...filterList];
        if (customhandleClick !== null) {
            // for (let i = 0; i < templist.length; i++) {
            //     if (templist[i].id === selectedfilter.id) {
            //         let selected = templist[i].selected;
            //         if (selected.includes(item.id)) {
            //             let filtered = selected.filter(ele => ele !== item.id);
            //             setSelectedFilter({ ...selectedfilter, selected: filtered });
            //             break
            //         } else {
            //             setSelectedFilter({ ...selectedfilter, selected: [...selected, item.id] });
            //             break
            //         }
            //     }
            // }
            let newSelectedFilter;
            let selected = selectedfilter.selected;
            if (selected.includes(item.id)) {
                let filtered = selected.filter(ele => ele !== item.id);
                newSelectedFilter = { ...selectedfilter, selected: filtered };
                setSelectedFilter({ ...selectedfilter, selected: filtered });

            } else {
                newSelectedFilter = { ...selectedfilter, selected: [...selected, item.id] };
                setSelectedFilter({ ...selectedfilter, selected: [...selected, item.id] });

            }
            customhandleClick(item, newSelectedFilter, filterList)
            return
        }
        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === selectedfilter.id) {
                let selected = templist[i].selected;
                if (selected.includes(item.id)) {
                    let filtered = selected.filter(ele => ele !== item.id);
                    templist[i].selected = filtered;
                    setFilterList(templist);
                    setSelectedFilter({ ...selectedfilter, selected: filtered });
                    break
                } else {
                    templist[i].selected = [...selected, item.id];
                    setFilterList(templist);
                    setSelectedFilter({ ...selectedfilter, selected: [...selected, item.id] });
                    break
                }
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setIsFilterOpen(false);
        // const prevData = localStorage.getItem('prevData');
        // setFilterList(JSON.parse(prevData));
        // localStorage.removeItem('prevData');
        // console.log('prevData = ', JSON.parse(globalStates.prevData));
        setFilterList(JSON.parse(globalStates.prevData));
        setGlobalStates({ ...globalStates, prevData: null, selectedFilterCopy: null });
    }
    const handleSave = () => {
        handleSaveFilter(filterList);
        setOpen(false);
        setIsFilterOpen(false);
    }

    const handleReset = () => {
        handleResetFilter();
        setSelectedFilter({ ...filterList[0], selected: [] });
        // let templist = [...filterList];
        // for (let i = 0; i < templist.length; i++) {
        //     templist[i].selected = [];
        // }
        // setFilterList(templist);
        setOpen(false);
    }


    // className={styles.main}
    return (
        <>
            <div className={styles.selectWrapper}>
                <ButtonBase
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    disabled={disabled}
                    //   className={styles.selector_button_base}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={(event) => {
                        if (disabled) return
                        setAnchorEl(event.currentTarget);
                        setOpen(true);
                        setIsFilterOpen(true);
                        setGlobalStates({ ...globalStates, prevData: JSON.stringify(filterList), selectedFilterCopy: null });
                        // localStorage.setItem('prevData', JSON.stringify(filterList));
                    }}>
                    <h1 >
                        <span>{label}</span>
                        <img src='/images/analytics/filter-icon.svg' alt='' title='Content Filter' />
                    </h1>
                </ButtonBase>
            </div >

            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                sx={{ top: '-4%' }}
                // keepMounted
                open={open}
                onClose={handleClose}>
                <Title titleText={title} padding={'0.6rem'} />
                {
                    withSearch ? <div className={styles.searchInputBox}>
                        <input ref={searchInputRef} placeholder="Search" type="text" onChange={handleLocalSearch} />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div> : null
                }
                <div className={styles.filterWrapper} style={{ width: isEmpty(selectedfilter) ? 'calc(680px/2)' : '680px' }}>
                    <div className={styles.filterList} style={{ width: isEmpty(selectedfilter) ? '100%' : '30%' }}>

                        {
                            filterList.length ?
                                (<>
                                    {filterList.map((ele, index) => (
                                        <div key={index} className={styles.listItem} onClick={() => _selectFilter(ele)} style={{
                                            backgroundColor: selectedfilter?.id === ele.id ? '#f9f9f9' : '#fff'
                                        }}>
                                            {column1Checkbox ? <Checkbox
                                                id={ele.id}
                                                type="checkbox"
                                                name={ele.name}
                                                // handleClick={handleClick}
                                                isChecked={selectedfilter?.id === ele.id}
                                            /> : null}
                                            <span>
                                                {/* {`${ele.name} (${ele.selected.length} / ${ele.subMenu.length})`} */}
                                                {ele.selected.length ? `${ele.name} (${ele.selected.length}/${ele.total})` : `${ele.name} (${ele.total})`}
                                            </span>
                                        </div>
                                    ))}
                                </>)
                                : <div style={{
                                    textAlign: 'center',
                                    color: 'rgb(0,0,0,0.7)',
                                    padding: '0.5rem 0'
                                }}>
                                    No Filters Available
                                </div>
                        }
                    </div>
                    {!isEmpty(selectedfilter) ?
                        <div id="sub-menu" className={`${styles.filterList} ${styles.subMenuList}`}>

                            {
                                selectedfilter.subMenu.length ?
                                    (<>
                                        {
                                            withSelectAll ?
                                                <div className={styles.listItem} onClick={handleSelectAll}>
                                                    <Checkbox
                                                        className={styles.checkBox}
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        isChecked={filterList.length && selectedfilter.subMenu.length === selectedfilter.selected.length}
                                                    />
                                                    <span style={{ color: '#f4511e' }}>Select All</span>
                                                </div> : null
                                        }
                                        {selectedfilter.subMenu.map((ele, index) => (
                                            <div key={index} className={styles.listItem} onClick={() => handleClick(ele)} title={ele.name} style={{
                                                // backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
                                            }}>
                                                <Checkbox
                                                    id={ele.id}
                                                    type="checkbox"
                                                    name={ele.name}
                                                    // handleClick={handleClick}
                                                    isChecked={selectedfilter.selected.includes(ele.id)}
                                                />
                                                <span>{ele.name}</span>
                                            </div>
                                        ))}
                                    </>)
                                    : <div style={{
                                        textAlign: 'center',
                                        color: 'rgb(0,0,0,0.7)',
                                        padding: '0.5rem 0'
                                    }}>
                                        {message}
                                    </div>
                            }
                        </div> : null}
                </div>

                <div className={styles.btnBox}>
                    <CustomButton
                        variant={'contained'}
                        textColor={'#fff'}
                        borderColor={'#f4511e'}
                        bgcolor={'#f4511e'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Save Filter"}
                        handleClick={handleSave}
                    />
                    <CustomButton
                        variant={'outlined'}
                        textColor={'#f4511e'}
                        borderColor={'#f4511e'}
                        bgcolor={'#fff'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Reset"}
                        handleClick={handleReset}
                    />
                    <h1> {selectedfilter.selected.length} {selectedfilter.name} Selected</h1>
                    {/* <h1>Selected : {selectedfilter.selected.length}</h1> */}
                </div>
            </Menu>
            }
        </>
    )

    // return (
    //     <div className={styles.main}>
    //         {withClose ? <IconButton
    //             aria-label="close"
    //             onClick={handleClose}
    //             sx={{
    //                 position: 'absolute',
    //                 right: '-1rem',
    //                 top: '-0.7rem',
    //                 color: '#f4511e',
    //                 height: '0.8rem',
    //                 width: '0.8rem',
    //                 border: '1px solid #f4511e',
    //                 padding: '0.8rem',
    //                 backgroundColor: '#fff !important',
    //                 zIndex: 100
    //             }}
    //         >
    //             <CloseIcon />
    //         </IconButton> : null}
    //         <Title titleText={title} />
    //         {
    //             withSearch ? <div className={styles.searchInputBox}>
    //                 <input placeholder="Search" type="text" onChange={handleLocalSearch} />
    //                 <i className="fa-solid fa-magnifying-glass"></i>
    //             </div> : null
    //         }
    //         <div className={styles.filterWrapper} style={{ width: isEmpty(selectedfilter) ? 'calc(450px/2)' : '450px' }}>
    //             <div className={styles.filterList} style={{ width: isEmpty(selectedfilter) ? '100%' : '50%' }}>

    //                 {
    //                     filterList.length ?
    //                         (<>
    //                             {filterList.map((ele, index) => (
    //                                 <div key={index} className={styles.listItem} onClick={() => _selectFilter(ele)} style={{
    //                                     backgroundColor: selectedfilter?.id === ele.id ? '#f9f9f9' : '#fff'
    //                                 }}>
    //                                     <Checkbox
    //                                         id={ele.id}
    //                                         type="checkbox"
    //                                         name={ele.name}
    //                                         // handleClick={handleClick}
    //                                         isChecked={selectedfilter?.id === ele.id}
    //                                     />
    //                                     <span>{ele.name}</span>
    //                                 </div>
    //                             ))}
    //                         </>)
    //                         : <div style={{
    //                             textAlign: 'center',
    //                             color: 'rgb(0,0,0,0.7)',
    //                             padding: '0.5rem 0'
    //                         }}>
    //                             No Filters Available
    //                         </div>
    //                 }
    //             </div>
    //             {!isEmpty(selectedfilter) ?
    //                 <div className={`${styles.filterList} ${styles.subMenuList}`}>

    //                     {
    //                         selectedfilter.subMenu.length ?
    //                             (<>
    //                                 {
    //                                     withSelectAll ?
    //                                         <div className={styles.selectAllLi} onClick={handleSelectAll}>
    //                                             <Checkbox
    //                                                 className={styles.checkBox}
    //                                                 type="checkbox"
    //                                                 name="selectAll"
    //                                                 id="selectAll"
    //                                                 isChecked={filterList.length && selectedfilter.subMenu.length === selectedfilter.selected.length}
    //                                             />
    //                                             <span>Select All</span>
    //                                         </div> : null
    //                                 }
    //                                 {selectedfilter.subMenu.map((ele, index) => (
    //                                     <div key={index} className={styles.listItem} onClick={() => handleClick(ele)} style={{
    //                                         // backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
    //                                     }}>
    //                                         <Checkbox
    //                                             id={ele.id}
    //                                             type="checkbox"
    //                                             name={ele.name}
    //                                             // handleClick={handleClick}
    //                                             isChecked={selectedfilter.selected.includes(ele.id)}
    //                                         />
    //                                         <span>{ele.name}</span>
    //                                     </div>
    //                                 ))}
    //                             </>)
    //                             : <div style={{
    //                                 textAlign: 'center',
    //                                 color: 'rgb(0,0,0,0.7)',
    //                                 padding: '0.5rem 0'
    //                             }}>
    //                                 {message}
    //                             </div>
    //                     }
    //                 </div> : null}
    //         </div>

    //         <div className={styles.btnBox}>
    //             <CustomButton
    //                 variant={'contained'}
    //                 textColor={'#fff'}
    //                 borderColor={'#f4511e'}
    //                 bgcolor={'#f4511e'}
    //                 fs={"0.6rem"}
    //                 fw={700}
    //                 btnText={"Save Filter"}
    //                 handleClick={() => handleSaveFilter(filterList)}
    //             />
    //             <CustomButton
    //                 variant={'outlined'}
    //                 textColor={'#f4511e'}
    //                 borderColor={'#f4511e'}
    //                 bgcolor={'#fff'}
    //                 fs={"0.6rem"}
    //                 fw={700}
    //                 btnText={"Reset"}
    //                 handleClick={handleReset}
    //             />
    //         </div>
    //     </div>
    // )
}

export default MultipleFilter;



const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};

