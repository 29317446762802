import React, { forwardRef } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Editor from '../../custom-styled/editor';

//components


const EditableTitle = forwardRef((props, ref) => {
    const {
        value,
        handeleTitleChange,
        fs = '1rem',
        fw = 600,
        align = 'start',
        textColor = '#707070',
        minHeight = '3rem',
        maxHeight = '162px',
        placeholder = "Enter Title Text Here",
        placeholderColor = 'rgb(112, 112, 112, 0.4)',
        handleTitleClick = () => { },
        showTitleToolbar,
        titleLimit,
        titleText=""
    } = props;
    const classes = useStyles({
        placeholderColor,
        fs,
        fw,
        align,
        textColor,
        minHeight,
        maxHeight,
        showTitleToolbar
    });


    return (
        <div className={`${classes.wrapper} ${titleLimit ? classes.limitBorder : ""}`} onClick={handleTitleClick}>
            <Editor
                showToolbar={showTitleToolbar}
                quillRef={ref}
                handleChange={handeleTitleChange}
                placeholder={placeholder}
                value={value}
            />
            <span className={classes.charLimit}>{titleText.length} chars.</span>
        </div>

    );
});


export default EditableTitle;


const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100%',
        position: "relative",
        padding:'0.4rem',
        border: '1px solid #E3E3E3',
        '&:hover': {
            border: '1px solid #E3E3E3',
        },
        '& > div': {
            border: 'none !important',
            fontSize: '1rem',
            '& > div:first-child': {
                display: ({ showTitleToolbar }) => (showTitleToolbar ? 'inherit' : 'none'),
                border: 'none !important',
                position: 'absolute',
                top: '-3rem',
                left: '-1rem',
                width: '118%'
            },
            '& > pre': {
                border: 'none !important',
                // position: 'absolute',
                width: '100%',
                '& *': {
                    color: ({ textColor }) => (textColor ? textColor : 'inherit'),
                },
                '& > div:first-child': {
                    fontSize: '1.2rem',
                    // minHeight: ({ minHeight }) => (minHeight),
                    // maxHeight: ({ maxHeight }) => (maxHeight),
                    overflow: 'hidden',
                    padding: '0 !important',
                    '&::before': {
                        left: '0 !important',
                        fontSize: '1.2rem',
                        color: ({ placeholderColor }) => (placeholderColor)
                    },
                },
                '&:focus-within': {
                    // outline: '1px solid #4F0060',
                },
            },
        },
    },
    charLimit: {
        color: "#f4511e",
        position: "absolute",
        bottom: '-1.6rem',
        right: "0.4rem",
        fontWeight: '600',
        fontSize:"0.8rem"
    },
    limitBorder: {
        outline: "3px solid red",
        transition:'outline 150ms ease-in-out'
    }
}));