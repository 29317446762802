import React, { useEffect, useRef } from "react";

//mui
import Checkbox from '@mui/material/Checkbox';

//components
import Loader from "../../../custom-styled/loader";
import ControlledSwitches from '../../../custom-styled/switch-button';


//styles
import useStyles from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";
import commonUtil from "../../../../utils/commonUtil";

//context
// import { GlobalStates } from "../../App";

const Table = ({
    loader = false,
    loaderText = "LOADING . . . ",
    tableHeadings,
    tableData,
    selected = [],
    handleSelectAll = () => { },
    handleSelect = () => { },
    resetStatsUsers = [],
    handleResetStatistics = () => { },
}) => {
    const styles = useStyles();
    const loadingDivRef = useRef(null);

    useEffect(() => {
        if (loader) {
            scrollToStart()
        }
    }, [loader])

    const scrollToStart = () => {
        if (loadingDivRef.current) {
            loadingDivRef.current.scrollLeft = 0;
        }
    };

    const getValue = (element, data) => {
        if (isEmpty(element[data.key])) return "-";
        return element[data.key];
    }
    const getBtnClass = (element, data) => {
        if (isEmpty(element[data.key])) return '';
        if (element[data.key] === "NEW LAUNCH") return styles.successBtnCell;
        if (element[data.key] === "RE LAUNCH") return styles.errorBtnCell;
        return styles.inProgressBtnCell;
    }

    const getIsBtn = (element, data) => {
        let valuesTypeBtn = ["NEW LAUNCH", "RE LAUNCH"];
        if (valuesTypeBtn.includes(element[data.key])) {
            return true;
        }
        return false;
    }

    return (
        <>
            <div className={`${styles.wrapper} ${loader || !tableData.length ? styles.disableScroll : ""}`} ref={loadingDivRef}>
                <table className={styles.Table}>
                    {true && <thead style={{
                        backgroundColor: '#fff',
                        position: 'sticky',
                        top: 0,
                        zIndex: 9
                    }}>
                        <tr className={styles.Table_Heading}>
                            {tableHeadings.map((element, i) => (
                                <th key={i} style={{ minWidth: element.isCheckbox ? 60 : 150 }}>
                                    {
                                        element.isCheckbox ?
                                            <Checkbox
                                                id='select-all'
                                                title="Select All"
                                                checked={tableData.length && tableData.length === selected.length}
                                                onChange={handleSelectAll}
                                                disabled={loader || !tableData.length}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: tableData.length && tableData.length === selected.length && '#f4511e' } }}
                                            />
                                            : element.title
                                    }

                                </th>
                            ))}
                        </tr>
                    </thead>}
                    {!loader && <tbody>
                        {tableData.map((element, key) => (
                            <tr className={styles.Table_Data} key={key}>

                                {
                                    tableHeadings.map((data, i) => {
                                        // ${styles.btnCell} ${getBtnClass(element, data)}
                                        if (getIsBtn(element, data)) {
                                            return (<td key={i} className={`${styles.btnCell} ${getBtnClass(element, data)}`}>
                                                <span>{getValue(element, data)}</span>
                                            </td>)
                                        } else if (data.isCheckbox) {
                                            return (<td key={i}>
                                                <Checkbox
                                                    id='select'
                                                    checked={selected.includes(element.Username)}
                                                    onChange={() => handleSelect(element)}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: selected.includes(element.Username) && '#f4511e' } }}
                                                />
                                            </td>)
                                        } else if (data.key === "options") {
                                            return (
                                                <td key={i}>
                                                    {element['status'] === "Relaunch" ?
                                                        <>
                                                            <span>Reset statistics</span>
                                                            <ControlledSwitches checked={resetStatsUsers.includes(element.Username)} handleChange={() => handleResetStatistics(element)} />
                                                        </>
                                                        : null}
                                                </td>
                                            )
                                        } else {
                                            return (<td key={i}>{getValue(element, data)}</td>)
                                        }
                                    }
                                    )
                                }
                            </tr>
                        ))}
                    </tbody>}
                </table>

                {
                    loader ?
                        <div className={styles.loaderBox}>
                            <Loader />
                            <h1>{loaderText}</h1>
                        </div> : null
                }
                {
                    (!loader && !tableData.length && <div className={styles.emptyTable} style={{ minHeight: '22rem' }}>
                        No data found
                    </div>)
                }
            </div>
        </>
    );
};

export default Table;