import React from 'react'
import FormBasedChallenge from '../../../components/form-based-challenge/index'
const FormBasedChallengePage = () => {
  return (
    <>
  <FormBasedChallenge/>
    </>
  )
}

export default FormBasedChallengePage