import React from 'react'

//components
import Files from '../Files';

const Step3 = ({
    learnerOption,
    setLearnerOption,
    fileUpload,
    setFileUpload,
    showDwModal,
    setShowDwModal,
    selectedUsers,
    setSelectedUsers,
    headings,
    setHeadings,
    userData,
    setUserData,
    isDwVisible,
    setIsDwVisible,
    setSelectedLaunchType,
    setLaunchType,
    setIsLaunchVisible,
    demographicFilter,
    setDemographicFilter,
    getSelectedDemographics,
    getMappingDetails,
    mappingObject,
    mapping,
    setMapping,
}) => {
    return (
        <div style={{
            marginBottom: '5rem'
        }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>Step 3: Learners</h2>
            <Files
                learnerOption={learnerOption}
                setLearnerOption={setLearnerOption}
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
                showDwModal={showDwModal}
                setShowDwModal={setShowDwModal}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                headings={headings}
                setHeadings={setHeadings}
                userData={userData}
                setUserData={setUserData}
                isDwVisible={isDwVisible}
                setIsDwVisible={setIsDwVisible}
                setSelectedLaunchType={setSelectedLaunchType}
                setLaunchType={setLaunchType}
                setIsLaunchVisible={setIsLaunchVisible}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                getSelectedDemographics={getSelectedDemographics}
                getMappingDetails={getMappingDetails}
                mappingObject={mappingObject}
                mapping={mapping}
                setMapping={setMapping}
            />
        </div>
    )
}

export default Step3;
