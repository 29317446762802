import React, { useState, useEffect, useContext, useRef } from 'react'

//  components
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import LaunchHistoryTable from './components/launch-history-table';
import Label from '../custom-styled/label';
import CustomButton from '../custom-styled/button';
import Step1 from './components/step-one';
import Step2 from './components/step-two';
import Step3 from './components/step-three';
import SelectUserModal from './components/user-modal';
import LaunchPageNav from './components/launch-page-nav';
import LJStep2 from './components/lourney-journey-step2';

//  styles
import useStyles from './index.styles';

//  mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Button, CircularProgress } from '@mui/material';
// import { TITLE_HEIGHT } from './index.styles';


//  utils
import commonUtil from '../../utils/commonUtil';
import useQuery from '../../utils/useQuery';
import isEmpty from '../../utils/isEmpty';
import useRouter from '../../utils/useRouter';

// services
import microskillServices from '../../services/microskill';
import formServices from '../../services/form';
import groupServices from '../../services/group';
import companiesServices from '../../services/companies';
import documentsServices from '../../services/documents';
import bulletinServices from '../../services/bulletin';

//constants
import constants from '../../constants';

//  configs
import { keys, urls } from '../../configs';
import { EventSourcePolyfill } from 'event-source-polyfill';

//global-states
import { GlobalStates } from '../../App';
import { set } from 'lodash';
import user from '../../services/user';
import moment from 'moment';
import dayjs from 'dayjs';
import AddLearnerInLJ from './components/learner-journey-add-user';

const launchBtnStyling = {
    backgroundColor: "#f4511e !important",
    border: '2px solid #f4511e !important',
    color: '#ffffff !important',
    '&:hover': {
        backgroundColor: "#f4511e !important",
        border: '2px solid #f4511e !important',
        color: '#ffffff !important',
        boxShadow: '1px 2px 7px 0px rgba(0,0,0,0.75)'
    }
}

const LAUNCHPG_NAV_TYPES = ['INDIVIDUAL_LAUNCH', 'LEARNING_JOURNEY'];
const defaultHeadings = [
    { title: "checkbox", key: "checkbox", isCheckbox: true },
    { title: "First Name", key: "fname" },
    { title: "Last Name", key: "lname" },
    { title: "Username", key: "username" },
    { title: "Email ID", key: "email" },
    { title: "Phone Number", key: "mobile" },
    { title: "Status", key: "status", isBtn: true },
    { title: "Options", key: "options" },
]

export const LEARNER_OPTIONS = {
    CSV: "CSV",
    DEMOGRAPHIC: "DEMOGRAPHIC",
}

export const CONTENT_TYPES = {
    MICROSKILLS: 'MICROSKILLS',
    FORMS: 'FORMS',
    DOCUMENTS: 'DOCUMENTS',
    BULLETIN: 'BULLETIN'
}

export const LAUNCH_TYPES = {
    IMMEDIATE: 'IMMEDIATE',
    DISABLE: 'DISABLE',
    SCHEDULED: 'SCHEDULED'
}

export const defaultValidationData = {
    "validUsersCount": 0,
    "validUsersUrl": "",
    "invalidShortUsersCount": 0,
    "invalidShortUsersUrl": "",
    "relaunchUsersCount": 0,
    "relaunchUsersUrl": "",
    "newLaunchUsersCount": 0,
    "newLaunchUsersUrl": ""
}


const UserLaunch = () => {
    const styles = useStyles();
    const query = useQuery();
    const router = useRouter();
    const companyId = query.get('c_id');
    const userType = localStorage.getItem("user-type");
    const [globalStates] = useContext(GlobalStates);
    const [companies, setCompanies] = useState([]);
    const companiesObject = commonUtil.objectify(companies, 'id')
    const [selectedCompany, setSelectedCompany] = useState([]);

    const [microskills, setMicroskills] = useState([]);
    const microskillsObject = commonUtil.objectify(microskills, 'id')
    const [selectedMicroskills, setSelectedMicroskills] = useState([]);

    const [documents, setDocuments] = useState([]);
    const documentsObject = commonUtil.objectify(documents, 'id')
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const [groups, setGroups] = useState([]);
    const groupsObject = commonUtil.objectify(groups, 'id')
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [forms, setForms] = useState([]);
    const formsObject = commonUtil.objectify(forms, 'id')
    const [selectedForms, setSelectedForms] = useState([]);

    const [bulletins, setBulletins] = useState([]);
    const bulletinsObject = commonUtil.objectify(bulletins, 'id');
    const [selectedBulletin, setSelectedBulletin] = useState([])

    const [contentType, setContentType] = useState([CONTENT_TYPES.MICROSKILLS]);
    const [launchType, setLaunchType] = useState([]);
    const [fileUpload, setFileUpload] = useState(null);
    const [learnerOption, setLearnerOption] = useState(LEARNER_OPTIONS.CSV);
    const [jobId, setjobId] = useState('');
    const [listening, setListening] = useState(false);
    const [step2, setStep2] = useState(false);//false
    const [step3, setStep3] = useState(false);//false
    const [unlockChallenges, setUnlockChallenges] = useState(false);
    const [addLearnerInLearningJourney, setAddLearnerInLearningJourney] = useState(false);

    const [failed, setFailed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [failedText, setFailedText] = useState('');
    const [successText, setSuccessText] = useState('');


    const [launching, setLaunching] = useState(false);

    const [selectedMicroskillsSequence, setSelectedMicroskillsSequence] = useState([]);
    const [scheduledDates, setScheduledDates] = useState([]);

    /**
     * @summary values for the selected nav will be
     * 1. INDIVIDUAL_LAUNCH
     * 2. LEARNING_JOURNEY
     */
    const [selectedNav, setSelectedNav] = useState(LAUNCHPG_NAV_TYPES[0]);
    const [journeyName, setJourneyName] = useState('');
    const [groupLaunchFlag, setGroupLaunchFlag] = useState(true);
    const [unlockCondition, setUnlockCondition] = useState([]);
    const [minScoreToUnlock, setMinScoreToUnlock] = useState(0);

    //demographic-wise launch
    const [showDwModal, setShowDwModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [userData, setUserData] = useState([]);
    const [loadingUserData, setLoadingUserData] = useState(false);
    const [individualLaunchListData, setIndividualLaunchListData] = useState([]);
    const [journeyLaunchListData, setJourneyLaunchListData] = useState([]);
    const [isLoadingTableData, setIsLoadingTableData] = useState(false);
    const [editingJourney, setEditingJourney] = useState(false);
    const [currentJourneyId, setCurrentJourneyId] = useState(null);
    const [isDataUploading, setIsDataUploading] = useState(false);
    // const [rowsCount, setRowsCount] = useState(10); // rows count per table
    // const tableRowsCountDebaounceRef = useRef(null);
    const [validationData, setValidationData] = useState(defaultValidationData);


    const firstRender = useRef(true);
    const immiLaunchTableHeadings = [
        {
            heading: 'Content Name',
            key: 'name'
        },
        {
            heading: 'Type',
            key: 'type'
        },
        {
            heading: 'Launch Date',
            key: 'launchDate'
        },
        {
            heading: 'Expiry Date',
            key: 'expiryDate'
        }
    ]
    const journeyLaunchTableHeadings = [
        {
            heading: 'Journey Name',
            key: 'name'
        },
        {
            heading: 'Type',
            key: 'type'
        },
        {
            heading: 'Microskills',
            key: 'microskills'
        },
        {
            heading: 'Users',
            key: 'users'
        }
    ]

    const fetchListData = async () => {
        handleClear();
        const data = {
            "companyId": companyId,
            "limit": 10
            // "limit": rowsCount
        }
        setIsLoadingTableData(true);
        if (selectedNav === LAUNCHPG_NAV_TYPES[0]) {
            let apiResp = await microskillServices.getImmidiateLaunchList(data);
            if (apiResp.status === 200 || apiResp.status === 201) {
                let formattedData = apiResp?.data?.data?.map((ele, index) => {

                    return {
                        ...ele,
                        launchDate: ele?.launchDate ?? "-",
                        expiryDate: ele?.expiryDate ?? "-"
                    }
                });
                setIndividualLaunchListData(formattedData);
            }
            setIsLoadingTableData(false);
        }

        if (selectedNav === LAUNCHPG_NAV_TYPES[1]) {
            let apiResp = await microskillServices.getLaunchedLearnerJourneyList(data);
            if (apiResp.status === 200 || apiResp.status === 201) {
                // console.log(apiResp.data.data, 'apiResp.data.data getLaunchedLearnerJourneyList');

                setJourneyLaunchListData(apiResp.data.data);
            }
            setIsLoadingTableData(false);
        }
    }

    // it will fetch and update the list data for selected navigation type
    useEffect(() => {
        fetchListData();
    }, [selectedNav])

    useEffect(() => {
        if (companyId !== 'null') {
            setSelectedCompany([companyId]);
        }

        if (userType === "superAdmin") {
            fetchCompanies();
        }
    }, [])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return
        }
        if (userType === "superAdmin" && selectedCompany.length) {
            router.history.replace(`/user-launch?c_id=${selectedCompany[0]}`);
            if (contentType.includes(CONTENT_TYPES.MICROSKILLS) && selectedCompany.length) {
                fetchMicroskills(selectedCompany[0]);
                fetchGroups(selectedCompany[0]);
            }
            if (contentType.includes(CONTENT_TYPES.FORMS) && selectedCompany.length) {
                fetchForms(selectedCompany[0]);
            }
            if (contentType.includes(CONTENT_TYPES.BULLETIN) && selectedCompany.length) {
                fetchBulletins(selectedCompany[0]);
            }
            if (contentType.includes(CONTENT_TYPES.DOCUMENTS) && selectedCompany.length) {
                fetchDocuments(selectedCompany[0], {});
                fetchGroups(selectedCompany[0]);
            }
        } else {
            if (contentType.includes(CONTENT_TYPES.MICROSKILLS) && companyId) {
                fetchMicroskills(companyId);
                fetchGroups(companyId);
            }
            if (contentType.includes(CONTENT_TYPES.FORMS) && companyId) {
                fetchForms(companyId);
            }
            if (contentType.includes(CONTENT_TYPES.BULLETIN) && companyId) {
                fetchBulletins(companyId);
            }
            if (contentType.includes(CONTENT_TYPES.DOCUMENTS) && companyId) {
                fetchDocuments(companyId, {});
                fetchGroups(companyId);
            }
        }

    }, [selectedCompany, contentType])

    useEffect(() => {
        if (!contentType.includes(CONTENT_TYPES.MICROSKILLS)) return;
        if (launchType.includes(LAUNCH_TYPES.DISABLE)) return;
        if (fileUpload) validateUsers();
        else setValidationData(defaultValidationData);

    }, [fileUpload])

    const validateUsers = async () => {

        const json = {
            excelFile: fileUpload,
            companyId,
            microskillIds: selectedMicroskills
        }

        const payload = await commonUtil.jsonToFormData(json);
        globalStates.handleProgressDialog(true, 'Validating users, please wait...');
        const response = await microskillServices.validateUsers(payload);
        if (response.status === 200) {
            globalStates.handleProgressDialog(false);
            setValidationData(response.data.data);
        } else {
            globalStates.handleProgressDialog(false);
            globalStates.handleToast(true, 'Failed to validate users', 'error')
        }
    }



    const fetchCompanies = async () => {
        const response = await companiesServices.fetchCompanies();
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            if (companyId === 'null') {
                setSelectedCompany([data[0].id]);
            }
            setCompanies(data);
        }
    }

    useEffect(() => {
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS) && selectedGroups.length && companyId !== 'null') {
            fetchMicroskills(companyId, null, selectedGroups);
            // fetchMicroskills(companyId, null, selectedGroups[0]);
        }
        if (contentType.includes(CONTENT_TYPES.DOCUMENTS) && selectedGroups.length && companyId !== 'null') {
            fetchDocuments(companyId, { groupIds: selectedGroups });
            // fetchDocuments(companyId, { groupIds: selectedGroups[0] });
        }
    }, [selectedGroups])

    useEffect(() => {
        if (listening && jobId.length) {
            const eventBaseURL = `${urls.baseUrl}${urls.apiV1}`;
            const session = JSON.parse(localStorage.getItem('session'));
            let type = launchType.includes(LAUNCH_TYPES.IMMEDIATE) ? "launch" : "disable";
            let endPoint = `${eventBaseURL}/microskill/${type}/${jobId}/status`;
            if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) endPoint = `${eventBaseURL}/repository/${type}/${jobId}/status`;
            const events = new EventSourcePolyfill(endPoint, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'x-api-key': keys.apiKey,
                    'x-access-token': session.accessToken,
                    'x-refresh-token': session.refreshToken
                }
            });

            events.onmessage = (res) => {
                const data = JSON.parse(res.data);
                // console.log('data = ', data);
                if (data.status === "completed") {
                    events.close();
                    setListening(false);
                    setjobId('');
                    let content = contentType.includes(CONTENT_TYPES.MICROSKILLS) ? "Microskills" : "Documents"
                    let message = launchType.includes(LAUNCH_TYPES.IMMEDIATE) ? `${content} launched successfully!` : `${content} disabled successfully!`
                    setSuccessText(message);
                    globalStates.handleProgressDialog(false);
                    handleClear();
                    setSuccess(true);
                }
            }
        }
    }, [listening]);

    useEffect(() => {
        if (selectedNav === LAUNCHPG_NAV_TYPES[1]) return
        if (step2 || step3) {
            commonUtil.scrollToBottom();
        }
        if (addLearnerInLearningJourney) {
            setAddLearnerInLearningJourney(false);
        }

    }, [selectedNav, step2, step3])


    const fetchMicroskills = async (companyId = null, search = null, groupIds = null) => {
        if (companyId === 'null') return
        const response = await microskillServices.fetchMicroskillsSearch(companyId, search, groupIds);
        if (response.status === 200 || response.status === 201) {
            let activeMicroskills = response.data.data.filter(ele => ele.active === true && ele.categoryId !== null);
            setMicroskills(activeMicroskills);
        }
    }

    const fetchGroups = async (companyId = null, search = null,) => {
        if (companyId === 'null') return
        const response = await groupServices.fetchGroupsSearch(companyId, search);
        if (response.status === 200 || response.status === 201) {
            let activeGroups = response.data.data.filter(ele => ele.active === true);
            setGroups(activeGroups);
        }
    }

    const fetchForms = async (companyId = null, search = null,) => {
        if (companyId === 'null') return;
        const response = await formServices.fetchFormSearch(companyId, search);
        if (response.status === 200 || response.status === 201) {
            console.log('response.data.data = ', response.data.data);
            let activeForms = response.data.data.filter(ele => ele.active === true && ele.launched === false && ele.isChallenge === false);
            setForms(activeForms);
        }
    }

    const fetchDocuments = async (companyId = null, { parentId = null, sortDirection = 'desc', groupIds = null }) => {
        if (companyId === 'null') return
        const response = await documentsServices.fetchDocuments(companyId, parentId, sortDirection, groupIds)
        if (response.status === 200 || response.status === 201) {
            let activeDocuments = response.data.data.filter(ele => ele.active === true);
            setDocuments(activeDocuments);
        }
    }

    const fetchBulletins = async (companyId) => {
        if (companyId === 'null' || companyId === null) return
        const response = await bulletinServices.fetchBulletins(companyId);
        if (response.status === 200 || response.status === 201) {
            // let activeForms = response.data.data.filter(ele => ele.active === true && ele.launched === false && ele.isChallenge === false);
            let bulletinData = response.data.data;
            bulletinData = bulletinData.filter(e => e.title !== '')
            for (let i = 0; i < bulletinData.length; i++) {
                let element = bulletinData[i];
                element = { ...element, name: element.title }
                bulletinData[i] = element;
            }
            // console.log(bulletinData);
            bulletinData.reverse();
            setBulletins(bulletinData);
        }
    }

    const _selectCompany = (item) => {
        setSelectedCompany([item.id]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        setSelectedGroups([]);
        setStep2(false);
        setStep3(false);
        setFileUpload(null);
        setLaunchType([]);
        return
    }

    const _selectGroup = (item) => {
        setStep2(false);
        setStep3(false);
        setLaunchType([]);
        if (selectedGroups.includes(item.id)) {
            let filtered = selectedGroups.filter(ele => ele !== item.id);
            if (!filtered.length) {
                setStep2(false);
                setStep3(false);
                setLaunchType([]);
            }
            setSelectedGroups(filtered);

            // setSelectedGroups([]);
            setSelectedMicroskills([]);
            setSelectedDocuments([]);
            if (!isEmpty(companyId)) {
                if (contentType.includes(CONTENT_TYPES.MICROSKILLS)) fetchMicroskills(companyId);
                if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) fetchDocuments(companyId, {});
            }
            return
        }
        setSelectedGroups(prev => ([...prev, item.id]));
        setSelectedMicroskills([]);
        setSelectedDocuments([]);
        return
    }

    const _selectDocuments = (item) => {
        if (selectedDocuments.includes(item.id)) {
            let filtered = selectedDocuments.filter(ele => ele !== item.id);
            if (!filtered.length) {
                setStep2(false);
                setStep3(false);
                setLaunchType([]);
            }
            setSelectedDocuments(filtered);
        } else {
            setSelectedDocuments([...selectedDocuments, item.id]);
            setStep2(true);
        }
        return
    }

    const _selectAllDocuments = (list) => {
        let isSelectAll = list.length === selectedDocuments.length;
        if (isSelectAll) {
            setSelectedDocuments([]);
            setStep2(false);
            setStep3(false);
            setLaunchType([]);
        } else {
            let all = list.map(ele => ele.id)
            setSelectedDocuments(all);
            setStep2(true);
        }
        return
    }

    const _selectMicroskills = (item) => {
        if (selectedMicroskills.includes(item.id)) {
            let filtered = selectedMicroskills.filter(ele => ele !== item.id);
            if (!filtered.length) {
                setStep2(false);
                setStep3(false);
                setLaunchType([]);
            }
            setSelectedMicroskills(filtered);
            setSelectedMicroskillsSequence(prev => {
                let filtered = prev.filter(ele => ele.id !== item.id);
                return filtered
            });
        } else {
            setSelectedMicroskills(prev => {
                setSelectedMicroskillsSequence(pre => {

                    return [...pre, { name: item.name, id: item.id, sequence: pre.length + 1 }]
                })
                return [...prev, item.id]
            })
            setStep2(true);
        }
        return
    }

    const _selectAllMicroskills = (list) => {
        let isSelectAll = list.length === selectedMicroskills.length;
        if (isSelectAll) {
            setSelectedMicroskills([]);
            setStep2(false);
            setStep3(false);
            setLaunchType([]);
            setSelectedMicroskillsSequence([]);
        } else {
            let all = list.map(ele => ele.id)
            setSelectedMicroskills(all);
            setSelectedMicroskillsSequence(prev => {
                return [...prev, ...list.map((item, index) => ({ name: item.name, id: item.id, sequence: index + 1 }))]
            })
            setStep2(true);
        }
        return
    }

    const _selectForm = (item) => {
        if (selectedForms.includes(item.id)) {
            setSelectedForms([]);
            setStep2(false);
            return
        }
        setSelectedForms([item.id]);
        setStep2(true);
        return
    }
    const _selectBulletin = (item) => {
        if (selectedBulletin.includes(item.id)) {
            setSelectedBulletin([]);
            setStep2(false);
            return
        }
        setSelectedBulletin([item.id]);
        setStep2(true);
        return
    }

    const handleClear = () => {
        setSelectedGroups([]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        setSelectedDocuments([]);
        setLaunchType([]);
        setStep2(false);
        setStep3(false);
        if (contentType.includes('Microskills')) fetchMicroskills(companyId);
        if (contentType.includes('Microskills')) fetchGroups(companyId);
        setFileUpload(null);

        if (selectedNav === LAUNCHPG_NAV_TYPES[1]) {
            setJourneyName('')
            setSelectedMicroskillsSequence([])
            setMinScoreToUnlock('')
            setUnlockCondition([])
            setCurrentJourneyId(null)
        }
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS)) {
            fetchMicroskills(companyId);
            fetchGroups(companyId);
        }
        if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) {
            fetchDocuments(companyId, {});
            fetchGroups(companyId);
        }
        if (learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC) setSelectedUsers([]);
        if (editingJourney) setEditingJourney(false);
        if (addLearnerInLearningJourney) setAddLearnerInLearningJourney(false);
        setLearnerOption(LEARNER_OPTIONS.CSV)
        return

    }

    const launchBulletinImmediate = async () => {
        let bulletinId = selectedBulletin[0];
        const data = {
            bulletInLaunchFile: fileUpload,
            bulletInId: bulletinId,
            companyId: companyId,
            launchType: launchType
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await bulletinServices.launchBulletin(payload);
        globalStates.handleProgressDialog(false, 'Please wait...');
        if (response.status === 200 || response.status === 201) {
            setSuccess(true);
            setSuccessText("Bulletin launched successfully!");
            handleClear();
        } else {
            globalStates.handleToast(true, 'Internal Server Error.', 'error');
            let message = `Something went wrong, please try again!`
            setFailedText(message);
            setFailed(true);
        }
        // console.log(response);
    }
    const launchFormsImmediate = async () => {
        let formId = selectedForms[0];
        const data = {
            formsLaunchFile: fileUpload,
            formIds: formId,
            companyId: companyId,
            launchType: launchType
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await formServices.launchForm(payload);
        if (response.status === 200 || response.status === 201) {
            globalStates.handleProgressDialog(false, 'Please wait...');
            setSuccess(true);
            setSuccessText("Form launched successfully!");
            handleClear();
        } else {
            globalStates.handleToast(true, 'Internal Server Error.', 'error');
        }
    }

    const csvLaunchMicroskillImmediate = async () => {
        const data = {
            microskillLaunchFile: fileUpload,
            microskillIds: selectedMicroskills,
            companyId: companyId,
            launchType: "IMMEDIATE",
            unlockChallenges
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await microskillServices.uploadUserMicroskill(payload);
        if (response?.status === 200 || response?.status === 201) {
            setjobId(response.data.data.jobId);
            setListening(true);
        } else {
            globalStates.handleProgressDialog(false);
            let message = `Something went wrong, please try again!`
            setFailedText(message);
            setFailed(true);
        }
    }

    const getOrderedAndStringifiedDates = (dates = []) => {
        let newDates = dates.map((item, i) => ({ ...item, order: i + 1 }));
        return JSON.stringify(newDates);
    }

    const csvLaunchMicroskillScheduled = async () => {
        const data = {
            microskillLaunchFile: fileUpload,
            microskillIds: selectedMicroskills,
            companyId,
            launchType: "SCHEDULE",
            scheduledDates: getOrderedAndStringifiedDates(scheduledDates)
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await microskillServices.uploadUserMicroskill(payload);
        globalStates.handleProgressDialog(false);
        let message = `Microskills scheduled successfully!`
        if (response.status === 200 || response.status === 201) {
            setSuccessText(message);
            setSuccess(true);
            handleClear()
        } else {
            message = `Something went wrong, please try again!`
            setFailedText(message);
            setFailed(true);
        }
    }

    const csvDisableMicroskillImmediate = async () => {
        const data = {
            microskillDisableFile: fileUpload,
            microskillIds: selectedMicroskills,
            companyId: companyId,
            disableType: "IMMEDIATE"
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await microskillServices.disableMicroskill(payload);
        if (response.status === 200 || response.status === 201) {
            setjobId(response.data.data.jobId)
            setListening(true);
        }
    }

    const launchDocuments = async () => {
        const data = {
            repositoryLaunchFile: fileUpload,
            repositoryIds: selectedDocuments,
            companyId: companyId,
            launchType: "IMMEDIATE"
        }
        if (launchType.includes("DISABLE")) {
            data.repositoryDisableFile = fileUpload;
            data.disableType = "IMMEDIATE";
            delete data.repositoryLaunchFile;
            delete data.launchType;
        }
        const payload = await commonUtil.jsonToFormData(data);
        let type = launchType.includes("DISABLE") ? "disable" : "launch"
        const response = await documentsServices.launchDocument(payload, type);
        if (response.status === 200 || response.status === 201) {
            setjobId(response.data.data.jobId);
            setListening(true);
        } else {
            globalStates.handleProgressDialog(false);
            globalStates.handleToast(true, "Something went wrong, please try again!", "error");
            return
        }
    }

    const runValidation = () => {
        if (!contentType.length) {
            setFailed(true);
            setFailedText("Please select the content");
            return false;
        }
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS) && !selectedMicroskills.length) {
            setFailed(true);
            setFailedText("Please select the microskills");
            return false;
        }
        if (contentType.includes(CONTENT_TYPES.FORMS) && !selectedForms.length) {
            setFailed(true);
            setFailedText("Please select the form");
            return false;
        }
        if (contentType.includes(CONTENT_TYPES.BULLETIN) && !selectedBulletin.length) {
            setFailed(true);
            setFailedText("Please select the bulletin");
            return false;
        }
        if (contentType.includes(CONTENT_TYPES.DOCUMENTS) && !selectedDocuments.length) {
            setFailed(true);
            setFailedText("Please select the document");
            return false;
        }
        if (learnerOption === LEARNER_OPTIONS.CSV && fileUpload === null) {
            setFailed(true);
            setFailedText("Please upload the file");
            return false;
        }
        if (learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC && !selectedUsers.length) {
            setFailed(true);
            setFailedText("Please select the users");
            return false;
        }
        if (!validationData.validUsersCount) {
            if (learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC) return true;
            if (!contentType.includes(CONTENT_TYPES.MICROSKILLS)) return true;
            if (launchType.includes(LAUNCH_TYPES.DISABLE)) return true;
            setFailed(true);
            setFailedText("All user IDs are invalid");
            return false;
        }
        return true;
    }

    const handleCsvLaunches = async () => {
        if (launchType.includes(LAUNCH_TYPES.IMMEDIATE)) {
            await csvLaunchMicroskillImmediate();
            return
        }
        else if (launchType.includes(LAUNCH_TYPES.DISABLE)) {
            await csvDisableMicroskillImmediate();
            return
        }
        else if (launchType.includes(LAUNCH_TYPES.SCHEDULED)) {
            await csvLaunchMicroskillScheduled();
            return
        }
    }

    const dwLaunchMicroskillImmediate = async () => {
        const payload = {
            companyId,
            microskillIds: selectedMicroskills,
            launchType: "IMMEDIATE",
            usernames: selectedUsers,
            isSelectAll: false,
            userDemographics: [],
            unlockChallenges
        }

        const response = await microskillServices.launchDemographicWise(payload);
        if (response?.status === 200 || response?.status === 201) {
            setjobId(response.data.data.jobId);
            setListening(true);
        } else {
            let message = `Something went wrong, please try again!`
            setFailedText(message);
            setFailed(true);
        }

    }

    const dwLaunchMicroskillScheduled = async () => {
        const data = {
            companyId,
            microskillIds: selectedMicroskills,
            launchType: "SCHEDULE",
            usernames: selectedUsers,
            isSelectAll: false,
            userDemographics: [],
            scheduledDates: scheduledDates?.map((item, index) => ({ ...item, order: index + 1 }))
        }
        const response = await microskillServices.launchDemographicWise(data);
        globalStates.handleProgressDialog(false);
        let message = `Microskills scheduled successfully!`
        if (response.status === 200 || response.status === 201) {
            setSuccessText(message);
            setSuccess(true);
            handleClear()
        } else {
            message = `Something went wrong, please try again!`
            setFailedText(message);
            setFailed(true);
        }

    }

    const handleDemographicwiseLaunches = async () => {
        if (launchType.includes(LAUNCH_TYPES.IMMEDIATE)) {
            await dwLaunchMicroskillImmediate();
            return
        }
        else if (launchType.includes(LAUNCH_TYPES.DISABLE)) {
            // await csvDisableMicroskillImmediate();
            return
        }
        else if (launchType.includes(LAUNCH_TYPES.SCHEDULED)) {
            // await csvLaunchMicroskillScheduled();
            await dwLaunchMicroskillScheduled();
            return
        }
    }

    const handleLaunch = async () => {
        // console.log('launchType ', launchType);
        if (selectedNav === LAUNCHPG_NAV_TYPES[1]) {
            await launchJourneyImmediate();
            return
        }
        let isValid = runValidation();
        if (!isValid) return
        globalStates.handleProgressDialog(true, 'Please wait...');

        if (contentType.includes(CONTENT_TYPES.FORMS)) {
            await launchFormsImmediate();
            return
        }
        if (contentType.includes(CONTENT_TYPES.BULLETIN)) {
            await launchBulletinImmediate();
            return
        }
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS)) {
            if (learnerOption === LEARNER_OPTIONS.CSV) {
                await handleCsvLaunches()
                return
            }
            if (learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC) {
                await handleDemographicwiseLaunches()
                return
            }

        }
        if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) {
            await launchDocuments();
            return
        }
    }

    const handleFailClick = () => {
        setFailed(false);
    }

    const handleSuccessClick = () => {
        setSuccess(false);
    }

    const enableDemographicwiseOption = () => {
        if (selectedNav === LAUNCHPG_NAV_TYPES[1]) return false
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS) && (launchType.includes(LAUNCH_TYPES.IMMEDIATE) || launchType.includes(LAUNCH_TYPES.SCHEDULED))) return true;
        return false
    }

    const launchJourneyImmediate = async () => {
        if (!runJourneyRelatedValidations()) return
        setIsDataUploading(true);
        globalStates.handleProgressDialog(true, 'Please wait...');
        const data = {
            learnerJourneyLaunchFile: fileUpload,
            // microskills: selectedMicroskills.map(ele => ({ microskillId: ele, active: true })),
            microskills: selectedMicroskillsSequence.map(item => ({ ...item, microskillId: item.id, active: true })),
            name: journeyName,
            companyId: companyId,
            launchType: groupLaunchFlag ? launchType.includes(LAUNCH_TYPES.SCHEDULED) ? 'SCHEDULE' : launchType.toString() : 'SCHEDULE',
            setting: {
                groupLaunch: groupLaunchFlag,
                unlockCondition: unlockCondition.toString(),
            }

            // launchType: "IMMEDIATE",
            // unlockChallenges
        }
        if (launchType.includes(LAUNCH_TYPES.SCHEDULED)) {
            data['scheduledDates'] = getOrderedAndStringifiedDates(scheduledDates);
        }
        if (unlockCondition.includes('SCORE')) {
            data.setting.minScorePercentToUnlock = minScoreToUnlock;
        }
        data.setting = JSON.stringify(data.setting);
        // console.log('launchMicroskillImmediate data = ', data);

        const payload = await commonUtil.jsonToFormData(data);

        const response = await microskillServices.launchLearnerJourney(payload);

        globalStates.handleProgressDialog(false);
        if (response.status === 200 || response.status === 201) {
            // setjobId(response.data.data.jobId);
            // setListening(true);
            setSuccess(true);
            setSuccessText("Journey will be launched shortly!");
            handleClear();
            fetchListData();
        } else {

            setFailed(true);
            setFailedText(response?.data?.errors[0]?.message);
        }
        setIsDataUploading(false);
    }

    const handleJourneyEditClick = async (item) => {
        setAddLearnerInLearningJourney(false);
        setEditingJourney(true);
        setCurrentJourneyId(item.id)

        const data = {
            "companyId": companyId,
            "learnerJourneyId": item.id
        }

        let ljApiResp = await microskillServices.getLearnerJourneyDetails(data);
        if (ljApiResp.status === 200 || ljApiResp.status === 201) {
            let journeyDetails = ljApiResp.data.data;
            setJourneyName(journeyDetails.name);

            let flatMicroskills = journeyDetails.microskills.map(ele => {
                let tempObj = { ...ele, ...ele.Microskill }
                delete tempObj.Microskill;
                return tempObj
            });
            // let settings = JSON.parse(journeyDetails.setting);
            let settings = journeyDetails.setting

            setSelectedMicroskills(journeyDetails.microskills.map(ele => ele.microskillId));
            setSelectedMicroskillsSequence(flatMicroskills);
            setGroupLaunchFlag(settings.groupLaunch);
            setUnlockCondition([settings.unlockCondition] || []);
            if (settings.unlockCondition === 'SCORE') {
                setMinScoreToUnlock(settings.minScorePercentToUnlock || 0);
            }


            if (!isEmpty(journeyDetails.scheduleDates)) {
                setLaunchType([LAUNCH_TYPES.SCHEDULED]);
                setScheduledDates(journeyDetails.scheduleDates);
                // setStep3(true);
            }
            setStep2(true);
        } else {
            console.log('ljApiResp = ', ljApiResp);
        }
    }

    const handleJourneyCopyClick = async (item) => {
        setAddLearnerInLearningJourney(false);
        console.log(editingJourney, 'editingJourney');
        setEditingJourney(false);
        setCurrentJourneyId(null);

        const data = {
            "companyId": companyId,
            "learnerJourneyId": item.id
        };

        let ljApiResp = await microskillServices.getLearnerJourneyDetails(data);
        if (ljApiResp.status === 200 || ljApiResp.status === 201) {
            let journeyDetails = ljApiResp.data.data;
            setJourneyName("");

            let flatMicroskills = journeyDetails.microskills.map(ele => {
                let tempObj = { ...ele, ...ele.Microskill }
                delete tempObj.Microskill;
                return tempObj
            });
            // let settings = JSON.parse(journeyDetails.setting);
            let settings = journeyDetails.setting

            setSelectedMicroskills(journeyDetails.microskills.map(ele => ele.microskillId));
            setSelectedMicroskillsSequence(flatMicroskills);
            setGroupLaunchFlag(settings.groupLaunch);
            setUnlockCondition([settings.unlockCondition] || []);
            if (settings.unlockCondition === 'SCORE') {
                setMinScoreToUnlock(settings.minScorePercentToUnlock || 0);
            }

            if (!isEmpty(journeyDetails.scheduleDates)) {
                setLaunchType([LAUNCH_TYPES.SCHEDULED]);
                setScheduledDates(journeyDetails.scheduleDates);
            } else {
                setLaunchType([LAUNCH_TYPES.IMMEDIATE]);
            }
            setStep2(true);
            setStep3(true);
        } else {
            console.log('ljApiResp = ', ljApiResp);
        }
    }

    const handleJourneyDltClick = async (item) => {
        setAddLearnerInLearningJourney(false);
        const journeyId = item.id;

        let confirm = window.confirm("Are you sure you want to delete this journey?");
        if (!confirm) return;

        setIsDataUploading(true);

        const data = {
            companyId,
            learnerJourneyId: journeyId
        };


        const deletedApiResp = await microskillServices.deleteLearnerJourney(data);
        if (deletedApiResp.status === 200 || deletedApiResp.status === 201) {
            setSuccess(true);
            setSuccessText("Journey deleted successfully!");
            fetchListData();
        } else {
            setFailed(true);
            setFailedText("Something went wrong, please try again!");
        }

        setIsDataUploading(false);
    }

    const handleJourneyUpdate = async () => {
        if (!runJourneyRelatedValidations()) return
        setIsDataUploading(true);
        const data = {
            name: journeyName,
            companyId,
            journeyId: currentJourneyId,
            microskills: selectedMicroskillsSequence.map(ele => {
                let tempObj = { microskillId: ele.id, active: true, sequence: ele.sequence, name: ele.name };
                if (!groupLaunchFlag) {
                    tempObj.launchesOn = ele.launchesOn;
                    tempObj.expiresOn = ele.expiresOn;
                }
                return tempObj
            }),
            launchType: groupLaunchFlag ? launchType.includes(LAUNCH_TYPES.SCHEDULED) ? 'SCHEDULE' : launchType.toString() : 'SCHEDULE',
            setting: {
                groupLaunch: groupLaunchFlag,
                unlockCondition: unlockCondition.toString(),
            }
        }
        if (unlockCondition.includes('SCORE')) {
            data.setting.minScorePercentToUnlock = minScoreToUnlock;
        }

        if (groupLaunchFlag && launchType.includes(LAUNCH_TYPES.SCHEDULED)) {
            data['scheduledDates'] = scheduledDates;
        }
        // data.setting = JSON.stringify(data.setting);
        let updateApiResp = await microskillServices.updateLearnerJourney(data);
        if (updateApiResp.status === 200 || updateApiResp.status === 201) {
            setSuccess(true);
            setEditingJourney(false);
            setSuccessText("Journey updated successfully!");
            handleClear();
            fetchListData();
        }
        setIsDataUploading(false);
    }

    const handleCancelJourneyEdit = () => {
        setEditingJourney(false);
        setCurrentJourneyId(null);
        handleClear();
    }

    const runJourneyRelatedValidations = () => {
        if (isEmpty(journeyName)) {
            setFailed(true);
            setFailedText("Please enter the journey name");
            return false;
        }
        if (journeyName.trim().length < 3) {
            setFailed(true);
            setFailedText("Journey name should be atleast 3 characters long");
            return false;
        }
        if (isEmpty(selectedMicroskillsSequence.length)) {
            setFailed(true);
            setFailedText("Please select the microskills");
            return false;
        }
        if (selectedMicroskillsSequence.length < 2) {
            setFailed(true);
            setFailedText("Please select atleast 2 microskills");
            return false;
        }
        if (unlockCondition.length <= 0) {
            setFailed(true);
            setFailedText("Please select the unlock condition");
            return false;
        }
        if (unlockCondition.includes('SCORE') && isEmpty(minScoreToUnlock)) {
            setFailed(true);
            setFailedText("Please enter the minimum score to unlock");
            return false;
        }
        if (groupLaunchFlag && !editingJourney && launchType.length <= 0) {
            setFailed(true);
            setFailedText("Please select the launch type");
            return false;
        }
        if (!editingJourney && fileUpload === null) {
            setFailed(true);
            setFailedText("Please upload the file");
            return false;
        }
        if (launchType.includes(LAUNCH_TYPES.SCHEDULED) && isEmpty(scheduledDates)) {
            setFailed(true);
            setFailedText("Please select the scheduled dates");
            return false;
        }
        return true;
    }

    const clearGroups = () => {
        setSelectedGroups([]);
        // console.log('clearGroups fetchGroups fetchMicroskills');
        if (contentType.includes(CONTENT_TYPES.MICROSKILLS)) {
            // console.log('clearGroups fetchMicroskills');
            setSelectedMicroskills([]);
            if (selectedNav === LAUNCHPG_NAV_TYPES[1]) setSelectedMicroskillsSequence([]);
            fetchMicroskills(companyId);
        } else if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) {
            setSelectedDocuments([]);
            fetchDocuments(companyId, {});
        }
    }

    const clearSelectedMs = () => {
        setSelectedMicroskills([]);
        setSelectedMicroskillsSequence([]);
        setStep2(false);
        setStep3(false);
    }

    // const handleRowsCountChange = (e) => {
    // clearTimeout(tableRowsCountDebaounceRef.current);
    //     setRowsCount(e.target.value);

    //     tableRowsCountDebaounceRef.current = setTimeout(() => {
    //         fetchListData();
    //     }, 1000);
    // }

    const handleJourneyAddUsersClick = async (item) => {
        setCurrentJourneyId(item.id)

        const data = {
            "companyId": companyId,
            "learnerJourneyId": item.id
        }

        let ljApiResp = await microskillServices.getLearnerJourneyDetails(data);
        if (ljApiResp.status === 200 || ljApiResp.status === 201) {
            let journeyDetails = ljApiResp.data.data;
            setJourneyName(journeyDetails.name);

            let flatMicroskills = journeyDetails.microskills.map(ele => {
                let tempObj = { ...ele, ...ele.Microskill }
                delete tempObj.Microskill;
                return tempObj
            });
            // let settings = JSON.parse(journeyDetails.setting);
            let settings = journeyDetails.setting

            setSelectedMicroskills(journeyDetails.microskills.map(ele => ele.microskillId));
            setSelectedMicroskillsSequence(flatMicroskills);
            setGroupLaunchFlag(settings.groupLaunch);
            let tempUnlockCondtion = settings.unlockCondition;
            tempUnlockCondtion = tempUnlockCondtion.charAt(0).toUpperCase() + tempUnlockCondtion.slice(1).toLowerCase();

            setUnlockCondition([tempUnlockCondtion] || []);
            if (settings.unlockCondition === 'SCORE') {
                setMinScoreToUnlock(settings.minScorePercentToUnlock || 0);
            }


            if (!isEmpty(journeyDetails.scheduleDates)) {
                setLaunchType([LAUNCH_TYPES.SCHEDULED]);
                setScheduledDates(journeyDetails.scheduleDates);
                // setStep3(true);
            }

            setAddLearnerInLearningJourney(true);
            setStep3(true);

        } else {
            console.log('ljApiResp = ', ljApiResp);
        }
    }

    const handleAddUsersInJourney = async () => {
        if (learnerOption === LEARNER_OPTIONS.CSV && fileUpload === null) {
            setFailed(true);
            setFailedText("Please upload the file");
            return false;
        }
        let req = {
            companyId,
            journeyId: currentJourneyId,
            learnerJourneyLaunchFile: fileUpload
        }
        const payload = await commonUtil.jsonToFormData(req);
        const response = await microskillServices.addUsersInJourney(payload);
        if (response.status === 200 || response.status === 201) {
            setSuccess(true);
            setSuccessText("Users added in journey successfully!");
            handleClear();
        }
    }

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.pageTitle} >Content Launch</h1>
            <main className={styles.containerWrapper}>

                <div className={`bgGrey ${styles.container} scrollbar orange-scrollbar-thumb`}>
                    <LaunchPageNav
                        selectedNav={selectedNav}
                        setSelectedNav={setSelectedNav}
                        LAUNCHPG_NAV_TYPES={LAUNCHPG_NAV_TYPES}
                    />
                    {
                        <>
                            <div className={styles.labelBtnWrapper}>
                                <Label labelText={selectedNav === LAUNCHPG_NAV_TYPES[0] ? "Past Launches" : "Previous Journeys"} fs='1rem' fw={800} />
                                <CustomButton btnText={selectedNav === LAUNCHPG_NAV_TYPES[0] ? "+ Launch new content" : "Create new Journey"} textColor='#fff' bgcolor='#f4511e' borderColor='#f4511e' fs='0.7rem' handleClick={handleClear} />
                            </div>
                            {/* // history table */}
                            <LaunchHistoryTable
                                selectedNav={selectedNav}
                                tableData={selectedNav === LAUNCHPG_NAV_TYPES[0] ? individualLaunchListData : journeyLaunchListData}
                                tableHeadings={selectedNav === LAUNCHPG_NAV_TYPES[0] ? immiLaunchTableHeadings : journeyLaunchTableHeadings}
                                actionBtnVisible={selectedNav === LAUNCHPG_NAV_TYPES[0] ? false : true}
                                isLoadingData={isLoadingTableData}
                                handleJourneyEditClick={handleJourneyEditClick}
                                handleJourneyDltClick={handleJourneyDltClick}
                                handleJourneyCopyClick={handleJourneyCopyClick}
                                handleJourneyAddUsersClick={handleJourneyAddUsersClick}
                            // rowsCount={rowsCount}
                            // handleRowsCountChange={handleRowsCountChange}
                            />
                        </>
                    }
                </div>

                {addLearnerInLearningJourney && (
                    <div className={`${styles.container}  scrollbar thick-scrollbar orange-scrollbar-thumb`}>
                        <AddLearnerInLJ
                            journeyName={journeyName}
                            selectedMicroskillsSequence={selectedMicroskillsSequence}
                            launchType={launchType}
                            unlockCondition={unlockCondition}
                            learnerOption={learnerOption}
                            setLearnerOption={setLearnerOption}
                            fileUpload={fileUpload}
                            setFileUpload={setFileUpload}
                            setShowDwModal={setShowDwModal}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            userData={userData}
                            setUserData={setUserData}
                            selectedMicroskills={selectedMicroskills}
                            setHeadings={setHeadings}
                            setLoadingUserData={setLoadingUserData}
                            withDemograhicwiseOption={enableDemographicwiseOption()}
                        />

                        <div style={{
                            display: 'flex',
                            gap: '4rem',
                            position: (step3) ? 'static' : 'absolute',
                            // position: (selectedNav !== LAUNCHPG_NAV_TYPES[0] && (step2 || step3)) ? 'static' : 'absolute',
                            bottom: (step3) ? '' : '26px',

                        }
                        }>

                            {fileUpload && (
                                <Button
                                    variant="contained"
                                    disabled={fileUpload === null ? true : false}
                                    className={styles.btn}
                                    sx={launchBtnStyling}
                                    onClick={handleAddUsersInJourney}
                                >
                                    {
                                        "Add Learners"
                                    }
                                </Button>
                            )}
                            {
                                !fileUpload && (
                                    <Button variant="contained" disabled className={styles.btn}>
                                        {
                                            "Add Learners"
                                        }
                                    </Button>
                                )
                            }
                        </div>

                    </div>
                )}

                {!addLearnerInLearningJourney && (
                    <div className={`${styles.container}  scrollbar thick-scrollbar orange-scrollbar-thumb`}>
                        {/* STEP-1 */}
                        <Step1
                            userType={userType}
                            setLaunchType={setLaunchType}
                            contentType={contentType}
                            setContentType={setContentType}
                            setStep2={setStep2}
                            setStep3={setStep3}
                            companies={companies}
                            companiesObject={companiesObject}
                            selectedCompany={selectedCompany}
                            setSelectedCompany={setSelectedCompany}
                            _selectCompany={_selectCompany}
                            groups={groups}
                            groupsObject={groupsObject}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            _selectGroup={_selectGroup}
                            microskills={microskills}
                            microskillsObject={microskillsObject}
                            selectedMicroskills={selectedMicroskills}
                            setSelectedMicroskills={setSelectedMicroskills}
                            _selectMicroskills={_selectMicroskills}
                            _selectAllMicroskills={_selectAllMicroskills}
                            forms={forms}
                            formsObject={formsObject}
                            selectedForms={selectedForms}
                            setSelectedForms={setSelectedForms}
                            _selectForm={_selectForm}
                            documents={documents}
                            documentsObject={documentsObject}
                            selectedDocuments={selectedDocuments}
                            setSelectedDocuments={setSelectedDocuments}
                            _selectDocuments={_selectDocuments}
                            _selectAllDocuments={_selectAllDocuments}
                            bulletins={bulletins}
                            setBulletins={setBulletins}
                            bulletinsObject={bulletinsObject}
                            selectedBulletin={selectedBulletin}
                            setSelectedBulletin={setSelectedBulletin}
                            _selectBulletin={_selectBulletin}
                            unlockChallenges={unlockChallenges}
                            setUnlockChallenges={setUnlockChallenges}
                            selectedNav={selectedNav}
                            LAUNCHPG_NAV_TYPES={LAUNCHPG_NAV_TYPES}
                            clearGroups={clearGroups}
                            clearSelectedMs={clearSelectedMs}
                        />


                        {/* STEP-2 */}
                        {
                            selectedNav === LAUNCHPG_NAV_TYPES[0] && step2 ?
                                <Step2
                                    contentType={contentType}
                                    launchType={launchType}
                                    setLaunchType={setLaunchType}
                                    setStep3={setStep3}
                                    scheduledDates={scheduledDates}
                                    setScheduledDates={setScheduledDates}
                                    selectedNav={selectedNav}
                                    LAUNCHPG_NAV_TYPES={LAUNCHPG_NAV_TYPES}
                                /> : null
                        }
                        {
                            selectedNav === 'LEARNING_JOURNEY' && step2 ? <LJStep2
                                journeyName={journeyName}
                                setJourneyName={setJourneyName}
                                selectedMicroskillsSequence={selectedMicroskillsSequence}
                                setSelectedMicroskillsSequence={setSelectedMicroskillsSequence}
                                setStep3={setStep3}
                                groupLaunchFlag={groupLaunchFlag}
                                setGroupLaunchFlag={setGroupLaunchFlag}
                                unlockCondition={unlockCondition}
                                setUnlockCondition={setUnlockCondition}
                                minScoreToUnlock={minScoreToUnlock}
                                setMinScoreToUnlock={setMinScoreToUnlock}
                                contentType={contentType}
                                launchType={launchType}
                                setLaunchType={setLaunchType}
                                scheduledDates={scheduledDates}
                                setScheduledDates={setScheduledDates}
                                selectedNav={selectedNav}
                                LAUNCHPG_NAV_TYPES={LAUNCHPG_NAV_TYPES}
                                editingJourney={editingJourney}

                            /> : null
                        }
                        {/* STEP-3 */}
                        {
                            !editingJourney && (step2 && step3) ?
                                <Step3
                                    learnerOption={learnerOption}
                                    setLearnerOption={setLearnerOption}
                                    fileUpload={fileUpload}
                                    setFileUpload={setFileUpload}
                                    setShowDwModal={setShowDwModal}
                                    selectedUsers={selectedUsers}
                                    setSelectedUsers={setSelectedUsers}
                                    userData={userData}
                                    setUserData={setUserData}
                                    selectedMicroskills={selectedMicroskills}
                                    setHeadings={setHeadings}
                                    setLoadingUserData={setLoadingUserData}
                                    withDemograhicwiseOption={enableDemographicwiseOption()}
                                    validationData={validationData}
                                /> : null
                        }



                        {/* STEP-3 */}
                        {/* {
                        (step2 && step3) ?
                            <Step3
                                learnerOption={learnerOption}
                                setLearnerOption={setLearnerOption}
                                fileUpload={fileUpload}
                                setFileUpload={setFileUpload}
                            /> : null
                    } */}

                        <div style={{
                            display: 'flex',
                            gap: '4rem',
                            position: (step3) ? 'static' : 'absolute',
                            // position: (selectedNav !== LAUNCHPG_NAV_TYPES[0] && (step2 || step3)) ? 'static' : 'absolute',
                            bottom: (step3) ? '' : '26px',

                        }
                        }>
                            {
                                editingJourney ? <>
                                    {
                                        isDataUploading ? (
                                            <div className={styles.centerFlex}>
                                                <CircularProgress sx={{
                                                    color: '#f4511e'
                                                }} />
                                            </div>
                                        ) : (
                                            <>
                                                <Button variant="contained" className={styles.btn} onClick={handleJourneyUpdate} sx={launchBtnStyling} >UPDATE </Button>
                                                <Button variant="outlined" className={styles.btn} onClick={handleCancelJourneyEdit} sx={{ ...launchBtnStyling, marginLeft: '1rem' }}>CANCEL</Button>
                                            </>
                                        )
                                    }
                                </> : <>
                                    {
                                        !(step2 && step3) ?
                                            //instead of loading btn here we have to show disabled button until all validation are clear on context list
                                            <Button variant="contained" disabled className={styles.btn}>
                                                {
                                                    launchType.length ?
                                                        launchType.includes("DISABLE") ? "DISABLE" : 'LAUNCH'
                                                        : "LAUNCH"
                                                }
                                            </Button> :
                                            (
                                                launching ?

                                                    <LoadingButton
                                                        loading
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        variant="outlined"
                                                        className={styles.btn}
                                                        sx={{
                                                            borderWidth: '2px !important'
                                                        }}
                                                    >

                                                        {

                                                            launchType.includes("DISABLE") ? "DISABLING..." : 'LAUNCHING...'

                                                        }
                                                    </LoadingButton>
                                                    : <Button title='LAUNCH'
                                                        variant="contained"
                                                        className={styles.btn}
                                                        onClick={handleLaunch}
                                                        sx={launchBtnStyling}
                                                    >
                                                        {
                                                            launchType.length ?
                                                                launchType.includes("DISABLE") ? "DISABLE" : 'LAUNCH'
                                                                : "LAUNCH"
                                                        }
                                                    </Button>
                                            )
                                    }


                                    <Button
                                        title='CLEAR'
                                        variant="outlined"
                                        onClick={handleClear}
                                        className={styles.btn}
                                        sx={{
                                            backgroundColor: "#FFFFFF !important",
                                            border: '2px solid #f4511e !important',
                                            color: '#f4511e !important',
                                            '&:hover': {
                                                backgroundColor: "#FFFFFF !important",
                                                border: '2px solid #f4511e !important',
                                                color: '#f4511e !important',
                                                boxShadow: '1px 2px 7px 0px rgba(0,0,0,0.4)'
                                            }
                                        }}>
                                        CLEAR & DELETE
                                    </Button>
                                </>
                            }

                        </div >

                        {failed && (
                            <FailureDialog
                                setErrorDialogOpen={setFailed}
                                errorDialogOpen={failed}
                                dialogText={failedText}
                                handleClick={handleFailClick}
                            />
                        )}

                        {
                            success && (
                                <SuccessDialog
                                    successDialogOpen={success}
                                    setSuccessDialogOpen={setSuccess}
                                    dialogText={successText}
                                    handleClick={handleSuccessClick}
                                    bgOpacity={50}
                                    buttonText={'OK'}
                                />
                            )
                        }
                    </div >
                )}
            </main >
            {success && (
                <SuccessDialog
                    successDialogOpen={success}
                    setSuccessDialogOpen={setSuccess}
                    dialogText={successText}
                    handleClick={handleSuccessClick}
                    bgOpacity={50}
                    buttonText={'OK'}
                />
            )}

            {showDwModal && (
                <SelectUserModal
                    loading={loadingUserData}
                    open={showDwModal}
                    setOpen={setShowDwModal}
                    headings={headings}
                    userData={userData}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            )}
        </div >
    )
}

export default UserLaunch;


