import React, { useState, useEffect } from 'react'

//mui
import MuiCheckbox from "@mui/material/Checkbox"

//components
import Question from '../custom-input';
import SkillTags from '../skill-tags';
import McqResponse from './mcq-response';
import Label from '../../../../../custom-styled/label';


export const CHECKBOX_TYPES = {
  ALL_OF_THE_ABOVE: "All of the above",
  NONE_OF_THE_ABOVE: "None of the above"
}


const MultipleChoiceQuestions = ({
  listitem,
  isUnChecked,
  isDisableAll,
  question,
  handleQuestionChange,
  list,
  setList,
  itemId,
  tags,
  selectedTag,
  handleTagClick,
  questionValidation,
  responsesCharLimit }) => {
  // console.log('MultipleChoiceQuestions listitem = ', listitem);
  // const [selectedTag, setSelectedTag] = useState(listitem.skillId);
  const [mcqResponse, setMcqResponse] = useState(listitem.answers);
  const [emptyAnswers, setEmptyAnswers] = useState(listitem.emptyAnswers);
  const [unChecked, setUnChecked] = useState(listitem.unCheckedOptions);

  useEffect(() => {
    // let result = list.filter((ele, i) => ele.id === itemId)
    // setMcqResponse(result[0].answers)
    setMcqResponse(listitem.answers);
    setEmptyAnswers(listitem.emptyAnswers);
  }, [list, mcqResponse])


  // const handleTagClick = (item) => {
  //   console.log('handleTagClick item = ',item );
  //   console.log('handleTagClick listitem = ',listitem );
  //   setSelectedTag(item.id)
  // }



  const handleResponseChange = (id, value) => {
    console.log('handleResponseChange list = ', list);
    let temp = [...mcqResponse]
    temp[id].option = value;
    setMcqResponse(temp);
    let tempEmpty = [...emptyAnswers]
    tempEmpty[id].isEmpty = false;
    setEmptyAnswers(tempEmpty);
  }

  const handleRadioChange = (event) => {
    if (isDisableAll) {
      return
    }
    let tempUnChecked = [...unChecked];
    for (let i = 0; i < tempUnChecked.length; i++) {
      tempUnChecked[i].unChecked = false;
    }
    setUnChecked(tempUnChecked);
    let value = parseInt(event.target.value)
    // setCorrect(value);
    let temp = [...mcqResponse];
    for (let i = 0; i < temp.length; i++) {
      temp[i].value = false;
    }
    temp[value].value = true;
    setMcqResponse(temp)
  };

  // const handleCheckboxChange = (e) => {
  //   if (isDisableAll) return
  //   const { name } = e?.target;

  //   let tempList = [...list]
  //   for (let i = 0; i < tempList.length; i++) {
  //     if (tempList[i].id === itemId) {
  //       // Determine if both checkboxes are currently checked.
  //       const bothChecked = tempList[i].answers[3].option === CHECKBOX_TYPES.ALL_OF_THE_ABOVE && tempList[i].answers[2].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE;

  //       if (name === CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {

  //         // Toggle the 'All of the above' checkbox
  //         if (tempList[i].answers[3].option === CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {
  //           tempList[i].answers[3].option = ''; // Uncheck it
  //         } else {
  //           tempList[i].answers[3].option = CHECKBOX_TYPES.ALL_OF_THE_ABOVE;
  //           // If we are checking 'All of the above', ensure 'None of the above' is unchecked if both checked scenario
  //           if (bothChecked) {
  //             tempList[i].answers[2].option = ''; // Uncheck 'None of the above'
  //           }
  //         }
  //       } else if (name === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
  //         // Toggle the 'None of the above' checkbox
  //         if (tempList[i].answers[2].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
  //           tempList[i].answers[2].option = ''; // Uncheck it
  //         } else {
  //           tempList[i].answers[2].option = CHECKBOX_TYPES.NONE_OF_THE_ABOVE;
  //           // If we are checking 'None of the above', ensure 'All of the above' is unchecked if both checked scenario
  //           if (bothChecked) {
  //             tempList[i].answers[3].option = ''; // Uncheck 'All of the above'
  //           }
  //         }
  //       }
  //     }
  //   }
  //   setList(tempList);
  // };

  const handleCheckboxChange = (e) => {
    // Return early if all actions are disabled
    if (isDisableAll) return;

    const { name } = e?.target;
    if (![CHECKBOX_TYPES.ALL_OF_THE_ABOVE, CHECKBOX_TYPES.NONE_OF_THE_ABOVE].includes(name)) {
      return; // Ignore if the checkbox name is not valid
    }

    // Find the index of the item in the list by itemId
    const itemIndex = list.findIndex(item => item.id === itemId);

    if (itemIndex === -1) return; // If item not found, exit early

    const item = list[itemIndex];

    // Handle 'All of the above' checkbox
    if (name === CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {
      if (item.answers[3].option === CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {
        if (item.answers[2].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
          item.answers[2].option = "";
          item.answers[3].option = CHECKBOX_TYPES.NONE_OF_THE_ABOVE;
        } else {
          item.answers[3].option = ''; // Uncheck 'All of the above'
        }
      } else {
        if (item.answers[3].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
          item.answers[2].option = CHECKBOX_TYPES.NONE_OF_THE_ABOVE;
          item.answers[3].option = CHECKBOX_TYPES.ALL_OF_THE_ABOVE; // Check 'All of the above'
        } else {
          item.answers[3].option = CHECKBOX_TYPES.ALL_OF_THE_ABOVE; // Check 'All of the above'
        }
      }
    }

    // Handle 'None of the above' checkbox
    else if (name === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
      if (item.answers[3].option !== CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {
        item.answers[3].option = (item.answers[3].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE)
          ? '' : CHECKBOX_TYPES.NONE_OF_THE_ABOVE;
      } else if (item.answers[2].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
        item.answers[2].option = ''; // Uncheck 'None of the above'
      } else {
        item.answers[2].option = CHECKBOX_TYPES.NONE_OF_THE_ABOVE; // Check 'None of the above'
      }
    }

    // Update the list in one go after modification
    const updatedList = [...list];
    updatedList[itemIndex] = item;
    setList(updatedList);
  };




  const isChecked = (name) => {
    let checked = false;
    if (name === CHECKBOX_TYPES.ALL_OF_THE_ABOVE) {
      checked = mcqResponse[3].option === CHECKBOX_TYPES.ALL_OF_THE_ABOVE
    } else if (name === CHECKBOX_TYPES.NONE_OF_THE_ABOVE) {
      checked = mcqResponse[2].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE || mcqResponse[3].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE
    }
    return checked;
  }

  const AoaAndNoaContainer = () => {
    return (
      <div>
        <MuiCheckbox
          key={mcqResponse[3].option}
          id={`${CHECKBOX_TYPES.ALL_OF_THE_ABOVE}_${itemId}`}
          type='checkbox'
          name={CHECKBOX_TYPES.ALL_OF_THE_ABOVE}
          disabled={isDisableAll}
          onClick={handleCheckboxChange}
          checked={isChecked(CHECKBOX_TYPES.ALL_OF_THE_ABOVE)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
          }}
        />
        <Label labelText={'All of the above'} htmlFor={isDisableAll ? '' : `${CHECKBOX_TYPES.ALL_OF_THE_ABOVE}_${itemId}`} fs='0.8rem' color='rgb(0, 0, 0, 0.9)' fw={700} />

        <MuiCheckbox
          key={mcqResponse[2].option}
          id={`${CHECKBOX_TYPES.NONE_OF_THE_ABOVE}_${itemId}`}
          type='checkbox'
          name={CHECKBOX_TYPES.NONE_OF_THE_ABOVE}
          disabled={isDisableAll}
          onClick={handleCheckboxChange}
          checked={isChecked(CHECKBOX_TYPES.NONE_OF_THE_ABOVE)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
          }}
        />
        <Label labelText={'None of the above'} htmlFor={isDisableAll ? '' : `${CHECKBOX_TYPES.NONE_OF_THE_ABOVE}_${itemId}`} fs='0.8rem' color='rgb(0, 0, 0, 0.9)' fw={700} />
      </div>
    )
  }

  return (
    <>
      <Question
        isDisabled={isDisableAll}
        // defaultValue={question}
        defaultValue={listitem.question}
        handleChange={handleQuestionChange}
        limitText={questionValidation.limitText}
        isExceeded={questionValidation.exceeded}
        isEmpty={listitem.isQuestionEmpty}
      />
      <SkillTags tags={tags} selectedTag={selectedTag} handleTagClick={handleTagClick} />
      <McqResponse
        listitem={listitem}
        isUnChecked={isUnChecked}
        itemId={itemId}
        responsesCharLimit={responsesCharLimit}
        isDisableAll={isDisableAll}
        mcqResponses={mcqResponse}
        handleResponseChange={handleResponseChange}
        handleRadioChange={handleRadioChange}
      />
      <AoaAndNoaContainer />
    </>
  )
}

export default MultipleChoiceQuestions;

