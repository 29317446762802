import React, { useState, useEffect } from 'react';

import { makeStyles } from "@mui/styles";

const RollingTextInput = ({
    value = '',
    setValue,
    handleChange
}) => {
    const classes = useStyles();
    let texts = [
        'Type specific keywords such as',
        '“Product information” or “Product Name”',
        'Generate questions on...',
        'Difficult questions on...',
        'Provide generic prompt to cover more topics'
    ];
    let menu = [
        'Generate questions on',
        'Difficult questions on'
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const h = 30


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000); 

        return () => clearInterval(interval);
    }, []);

    const handleFocus = () => {
        setShowMenu(true);
    }

    const handleBlur = () => {
       setTimeout(()=>setShowMenu(false),500)
    }

    const handleMenuItem = (text) => {
        setValue(text)
        setShowMenu(false);

    }

    return (
        <div className={classes.wrapper} onBlur={handleBlur}>
            <div className={classes.inputContainer} style={{ height: 38 }}>
                <input onFocus={handleFocus} onClick={handleFocus}  type="text" value={value} onChange={handleChange} className={classes.animatedInput} />
                {!value.length && <div className={classes.textOverlay} style={{ height: h }}>
                    <div className={classes.textWrapper} style={{ transform: `translateY(${-currentIndex * h}px)` }}>
                        {texts.map((text, index) => (
                            <p key={index} className={classes.textItem} style={{ height: h }}>
                                {text}
                            </p>
                        ))}
                    </div>

                </div>}

                {/* <div className={classes.rolllerBox}>
                {texts.map((item, index) => (
                    <div key={index} className={classes.rollerItem} style={{ animationDelay: `${(10 / 5) * index}s` }}>{item}</div>
                ))}
            </div> */}
            </div>
            {showMenu ? <div className={classes.menu}>{menu.map((text, index) => (<p key={index} onClick={()=>handleMenuItem(text)}>{text}</p>))} </div> : null}

        </div>

    );
};

export default RollingTextInput;



const useStyles = makeStyles(() => ({
    wrapper: {
        position: 'relative',

    },

    inputContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: 300,
        outline: '1.5px solid rgb(184, 184, 184, 0.5)',
        borderRadius: '3px',
        width: '100%',
        margin: '0.5rem 0',
        '&:focus-within': {
            outline: '2px solid #4F0060',
        },
        '&:hover': {
            outline: '2px solid #4F0060',
        },
    },

    animatedInput: {
        // display:'none',
        width: '100%',
        padding: 10,
        border: 'none',
        color: 'rgb(112, 112, 112)',
        fontSize: "0.9rem",
        fontWeight: 500,
        '&:focus': {
            outline: 'none',
        },
    },

    textOverlay: {
        position: 'absolute',
        top: 10, /* Adjust based on padding of input */
        left: 10, /* Adjust to match input padding */
        pointerEvents: 'none',
        // height: 30, /* Set the height of one text */
        fontSize: 16,
        color: '#aaa' /* Placeholder-like color */
    },

    textWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'transform 0.5s ease-in-out',
        minWidth: '20rem'
    },


    textItem: {
        color: 'rgb(112, 112, 112, 0.5)',
        fontSize: "0.9rem",
        fontWeight: 500,
    },


    error: {
        outline: '2px solid #EB3917 !important',
        '&:hover': {
            outline: '2px solid #EB3917 !important',
        },
    },
    menu: {
        position: 'absolute',
        top: 50,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 999,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;',
        '& > p': {
            color: '#484848',
            padding: '0.8rem',
            '&:hover': {
                backgroundColor: '#f9f9f9 !important',
                cursor: 'pointer'
            }
        }
    },

    rolllerBox: {
        width: '100%',
        height: 30,
        overflowY: 'hidden',
        position: 'relative',
        marginTop: '3rem',
        border: '1px solid #000',
    },
    rollerItem: {
        width: '100%',
        height: 30,
        fontSize: '1rem',
        animation: '$scroll 10s linear infinite',
        position: 'absolute',
        top: '0',
        left: '0',
        visibility: 'hidden',
    },
    "@keyframes scroll": {
        "0%": {
            top: '100%',
            visibility: 'visible'
        },

        '20%': {
            top: '0px'
        },
        '40%': {
            top: '-100%',
            visibility: 'hidden'
        },
        '100%': {
            top: '100%',
            visibility: 'hidden'
        }

    },

}));


