import React, { useState, useRef, useEffect } from "react";

// mui
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//components
import Table from '../table';
import CustomButton from "../../../custom-styled/button";
import SearchBar from "../../../custom-styled/search-bar";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '85rem',
    minWidth: '30rem',
    width: '100%',
    maxHeight: '38rem',
    height: "auto",
    // height: "100%",
    bgcolor: 'background.paper',
    border: 'none !important',
    outline: 'none !important',
    boxShadow: 24,
    border: 'none',
    overflow: 'hidden',
    // p: 3,
    borderRadius: '5px',
    '& .MuiTypography-root': {
        fontFamily: 'montserrat'
    }
}


const SelectUserModal = ({
    loading,
    open = false,
    setOpen = () => { },
    userData = [],
    headings,
    selectedUsers,
    setSelectedUsers
}) => {

    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [selected, setSelected] = useState(selectedUsers);
    const [resetStatsUsers, setResetStatsUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const debounceSearch = useRef();

    useEffect(() => {
        setTableData(userData)
    }, [userData])

    const handleSelectAllUsers = (e) => {
        let isSelectAll = tableData.length === selected.length;
        if (isSelectAll) {
            setSelected([]);
        } else {
            let all = tableData.map(ele => ele.Username)
            setSelected(all);
        }

    }
    const handleSelectUsers = (item) => {
        if (selected.includes(item.Username)) {
            let filtered = selected.filter(ele => ele !== item.Username);
            setSelected(filtered);
        } else {
            setSelected([...selected, item.Username])
        }
    }

    const handleResetStatistics = (item) => {
        // console.log('item = ', item);
        if (resetStatsUsers.includes(item.Username)) {
            let filtered = resetStatsUsers.filter(ele => ele !== item.Username);
            setResetStatsUsers(filtered);
        } else {
            setResetStatsUsers([...resetStatsUsers, item.Username])
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        clearTimeout(debounceSearch.current);
        let value = e.target.value;
        setSearchValue(value);
        let delay = 200;
        if (!value.length) {
            setTableData(userData);
            return
        }
        debounceSearch.current = setTimeout(() => {
            let result = userData.filter((item) => {
                if (item?.Username?.toLowerCase().includes(value.toLowerCase())) return item;
            })
            setTableData(result)
            clearTimeout(debounceSearch.current);
        }, delay)
    }

    // const searchMultiple = (item, search = '') => {
    //     let isMatching = false;
    //     if (item.username.toLowerCase().includes(search)) isMatching = true;
    //     if (item.email.toLowerCase().includes(search)) isMatching = true;
    //     if (item.mobile.toLowerCase().includes(search)) isMatching = true;
    //     return isMatching
    // }


    const handleClear = () => {
        setSearchValue('');
        setTableData(userData);
    }

    const handleSave = () => {
        setSelectedUsers(selected);
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                '& > .MuiBackdrop-root ': {
                    // backgroundColor: 'rgb(255 255 255 / 70%)',
                    backgroundColor: 'rgb(0 0 0 / 75%)',
                    backdropFilter: 'blur(4px)'
                }
            }}
        >
            <Box sx={style}>
                <div className={classes.header}>
                    {/* <img onClick={() => setOpen(false)} src='/images/gba-assets/left-arrow.svg' alt="back-btn" /> */}
                    <h1 className={classes.title}>Selected Users</h1>
                    <SearchBar
                        handleChange={handleSearch}
                        handleClear={handleClear}
                        value={searchValue}
                    />


                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            color: '#f4511e',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.row2}>
                    <Table
                        loader={loading}
                        tableHeadings={headings}
                        tableData={tableData}
                        selected={selected}
                        handleSelect={handleSelectUsers}
                        handleSelectAll={handleSelectAllUsers}
                        resetStatsUsers={resetStatsUsers}
                        handleResetStatistics={handleResetStatistics}
                    />
                    <CustomButton
                        btnText="Save Selected"
                        width="10rem"
                        fs="0.7rem"
                        fw={700}
                        textColor='#fff'
                        borderColor='#f4511e'
                        bgcolor='#f4511e'
                        handleClick={handleSave}
                    />
                </div>
            </Box>
        </Modal>
    )
}

export default SelectUserModal;

const useStyles = makeStyles((theme) => ({


    header: {
        // border: '1px solid black',
        position: 'relative',
        width: '100%',
        height: '3.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        padding: '0 2rem',
        '& > form': {
            position: 'absolute',
            left: '30%',
            top: '0.5rem',
            border: '1px solid #70707050',
            boxShadow: 'none',
            width: 600
        }

    },
    title: {
        color: '#1D1D1D',
        fontSize: '1.2rem',
        fontWeight: 'Bold',
        fontFamily: 'Montserrat'
    },
    row2: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        alignItems: 'center',
        backgroundColor: '#70707009',
        minHeight: '35rem',
        padding: '1rem',
        textAlign: 'center',
        '& > button:hover': {
            color: '#fff',
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',

        }
    },
}));