import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: '0.5rem'
    },
    header: {

    },
    addContentBtn: {
        position: 'relative',
        color: "#F4511E",
        backgroundColor: '#fff',
        border: '1px dashed #ff7043',
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '180px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
        '& > svg': {
            marginLeft: '0.6rem'

        },
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    customFieldStyle: {
        display: 'flex',
        gap: '2rem',
        margin: '1rem 0',
        alignItems: 'center',
        "& > img": {
            cursor: "pointer",
            height: '1rem',
            transition: 'scale 0.3s',
            "&:hover": {
                scale: 1.2,
                filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'

            }
        },
        "& > h5": {
            fontFamily: 'Montserrat',
            fontSize: '0.7rem',
            fontWeight: 700,
            color: '#707070',
            textAlign: 'center',
            padding: '0.5rem 0',
        },
        "& > div:first-child, button": {
            backgroundColor: "#ffffff",
        },
        "& > input": {
            backgroundColor: "#ffffff",
            border: '1px solid rgb(112, 112, 112, 0.4)',
            cursor: 'pointer',
            height: '33px',
            borderRadius: 4,
            padding: '0 0.5rem',
            color: 'rgb(112, 112, 112, 0.9)',
        }
    },
    toggleWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        // top: '-0.2rem',
        "& > h4": {
            fontFamily: 'Montserrat',
            fontSize: '0.7rem',
            fontWeight: 700,
            color: '#707070',
        }
    },
    checkboxContainer: {
        marginRight: "5px",
    },
    checkBoxDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapperTable: {
        width: "100%",
        borderCollapse: "collapse",
        "& th": {
            padding: "10px",
            textAlign: "left",
        },
        "& td": {
            padding: "10px",
            textAlign: "left",
        },
    },
    customFieldRow: {
        "&:hover": {
            backgroundColor: "#f9f9f9",
        },
    },
    toggleWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    checkBoxDiv: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
}));
export default useStyles;