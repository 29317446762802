import React, { useRef } from "react";
import Editor from "../../../custom-styled/editor";
import useStyles from "./index.styles";

const LinkTitleEditor = ({
    titleBody,
    setTitleBody,
    setTitleText,
}) => {
    const titleRef = useRef(null);
    const classes = useStyles();

    const handeleTitleChange = (text) => {

        const editor = titleRef.current.getEditor();
        let value = editor.getText().trim();

        setTitleBody(prev => ({ ...prev, title: text }))
        setTitleText(prev => prev = value)
    }

    return (
        <div className={classes.wrapper}>
            <Editor
                quillRef={titleRef}
                handleChange={handeleTitleChange}
                placeholder={"Enter Title Text Here"}
                value={titleBody.title}
            />
        </div>
    )
};

export default LinkTitleEditor;