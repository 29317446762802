import React, { useEffect, useRef, useState } from 'react'

//mui
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

//components
import MultipleAnswers from './components/multiple-answers';
import MultipleChoiceQuestions from './components/multiple-choice-questions';
import MatchTheRightResponse from './components/match-the-right-response';
import TrueFalse from './components/true-false';
import isEmpty from '../../../../utils/isEmpty';

//constants
import constants from '../../../../constants';

//utils
import useQuery from '../../../../utils/useQuery';

//styles
import useStyles from './index.styles';

const QuestionAnswer = ({
    listitem,
    isDisableAll,
    getQuestionNumber,
    type,
    designType,
    tags,
    itemId,
    list,
    setList,
    setSettings }) => {
    const query = useQuery();
    const isEdit = query.get('edit');
    const [selectedTag, setSelectedTag] = useState(listitem.skillId);
    const [question, setQuestion] = useState(listitem.question)
    const [context, setContext] = useState(listitem?.contextObj?.context)
    const questionCharlimit = constants.GBA_VALIDATIONS[designType].questionMaxLength;
    const [questionValidation, setQuestionValidation] = useState({ exceeded: false, limitText: `${questionCharlimit} Characters` });
    const contextCharlimit = constants.GBA_VALIDATIONS[designType].contextMaxLength;
    const [contextValidation, setContextValidation] = useState({ exceeded: false, limitText: `${contextCharlimit || 0} Characters` });
    const responsesCharLimit = constants.GBA_VALIDATIONS[designType].responseMaxLength;


    const classes = useStyles();

    useEffect(() => {
        setQuestion(listitem.question);
        setSelectedTag(listitem.skillId);
        //SETTING CHARACTER VALIDATION ON COLLAPSE TOGGGLE
        if (type !== "MTRR") {
            let questionTempLimit = {};
            let value = listitem.question;
            if (value.length > questionCharlimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
                questionTempLimit = { exceeded: true, limitText: `${value.length - questionCharlimit} Characters Exceeded` };
                setQuestionValidation(questionTempLimit);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
                if (listitem.isQuestionEmpty) {
                    questionTempLimit = { exceeded: false, limitText: `*Required` };
                } else {
                    questionTempLimit = { exceeded: false, limitText: `${questionCharlimit - value.length} Characters` };
                }
                setQuestionValidation(questionTempLimit);
            }
        }


        if (type !== "MCQ" && type !== "MA") {
            setContext(listitem?.contextObj?.context);
            //SETTING CHARACTER VALIDATION ON COLLAPSE TOGGGLE
            let contextTempLimit = {};
            let value = listitem.contextObj?.context;
            if (value?.length > contextCharlimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN contextCharlimit CHARACTERS.
                contextTempLimit = { exceeded: true, limitText: `${value.length - contextCharlimit} Characters Exceeded` };
                setContextValidation(contextTempLimit);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN contextCharlimit CHARACTERS.
                if (listitem.isContextEmpty) {
                    contextTempLimit = { exceeded: false, limitText: `*Required` };
                } else {
                    contextTempLimit = { exceeded: false, limitText: `${contextCharlimit - value.length} Characters` };
                }
                setContextValidation(contextTempLimit);
            }

        }

    }, [])



    const handleContextChange = (e) => {
        // console.log('Context = ', itemId, " ", e.target.value);
        const value = e.target.value.trim();

        let tempLimit = {};
        if (value.length > contextCharlimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN contextCharlimit CHARACTERS.
            tempLimit = { exceeded: true, limitText: `${value.length - contextCharlimit} Characters Exceeded`, isEmpty: false };
            setContextValidation(tempLimit);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN contextCharlimit CHARACTERS.
            tempLimit = { exceeded: false, limitText: `${contextCharlimit - value.length} Characters`, isEmpty: false };
            setContextValidation(tempLimit);
        }

        let temp = [...list]
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === itemId) {
                temp[i].contextObj.context = value;
                temp[i].isContextEmpty = false;
                console.log('isEdit = ', isEdit);
                console.log('type = ', type);
                if (isEdit && type === "TF") {
                    temp[i].contextObj.id = i;
                    temp[i].contextId = i;
                }
                if (designType === "MTRR_PopcornMatch" && !isEmpty(temp[i].contextObj.speechUrl)) {
                    temp[i].contextObj.speechUrl = "";
                }
                setList(temp);
                break
            }
        }
        return
    }

    const handleQuestionChange = (e) => {
        // const value = e.target.value.trim();
        const value = e.target.value;

        let tempLimit = {};
        if (value.length > questionCharlimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
            tempLimit = { exceeded: true, limitText: `${value.length - questionCharlimit} Characters Exceeded`, isEmpty: false };
            setQuestionValidation(tempLimit);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
            tempLimit = { exceeded: false, limitText: `${questionCharlimit - value.length} Characters`, isEmpty: false };
            setQuestionValidation(tempLimit);
        }

        setQuestion(value)

        let temp = [...list];
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === itemId) {
                temp[i].question = value;
                temp[i].isQuestionEmpty = false;
                if ((designType === "MA_SpaceRocks" || designType === "TF_CrazyCars" || designType === "TF_BuildingBlocks" || designType === "TF_TastyTruth") && !isEmpty(temp[i].speechUrl)) {
                    temp[i].speechUrl = "";
                }
                setList(temp);
                break
            }
        }
        return
    }

    const handleTagClick = (item) => {
        if (isDisableAll) return
        if (listitem.skillId === item.id) {
            setSelectedTag("");
            let temp = [...list];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id === itemId) {
                    temp[i].skillId = "";
                    setList(temp);
                    break
                }
            }
            return
        }
        setSelectedTag(item.id)
        let temp = [...list];
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === itemId) {
                temp[i].skillId = item.id;
                setList(temp);
                break
            }
        }
        return
    }

    const isUnChecked = (idx) => {
        let unChecked = false;
        if (listitem.unCheckedOptions[idx].unChecked) unChecked = true;
        return unChecked;
    }

    return (
        <>
            <div className={classes.mainContainerQA}>
                {type === 'MCQ' ?
                    <MultipleChoiceQuestions
                        listitem={listitem}
                        isUnChecked={isUnChecked}
                        isDisableAll={isDisableAll}
                        question={question}
                        questionValidation={questionValidation}
                        handleQuestionChange={handleQuestionChange}
                        itemId={itemId}
                        tags={tags}
                        selectedTag={selectedTag}
                        handleTagClick={handleTagClick}
                        list={list}
                        setList={setList}
                        responsesCharLimit={responsesCharLimit}
                    /> : null}
                {type === 'MTRR' ?
                    <MatchTheRightResponse
                        listitem={listitem}
                        isDisableAll={isDisableAll}
                        designType={designType}
                        itemId={itemId}
                        tags={tags}
                        selectedTag={selectedTag}
                        handleTagClick={handleTagClick}
                        list={list}
                        context={context}
                        contextValidation={contextValidation}
                        handleContextChange={handleContextChange}
                        questionCharlimit={questionCharlimit}
                        responseCharlimit={responsesCharLimit}
                    /> : null}
                {type === 'TF' ?
                    <TrueFalse
                        listitem={listitem}
                        isUnChecked={isUnChecked}
                        isDisableAll={isDisableAll}
                        itemId={itemId}
                        tags={tags}
                        selectedTag={selectedTag}
                        handleTagClick={handleTagClick}
                        designType={designType}
                        list={list}
                        setList={setList}
                        context={context}
                        contextValidation={contextValidation}
                        handleContextChange={handleContextChange}
                        question={question}
                        questionValidation={questionValidation}
                        handleQuestionChange={handleQuestionChange}
                        getQuestionNumber={getQuestionNumber}
                        responsesCharLimit={responsesCharLimit}
                        setSettings={setSettings}
                    /> : null}
                {type === 'MA' ?
                    <MultipleAnswers
                        listitem={listitem}
                        isUnChecked={isUnChecked}
                        isDisableAll={isDisableAll}
                        itemId={itemId}
                        tags={tags}
                        selectedTag={selectedTag}
                        handleTagClick={handleTagClick}
                        designType={designType}
                        list={list}
                        setList={setList}
                        question={question}
                        questionValidation={questionValidation}
                        handleQuestionChange={handleQuestionChange}
                        responsesCharLimit={responsesCharLimit}
                    /> : null}
            </div>
        </>
    )
}

export default QuestionAnswer;
