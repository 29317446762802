import { makeStyles } from "@mui/styles";


const commonStyles = makeStyles(() => ({
  unavailable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',

  },
  suggestionBox: {
    backgroundColor: '#fff',
    // border: '1px solid',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    // height: '15.8rem', //changing height while hiding suggested images
    // width : '10rem',
    height: '7.8rem',
    width: '10.2rem',
    position: 'absolute',
    top: '9rem',
    right: '-10.2rem',
    padding: '0.5rem',
    paddingLeft: '0.3rem',
    paddingRight: '1.5rem'
  },
  card: {
    // border: '1px solid',
    position: 'relative',
    // height: '560px',
    width: '420px',
    maxWidth: '420px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    gap: '2rem',
    overflowY: 'scroll',
    overflowX:"hidden",
    '&::-webkit-scrollbar': {
      width: '8px', // Set the scrollbar width
      height: '5px', // Set the scrollbar height
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#f4511e', // Thumb color
      borderRadius: '10px', // Rounded edges for the thumb
    },
    '&::-webkit-scrollbar-button': {
      display: 'none', // Removes the up/down or left/right arrow buttons
    },
  },
  aspect1: {
    aspectRatio: 3 / 4,
  },
  aspect2: {
    aspectRatio: 9 / 16,
  },
  btnBox: {
    // border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3.8rem',
    width: '100%',
    gap: '6rem',
    '& > button': {
        height: '42px',
        width: '150px',
        borderRadius: '1px',
        '&:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    }

},
titleContainer: {
  // border : '1px solid',
  width: '100%',
  overflow: 'hidden',
  minHeight: '48px',
  margin: '0.8rem 0 !important',
  '& > div': {
    margin: '0 !important',
    maxHeight: '100%',
    height: '100%',
    '&:hover': {
      border: 'none',
    }
  },
  '&:hover': {
    border: '1px dashed rgb(204, 204, 204, 0.5)',
  }
},
}));

export default commonStyles;