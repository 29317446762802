import React, { useEffect, useState } from 'react'

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';

//styles
import useStyles from '../../index.styles';
import commonUtil from '../../../../utils/commonUtil';
import Title from '../../../title';
import CustomInput from '../../../custom-styled/custom-input';

// const initialTitleCharLimit = 50;

const Step1 = ({
    userType,
    title,
    setTitle,
    message,
    setMessage,
    setStep2,
    setStep3,
    setStep4,
    companies,
    companiesObject,
    selectedCompany,
    setSelectedCompany,
    _selectCompany,
    setLaunchType,
    setFileUpload,
    launching,
    globalStates,
    titleCharLimit,
    setTitleCharLimit,
    isTitleCharLimitExceeded,
    setIsTitleCharLimitExceeded,
    initialTitleCharLimit,
    descriptionCharLimit,
    setDescriptionCharLimit,
    isDescriptionCharLimitExceeded,
    setIsDescriptionCharLimitExceeded,
    initialDescriptionCharLimit,

}) => {
    const classes = useStyles();

    // const handleTitleChange = (e) => {

    //     let value = e.target.value;
    //     setTitle(value);

    //     if (!value.length) {
    //         setStep2(false);
    //         setStep3(false);
    //         setStep4(false);
    //         setLaunchType([]);
    //         setFileUpload(null);
    //         return;
    //     }
    //     if (message.length) setStep2(true);
    // }

    const handleTitleChange = (e) => {
        let value = e?.target?.value;
        setTitle(value);

        // Calculate remaining characters :
        let remainingChars = initialTitleCharLimit - value.length;

        // Check if the character limit is exceeded :
        const exceededLimit = remainingChars < 0;

        // Update states based on exceededLimit :
        setIsTitleCharLimitExceeded(exceededLimit);
        setTitleCharLimit(exceededLimit ? Math.abs(remainingChars) : remainingChars);
    };


    // const handleMessageChange = (e) => {
    //     let value = e.target.value;
    //     setMessage(value);
    //     if (!value.length || isTitleCharLimitExceeded) {
    //         setStep2(false);
    //         setStep3(false);
    //         setStep4(false);
    //         setLaunchType([]);
    //         setFileUpload(null);
    //         return;
    //     }
    //     if (title.length && !isTitleCharLimitExceeded) setStep2(true);
    // }


    const handleMessageChange = (e) => {
        let value = e?.target?.value;
        setMessage(value);

        // Calculate remaining characters for the message (description):
        let remainingChars = initialDescriptionCharLimit - value.length;

        // Check if the character limit is exceeded:
        const exceededLimit = remainingChars < 0;

        // Update states based on exceededLimit:
        setIsDescriptionCharLimitExceeded(exceededLimit);
        setDescriptionCharLimit(exceededLimit ? Math.abs(remainingChars) : remainingChars);

    };

    useEffect(() => {

        if (title.length > initialTitleCharLimit || message.length > initialDescriptionCharLimit) {
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setLaunchType([]);
            setFileUpload(null);
            return;
        }

        if (title.length && message.length) {
            setStep2(true);
            return;
        } else {
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setLaunchType([]);
            setFileUpload(null);
            return;
        }
    }, [title, message])


    return (
        <>
            {userType === "superAdmin" &&
                <div className={classes.row} style={{ gap: '0.6rem', margin: '0.5rem 0' }}>
                    <Title titleText={"Company"} />
                    <CustomSelect
                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Company', selected: selectedCompany, dataObject: companiesObject })}
                        autoClose={true}
                        withSearch={true}
                        withSelectAllOption={false}
                        listData={companies}
                        selected={selectedCompany}
                        handleSelect={_selectCompany}
                    />
                </div>
            }
            <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem' }}>Step 1: Content</h2>


            <div className={classes.titleWrapper}>
                <Title titleText={"Title"} />
                <CustomInput
                    id="title"
                    withLimitWarning={true}
                    value={title}
                    placeholder="Notification Title Here"
                    handleChange={handleTitleChange}
                    wrapperWidth="50rem"
                    isExceeded={isTitleCharLimitExceeded}
                    exceedText={
                        isTitleCharLimitExceeded
                            ? `${titleCharLimit} Characters Exceeded`
                            : `${titleCharLimit} Characters`
                    }
                />



            </div>


            <div className={classes.messageWrapper}>
                <Title titleText={"Message"} />

                {/* <textarea
                    value={message}
                    placeholder='Notification Description Here'
                    onChange={handleMessageChange}
                /> */}

                <CustomInput
                    id="description"
                    withLimitWarning={true}
                    value={message}
                    placeholder="Notification Description Here"
                    handleChange={handleMessageChange}
                    wrapperWidth="50rem"
                    isExceeded={isDescriptionCharLimitExceeded}
                    exceedText={
                        isDescriptionCharLimitExceeded
                            ? `${descriptionCharLimit} Characters Exceeded`
                            : `${descriptionCharLimit} Characters`
                    }
                    isNotificationDescription={true}
                />


            </div>
        </>
    )
}

export default Step1;

