import { makeStyles } from "@mui/styles";
import { color, display, padding } from "@mui/system";

const useStyles = makeStyles(() => ({


    mainContainer: {
        display: 'flex'
    }
    ,
    leftContainer: {
        width: '25%',
        height: '90vh',
    },
    rightContainer: {
        width: '75%',
        height: '90vh',
        backgroundColor: 'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    radioContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap:"1rem",
        flexWrap: "wrap",
        '& > div > label': {
            padding: '0 0.25rem'
            // display:"none"
        },
        '& > div > input': {
            color: 'orange'
        }
    },
    LabelInputBox: {
        marginTop: "1rem",
        display:'flex',
        flexDirection:"column"
    },
    Label: {
        display: "inline-block",
        fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "12px",
        fontWeight: "Bold",
        characterSpacing: "0",
        lineSpacing: "146",
        padding: "0.8rem 0",
        "& > i": {
            color: "#f4511e",
            fontSize: "10px",
            fontWeight: 700,
            animation: '$highlight 2s infinite ease'
        },
    },
    '@keyframes highlight': {
        '0%': { color: '#f4511e' },
        '50%': { color: 'white' },
        '100%': { color: '#f4511e' },
    },
    Custom_File_Input: {
        display: "flex",
        alignItems: 'center',
        boxSizing: "border-box",
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: "400",
        width: "85%",
        minHeight: "2rem",
        height: "2rem",
        margin: "0.5rem 0",
        border: "1.4px solid #70707050",
        // boxShadow: "0 0 6px #DDE0E2",
        color: "#707070"
    },
    FilePath: {
        color: "#707070",
        overflow: "hidden",
        fontSize: "0.7rem",
        minWidth: "76%",
        width: "76%",
        fontFamily: "Open Sans",
        fontWeight: "400",
        paddingLeft: '3%'
    },
    Folder_Icon: {
        marginRight: "0.5rem"
    },
    
    Btn: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
        width: "24%",
        height: '100%',
        padding: "0.6rem",
        backgroundColor: "#F9F9F9",
        textAlign: "center",
        fontFamily: "Open Sans",
        fontSize: "0.5rem",
        fontWeight: "700",
        color: "#F4511E",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#EBEBF2",
        }
    },
    externalLinkInput: {
      border: '1.4px solid #ccc',
      borderRadius: '4px',
      position: 'relative',
    //   zIndex: 99999,
      color: "rgb(112, 112, 112, 0.6)",
      // fontFamily: "Montserrat",
    //   display:"none",
      fontSize: "0.7rem",
      fontWeight: "500",
      padding: '0.4rem',
      marginTop: '0.9rem',
      width: '82%'
    },
    captureExternalLinkInput: {
      border: '1px solid #f4511e',
  
    },
    labelInputContainer: {
        marginTop: "1rem",
        display:'flex',
        flexDirection:"column",
        gap: "1rem",
    },
    externalLinkBox: {
        marginTop: '0',
    }
}))

export default useStyles