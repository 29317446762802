import React from 'react'
import ManageBulletin from '../../components/manage-bulletin'

const ManageBulletinPage = () => {
  return (
    <>
    <ManageBulletin/>
    </>
  )
}

export default ManageBulletinPage