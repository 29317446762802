import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import { Button } from '@mui/material';

//components
import CustomBanner from "../../../banner";

//services
import challengesServices from '../../../../services/challenges';

//constants
import constants from '../../../../constants';
import draglistData from '../../draglist-data';

const GameType = ({ selectedGame, setSelectedGame, selectedGBATypeData, setSelectedGBATypeData, list, setList, isEdit, challengeId, menuItemStatus, setMenuItemStatus, setSelectedMenuItem, globalStates, setIsDisableAll }) => {
    const classes = useStyles();
    const [isGameDesignChange, setIsGameDesignChange] = useState(false);

    const handleSelectGameType = (e) => {
        const result = constants.GBA_GAMETYPE_DATA.filter((element) => element.type === e.target.value);
        //Onchange selecting first game of every type by default.
        let firstGame = result[0].challenges[0];
        setSelectedGame({
            ...selectedGame,
            index: 0,
            type: e.target.value,
            designType: firstGame.designType,
            name: firstGame.name,
            aboutTitle: firstGame.aboutTitle,
            json: firstGame.json,
            screen: firstGame.screen
        })
        setSelectedGBATypeData(result[0]);
        let selectedJson = JSON.stringify(draglistData[e.target.value]);
        setList(JSON.parse(selectedJson));
    }

    const handleSelectGame = (e, element, index) => {
        setSelectedGame({
            ...selectedGame,
            index: parseInt(e.target.id),
            designType: element.designType,
            name: element.name,
            aboutTitle: element.aboutTitle,
            json: element.json,
            screen: element.screen
        })
        if (element.designType === "TF_SoundRight") {
            let selectedJson = JSON.stringify(draglistData["TF_SoundRight"]);
            setList(JSON.parse(selectedJson));
            return
        }
        if (element.designType === "MA_RevealThePicture") {
            let selectedJson = JSON.stringify(draglistData["MA_RevealThePicture"]);
            setList(JSON.parse(selectedJson));
            return
        }
        if (element.designType === "MTRR_LearnoCasino") {
            let selectedJson = JSON.stringify(draglistData["MTRR_LearnoCasino"]);
            setList(JSON.parse(selectedJson));
            return
        }
        let selectedJson = JSON.stringify(draglistData[selectedGame.type]);
        !isEdit && setList(JSON.parse(selectedJson));
        (isEdit && selectedGame.designType !== element.designType) && setIsGameDesignChange(true)
    }

    const updateChallenge = async () => {
        let payload = {
            challengeId: challengeId,
            designType: selectedGame.designType
        }
        const res = await challengesServices.updateChallenge(payload);
        globalStates.handleProgressDialog(true, "Please Wait....");
        if (res.status === 200) {
            globalStates.handleProgressDialog(false);
            setIsDisableAll(false);
            setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'inProgress' })
            setSelectedMenuItem({ firstColumn: 'Content', secondColumn: 'ContextList' })
        }

    }
    return (
        <div className={classes.mainContainer}>
            {!isEdit &&
                <select onChange={handleSelectGameType} value={selectedGame.type} className={classes.selectBox}>
                    <option value='MCQ'>Multiple Choice Questions (MCQ)</option>
                    <option value='MTRR'>Match The Right Response (MTRR)</option>
                    <option value='MA'>Multiple Answers (MA)</option>
                    <option value='TF'>True/False (TF)</option>
                </select>
            }
            {/*GAME LIST/CARDS OF CURRENT SELECTED TYPE GBA*/}
            <div className={classes.imgContainer}>
                {selectedGBATypeData.challenges.map((ele, index) => {
                    console.log('challenges ele = ', ele);

                    const isSelectedGameDesignType = selectedGame.designType === ele.designType;
                    const showGameDesignCard = () => (
                        <div key={index}
                            id={index.toString()}
                            onClick={(e) => handleSelectGame(e, ele, index)}
                            className={classes.imgBox}
                            style={{
                                background: `url(${ele.bgImgUrl}) no-repeat center`
                            }}>
                            {isSelectedGameDesignType && <i className="fa-solid fa-circle-check"></i>}
                            {ele.banner ? <span className={classes.banner}>
                                <CustomBanner bannerImage={ele.banner} bannerAltText='banner' />
                            </span> : null}
                        </div>
                    );

                    if (isEdit && selectedGame.type !== "MCQ") {
                        if (selectedGame.type === "TF" && ((selectedGame.designType !== "TF_SoundRight" && ele.designType !== "TF_SoundRight") || (selectedGame.designType === "TF_SoundRight" && ele.designType === "TF_SoundRight"))) {
                            return showGameDesignCard();
                        }
                        if (selectedGame.type === "MTRR" && ((selectedGame.designType !== "MTRR_LearnoCasino" && ele.designType !== "MTRR_LearnoCasino") || (selectedGame.designType === "MTRR_LearnoCasino" && ele.designType === "MTRR_LearnoCasino"))) {
                            return showGameDesignCard();
                        }
                        if (selectedGame.type === "MA" && ((selectedGame.designType !== "MA_RevealThePicture" && ele.designType !== "MA_RevealThePicture") || (selectedGame.designType === "MA_RevealThePicture" && ele.designType === "MA_RevealThePicture"))) {
                            return showGameDesignCard();
                        }
                    } else {
                        return showGameDesignCard();
                    }
                })}
                {isGameDesignChange && (
                    <Button
                        onClick={updateChallenge}
                        className={`${classes.btn} ${classes.saveBtn}`}
                        variant="contained"
                        color="warning">
                        UPDATE
                    </Button>
                )}
            </div>
        </div>
    )
}

export default GameType;

const useStyles = makeStyles(() => ({
    mainContainer: {
        "& select": {
            margin: '1.2rem',
            marginTop: '2rem',
            outline: 'none',
            width: '75%',
            '-webkit-text-fill-color': 'rgb(0, 0, 0, 0.7)',
            fontWeight: '800'
        },
        "& option": {
            color: "#8D8D8D",
            fontSize: "0.8rem",
        },
    },
    selectBox: {
        width: '75%',
        padding: '0.6rem',
        border: "2px solid rgb(221, 224, 226, 0.7)",
        borderRadius: '3px',
        color: "#F4511E",
        fontFamily: "Montserrat",
        fontSize: "0.7rem",
        fontWeight: "700",
        cursor: "pointer",
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0rem 1.2rem',
        marginRight: '1rem'
    },
    imgBox: {
        position: 'relative',
        margin: '0.7rem 0',
        aspectRatio: '21/9',
        // height: '8rem',
        // width: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: '7px',
        backgroundSize: 'cover !important',
        cursor: 'pointer',
        '& .fa-solid': {
            color: '#15B725', //checked green color
            // color: '#f4511e', //checked orange color
            marginRight: '0.5rem',
            marginTop: '0.5rem',
        },
        '&:hover': {
            boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.8)'
        }
    },
    banner: {
        position: 'absolute',
        left: -17,
        top: 3,
    }
}));