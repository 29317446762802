import React, { useState, useContext, useRef } from "react";

// mui
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

//components
import CustomButton from "../../../custom-styled/button";
import CustomInput from "../../../custom-styled/custom-input";
import Label from "../../../custom-styled/label";
import Title from "../../../title";
import Loader from "../../../custom-styled/loader";

// //utils
import useQuery from "../../../../utils/useQuery";

//services
import challengesServices from "../../../../services/challenges";
import azureServices from "../../../../services/microsoft-azure";

// //global-states
import { GlobalStates } from "../../../../App";
import PdfImageExtractor from "../../../../utils/extractImagesFromPdf";
import commonUtil from "../../../../utils/commonUtil";
import constants from "../../../../constants";


const style = {
    position: 'absolute !important',
    top: 0,
    right: 0,
    // transform: 'translate(-50%, -50%)',
    minWidth: 600,
    maxWidth: 600,
    height: '100vh',
    // width: '90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    border: 'none',
    p: 3,
    borderRadius: '5px',
    '& .MuiTypography-root': {
        fontFamily: 'montserrat'
    }
}

const GenerateContentModal = ({
    open = false,
    setOpen,
    handleSaveCard,
    content, setContent
}) => {
    const defaulRetries = 3;
    const classes = useStyles();
    const query = useQuery();
    const companyId = query.get("c_id");
    const microskillId = query.get("ms_id");
    const challengeId = query.get("challenge_id");
    const [topic, setTopic] = useState('');
    const [result, setResult] = useState([]);
    // const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numberOfCards, setNumberOfCards] = useState(6);
    const [globalStates] = useContext(GlobalStates);
    const [imagesExtracted, setImagesExtracted] = useState([]);
    let retryRef = useRef(defaulRetries);
    let cardCountRef = useRef(numberOfCards);

    const handleTopicChange = async (e) => {
        setTopic(e.target.value)
        cardCountRef.current = numberOfCards;
        retryRef.current = defaulRetries;
    }

    const handleBackdropClose = () => {
        return
    }
    const handleClose = () => {
        if (loading) return
        setOpen(false)
    }


    const extractImagesFromFile = async (file) => {
        let fileMimeType = file.type;
        if (fileMimeType === constants.MIME_TYPES.PDF) {
            const imagesFromPdf = await PdfImageExtractor({ file })
            console.log('imagesFromPdf = ', imagesFromPdf);
            let imgData = [];
            for (const image of imagesFromPdf) {
                const blob = new Blob([image.data], { type: 'image/webp' });
                // Create a URL representing the Blob
                const url = URL.createObjectURL(blob);
                let temp = {
                    url,
                    height: image.height > 200 ? 200 : image.height,
                    width: image.width > 400 ? 400 : image.width,
                    name: image.name
                }
                imgData.push(temp)
            }
            console.log('imgData = ', imgData);
            setImagesExtracted(imgData)
            return
        }

        globalStates.handleToast(true, 'File format not supported', 'error')
    }

    const extractImages = async (e) => {
        const file = e?.target?.files[0] || null;
        console.log('file = ', file);
        if (!file) return
        await extractImagesFromFile(file);
        return
    }

    const handleGenerate = async ({ inScope = true, cardCount = numberOfCards }) => {
        if (!topic.length) {
            globalStates.handleToast(true, 'Please enter the prompt first', 'error');
            return

        }
        if (loading) return
        console.log('Content generation started...');
        const format = [{ "id": 0, "point": "Here goes the point 1", "description": "Here goes the description of point 1" }, { "id": 1, "point": "Here goes the point 2", "description": "Here goes the description of point 2" }]

        // let prompt = `Generate ${cardCount} key points along with detailed information about ${topic}. Arrange the points in a JSON array adhering to this structure: ${JSON.stringify(format)}. Ensure that each point does not exceed 60 characters in length, and each description is limited to 200 characters. Crucially, your response should only include the array`
        //IMPORTANT! - TEMPORARILY COMMENGED THE ABOVE PROMPT FOR RAW PROMPT TESTING KM
        let prompt = `${topic}. Arrange the points in a JSON array adhering to this structure: ${JSON.stringify(format)}. Ensure that each point does not exceed 60 characters in length, and each description is limited to 200 characters. Crucially, your response should only include the array`

        const messages = [{
            "role": "user",
            "content": prompt
        }]
        setLoading(true);

        const response = await azureServices.createContentUsingCustomData({ indexName: companyId, messages, inScope });
        console.log('laCardOpenAi response = ', response);
        if (response.status === 200 || response.status === 201) {
            // let content = response?.data?.choices[0]?.messages[1]?.content;
            let content = response?.data?.choices[0]?.message?.content;

            // console.log('laCardOpenAi content = ', content);
            if (content) {
                let cleanedString = content.replace(/\n/g, '').replace(/```json/g, '').replace(/```/g, '');
                console.log('cleanedString  = ', cleanedString);

                try {
                    let parsed = JSON.parse(cleanedString);
                    console.log('parsed  = ', parsed);
                    setContent(parsed);
                    setLoading(false);
                    if (parsed?.length) {
                        globalStates.handleToast(true, 'Learning aid cards creation started...', 'info');
                        let interactionTopic = ''
                        let interactionIdxs = [];
                        if (parsed.length <= 3) interactionIdxs = [parsed.length - 1];
                        else {
                            for (let i = 0; i < parsed.length; i++) {
                                if (i !== 0 && i % 3 === 0) interactionIdxs.push(i);
                            }
                        }
                        console.log('interactionIdxs  = ', interactionIdxs);
                        let createdCards = [];
                        let cardsWithInteractions = [];
                        for (let i = 0; i < parsed.length; i++) {
                            let cardData = {
                                id: 0,
                                cardType: "CREATED",
                                ratio: "4:3",
                                title: parsed[i].point,
                                body: parsed[i].description,
                                thumbImageFile: null,
                                thumbFileSize: 0,
                                isGenAi: true,
                                sequence: i,
                            }
                            const type = 'TB'
                            const withImage = false
                            const isSuggested = false
                            const withLoading = false
                            const response = await handleSaveCard(type, cardData, withImage, isSuggested, withLoading);
                            console.log('response = ', response);
                            if (response.status === 200 || response.status === 201) {
                                let cardId = response?.data?.data.at(-1)?.id;
                                console.log('cardId = ', cardId);
                                createdCards.push({
                                    id: cardId,
                                    seq: i,
                                    point: parsed[i].point,
                                    description: parsed[i].description

                                })
                                if (cardId && interactionIdxs.includes(i)) {
                                    let data = {
                                        cardId,
                                        sequence: i,
                                        interactionTopic
                                    }
                                    cardsWithInteractions.push(data)
                                    await handleAddInteraction(interactionTopic, cardId);
                                    interactionTopic = ''
                                } else {
                                    interactionTopic += parsed[i].description
                                }
                            }



                        }
                        globalStates.handleProgressDialog(false);
                    }
                } catch (error) {
                    console.log('error = ', error);
                    console.log('retryRef.current = ', retryRef.current);
                    if (retryRef.current > 0 && cardCountRef.current > 1) {
                        console.log('Retrying... ');
                        cardCountRef.current = cardCountRef.current - 1
                        handleGenerate({ inScope: false, cardCount: cardCountRef.current })
                        retryRef.current = retryRef.current - 1
                        return
                    }
                    setContent([]);
                    globalStates.handleProgressDialog(false);
                    setLoading(false);
                    globalStates.handleToast(true, 'The requested information is out of the scope of the documents provided', 'error');
                }
                // return

            }
        }





    }

    const handleAddInteraction = async (interactionTopic, cardId) => {
        console.log('interactionTopic  = ', interactionTopic);
        let interactionPrompt = `Please generate ${2} multiple-choice questions based on the topic ${interactionTopic}. Ensure that the correct answer is marked as 'true' in the answers array. Provide the questions in an array of json following this format: [{"id": 0, "sequence": 1, "designType": "ILA_MCQ_1","mandatory": true,"score": 10, "question": "Here goes the question 1","answers": [{"id": 0,"sequence": 1, "answer": "Here goes the answer 1 for question 1","isCorrect": true},{"id": 1,"sequence": 2,"answer": "Here goes the answer 2 for question 1","isCorrect": false},{"id": 2,"sequence": 3,"answer": "Here goes the answer 3 for question 1","isCorrect": false},{"id": 3,"sequence": 4, "answer": "Here goes the answer 4 for question 1", "isCorrect": false}] } ]. Also please make sure the length of answers array should be 4. Max length of question should be 200 and max length of answer field should be 50. Just return the array in reponse`

        const messages = [
            { role: "user", content: interactionPrompt },
        ];

        let response = await azureServices.azureOpenAi(messages);
        let content = response?.choices[0]?.message?.content;
        let cleanedString = content.replace(/\n/g, '');
        // console.log('cleanedString  = ', cleanedString);
        let parsedInteractions = JSON.parse(cleanedString);
        console.log('parsedInteractions  = ', parsedInteractions);
        // return

        //add interactions
        let contexts = [];
        let allQuestions = parsedInteractions;
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            cardId: cardId,
            interactions: {
                gameType: "MCQ",
                contexts: contexts,
                questions: allQuestions
            }
        }

        let res = await challengesServices.addInteractions(payload);
        //update maxScore pending

    }



    const imagesExtractionFileTypes = ".pdf"

    return (
        <Modal
            open={open}
            onClose={handleBackdropClose}
        >
            <Box sx={style} className={classes.box}>
                <Title titleText={"CREATE LEARNING AID CARDS"} fs="1rem" padding="0" />
                <CloseIcon sx={{ position: 'absolute', top: 12, right: 12, fontSize: '2rem', cursor: 'pointer', color: '#f4511e' }} onClick={handleClose} />
                <hr />
                {/* <Label labelText={"Topic:"} /> */}
                <div className={classes.inputBtnWrapper}>
                    <CustomInput
                        value={topic}
                        handleChange={handleTopicChange}
                        height="2.5rem"
                        width="48rem"
                        placeholder="Enter your prompt..."
                    />
                    <div className={classes.btnSelectWrapper}>
                        <select defaultValue={6} className={classes.selectMenu} onChange={(e) => setNumberOfCards(e.target.value)}>
                            <option value={'default'} disabled>Select number of cards</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                        {/* <input type="file" accept={imagesExtractionFileTypes} onChange={extractImages} /> */}
                        <CustomButton
                            fs="0.8rem"
                            fw={700}
                            btnText="GENERATE"
                            handleClick={handleGenerate}
                            width={"10rem"}
                            isDisabled={loading}
                        />
                    </div>


                </div>
                <div className={classes.contentWrapper}>
                    {
                        loading ?
                            <div className={classes.loaderBox}>
                                <Loader />
                            </div> :
                            content.map(card => (
                                <div key={card?.id} className={classes.questionWrapper}>
                                    <h3 >{card?.point}</h3>
                                    <p style={{ fontWeight: 500, fontFamily: 'monospace' }}>{card?.description}</p>
                                </div>
                            ))
                    }
                    {imagesExtracted.map((image, index) => (
                        <img
                            key={index}
                            src={image.url}
                            alt={image.name}
                            width={image.width}
                            height={image.height}
                            style={{ objectFit: 'cover' }}
                        />
                    ))}
                </div>

            </Box>
        </Modal>
    )
}

export default GenerateContentModal;

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    inputBtnWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column'
    },
    contentWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        margin: '1rem',
        overflowY: 'auto'
    },
    loaderBox: {
        minHeight: '20vh',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
    },
    questionWrapper: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        '& *': {
            fontFamily: 'monospace'
        }
    },
    answerWrapper: {
        display: 'flex',
        gap: '1rem',
    },
    btnSelectWrapper: {
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center'
    },
    selectMenu: {
        height: '2.5rem',
        width: '5rem',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        backgroundColor: '#fff',
        color: '#000'
    },
}));