import { authFetch } from "../utils/fetch";
import { authFetchFormData } from "../utils/fetch";

// const fetchImages = async (url) => {
//     try {
//         const resData = await authFetch.get(`/microskill/challenge?startIndex=0&limit=50&sort=createdOn&sortDirection=asc&microskillIds=${microskillId}`);
//         return resData;
//     } catch (err) {
//         return err.response;
//     }
// }

const fetchChallenges = async (microskillId) => {
    try {
        const resData = await authFetch.get(`/microskill/challenge?startIndex=0&limit=50&sort=createdOn&sortDirection=asc&microskillIds=${microskillId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createChallenge = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge`, data);
        return resData;
    } catch (err) {
        console.log("createChallenge:",err.response);
        return err.response;
    }
}

const copyChallenge = async (payload) => {
    let url = `/microskill/challenge/copy-challenge`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

const uploadThumbnail = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/microskill/challenge/upload-thumbnail-for-cards`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const uploadMediaResources = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/microskill/challenge/upload-media-for-cards`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const addQuestionsGba = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/add-questions`, data);
        return resData;
    } catch (err) {
        console.log("createChallenge:",err.response);
        return err.response;
    }
}

const updateQuestions = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge/update-questions`, payload);
        return resData
    } catch (err) {
        return err.response;
    }
}

const deleteQuestion = async (data) => {
    try {
        const resData = await authFetch.delete(`/microskill/challenge/delete-question`, {data});
        return resData;
    } catch (err) {
        return err.response;
    }
}

const addLearningCards = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/add-learning-aid-cards`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateLearningCard = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge/update-learning-aid-cards`, payload);
        return resData
    } catch (err) {
        return err.response;
    }
}
const updateCardSequence = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge/update-learning-aid-cards-sequence`, payload);
        return resData
    } catch (err) {
        return err.response;
    }
}


const deleteLearningCard = async (data) => {
    try {
        const resData = await authFetch.delete(`/microskill/challenge/delete-learning-aid-cards`, {data});
        return resData;
    } catch (err) {
        return err.response;
    }
}

const addInteractions = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/add-interaction-cards`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateInteraction = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge/update-interaction-cards`, payload);
        return resData
    } catch (err) {
        return err.response;
    }
}

const deleteInteraction = async (data) => {
    try {
        const resData = await authFetch.delete(`/microskill/challenge/delete-interaction-cards`, {data});
        return resData;
    } catch (err) {
        return err.response;
    }
}

const uploadTranscriptMediaFile = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/microskill/challenge/get-transcript`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const addSpotlight = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/add-spotlight`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateSpotlight = async (data) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge/update-spotlight`, data);
        return resData;
    } catch (err) {
        return err.response;
    }
}



const getChallengeData = async (microskillId, challengeId) => {
    try {
        const resData = await authFetch.get(`/microskill/challenge/${microskillId}/${challengeId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}


const updateChallenge = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill/challenge`, payload);
        return resData
    } catch (err) {
        return err.response;
    }
}

const deleteChallenge = async (data) => {
    try {
        const resData = await authFetch.delete(`/microskill/challenge`, {data});
        return resData
    } catch (err) {
        return err.response;
    }
}

const saveGenAiPrompt = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/gen-ai-prompt`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const generateGbaQuestionsUsingGenAi = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/challenge/generate-questions`, data)
        return resData
    } catch (err) {
        return err.response
    }
}


export default { 
    fetchChallenges, 
    createChallenge, 
    copyChallenge,
    addQuestionsGba, 
    updateQuestions,
    deleteQuestion,
    addLearningCards, 
    updateLearningCard,
    updateCardSequence,
    deleteLearningCard, 
    addInteractions, 
    updateInteraction,
    deleteInteraction,
    uploadThumbnail, 
    uploadMediaResources, 
    uploadTranscriptMediaFile,
    addSpotlight,
    updateSpotlight,
    getChallengeData, 
    updateChallenge, 
    deleteChallenge,
    saveGenAiPrompt,
    generateGbaQuestionsUsingGenAi
}