import React, { useEffect, useState } from 'react'

//mui
import MuiCheckbox from "@mui/material/Checkbox";
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

//components
import Title from '../../../title';
import CustomButton from '../../../custom-styled/button';

//styles
import useStyles from "./index.styles";
import isEmpty from '../../../../utils/isEmpty';
import { useRef } from 'react';

import { GlobalStates } from '../../../../App';
import { useContext } from 'react';

let defaultMessage = 'No Data Available'

const defaultData = [{ id: "default", name: 'Default', subMenu: [], selected: [], total: 0 }];


const MultipleFilter = ({
    label = "Content Filters",
    title = "Select Content",
    // filterData = [],
    filterList = defaultData,
    withSelectAll = true,
    emptyListMessage = defaultMessage,
    handleSaveFilter,
    handleResetFilter,
    handleSearch,
    disabled = false
}) => {
    const styles = useStyles();
    const [globalStates, setGlobalStates] = useContext(GlobalStates)
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [localList, setLocalList] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({ id: null, name: "", subMenu: [], selected: [], total: 0 });
    const [message, setMessage] = useState(emptyListMessage);

    const searchInputRef = useRef();

    // useEffect(() => {
    //     if (localList?.length && selectedFilter.id) {
    //         for (let i = 0; i < localList.length; i++) {
    //             if (localList[i].id === selectedFilter.id) {
    //                 setSelectedFilter({ ...selectedFilter, subMenu: localList[i].subMenu, selected: localList[i].selected, })
    //                 break
    //             }
    //         }
    //     }


    // }, [localList])

    useEffect(() => {
        if (open) {
            setSelectedFilter(filterList[0])
            setLocalList(filterList)
        }
    }, [open])


    const handleLocalSearch = (e) => {
        let value = e.target.value;
        // handleSearch(value, selectedFilter);
        let newSubmenu = []
        for (let i = 0; i < filterList.length; i++) {
            if (filterList[i].id === selectedFilter.id) {
                // const selectedDemographicSubmenu = allDemographics[selectedFilter.id].subMenu;
                if (value === "") {
                    // templist[i].subMenu = selectedDemographicSubmenu;
                    newSubmenu = filterList[i].subMenu;
                    // setSelectedFilter({ ...selectedFilter, subMenu: filterList[i].subMenu })
                    break
                } else {
                    let result = filterList[i].subMenu.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
                    // templist[i].subMenu = result;
                    // setSelectedFilter({ ...selectedFilter, subMenu:result })
                    newSubmenu = result;

                    break
                }
            }
        }

        console.log('newSubmenu = ', newSubmenu);

        setSelectedFilter({ ...selectedFilter, subMenu: newSubmenu })

    }

    const handleSelectAll = () => {
        let isSelectAll = selectedFilter.subMenu.length === selectedFilter.selected.length;
        let templist = [...localList];
        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === selectedFilter.id) {
                if (isSelectAll) {
                    templist[i] = { ...templist[i], selected: [] };
                    break
                } else {
                    let all = selectedFilter.subMenu.map(ele => ele.id);
                    templist[i] = { ...templist[i], selected: all };
                    break
                }
            }
        }

        setLocalList(templist);
    };

    const _selectFilter = (item) => {
        // console.log('_selectFilter item = ', item);
        // console.log('prev selectedFilter = ', selectedFilter);
        // console.log('searchInputRef = ', searchInputRef.current.value);
        if (searchInputRef?.current?.value?.length) {
            searchInputRef.current.value = "";
            // handleSearch("", selectedFilter);
        }
        setSelectedFilter(item);
        const subMenuDiv = document.getElementById('sub-menu');
        subMenuDiv.scrollTop = 0;
    };

    const handleClick = (item) => {
        // console.log('handleClick filterList = ', filterList);
        console.log('handleClick localList = ', localList);
        console.log('handleClick item = ', item);
        let templist = [...localList];
        let newSelected = []
        for (let i = 0; i < templist.length; i++) {
            if (templist[i].id === selectedFilter.id) {
                let selected = templist[i].selected;
                if (selectedFilter.id === "language") {
                    templist[i] = { ...templist[i], selected: [item.id] };
                    newSelected = [item.id];
                    break
                }
                if (selected.includes(item.id)) {
                    let filtered = selected.filter(ele => ele !== item.id);
                    templist[i] = { ...templist[i], selected: filtered }
                    newSelected = filtered;
                    break
                } else {
                    templist[i] = { ...templist[i], selected: [...templist[i].selected, item.id] }
                    newSelected = [...templist[i].selected, item.id];
                    break
                }
            }
        }
        console.log('updated templist = ', templist);
        console.log('updated newSelected = ', newSelected);
        setSelectedFilter({ ...selectedFilter, selected: newSelected })
        setLocalList(templist);
    }

    const handleClose = () => {
        setOpen(false);
        // const prevData = localStorage.getItem('prevData');
        // setFilterList(JSON.parse(prevData));
        // localStorage.removeItem('prevData');
        // console.log('prevData = ', JSON.parse(globalStates.prevData));

        // setFilterList(JSON.parse(globalStates.prevData));
        // setGlobalStates({ ...globalStates, prevData: null, selectedFilterCopy: null });
    }
    const handleSave = () => {
        handleSaveFilter(localList);
        setOpen(false);
    }

    const handleReset = () => {
        // handleResetFilter();
        // setSelectedFilter({ ...filterList[0], selected: [] });
    }


    // className={styles.main}

    const getIsSelectAll = () => {
        return selectedFilter.total === selectedFilter.selected.length
    }

    return (
        <>
            <ButtonBase
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disabled={disabled}
                className={styles.chip2}
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={(event) => {
                    if (disabled) return
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}>
                <h6> Select Groups </h6>
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: '0.7rem',
                        // top: '0.2rem',
                        color: '#f4511e',
                        height: '0.8rem',
                        width: '0.8rem',
                    }}>
                    <AddIcon />
                </IconButton>


            </ButtonBase>

            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                sx={{ top: '-4%' }}
                // keepMounted
                open={open}
                onClose={handleClose}>
                <Title titleText={title} padding={'0.6rem'} />
                <div className={styles.searchInputBox}>
                    <input ref={searchInputRef} placeholder="Search" type="text" onChange={handleLocalSearch} />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>

                <div className={styles.filterWrapper} style={{ width: isEmpty(selectedFilter) ? 'calc(680px/2)' : '680px' }}>
                    <div className={styles.filterList} style={{ width: isEmpty(selectedFilter) ? '100%' : '30%' }}>

                        {
                            localList.length ?
                                (<>
                                    {localList.map((ele, index) => (
                                        <div key={index} className={styles.listItem} onClick={() => _selectFilter(ele)} style={{
                                            backgroundColor: selectedFilter?.id === ele.id ? '#f9f9f9' : '#fff'
                                        }}>
                                            <span>
                                                {ele.selected.length ? `${ele.name} (${ele.selected.length}/${ele.total})` : `${ele.name} (${ele.total})`}
                                            </span>
                                        </div>
                                    ))}
                                </>)
                                : <div style={{
                                    textAlign: 'center',
                                    color: 'rgb(0,0,0,0.7)',
                                    padding: '0.5rem 0'
                                }}>
                                    No Filters Available
                                </div>
                        }
                    </div>
                    {!isEmpty(selectedFilter.id) ?
                        <div id="sub-menu" className={`${styles.filterList} ${styles.subMenuList}`}>

                            {
                                selectedFilter.subMenu.length ?
                                    (<>
                                        {
                                            withSelectAll && !selectedFilter.singleSelect ?
                                                <div className={styles.listItem} onClick={handleSelectAll}>
                                                    <Checkbox
                                                        className={styles.checkBox}
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        isChecked={getIsSelectAll()}
                                                    />
                                                    <span style={{ color: '#f4511e' }}>Select All</span>
                                                </div> : null
                                        }
                                        {selectedFilter.subMenu.map((ele, index) => (
                                            <div key={index} className={styles.listItem} onClick={() => handleClick(ele)} title={ele.name} style={{
                                                // backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
                                            }}>
                                                {
                                                    selectedFilter.singleSelect ?
                                                        <RadioBtn
                                                            id={ele.id}
                                                            name={ele.name}
                                                            // handleClick={handleClick}
                                                            isChecked={selectedFilter.selected.includes(ele.id)}
                                                        /> :
                                                        <Checkbox
                                                            id={ele.id}
                                                            type="checkbox"
                                                            name={ele.name}
                                                            // handleClick={handleClick}
                                                            isChecked={selectedFilter.selected.includes(ele.id)}
                                                        />
                                                }


                                                <span>{ele.name}</span>
                                            </div>
                                        ))}
                                    </>)
                                    : <div style={{
                                        textAlign: 'center',
                                        color: 'rgb(0,0,0,0.7)',
                                        padding: '0.5rem 0'
                                    }}>
                                        {message}
                                    </div>
                            }
                        </div> : null}
                </div>

                <div className={styles.btnBox}>
                    <CustomButton
                        variant={'contained'}
                        textColor={'#fff'}
                        borderColor={'#f4511e'}
                        bgcolor={'#f4511e'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Save"}
                        handleClick={handleSave}
                    />
                    {/* <CustomButton
                        variant={'outlined'}
                        textColor={'#f4511e'}
                        borderColor={'#f4511e'}
                        bgcolor={'#fff'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Reset"}
                        handleClick={handleReset}
                    /> */}
                    <h1> {selectedFilter.selected.length} {selectedFilter.name} Selected</h1>
                </div>
            </Menu>
            }
        </>
    )
}

export default MultipleFilter;



const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id.toString()}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};

const RadioBtn = ({ id, name, handleClick, isChecked }) => {
    return (
        <Radio
            id={id.toString()}
            name={name}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};

