import { authFetch } from "../utils/fetch"


const fetchBulletins = async (companyId) => {
    try {
        let url = `/bulletin?companyIds=${companyId}`
        const respData = await authFetch.get(url);
        return respData
    } catch (error) {
        return error.response
    }
}


const createBulletin = async (payload) => {
    try {
        const respData = await authFetch.post('/bulletin', payload)
        return respData;
    } catch (error) {
        return error.response;
    }
}

const launchBulletin = async (payload) => {
    try {
        const respData = await authFetch.post('/bulletin/launch-file', payload)
        return respData;
    } catch (error) {
        return error.response;
    }
}


const bulletinMediaUpload = async (payload) => {
    try {
        const respData = await authFetch.post('/bulletin/upload-bulletin-media', payload)
        return respData
    } catch (error) {
        return error.response
    }
}

const bulletinUpdate = async (payload) => {
    try {
        const respData = await authFetch.put('/bulletin', payload)
        return respData
    } catch (error) {

    }
}
const bulletinCopy = async (payload) => {
    try {
        const respData = await authFetch.post('/bulletin/copy', payload)
        return respData
    } catch (error) {

    }
}
const bulletinDelete = async (data) => {
    try {
        const respData = await authFetch.delete('/bulletin', { data })
        return respData
    } catch (error) {

    }
    // try {
    //     const resData = await authFetch.delete(`/bulletin`, { data });
    //     return resData;
    // } catch (err) {
    //     return err.response;
    // }
}

export default { fetchBulletins, createBulletin, launchBulletin, bulletinMediaUpload, bulletinUpdate, bulletinCopy, bulletinDelete };