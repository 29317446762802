import { authFetch } from "../utils/fetch";

const fetchUserDetails = async (startIndex = 0, search = null) => {
    let url = `/user/getAll?startIndex=${startIndex}&sort=createdOn&sortDirection=asc&limit=50`
    if (search !== null && search?.length) url += `&search=${search}`

    try {
        const resData = await authFetch.get(`${url}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchUsers = async (companyId) => {
    let url = `/user?startIndex=0&companyIds=${companyId}&limit=1000`

    try {
        const resData = await authFetch.get(url);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchUsersFromDemographics = async (payload) => {

    const url = `/user/get-users-from-demographics`
    try {
        const resData = await authFetch.post(url, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}


export default { fetchUserDetails, fetchUsers, fetchUsersFromDemographics }