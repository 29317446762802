import React, { useState, useContext, useEffect } from 'react'

//components
import MasteroTable from '../mastero-table';
import LoadingContainer from '../loading-container';
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import CreateGroup from '../create-new-company'

//utils
import useRouter from '../../utils/useRouter';
import useQuery from '../../utils/useQuery';

//services
import groupServices from '../../services/group';

//styles
import useStyles from './index.styles';

//global-states
import { GlobalStates } from "../../App";

let descriptionTitle = "How to use Groups:"
let descriptionText = "This tool gives you the ability to make different groups for your micro learning needs. Every group can house unlimited Microskills and a documents section wherein you can store additional macro-learning files and folders as reference material for your learning audience. You have full control on whether you want a documents folder or not."
let defaultPagination = {
    limit: 0,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}
const Groups = () => {
    const [globalStates] = useContext(GlobalStates);
    const classes = useStyles();
    const router = useRouter();
    const query = useQuery();
    const companyId = query.get('c_id')
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValues, setInputValues] = useState({
        name: "",
        validity: "",
        isLanguage: false, 
    });
    const [editGroup, setEditGroup] = useState({ edit: false, key: null });
    const [dialogContent, setDialogContent] = useState('');
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState(defaultPagination); 
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [withToggle, setWithToggle] = useState(true);
  

    useEffect(() => {
        fetchGroups(companyId, { startIndex, limit: 10 })
    }, [startIndex])

    const fetchGroups = async (companyId, options = { startIndex, limit: 10 }) => {
        setLoading(true)
        let res = await groupServices.fetchGroups(companyId, options);
        console.log('fetchGroups res =', res);
        if (res.status === 200 || res.status === 201) {
            let activeGroups = res.data.data.filter(element => element.active === true);
            activeGroups.forEach(ele => {
                ele.repository = true;
            })
            console.log('fetchGroups activeGroups =', activeGroups);
            setPageCount(res.data.pagination.pageCount);
            setTotalCount(res.data.pagination.totalCount);
            setPagination(res.data.pagination);
            setTableData(activeGroups);
            setLoading(false);
        }
    }

    const handleCreateGroup = async (data) => {
        console.log('handleCreateGroup data = ', data);
        
        let payload = {
            companyId: companyId,
            name: data.name,
            isLanguage: inputValues.isLanguage
        }
        console.log('handleCreateGroup payload = ', payload);
        console.log('editGroup  = ', editGroup);
        if (editGroup.edit) {
            let updatePayload = {
                id: tableData[editGroup.key].id,
                name: data.name,
            }
            console.log('handleSubmit updatePayload = ', updatePayload);
            console.log('handleSubmit tableData[editProject.key] = ', tableData[editGroup.key]);
            let res = await groupServices.updateGroup(updatePayload);
            console.log('updateGroup res = ', res);
            if (res.status === 200 || res.status === 201) {
                setDialogContent("Group Updated Successfully!");
                setSuccessDialogOpen(true);
                setInputValues({ name: "", validity: "" })
                setEditGroup({ edit: false, key: null });
                setWithToggle(true)
                fetchGroups(companyId)
                return
            } else {
                globalStates.handleToast(true, 'Internal Server Error.', 'error')
            }
        } else {
            let res = await groupServices.createGroup(payload);
            console.log('create group res =', res);
            if (res.status === 400) {
                if (res.data.errors) {
                    setDialogContent(res.data.errors[0].message);
                    setErrorDialogOpen(true);
                }
                return
            }
            if (res.status === 200 || res.status === 201) {
                setDialogContent("Group Created Successfully!");
                setSuccessDialogOpen(true);
                // setIsRepository(false)
                setInputValues({
                    name: "",
                    validity: "",
                })
                fetchGroups(companyId);
            }

            return
        }
        return
    }

    const handleToggleChange = (e) => {
        console.log("dwf")
        console.log(e.target.checked);
        console.log("dwf")
        setInputValues({ ...inputValues, isLanguage: e.target.checked }); // Dynamically set toggle value
      };

    const handleDelete = async (key) => {
        let confirm = window.confirm("Do you really want to delete this group?")
        if (confirm) {
            const payload = { id: tableData[key].id }
            try {
                let res = await groupServices.deleteGroup(payload)
                console.log('handleDelete', res);
                if (res.status === 200 || res.status === 201) {
                    setDialogContent("Deleted sucessfully!");
                    setSuccessDialogOpen(true);
                    fetchGroups(companyId);
                }
                return
            } catch (e) {
                console.log(e);
            }
        } else {
            return;
        }
    };

    const handleEdit = (element, key) => {
        console.log('handleEdit element = ', element);
        setInputValues({
            name: element.name,
            validity: '',
        });
        setEditGroup({ edit: true, key: key });
        setWithToggle(false)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const handleCreateRepository = (element) => {
        console.log('handleCreateRepository element = ', element);
        router.history.push(`/documents/${element.companyId}`)
    }

    const handleBackClick = () => {
        if (startIndex >= 10) {
            setStartIndex(startIndex - 10)
            setPageNo(pageNo - 1);
        }
    }

    const handleForwardClick = () => {
        if (startIndex + 10 < totalCount) {
            setStartIndex(startIndex + 10);
            setPageNo(pageNo + 1);
        }
    }

    const TITLE = "Create New Group";
    const PLACEHOLDER = "Group name"
    const LABELS = {
        companyName: "Group Name:",
        validity: "Group Validity:",
    };
    const SAVE_BTN_TEXT = "Save Group";
    const TABLE_TITLE = "All Groups";
    const HEADINGS = [
        { title: "Group Name", key: "name", link: false },
        { title: "Microskills", key: "microskillCount", link: false },
        { title: "Content Repository", key: "repository", link: true },
        { title: "Quick Actions", key: "quickActions", link: false }
    ];

    if (loading) {
        return (
            <LoadingContainer />
        )
    } else {
        return (
            <div className={classes.container}>
                <CreateGroup
                    title={TITLE}
                    labels={LABELS}
                    placeholder={PLACEHOLDER}
                    btnTxt={SAVE_BTN_TEXT}
                    handleSubmit={handleCreateGroup}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    withValidity={false}
                    withCheckBox={false}
                    withToggle={withToggle}
                    handleToggleChange={handleToggleChange}
                    descriptionTitle={descriptionTitle}
                    descriptionText={descriptionText}
                />

                <div className={classes.row2}>
                    <MasteroTable
                        tableTitle={TABLE_TITLE}
                        tableHeadings={HEADINGS}
                        tableData={tableData}
                        quickActions={true}
                        deleteKey={true}
                        copyKey={false}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleCreateRepository={handleCreateRepository}
                        handleForwardClick={handleForwardClick}
                        handleBackClick={handleBackClick}
                        pageCount={pageCount}
                        pageNo={pageNo}
                        pagination={pagination}
                    />
                </div>


                {
                    successDialogOpen &&
                    <SuccessDialog
                        successDialogOpen={successDialogOpen}
                        setSuccessDialogOpen={setSuccessDialogOpen}
                        dialogText={dialogContent}
                        handleClick={() => setSuccessDialogOpen(false)}
                        buttonText='Okay'
                    />
                }
                {
                    errorDialogOpen &&
                    <FailureDialog
                        errorDialogOpen={errorDialogOpen}
                        setErrorDialogOpen={setErrorDialogOpen}
                        dialogText={dialogContent}
                        handleClick={() => setErrorDialogOpen(false)}
                    />
                }
                {/* <div className={classes.row1}>

                </div> */}


            </div>
        )
    }

}

export default Groups
