import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import ReportsHistory from "../report-history";
import SchedulesHistory from "../report-schedule-history";

const CustomReportHistoryData = ({ setBackdrop, mapping }) => {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <Box style={{ marginLeft: "20px", marginTop: "20px", width: "80%" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <h1 style={{ marginRight: '50px' }}>History</h1>
                <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} centered sx={{
                    "& span": {
                        backgroundColor: "unset",
                    }
                }}>
                    <Tab label="Reports" style={{ textTransform: 'capitalize' }} sx={{
                        "&.Mui-selected": {
                            backgroundColor: "#ff5722",
                            color: "#fff",
                            borderRadius: "5px",
                        }
                    }} />
                    <Tab label="Schedules" style={{ textTransform: 'capitalize' }} sx={{
                        "&.Mui-selected": {
                            backgroundColor: "#ff5722",
                            color: "#fff",
                            borderRadius: "5px",
                        }
                    }} />
                </Tabs>
            </div>
            <Box hidden={currentTab !== 0}>
                <ReportsHistory setBackdrop={setBackdrop} />
            </Box>
            <Box hidden={currentTab !== 1}>
                <SchedulesHistory />
            </Box>
        </Box>
    );
};

export default CustomReportHistoryData;