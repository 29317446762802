// Mapper for environment variables
export const environment = process.env.NODE_ENV;

export const tokenInfo = {
    accessTokenValidityDays: parseInt(process.env.REACT_APP_ACCESS_TOKEN_VALIDITY_DAYS),
    refreshTokenValidityDays: parseInt(process.env.REACT_APP_REFRESH_TOKEN_VALIDITY_DAYS),
    issuer: process.env.REACT_APP_TOKEN_ISSUER,
    audience: process.env.REACT_APP_TOKEN_AUDIENCE,
};

export const defaultCompanyValidityDays = parseInt(process.env.REACT_APP_DEFAULT_COMPANY_VALIDITY_DAYS) * 24 * 60 * 60 * 1000;

export const keys = {
    privateKey: process.env.REACT_APP_PRIVATE_KEY,
    publicKey: process.env.REACT_APP_PUBLIC_KEY,
    apiKey: process.env.REACT_APP_API_KEY,
    openaiApiKey: process.env.REACT_APP_AZURE_OPENAI_KEY
};

export const encryption = {
    secretKey: process.env.REACT_APP_ENCRYPTION_SECRET_KEY,
    iv: process.env.REACT_APP_ENCRYPTION_IV,

};
export const urls = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiV1: process.env.REACT_APP_API_V1,
    openAiBaseUrl: process.env.REACT_APP_AZURE_OPENAI_BASE_URL,
    azureSearchServiceBaseUrl: process.env.REACT_APP_AZURE_SEARCH_ENDPOINT,
}