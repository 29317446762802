import React from 'react'
import Bulletin from '../../components/bulletin'

const BulletinPage = () => {
  return (
    <>
    <Bulletin/>
    </>
  )
}

export default BulletinPage