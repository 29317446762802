import React, { useContext, useRef, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import MuiCheckbox from "@mui/material/Checkbox";
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
// import LoadingButton from '@mui/lab/LoadingButton';
import ReactTooltip from 'react-tooltip';

//components
import Title from '../../../title';
import CustomButton from '../../../custom-styled/button';

//utils
import isEmpty from '../../../../utils/isEmpty';
import commonUtil from '../../../../utils/commonUtil';


//global-states
import { GlobalStates } from '../../../../App';


let defaultMessage = 'No Documents Available'

const LinkDocument = ({ link, setLink, documents }) => {
    // documents = [...documents, ...documents]
    const classes = useStyles();
    const [globalStates, setGlobalStates, sideMenu] = useContext(GlobalStates);
    const [list, setList] = useState(documents);
    const [anchorEl, setAnchorEl] = useState(null);
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(null);
    const [addExternal, setAddExternal] = useState(null);
    const [addDocument, setAddDocument] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [message, setMessage] = useState(defaultMessage);
    const [tempLink, setTempLink] = useState({ type: "link", value: "" });
    // console.log('documents = ', documents);

    const searchInputRef = useRef();
    if (false) setGlobalStates({ ...globalStates })
    const handleBaseBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (!isEmpty(link) && link !== null && link.type === "link") {
            setAddExternal(true);
            setTempLink({ type: "link", value: link.value });
            return
        }
        if (!isEmpty(link) && link !== null && link.type === "document") {
            if (link.value[0].parentId) {
                let parentElement = null;
                documents.forEach(element => {
                    if (element.id === link.value[0].parentId && element.type === "FOLDER") parentElement = element;
                });
                setSelectedFolder(parentElement);
            }
            setSelectedDocument(link.value);
            setAddDocument(true);
            return
        }
        setOptionsMenuOpen(true);
    }

    const handleCloseOptionsMenu = () => {
        setOptionsMenuOpen(false);
    }

    const handleAttachUrl = (e) => {
        e.preventDefault();
        if (isEmpty(tempLink.value)) {
            globalStates.handleToast(true, "Please enter the URL", "error")
            return
        }
        setLink(tempLink);
        globalStates.handleToast(true, "URL linked successfully!", "success");
        setAddExternal(false);
    }
    const handleRemoveUrl = (e) => {
        e.preventDefault();
        setLink(null);
        if (link !== null && !isEmpty(link.value)) globalStates.handleToast(true, "URL removed successfully!", "success");
        setTempLink({ type: "link", value: "" })
        setAddExternal(false);
    }

    const handleExternalLinkClick = (e) => {
        setAddExternal(true);
        setOptionsMenuOpen(false);
    }

    const handleCloseAddExternal = (e) => {
        setAddExternal(false);
        setTempLink({ type: "link", value: "" });
    }

    const handleUrlChange = (e) => {
        let temp = {
            type: "link",
            value: e.target.value
        }
        setTempLink(temp);
    }

    const handleDocumentClick = () => {
        setAddDocument(true);
        setOptionsMenuOpen(false);
    }

    const handleCloseAddDocument = () => {
        setSelectedDocument([]);
        setSelectedFolder(null);
        setAddDocument(false);
        setList(documents);
    }

    const handleClick = (item) => {
        console.log('handleClick item = ', item);
        if (item.type === 'FOLDER') {
            setSelectedFolder(item);
            setSelectedDocument([]);
            return
        }
        setSelectedDocument([item]);
        setSelectedFolder(null);
    }

    const handleSubmenuClick = (item) => {
        console.log('handleSubmenuClick item = ', item);
        setSelectedDocument([item]);
    }

    const isChecked = (item) => {
        if (item.type === 'FOLDER') {
            if (selectedFolder === null) return false;
            return item.id === selectedFolder.id;
        }
        if (selectedDocument.length) {
            return selectedDocument[0].id === item.id;
        }
        return false;
    }

    const getIcon = (ele) => {
        if (ele.type === "FOLDER") return (<i className="fa-regular fa-folder"></i>)
        return (<i className="fa-regular fa-file"></i>)
    }

    const handleSave = () => {
        console.log('selected document = ', selectedDocument);
        if (!selectedDocument.length) {
            globalStates.handleToast(true, "Please select the document.", "error");
            return
        }
        let temp = {
            type: 'document',
            value: selectedDocument
        }
        setLink(temp);
        globalStates.handleToast(true, "Document linked successfully!", "success");
        setAddDocument(false);
    }

    const handleReset = () => {
        setSelectedDocument([]);
        setSelectedFolder(null);
        if (link !== null) globalStates.handleToast(true, "Document removed successfully!", "success");
        setLink(null);
        setAddDocument(false);
        setList(documents);
    }

    const handleSearchDocument = (e) => {
        let value = e.target.value;
        if (selectedFolder !== null) setSelectedFolder(null);
        console.log(value);
        if (value === '') {
            setList(documents);
            setMessage(defaultMessage)
            return
        }
        let result = documents.filter(li => {
            if (li.name.toLowerCase().includes(value.toLowerCase())) return li
            else if (li.name.toLowerCase() === value.toLowerCase()) return li
        });
        setList(result);
        if (!result.length) {
            setMessage('No results found!')
        } else {
            setMessage(defaultMessage)
        }
    }

    return (
        <>
            <ButtonBase
                id="basic-button"
                aria-controls={optionsMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsMenuOpen ? 'true' : undefined}
                className={`${classes.baseBtn} ${!isEmpty(link) ? classes.active : ""}`}
                data-tip={'Add Link'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    height: '20px',
                    width: '20px',
                    transition: '0.8s',
                    // right: sideMenu.isOpen ? '29%' : '31%',
                    right: sideMenu.isOpen ? '23%' : '25%',
                    top: '2%'
                }}
                onClick={handleBaseBtnClick}>
                <img

                    src='/images/learning-aid-assets/link-solid.svg'
                    alt=''
                    // title='Add Link'
                    height='20px'
                    width='20px'
                />
                <ReactTooltip
                    place="bottom"
                    type="light"
                    effect="solid"
                    textColor="#707070"
                    className={classes.toolTipStyle}
                />

            </ButtonBase >
            {optionsMenuOpen && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.optionsMenu}
                open={optionsMenuOpen}
                onClose={handleCloseOptionsMenu}>
                {/* <ButtonBase onClick={handleExternalLinkClick}>
                    <img src='/images/icons/web-icon.svg' /> <h6>External Link</h6>
                </ButtonBase> */}
                <ButtonBase onClick={handleDocumentClick}>
                    <img src='/images/icons/file-lines-regular.svg' /> <h6>Document</h6>
                </ButtonBase>
            </Menu>
            }
            {addExternal && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.externalLinkMenu}
                open={addExternal}
                onClose={handleCloseAddExternal}>
                <form className={classes.form} onSubmit={handleAttachUrl}>
                    <input required type='url' value={tempLink.value} placeholder='Enter URL here' title='Enter URL here' onChange={handleUrlChange} />
                    <ButtonBase type='submit' className={classes.btn}>
                        {link && link.value.length ? 'Update URL' : 'Attach URL'}
                    </ButtonBase>
                    {(link && link.value.length) ? <ButtonBase onClick={handleRemoveUrl} className={`${classes.btn} ${classes.btn2}`}>Remove</ButtonBase> : null}

                </form>
            </Menu>
            }

            {addDocument && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.documentMenu}
                // keepMounted
                sx={{
                    "& > .MuiPaper-root": {
                        left: selectedFolder !== null ? "54% !important" : "60% !important"
                    }
                }}
                open={addDocument}
                onClose={handleCloseAddDocument}
            >
                <Title titleText={"Select Document"} padding={'0.6rem'} fs={'16px'} />
                {
                    true ? <div className={classes.searchInputBox}>
                        <input ref={searchInputRef} placeholder="Search Document" type="text" onChange={handleSearchDocument} />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div> : null
                }
                <div className={classes.filterWrapper} style={{ width: isEmpty(selectedFolder) ? 'calc(680px / 2)' : '680px' }}>
                    <div className={classes.filterList} style={{ width: isEmpty(selectedFolder) ? 'calc(680px / 2)' : '50%' }}>

                        {
                            list.length ?
                                (<>
                                    {list.map((ele, index) => (
                                        <div key={index} className={classes.listItem}
                                            title={ele.name}
                                            onClick={() => handleClick(ele)}
                                            style={{
                                                // backgroundColor: selectedFolder?.id === ele.id ? '#f9f9f9' : '#fff'
                                                backgroundColor: isChecked(ele) ? '#f9f9f9' : '#fff'
                                            }}>
                                            <Checkbox
                                                id={ele.id}
                                                type="checkbox"
                                                name={ele.name}
                                                isChecked={isChecked(ele)}
                                            />
                                            <span>
                                                {`${commonUtil.truncateString(ele.name, 27)}`}

                                            </span>
                                            <span > {getIcon(ele)}</span>
                                        </div>
                                    ))}
                                </>)
                                : <div style={{
                                    textAlign: 'center',
                                    color: 'rgb(0,0,0,0.7)',
                                    padding: '0.5rem 0'
                                }}>
                                    {message}

                                </div>
                        }
                    </div>
                    {!isEmpty(selectedFolder) ?
                        <div id="sub-menu" className={`${classes.filterList} ${classes.subMenuList}`}>

                            {
                                selectedFolder.subMenu.length ?
                                    (<>
                                        {
                                            // withSelectAll 
                                            false ?
                                                <div className={classes.listItem}
                                                // onClick={handleSelectAll}
                                                >
                                                    <Checkbox
                                                        className={classes.checkBox}
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                    // isChecked={filterList.length && selectedFolder.subMenu.length === selectedFolder.selected.length}
                                                    />
                                                    <span style={{ color: '#f4511e' }}>Select All</span>
                                                </div> : null
                                        }
                                        {selectedFolder.subMenu.map((ele, index) => (
                                            <div key={index} className={classes.listItem}
                                                onClick={() => handleSubmenuClick(ele)}
                                                title={ele.name} style={{
                                                    // backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
                                                }}>
                                                <Checkbox
                                                    id={ele.id}
                                                    type="checkbox"
                                                    name={ele.name}
                                                    // handleClick={handleClick}
                                                    isChecked={isChecked(ele)}
                                                />
                                                <span>{ele.name}</span>
                                                <span>{getIcon(ele)}</span>
                                            </div>
                                        ))}
                                    </>)
                                    : <div style={{
                                        textAlign: 'center',
                                        color: 'rgb(0,0,0,0.7)',
                                        padding: '0.5rem 0'
                                    }}>
                                        No Data Available
                                    </div>
                            }
                        </div> : null}
                </div>

                <div className={classes.btnBox}>
                    <CustomButton
                        variant={'contained'}
                        textColor={'#fff'}
                        borderColor={'#f4511e'}
                        bgcolor={'#f4511e'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Save Selection"}
                        handleClick={handleSave}
                    />
                    <CustomButton
                        variant={'outlined'}
                        textColor={'#f4511e'}
                        borderColor={'#f4511e'}
                        bgcolor={'#fff'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Reset"}
                        handleClick={handleReset}
                    />
                    {/* <h1> {selectedFolder.selected.length} {selectedFolder.name} Selected</h1> */}
                </div>
            </Menu>}
        </>
    )
}

export default LinkDocument;

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};


const useStyles = makeStyles(() => ({
    baseBtn: {
        padding: '1.2rem',
        borderRadius: '50%',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgb(244, 81, 30,0.2)',
            '& > img': {
                filter: 'invert(56%) sepia(75%) saturate(6044%) hue-rotate(351deg) brightness(99%) contrast(93%)'
            },
        }
    },
    active: {
        '& > img': {
            filter: 'invert(56%) sepia(75%) saturate(6044%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    toolTipStyle: {
        color: "rgb(0,0,0) !important",
        fontFamily: "Montserrat",
        fontSize: "0.7rem",
        fontWeight: 800,
        background: '#FFFFFF',
        boxShadow: '0px 3px 6px #00000029',
        // opacity: 1,
        height: '32px',
    },
    optionsMenu: {
        '& > .MuiPaper-root': {
            left: "76% !important",
            width: "170px",
            // height: "90px",//commented while removing external link
            height: "45px",
            overflow: "hidden",

            '& > .MuiList-root': {
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                height: "90px",
                padding: 0,
                '& > .MuiButtonBase-root': {
                    width: "170px",
                    height: "50%",
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "flex-start",
                    padding: "0 1rem",
                    '& > img': {
                        height: "15px",
                        width: "15px"
                    },
                    '& > h6': {
                        color: "rgb(0,0,0,0.8)",
                        fontFamily: "Montserrat",
                        fontSize: "0.7rem",
                        fontWeight: 700
                    },
                    '&:first-child': {
                        borderBottom: '1px solid rgb(112, 112, 112,0.3)'
                    }
                }
            }
        }
    },
    externalLinkMenu: {
        '& > .MuiPaper-root': {
            // top: "17% !important",
            left: "60% !important",
            width: "500px",
            height: "46px",

            '& > .MuiList-root': {
                display: "flex",
                padding: "6px",
                height: "46px"
            }
        }
    },
    documentMenu: {
        zIndex: 99999,
        '& > .MuiPaper-root': {
            // top: "17% !important",
            // left: "60% !important",
            // width: "500px",
            // height: "46px",

            '& > .MuiList-root': {
                // display: "flex",
                // padding: "6px",
                // height: "46px"
            }
        }
    },
    form: {
        display: "flex",
        gap: "0.5rem",
        height: "100%",
        width: "100%",
        '& > input': {
            width: "80%",
            border: "1px solid rgb(112,112,112, 0.4)",
            padding: "0 0.5rem",
            color: "rgb(112, 112, 112)",
        },

    },
    btn: {
        width: "110px",
        backgroundColor: "#f4511e",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: 700,
        borderRadius: "4px"
    },
    btn2: {
        backgroundColor: "#ffffff",
        color: "#f4511e",
        border: "1px solid #f4511e"
    },

    //multiple-filter styles

    selectWrapper: {
        border: '1px solid rgb(112,112,112, 0.3)',
        borderRadius: '3px',
        '& .MuiButtonBase-root': {

            '& > h1': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: "rgb(112, 112, 112)",
                padding: '0.4rem 1rem',
                gap: '1rem',
                height: '32px',
                '& > span': {
                    fontFamily: "Montserrat",
                    fontSize: '0.8rem',
                    fontWeight: 600,
                }


            }
        },
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
        }

    },
    searchInputBox: {
        backgroundColor: "#fff",
        border: '1px solid rgb(112,112,112,0.4)',
        padding: '0rem 1rem',
        height: '2rem',
        width: '318px',
        display: 'flex',
        alignItems: 'center',
        margin: '0.5rem 0.3rem',
        borderRadius: '3px',
        "& input": {
            width: "95%",
            backgroundColor: "#fff",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            }
        },
        "& i": {
            color: "#888888"
        }
    },
    filterWrapper: {
        display: 'flex',
        height: '300px',
    },

    filterList: {
        height: '100%',
        // width: '30%',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            width: "7px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f9f9f9",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: '#f4511e'

        }
    },
    subMenuList: {
        backgroundColor: '#f9f9f9',
        width: '50%',
        '& > div': {
            "&:hover": {
                cursor: 'pointer',
                // backgroundColor: '#FFFFFF',
            }
        }
    },
    listItem: {
        position: 'relative',
        paddingLeft: "0.5rem",
        height: '2.6rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '0.5rem',
        "& span": {
            // paddingLeft: "0.3rem",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            color: "#484848",
        },
        '& > span:first-child': {
            padding: '0.3rem'
        },
        '& > span:nth-child(2)': {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: '1rem',
            maxHeight: '1rem',
            overflow: 'hidden'
        },
        '& > span:nth-child(3)': {
            position: 'absolute',
            right: '0.8rem',
            '& > i': {
                fontSize: '1rem'
            }
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9 !important'
        }
    },
    selectAllLi: {
        padding: "0 0.5rem",
        height: '2.3rem',
        "& span": {
            paddingLeft: "0.3rem",
            color: "#F4511E",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    btnBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        position: 'relative',
        '& > .MuiButtonBase-root': {
            width: '150px',
            height: '38px',
            minWidth: '100px',
            padding: 0,
            fontWeight: 800,
            letterSpacing: 1
        },
        '& > .MuiButton-contained': {
            backgroundColor: '#f4511e',
            color: '#fff',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e'
            }
        },
        '& > .MuiButton-outlined': {
            backgroundColor: '#fff',
            color: '#f4511e',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

            }
        },
        '& > h1': {
            position: "absolute",
            right: "3%",
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
        }
    }
}));