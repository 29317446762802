import React from 'react'

import Button from '@mui/material/Button';

import { makeStyles } from "@mui/styles";

const CustomButton = ({
    variant = 'outlined',
    textColor = '#f4511e',
    borderColor = '#f4511e',
    bgcolor = '#fff',
    fs = "0.6rem",
    fw = 600,
    ls = 0,
    btnText,
    handleClick = () => { },
    isDisabled = false,
    width = '100%',
    height = '2.5rem',
}) => {
    const classes = useStyles();
    return (
        <Button
            variant={variant}
            className={classes.btn}
            disabled={isDisabled}
            sx={{
                color: textColor,
                border: `2px solid ${borderColor}`,
                bgcolor: bgcolor,
                fontSize: fs,
                fontWeight: fw,
                letterSpacing: ls,
                width,
                height,
                '&:hover': {
                    borderColor,
                    bgcolor,
                    color: textColor,
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'

                },
            }}
            onClick={handleClick}
        >
            {btnText}
        </Button>
    )
}

export default CustomButton;



const useStyles = makeStyles(() => ({
    btn: {
        // width: '100%',
        // height: '2.5rem',
        fontFamily: "Montserrat",
        // fontSize: "0.6rem",
        // letterSpacing: "75",
        cursor: "pointer",
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            // borderColor: 'inherit',
            borderWidth: '2px',
       }
    }
}));
