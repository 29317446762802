import React, { useState, useRef, useContext, useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components

//utils
import commonUtil from '../../../../utils/commonUtil';

//constants
// import constants from '../../../../constants';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';
import isEmpty from '../../../../utils/isEmpty';
import CustomButton from '../../../buttons';
import SuggestedOrUpload from '../../../learning-aid/components/suggested-or-upload';
import DragDropFiles from './drag-drop-files-uploader';
import EditableTitle from '../rtf-title';
import EditableBody from '../rtf-body';


const VideoCard = ({
  customAspectRatio = true,
  titleBody,
  titleText,
  titleLimit,
  setTitleBody,
  setTitleText,
  setBodyText,
  video,
  videoFile,
  handleVideoUpload,
  setIsYoutube,
  link,
  handleRemoveVideo,
  handleExternalLink
}) => {
  const classes = useStyles();
  const styles = commonStyles();
  const [globalStates] = useContext(GlobalStates)
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  let capture = useRef();
  let titleRef = useRef(null);
  let bodyRef = useRef(null);

  //   console.log(
  //     // customAspectRatio,
  //     // handleSaveCard,
  //     // isEdit,
  //     // editCardData,
  //     // handleCancel,
  //     handleUpdateCard,
  //     // titleBody,
  //     // setTitleBody,
  //     // handeleTitleChange,
  //     // handleBodyChange,
  //     // handleTitleClick,
  //     // handleBodyClick,
  //     // toolbar,
  //     // setToolbar,
  //     // laBgImgData,
  //     // handleChangeLaBgImg
  //   );

  //   useEffect(() => {
  //     // setToolbar({ title: false, body: false })
  //     if (isEdit && editCardData !== null) {
  //       let card = editCardData.card;
  //       let videoObj = commonUtil.getVideoObjFromContents(card.contents);
  //       // console.log('videoObj = ', videoObj);
  //       if (videoObj.name === 'YOUTUBE') {
  //         // console.log('Its youtube link ');
  //         setIsYoutube(true);
  //         setLink(videoObj.content);
  //         setVideoFile(null);
  //         setVideo('');
  //       } else {
  //         // console.log('Its custom video ');
  //         setIsYoutube(false);
  //         setVideo(videoObj.content);
  //         setVideoFile(videoObj.content);
  //         setLink('')
  //       }
  //       let title = commonUtil.getTitleFromContents(card.contents);
  //       // titleRef.current.innerHTML = commonUtil.removeStyleAndHrefTagsFromString(title);
  //       let body = commonUtil.getBodyFromContents(card.contents);
  //       // bodyRef.current.innerHTML = commonUtil.removeStyleAndHrefTagsFromString(body);
  //       setTitleBody({ title: title, body: body })

  //     } else {
  //       setTitleBody({ title: '', body: '' })
  //     }

  //   }, [editCardData])


  //   const captureScreenshot = async () => {
  //     if (!link.length && !videoFile) {
  //       globalStates.handleToast(true, 'Please enter the URL or upload video', 'error')
  //       return
  //     }
  //     if (!titleBody.title.length) {
  //       globalStates.handleToast(true, 'Please enter the Title', 'error');
  //       return
  //     }
  //     if (!titleBody.body.length) {
  //       globalStates.handleToast(true, 'Please enter the Body', 'error');
  //       return
  //     }


  //     let cardData = {
  //       cardType: 'CREATED',
  //       title: titleBody.title,
  //       body: titleBody.body,
  //       isYoutube: isYoutube,
  //       youtubeUrl: link,
  //       videoFile: videoFile,
  //       thumbImage: constants.VTB_DEFAULT_THUMBNAIL,
  //       thumbFileSize: 0
  //     };
  //     if (!isEmpty(videoFile) && typeof (videoFile) !== "string") {
  //       cardData.thumbFileSize = videoFile.size;
  //     }

  //     const type = 'VTB';

  //     if (isEdit) {
  //       cardData.cardId = editCardData.id;
  //       // cardData.prevImageUrl = prevImageUrl;
  //       handleUpdateCard(type, cardData)
  //       return
  //     }
  //     handleSaveCard(type, cardData);

  //   }


  // console.log(videoFile)


  const handeleTitleChange = (text) => {
    const editor = titleRef.current.getEditor();
    let value = editor.getText().trim();
    setTitleBody(prev => ({ ...prev, title: text }))
    setTitleText(prev => prev = value)
  }

  const handleBodyChange = (text) => {
    const editor = bodyRef.current.getEditor();
    let value = editor.getText().trim();
    setTitleBody(prev => ({ ...prev, body: text }))
    setBodyText(prev => prev = value)
  }



  const handleSelectVideo = () => {
    // if(file)
    handleVideoUpload();
    setIsYoutube(false);
    // console.log('handleSelectVideo = ', file);
  }

  useEffect(() => {
    handleSelectVideo()
  }, [])





  const fileTypes = ["MP4", "AVI", "MOV", "GIF"];


  return (


    <>
      <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}

      >
        <div className={classes.videoBox} onMouseLeave={() => setShowDeleteIcon(false)}>
          {
            (!link.length && !videoFile) ?
              <DragDropFiles
                typesAccepted={fileTypes}
                withExternalLink={true}
                handleExternalLink={handleExternalLink}
                handleChange={handleSelectVideo}
                onMouseOver={() => setShowDeleteIcon(true)}
              /> :

              (link.length) ?
                <iframe width="100%" height="100%"
                  src={link}
                  onMouseOver={() => setShowDeleteIcon(true)}>
                </iframe> :
                <video width="100%" height="100%" controls>
                  <source src={video}
                    onMouseOver={() => setShowDeleteIcon(true)}
                  />
                </video>
          }

        </div>
        {(link.length > 0 || videoFile !== null || video) ?
          <span className={classes.deleteIcon}>
            {/* <CustomButton
              btnText={'REMOVE VIDEO'}
              bgcolor={'#F4511E'}
              textColor={'#FFF'}
              fw={800}
              ls={1}
              handleClick={handleRemoveVideo}
            /> */}
            {/* {showDeleteIcon && (
              // <span className={classes.deleteIcon}>
              // </span>
            )} */}
            <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Video" onClick={handleRemoveVideo} />
          </span>
          : null}



        <EditableTitle
          ref={titleRef}
          minHeight={'3rem'}
          maxHeight={'6rem'}
          handeleTitleChange={handeleTitleChange}
          value={titleBody.title}
          titleText={titleText}
          titleLimit={titleLimit}
        />
        <EditableBody
          ref={bodyRef}
          minHeight={'10rem'}
          maxHeight={'10rem'}
          handleBodyChange={handleBodyChange}
          value={titleBody.body}
          titleText={titleText}
        />

      </div>

    </>
  )
}

export default VideoCard;


const useStyles = makeStyles(() => ({
  videoBox: {
    borderRadius: '4px',
    minHeight: '50%',
    // height: '50%',
    width: '100%',
    maxWidth: 'calc(420px - 1rem)',
    aspectRatio: 4 / 3
  },
  // removeBtnBox: {
  //   position: 'absolute',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   // top: '0.5rem',
  //   // right: '0.5rem',
  //   height: '2.2rem',
  //   width: '2.2rem',
  //   borderRadius: '50%',
  //   backgroundColor: 'rgb(112,112,112,0.5)',
  //   top: '3rem',
  //   right: '1rem',
  //   '& .MuiButtonBase-root': {
  //     '&:hover': {
  //       borderColor: '#f4511e',
  //       backgroundColor: '#fff',
  //       color: '#f4511e'
  //     }
  //   },

  // },
  deleteIcon: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '1rem',
    right: '0.8rem',
    height: '2.2rem',
    width: '2.2rem',
    borderRadius: '50%',
    backgroundColor: 'rgb(112,112,112,0.5)',
    '& > img': {
      height: '1rem',
      width: '1rem',
      filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
    },
    '&:hover': {
      cursor: 'pointer',
      '& > img': {
        scale: 1.2,
        filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)',
      }
    }
  },

  titleContainer: {
    // border : '1px solid',
    width: '100%',
    height: '8rem',
    overflow: 'hidden',
    margin: '0.8rem 0 !important',
    '& > div': {
      margin: '0 !important',
      maxHeight: '100%',
      height: '100%',
      '&:hover': {
        border: 'none',
      }
    },
    '&:hover': {
      border: '1px dashed rgb(204, 204, 204, 0.5)',
    }
  },


}));
