import { authFetch } from "../utils/fetch";

const fetchTrainings = async (companyId, { trainerIds = null, limit = 100, startIndex = 0, id = null }) => {
    let url = `/training?startIndex=${startIndex}&limit=${limit}&sort=createdOn&sortDirection=desc&companyIds=${companyId}`
    if (trainerIds !== null) url += `&trainerIds=${trainerIds}`
    if (id) url += `&ids=${id}`
    try {
        const resData = await authFetch.get(`${url}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createTraining = async (payload) => {
    try {
        const resData = await authFetch.post(`/training`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateTraining = async (payload) => {
    try {
        const resData = await authFetch.put(`/training`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const deleteTraining = async (data) => {
    try {
        const resData = await authFetch.delete(`/training`, { data });
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchTrainingEvents = async (companyId, trainingId) => {
    try {
        const resData = await authFetch.get(`/training/event?startIndex=0&limit=10&sort=createdOn&sortDirection=asc&companyIds=${companyId}&trainingIds=${trainingId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchTrainingLocations = async (companyId, searchParam = null, id = null) => {
    let url = `/training/location?startIndex=0&limit=10&sort=createdOn&sortDirection=asc&companyIds=${companyId}`
    if (searchParam !== null) url += `&search=${searchParam}`
    if (id) url += `&ids=${id}`
    try {
        const resData = await authFetch.get(`${url}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createTrainingLocation = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/location`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const launchEventMicroskills = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/launch-microskills`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateTrainingLocation = async (payload) => {
    try {
        const resData = await authFetch.put(`/training/location`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const deleteTrainingLocation = async (data) => {
    try {
        const resData = await authFetch.delete(`/training/location`, { data });
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchLearnersAttendance = async (trainingId, eventId) => {
    try {
        const resData = await authFetch.get(`/training/event/learners-attendance?startIndex=0&limit=10000&sort=createdOn&sortDirection=asc&trainingIds=${trainingId}&eventIds=${eventId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const markAllLearnersPresent = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/mark-all-present`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const markLearnerPresent = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/mark-learners-present`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const markLearnerAbsent = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/mark-learners-absent`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const markDateValid = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/switch-date-on`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const markDateInValid = async (payload) => {
    try {
        const resData = await authFetch.post(`/training/event/switch-date-off`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchTrainers = async (companyId, { limit = 100, startIndex = 0 }) => {
    let url = `/training/get-trainers-details?startIndex=${startIndex}&limit=${limit}&sort=createdOn&sortDirection=asc&companyIds=${companyId}`

    try {
        const resData = await authFetch.get(`${url}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const saveRemarkForLearner = async (payload) => {
    let url = '/training/event/save-comment';
    try {
        const resData = await authFetch.post(url, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const downloadAttendanceSheet = async (payload) => {
    try {
        const resData = await authFetch.post(`/reports/tms-attendance-sheet`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}


export default {
    fetchTrainings,
    createTraining,
    updateTraining,
    deleteTraining,
    fetchTrainingEvents,
    launchEventMicroskills,
    fetchTrainingLocations,
    createTrainingLocation,
    updateTrainingLocation,
    deleteTrainingLocation,
    markAllLearnersPresent,
    markLearnerPresent,
    markLearnerAbsent,
    fetchLearnersAttendance,
    markDateValid,
    markDateInValid,
    fetchTrainers,
    saveRemarkForLearner,
    downloadAttendanceSheet
}