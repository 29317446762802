import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
    selectMenusWrapper: {
        padding: '0.5rem 0',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        "& > button":{
            backgroundColor: "#ffffff",
        },
    },
    addContentBtn: {
        position: 'relative',
        color: "#F4511E",
        backgroundColor: '#fff',
        border: '1px dashed #ff7043',
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '180px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
        '& > svg': {
            marginLeft: '0.6rem'

        },
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
}));

export default useStyles;