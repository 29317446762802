import React, { useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Slide from "@mui/material/Slide";
import { ButtonBase } from '@mui/material';
import { makeStyles } from "@mui/styles";
import commonUtil from '../../../../utils/commonUtil';
import constants from '../../../../constants';
import isEmpty from '../../../../utils/isEmpty';


export default function SearchBar({
    id = '',
    allCategories = [],
    allCategoryWiseMs = [],
    unCategorizedMs = [],
    handleEditMS
}) {
    // console.log('allCategories = ', allCategories);
    // console.log('allCategoryWiseMs = ', allCategoryWiseMs);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState('');
    const [showMore, setShowMore] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [microskills, setMicroskills] = React.useState([]);
    // console.log('microskills = ', microskills);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [showToggleBtn, setShowToggleBtn] = React.useState(true);
    const filterBtnContainerRef = useRef();
    const tableRef = useRef();
    const unCategorizedData = { id: 'uncategorized', name: 'Uncategorized', active: true, Microskills: unCategorizedMs };
    const allData = [...allCategoryWiseMs, unCategorizedData];

    useEffect(() => {
        if (open) {
            let tempCategories = [...allCategories].sort((a, b) => a?.sequence - b?.sequence);
            if (unCategorizedMs.length) tempCategories = [...tempCategories, { id: 'uncategorized', name: 'Uncategorized', sequence: null }];
            setCategories(tempCategories);
            setSelectedCategories([]);
            updateMicroskills(allData);
            setSearchVal('');
            setShowMore(false)
        }
    }, [open])

    useEffect(() => {
        if (!isEmpty(filterBtnContainerRef.current)) {
            let childrenWidth = 0
            let children = filterBtnContainerRef.current.childNodes;
            children.forEach(child => {
                let childWidth = child.getBoundingClientRect().width
                childrenWidth += childWidth + 16
            })
            let containerWidth = filterBtnContainerRef.current.getBoundingClientRect().width;
            if (childrenWidth > containerWidth) {
                setShowToggleBtn(true)
            } else {
                setShowToggleBtn(false)
            }
        }

    }, [filterBtnContainerRef.current, open])

    useEffect(() => {
        if (!showMore && !isEmpty(filterBtnContainerRef.current)) {
            filterBtnContainerRef.current.scrollTop = 0;
        }
    }, [showMore])

    useEffect(() => {
        if (microskills.length && !isEmpty(tableRef.current)) {
            tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [microskills])


    const updateMicroskills = (categoryData = []) => {
        let allMicroskills = [];
        categoryData.forEach(ele => {
            if (ele.active) {
                const categoryMs = ele?.Microskills || [];
                categoryMs.forEach(ms => {
                    if (ms.active) allMicroskills.push({ ...ms, categoryName: ele.name, categoryId: ele.id })
                });
            }
        })
        setMicroskills(allMicroskills);
    }

    const handleClickOpen = () => {
        if (!open) setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSearchVal('');
    };

    const handleSearchWithinCategories = (value) => {
        let filtered = allData.filter(ele => {
            if (selectedCategories.includes(ele.id)) return ele
        })

        if (!value.length) {
            updateMicroskills(filtered);
            return;
        }
        let categoriesWithSimilarMicroskills = getSearchedMicroskills(filtered, value);

        updateMicroskills(categoriesWithSimilarMicroskills);


    }

    const getSearchedMicroskills = (data, searchValue) => {
        let filterBySearch = [];
        data.forEach(ele => {
            let result = ele.Microskills.filter((item) => {
                if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                }
            })
            if (result.length) {
                let filteredMicroskills = { ...ele, Microskills: result }
                filterBySearch.push(filteredMicroskills);
            }
        })

        return filterBySearch;
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchVal(value);
        if (selectedCategories.length) {
            handleSearchWithinCategories(value)
            return
        }

        if (!value.length) {
            updateMicroskills(allData);
            return;
        }
        let categoriesWithSimilarMicroskills = getSearchedMicroskills(allData, value);

        updateMicroskills(categoriesWithSimilarMicroskills);


    }

    const handleSelectCategory = (item) => {
        setSearchVal('');
        let tempSelected = [...selectedCategories];
        if (tempSelected.includes(item.id)) {
            let filtered = tempSelected.filter(ele => ele !== item.id);
            setSelectedCategories(filtered);
            if (!filtered.length) {
                updateMicroskills(allData);
                return
            }
            const selectedCategoriesData = allData.filter(ele => {
                if (filtered.includes(ele.id)) return ele
            })
            updateMicroskills(selectedCategoriesData);
            return
        }
        tempSelected = [...tempSelected, item.id];
        setSelectedCategories(tempSelected);
        const selectedCategoriesData = allData.filter(ele => {
            if (tempSelected.includes(ele.id)) return ele
        })
        updateMicroskills(selectedCategoriesData);
    }

    return (
        <>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 400,
                    boxShadow: '0px 0px 6px -1px rgba(0,0,0,0.2)',
                    borderBottomLeftRadius: open ? 0 : 'inherit',
                    borderBottomRightRadius: open ? 0 : 'inherit',
                    cursor: 'text',
                    ':hover': {
                        backgroundColor: '#f1f1f150'
                    }
                }}
            // ref={containerRef}
            >
                <IconButton sx={{ p: '5px', color: '#f4511e' }} aria-label="menu">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    id={id}
                    sx={{ ml: 1, flex: 1, fontFamily: 'Open Sans' }}
                    placeholder={open ? "Start typing a microskill name..." : "Search Microskills"}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearch}
                    onFocus={handleClickOpen}
                    value={searchVal}
                />
                {open ?
                    <IconButton sx={{ p: '5px', ':hover': { color: '#f4511e' } }} aria-label="menu" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton> : null}
            </Paper>
            {
                open ?
                    <Slide direction="down" in={open} >
                        <div className={classes.slide}>
                            <h5>Search within these specific categories:</h5>
                            <div ref={filterBtnContainerRef} className={`${classes.categoryWrapper} ${showMore ? classes.categoryShowMore : ''}`} >
                                {/* <h5>Search within these specific categories:</h5> */}
                                {
                                    categories.map((item, i) => (
                                        <ButtonBase key={i} className={`${classes.btn} ${item.id === 'uncategorized' && classes.unCategorizedBtn} ${selectedCategories.includes(item.id) && classes.selected}`} onClick={() => handleSelectCategory(item)}>
                                            {item.name}
                                        </ButtonBase>
                                    ))
                                }
                            </div>
                            {showToggleBtn ? <div style={{ textAlign: 'center' }} >
                                <ButtonBase className={classes.btn2} onClick={() => setShowMore(prev => !prev)} >
                                    {showMore ? 'Show Less' : 'Show More'}
                                </ButtonBase>
                            </div> : null}

                            <div className={`${classes.microskillsWrapper}`} style={{ flexDirection: !microskills.length ? 'column' : 'row' }} ref={tableRef}>
                                <table className={classes.table}>
                                    <thead style={{
                                        backgroundColor: '#fff',//while freezing table header
                                        position: 'sticky',//while freezing table header
                                        top: 0, //while freezing table header
                                        zIndex: 1
                                    }}>
                                        <tr key={microskills.length} className={classes.tableHeading}>
                                            <th>Microskill Name</th>
                                            <th>Category</th>
                                            <th>Challenges</th>
                                            <th>Date Created</th>
                                        </tr>
                                    </thead>
                                    {/* {microskills.length ?  */}
                                    <tbody>
                                        {
                                            microskills.map((item, i) => (
                                                <tr key={i} className={classes.tableData}>
                                                    <td style={{ position: 'relative', minWidth: 300 }} title={item.name} onClick={() => handleEditMS(item)}>
                                                        <img src={item?.coverImage || item.OrgCoverImage || constants.MS_DEFAULT_COVER_IMAGE} />
                                                        <span style={{ position: 'absolute', top: '1rem' }}>{commonUtil.truncateString(item.name)}</span>
                                                    </td>
                                                    <td>{item.categoryId === 'uncategorized' ? '-' : commonUtil.truncateString(item.categoryName, 17)}</td>
                                                    <td>{isEmpty(item?.Challenges?.length) ? '-' : item?.Challenges?.length}</td>
                                                    <td title={item.createdOn}>{`${new Date(item.createdOn).toDateString()} ${new Date(item.createdOn).toLocaleTimeString()}`}</td>

                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    {/* : null} */}

                                </table>
                                {
                                    !microskills.length && <div className={classes.emptyTable}>
                                        No microskills found
                                    </div>
                                }
                            </div>
                        </div>
                    </Slide>
                    : null
            }
        </>
    );
}


const useStyles = makeStyles(() => ({
    slide: {
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: '0px 5px 6px -1px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 999,
        padding: '0.3rem 1rem',
        '& > h5': {
            color: 'rgb(112, 112, 112, 0.8)',
            fontWeight: 600,
            maxHeight: '1rem',
            minWidth: 'fit-content',
        }
    },
    categoryWrapper: {
        display: 'flex',
        gap: '1rem',
        overflow: 'hidden',

        maxHeight: '2rem',
        flexWrap: 'wrap',
        paddingBottom: '0.5rem'

    },
    categoryShowMore: {
        // flexWrap: 'wrap',
        maxHeight: '8rem',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: '#f4511e'
        },
    },
    selected: {
        border: '2px solid #f4511e',
        color: '#f4511e !important',
        fontWeight: 800,
        borderRadius: 3,

    },
    btn: {
        padding: '0.4rem 0.5rem',
        backgroundColor: '#CCCCCC70',
        minWidth: 'fit-content',
        color: '#484848',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '0.8rem'
    },
    unCategorizedBtn: {
        padding: '0.4rem 0.5rem',
        backgroundColor: '#fff',
        minWidth: 'fit-content',
        color: '#f4511e',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '0.8rem',
        // textDecoration: 'underline'
    },
    btn2: {
        minWidth: 'fit-content',
        color: '#f4511e',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: '0.8rem',
        textDecoration: 'underline'
    },
    microskillsWrapper: {
        height: '100%',
        maxHeight: '22rem',
        overflowY: 'auto',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: '#f4511e'
        },

    },
    table: {
        borderSpacing: "0px",
    },
    tableHeading: {
        color: "#484848",
        fontSize: "0.8rem",
        height: '3rem',
        textAlign: 'start',
        '& > th': {
            // borderBottom: '2px solid rgb(200, 200, 200, 0.5)',
            // backgroundColor: "rgb(200, 200, 200, 0.1)",
            fontFamily: 'Montserrat',
            fontWeight: 700,
            minWidth: '5rem',
            textWrap: 'nowrap',
            textTransform: 'capitalize',
            textAlign: 'start',
            paddingLeft: '1.8rem'
        }
    },
    tableData: {
        borderRadius: "2px",
        color: "#707070",
        fontSize: "0.8rem",
        height: '3rem',
        textAlign: 'start',
        '& > td': {
            borderTop: '2px solid rgb(200, 200, 200, 0.5)',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            textWrap: 'nowrap',
            paddingLeft: '1.8rem',
            '&:nth-child(1)': {
                '& > img': {
                    border: '1px solid rgb(200, 200, 200, 0.5)',
                    borderRadius: 4,
                    width: 35,
                    objectFit: 'cover',
                    aspectRatio: 1,
                    marginRight: '0.5rem'
                },
                '& > span:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }
            },

        },
        '&:last-child > td': {
            borderBottom: '2px solid rgb(200, 200, 200, 0.5)',
        }
        // '&:nth-child(even)': {
        //     backgroundColor: '#f9f9f9'
        // }
    },
    emptyTable: {
        minHeight: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        borderBottom: "1px solid rgb(112, 112, 112, 0.4)",
    },
}))

