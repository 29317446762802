import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    main: {
        border: "2px solid rgba(112, 112, 112, 0.2)",
        borderRadius: "4px",
        minHeight: "454px",
        maxHeight: '454px',
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 100,
        '& > h2': {
            padding: '0.6rem'
        }
    },
    selectWrapper: {
        border: '1px solid rgb(112,112,112, 0.3)',
        borderRadius: '3px',
        '& .MuiButtonBase-root': {

            '& > h1': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: "rgb(112, 112, 112)",
                padding: '0.4rem 1rem',
                gap: '1rem',
                height: '32px',
                '& > span': {
                    fontFamily: "Montserrat",
                    fontSize: '0.8rem',
                    fontWeight: 600,
                }


            }
        },
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
        }

    },
    searchInputBox: {
        backgroundColor: "#fff",
        border: '1px solid rgb(112,112,112,0.4)',
        padding: '0rem 1rem',
        height: '2rem',
        display: 'flex',
        alignItems: 'center',
        margin: '0 0.3rem',
        borderRadius: '3px',
        "& input": {
            width: "95%",
            backgroundColor: "#fff",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            }
        },
        "& i": {
            color: "#888888"
        }
    },
    filterWrapper: {
        marginTop: '1rem',
        display: 'flex',
        height: '300px',
        width: '680px !important'
    },

    filterList: {
        height: '100%',
        width: '30%',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            width: "0px",
        },
        '&:hover': {
            "&::-webkit-scrollbar": {
                width: "7px",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#f9f9f9",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: '#f4511e'

            }
        }
    },
    subMenuList: {
        backgroundColor: '#f9f9f9',
        width: '70%',
        '& > div': {
            "&:hover": {
                cursor: 'pointer',
                // backgroundColor: '#FFFFFF',
            }
        }
    },
    searchList: {
        height: "88%",
        width: "100%",
        maxHeight: '16rem',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            // 'scrollbar-width': 'thin'
            width: "0px",
        },
        '&:hover': {
            "&::-webkit-scrollbar": {
                // 'scrollbar-width': 'thin'
                width: "7px",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                // boxShadow: 'inset 0 0 6px rgba(112, 112, 112, 0.5)',
                borderRadius: "4px",
                backgroundColor: '#f4511e'
            }
        }


    },
    listItem: {
        paddingLeft: "0.5rem",
        height: '2.6rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '0.5rem',
        "& span": {
            // paddingLeft: "0.3rem",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            color: "#484848",
        },
        '& > span:first-child': {
            padding: '0.3rem'
        },
        '& > span:nth-child(2)': {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: '1rem',
            maxHeight: '1rem',
            overflow: 'hidden'
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9 !important'
        }
    },
    selectAllLi: {
        padding: "0 0.5rem",
        height: '2.3rem',
        "& span": {
            paddingLeft: "0.3rem",
            color: "#F4511E",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    btnBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        position: 'relative',
        '& > .MuiButtonBase-root': {
            width: '150px',
            height: '38px',
            minWidth: '100px',
            padding: 0,
            fontWeight: 800,
            letterSpacing: 1
        },
        '& > .MuiButton-contained': {
            backgroundColor: '#f4511e',
            color: '#fff',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#f4511e',
                color: '#fff',
                borderColor: '#f4511e'
            }
        },
        '& > .MuiButton-outlined': {
            backgroundColor: '#fff',
            color: '#f4511e',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

            }
        },
        '& > h1': {
            position: "absolute",
            right: "3%",
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
        }

    }
}))

export default useStyles