import React from 'react'

import { makeStyles } from "@mui/styles";

const CustomBanner = ({ bannerImage, bannerAltText

}) => {
    const classes = useStyles();
    return (
        <div className={classes.customBannerContainer}>
            <img className={classes.customBanner} src={bannerImage} alt={bannerAltText} />
        </div>
    )
}

export default CustomBanner;



const useStyles = makeStyles(() => ({
    customBannerContainer: {

    },
    customBanner: {

    },
}));
