import React, { useEffect, useRef, useState, useContext } from 'react'
import DraggableList from "react-draggable-list";

//mui
import { CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { ButtonBase } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

//utils
import commonUtil from '../../../../utils/commonUtil';


//components
import Label from '../../../custom-styled/label';
import Listitem from './list-item';
import CustomSelect from '../../../custom-styled/custom-select';

//constants
import constants from '../../../../constants';

//services
import textToSpeechServices from '../../../../services/text-to-speech';
import GbaSkeleton from '../skeleton';

//styles
import useStyles from './index.styles';
import { GlobalStates } from '../../../../App';


const BTN_STATES = {
  CONVERT_TO_AUDIO: "CONVERT_TO_AUDIO",
  CONVERTING: "CONVERTING",
  PLAYBACK: "PLAYBACK",
}

const defaultVoice = {
  "Name": "Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)",
  "DisplayName": "Christopher",
  "LocalName": "Christopher",
  "ShortName": "en-US-ChristopherNeural",
  "Gender": "Male",
  "Locale": "en-US",
  "LocaleName": "English (United States)",
  "SampleRateHertz": "48000",
  "VoiceType": "Neural",
  "Status": "GA",
  "WordsPerMinute": "149",
  id: "en-US-ChristopherNeural",
  name: "Christopher (Male)"
}

const ContextList = (props) => {
  const {
    type,
    selectedGame,
    isDisableAll,
    list,
    setList,
    addContextBtn,
    setAddContextBtn,
    handleAddContext,
    toggleCollapse,
    setToggleCollapse,
    isDragable,
    allExpanded,
    setAllExpanded,
    handleExpandCollapseAll,
    isContextAdded,
    setIsContextAdded,
    skills,
    handleDelete = () => { },
    settings,
    setSettings,
    generating = false,
    maxQuestions,
  } = props;
  const classes = useStyles();

  const bottomRef = useRef(null);
  const containerRef = useRef();

  const [textToSpeechContext, setTextToSpeechContext] = useState('')
  const [btnState, setBtnState] = useState(BTN_STATES.CONVERT_TO_AUDIO);

  const [globalStates] = useContext(GlobalStates);
  const [audioFileUrl, setAudioFileUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [voices, setVoices] = useState([defaultVoice]);
  const voicesObject = commonUtil.objectify(voices, 'id')
  const [selectedVoice, setSelectedVoice] = useState([defaultVoice.id]);
  const [selectedTtsLang, setSelectedTtsLang] = useState([defaultVoice.Locale]);
  const [ttsLanguages, setTtsLanguages] = useState(constants.TEXT_TO_SPEECH_LANGUAGES);
  const langObject = commonUtil.objectify(ttsLanguages, 'id');

  const audioRef = useRef(null);
  const handleAudioEnded = () => {
    setIsPlaying(!isPlaying);
  };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return
  }

  useEffect(() => {
    if (selectedGame.designType === "TF_SoundRight") {
      let language = settings?.textToSpeechLanguage?.length ? settings?.textToSpeechLanguage : defaultVoice.Locale;
      updateVoicesToDefault(language)
      if (!settings?.textToSpeechContext?.length) setTextToSpeechContext('')
      if (!settings?.textToSpeechContext?.length) setBtnState(BTN_STATES.CONVERT_TO_AUDIO)
    }
  }, [settings])

  useEffect(() => {
    if (settings?.textToSpeechContext?.length && settings?.textToSpeechS3Url?.length) {
      setTextToSpeechContext(settings?.textToSpeechContext || '')
      setAudioFileUrl(settings?.textToSpeechS3Url || '')
      if (settings?.textToSpeechVoice?.length) setSelectedVoice([settings.textToSpeechVoice]);
      setBtnState(BTN_STATES.PLAYBACK)
    }
  }, [])

  useEffect(() => {
    if (isContextAdded && type !== "MA") {
      scrollToBottom()
      setIsContextAdded(false);
    }
  }, [list])


  const updateVoicesToDefault = (language = defaultVoice.Locale) => {
    const filteredVoices = [];
    constants.TEXT_TO_SPEECH_VOICES.forEach(ele => {
      if (language === ele.Locale) filteredVoices.push({ ...ele, id: ele.ShortName, name: `${ele.DisplayName} (${ele.Gender})` })
    })
    filteredVoices.sort((a, b) => a.name - b.name)
    setVoices(filteredVoices);
    if (!selectedTtsLang.includes(language)) setSelectedTtsLang([language])
  }


  const _onListChange = (newList) => {
    //HERE IT GIVES US FINAL/CHANGED LIST AFTER DRAG
    setList(newList);
  };

  const typesOfAssessments = {
    MCQ: 'Multiple Choice Questions',
    MA: 'Multiple Answers',
    TF: 'True/False',
    MTRR: 'Match The Right Response'
  }

  const getAssessmentType = (value) => {
    return typesOfAssessments[value]
  }

  const textToSpeechContextChange = (e) => {
    setTextToSpeechContext(e.target.value);
    if (list.length && list[0]?.audioFile) {
      //also remove text from settings
      //change btn state to convert to text
      // setSettings(prev => ({ ...prev, textToSpeechContext: '', textToSpeechS3Url: null }));
      setBtnState(BTN_STATES.CONVERT_TO_AUDIO);
      setList(prev => {
        const newList = prev.map((item) => ({ ...item, audioFile: null, fileName: 'No File Chosen' }));
        return newList
      })
    }
  }

  //btn
  const btnStyle = {
    border: '2px solid #f4511e',
    padding: '0 0.5rem',
    fontSize: '0.7rem',
    fontWeight: 700,
    color: '#f4511e',
    position: 'absolute',
    right: '0.5rem',
    height: 38,
    placeSelf: 'center',
    borderRadius: '4px !important',
    fontFamily: 'Montserrat',
    width: 140
  }



  const handleTogglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleRemoveAudio = () => {
    setAudioFileUrl(null);
    setIsPlaying(false);
    setBtnState(BTN_STATES.CONVERT_TO_AUDIO);
    setList(prev => {
      const newList = prev.map((item) => ({ ...item, audioFile: null, fileName: 'No File Chosen' }));
      return newList
    })
  }

  const fileName = 'speech.wav';


  const handleConvertToAudio = async () => {
    if (!textToSpeechContext.length) {
      globalStates.handleToast(true, 'Please enter the context', 'error');
      return
    }
    if (!list.length) {
      globalStates.handleToast(true, 'Please add one question first', 'error');
      return
    }
    setBtnState(BTN_STATES.CONVERTING);
    const response = await textToSpeechServices.convertTextToSpeech({ text: textToSpeechContext, name: selectedVoice[0], gender: voices.find(ele => ele.ShortName === selectedVoice[0]).Gender, lang: selectedTtsLang[0] });
    if (response?.status === 200) {
      const blob = new Blob([response.data], { type: 'audio/wav' });
      const file = new File([blob], 'text-to-speech.wav', { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(blob);
      setAudioFileUrl(audioUrl);
      setList(prev => {
        const newList = prev.map((item) => ({ ...item, audioFile: file, fileName: 'text-to-speech.wav' }));
        return newList
      })
      setBtnState(BTN_STATES.PLAYBACK);
      //set text in settings
      setSettings(prev => ({ ...prev, textToSpeechContext: textToSpeechContext, textToSpeechVoice: selectedVoice[0], textToSpeechLanguage: selectedTtsLang[0] }));
    } else {
      globalStates.handleToast(true, 'Failed to convert text to speech', 'error');
      setBtnState(BTN_STATES.CONVERT_TO_AUDIO);
    }



  }

  const isExceeded = false;
  const isEmpty = false;
  const disabled = false;
  const maxLength = 3000;
  const limitText = `Max. Characters: ${maxLength - textToSpeechContext.length}`;

  const _selectVoice = (item) => {
    setSelectedVoice([item.id]);
    setBtnState(BTN_STATES.CONVERT_TO_AUDIO)
  }

  const _selectTtsLang = (item) => {
    const filteredVoices = [];
    constants.TEXT_TO_SPEECH_VOICES.forEach(ele => {
      if (item.id === ele.Locale) filteredVoices.push({ ...ele, id: ele.ShortName, name: `${ele.DisplayName} (${ele.Gender})` })
    })
    filteredVoices.sort((a, b) => a.name - b.name)
    setVoices(filteredVoices);
    if (filteredVoices.length) setSelectedVoice([filteredVoices[0].id]);
    setSelectedTtsLang([item.id]);
    setBtnState(BTN_STATES.CONVERT_TO_AUDIO);

  }


  return (
    <div className={classes.contextListMainContainer}>

      <div className={classes.row1}>
        <h1 className={classes.title}>Assessment Type : {getAssessmentType(type)}</h1>
        <ButtonBase className={classes.expandCollapse} onClick={handleExpandCollapseAll}>{allExpanded ? 'EXPAND ALL' : 'COLLAPSE ALL'}</ButtonBase>
      </div>
      {
        generating ?
          <div className={classes.row2}>
            <GbaSkeleton maxQuestions={maxQuestions} />
          </div> :
          <div
            ref={containerRef}
            className={classes.row2}
          >

            {selectedGame.designType === "TF_SoundRight" &&
              <div className={classes.textToSpeechContextWrapper}>
                <div className={classes.labelMenuWrapper}>
                  <Label labelText={`Generate Context:`} fs={'0.7rem'} />
                  <div>
                    <CustomSelect
                      defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Language', selected: selectedTtsLang, dataObject: langObject })}
                      autoClose={true}
                      withSearch={true}
                      withSelectAllOption={false}
                      listData={ttsLanguages}
                      selected={selectedTtsLang}
                      width={150}
                      handleSelect={_selectTtsLang}
                    />
                    <CustomSelect
                      defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Voice', selected: selectedVoice, dataObject: voicesObject })}
                      autoClose={true}
                      withSearch={true}
                      withSelectAllOption={false}
                      listData={voices}
                      selected={selectedVoice}
                      width={150}
                      handleSelect={_selectVoice}
                    />
                  </div>

                </div>
                <div className={`${classes.customTextareaBox} ${isExceeded || isEmpty ? classes.error : ''} ${disabled ? classes.disabledStyle : ''}`}>
                  <textarea disabled={disabled} value={textToSpeechContext} maxLength={maxLength} type='text' onChange={textToSpeechContextChange} style={{ resize: 'none' }} />
                  <>
                    {btnState === BTN_STATES.CONVERT_TO_AUDIO ? <ButtonBase sx={btnStyle} onClick={handleConvertToAudio}>Convert to Audio</ButtonBase> : null}
                    {btnState === BTN_STATES.CONVERTING ? <ButtonBase sx={btnStyle}><CircularProgress sx={{ width: '15px !important', height: '15px !important', marginRight: '10px !important', color: '#f4511e' }} /> Converting...</ButtonBase> : null}
                    {btnState === BTN_STATES.PLAYBACK ? <ButtonBase sx={btnStyle}>
                      {isPlaying ?
                        <PauseIcon sx={{ marginRight: '0.5rem', }} onClick={handleTogglePlay} /> :
                        <PlayArrowIcon sx={{ marginRight: '0.5rem', }} onClick={handleTogglePlay} />}
                      {commonUtil.truncateString(fileName, 24)}
                      <DeleteIcon sx={{ fontSize: '1rem', marginLeft: '0.5rem', }} onClick={handleRemoveAudio} />
                    </ButtonBase> : null}

                    {
                      audioFileUrl ?
                        <audio key={audioFileUrl} ref={audioRef} onEnded={handleAudioEnded}>
                          <source src={audioFileUrl} type="audio/wav" />
                          Your browser does not support the audio element.
                        </audio> : null
                    }
                  </>

                </div>
                <div className={classes.maxCharWarning}>{limitText}</div>
              </div>
            }


            <DraggableList
              itemKey="id"
              commonProps={{
                isDisableAll,
                toggleCollapse,
                setToggleCollapse,
                isDragable,
                list,
                setList,
                containerRef,
                type,
                allExpanded,
                setAllExpanded,
                selectedGame,
                skills,
                handleDelete,
                setSettings,
              }}
              template={Listitem}
              list={list}
              onMoveEnd={(newList) => _onListChange(newList)}
              container={() => containerRef.current}
            />
            <div ref={bottomRef} />
            {addContextBtn ?
              <button
                className={classes.btn}
                onClick={handleAddContext}>
                {type === "MCQ" ? `Add Question` : `Add Context`}  <i className="fa-solid fa-plus"></i>
              </button> : null}
          </div>
      }

    </div>
  );
}

export default ContextList;