import React, { useEffect, useState, useRef, useContext } from 'react'
import DraggableList from "react-draggable-list";

//components
import Listitem from './list-item';

//utils
import commonUtil from '../../../../utils/commonUtil';

//styles
import useStyles from './index.styles';
import { GlobalStates } from '../../../../App';

const LaDragableList = ({
  isActive,
  setIsActive,
  coverAndObjective,
  list,
  setList,
  setPreview,
  selectedCard,
  setSelectedCard,
  handleAddInteraction,
  handleManageInteraction,
  handleDeleteCard,
  setIsEdit,
  setEditCardData,
  setShowFirstColumn,
  setLink,
  laAudioData,
  setLaAudioData,
  laBgImgData,
  setLaBgImgData
}) => {
  const classes = useStyles();
  const [globalStates] = useContext(GlobalStates);
  const [isDragging, setIsDragging] = useState(null);
  const containerRef = useRef(null);
  const bottomRef = useRef(null);
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return
  }
  const scrollToItem = (id) => {
    let item = document.getElementById(`#${id}`);
    // // console.log('item = ', item);
    item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    return
  }
  useEffect(() => {
    let isScrollToBottom = localStorage.getItem('scrollToBottom');
    if (isScrollToBottom) {
      localStorage.removeItem('scrollToBottom');
      scrollToBottom();
    }
  }, [])

  useEffect(() => {
    let isScrollToItem = localStorage.getItem('scrollToItem');
    if (isScrollToItem) {
      scrollToItem(isScrollToItem);
      setIsActive(isScrollToItem);
      localStorage.removeItem('scrollToItem');
    }
  }, [])

  const _onListChange = (newList) => {
    //HERE IT GIVES US FINAL/CHANGED LIST AFTER DRAG
    //ALSO HERE WE HAVE TO UPDATE SEQUENCE OF EACH LIST-ITEM
    setIsDragging(null)
    setList(newList);
  };


  const cardTypes = {
    ITB: 'imageTitleBody',
    VTB: 'videoTitleBody',
    I: 'imageOnly',
    COVER: 'cover',
    OBJECTIVE: 'objective',
    IMPORTED: 'imageOnly',
    TB: 'titleBody',
    B: 'body'
  }

  const getCardType = (type) => {
    return cardTypes[type]
  }

  const handleEdit = async (e, item) => {
    // console.log('handleEdit item = ', item);
    e.stopPropagation();
    let card = item.card;
    setIsActive(item.id)
    let cardType = getCardType(item.card.cardType);
    if (cardType === 'objective' || !cardType) {
      globalStates.handleToast(true, 'Coming soon...', 'info')
      return
      setShowFirstColumn(false)
    }

    setEditCardData(item);
    let tempLinkData = item.card.link;
    setLink(tempLinkData);
    let audioDataCopy = {
      ...laAudioData,
      fileName: card.audioFileName,
      audioFileUrl: card.audioURL,
      mimeType: card.audioMimeType
    };
    setLaAudioData(audioDataCopy)
    let bgImgDataCopy = { ...laBgImgData };
    let prevBgUrl = card?.backgroundImageURL;
    if (prevBgUrl) {
      globalStates.handleProgressDialog(true, 'Please wait...');
      await commonUtil.imageUrlToBase64(prevBgUrl, (base64) => {
        bgImgDataCopy.bgImg = base64;
        bgImgDataCopy.prevBgImageUrl = prevBgUrl;
        setLaBgImgData(bgImgDataCopy);
        setIsEdit(true);
        // // console.log('handle edit bgImgDataCopy = ', bgImgDataCopy);
        setSelectedCard({ ...selectedCard, cardType: cardType });
      })
      globalStates.handleProgressDialog(false);
      return
    }
    setLaBgImgData(bgImgDataCopy);
    setIsEdit(true);
    // // console.log('handle edit bgImgDataCopy = ', bgImgDataCopy);
    setSelectedCard({ ...selectedCard, cardType: cardType });

  }

  const handleThumbImgClick = async (e, item) => {
    e.stopPropagation();
    setIsActive(item.id)
    setIsDragging(null);
    if (item.card.cardType === "VTB") {
      setEditCardData(item)
      setLink(item.card.link);
      setSelectedCard({ ...selectedCard, cardType: 'videoTitleBody' });
      setIsEdit(true);
      let bgImgDataCopy = { ...laBgImgData };
      let prevBgUrl = item.card?.backgroundImageURL;
      if (prevBgUrl) {
        globalStates.handleProgressDialog(true, 'Please wait...');
        await commonUtil.imageUrlToBase64(prevBgUrl, (base64) => {
          bgImgDataCopy.bgImg = base64;
          bgImgDataCopy.prevBgImageUrl = prevBgUrl;
          setLaBgImgData(bgImgDataCopy);
          globalStates.handleProgressDialog(false);
        })
      }

    }
    // else if(item.card.isGenAi){
    //   handleEdit(e, item)
    // }
     else {
      setSelectedCard({ ...selectedCard, cardType: 'default' })
      setPreview(item.thumbImage)
    }


  }


  return (
    <>
      <div className={classes.mainContainerDraglist} >
        <div className={classes.coverAndObjectiveLi}>
          {
            coverAndObjective.map((ele, i) => (
              //CHANGED FROM COMPONENT TO DIV
              <div key={i} className={`${classes.listItemLaLI}`}
                style={{
                  backgroundColor: (isActive === ele.id) ?
                    "rgb(204, 204, 204, 0.3)" : "#fff"
                }}
              >
                <div className={classes.columnLI} onClick={(e) => handleThumbImgClick(e, ele)}>
                  <img src={ele.thumbImage} alt='' />
                  <div className={classes.cardDetailsLI} >
                    <h4>{ele.card.cardType}</h4>
                    <h5>{ele.card.cardType}</h5>
                    <h6>{commonUtil.formatBytes(ele.size)}</h6>
                  </div>
                  <div className={classes.duplicateDeleteLI}>
                    <img src='/images/icons/pencil-edit-button.svg' alt='' style={{ marginRight: '0.2rem' }} onClick={(e) => handleEdit(e, ele)} />
                    {/* <img src='/images/icons/delete-photo.svg' alt='' onClick={(e) => handleDeleteCard(e, ele)} /> */}
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        {/* <LaListItem isManageInteraction={false} type={'cover'} item={coverCard} /> */}
        <div ref={containerRef} style={{ marginTop: '0.6rem', }}>
          <DraggableList
            itemKey="id"
            commonProps={{
              list,
              setPreview,
              isDragging,
              setIsDragging,
              selectedCard,
              setSelectedCard,
              handleAddInteraction,
              handleManageInteraction,
              handleDeleteCard,
              isActive,
              setIsActive,
              coverAndObjective,
              handleEdit,
              handleThumbImgClick
            }}
            template={Listitem}
            list={list}
            onMoveEnd={_onListChange}
            container={() => containerRef.current}
            springConfig={{ stiffness: 300, damping: 42 }}
          // constrainDrag={true}
          // padding={10}
          />
          <div ref={bottomRef} />
        </div>


      </div>
    </>
  )
}

export default LaDragableList;

