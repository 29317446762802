import React from "react";

//mui
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Switch, { SwitchProps } from '@mui/material/Switch';

//components
import Title from "../title";
// styles
import useStyles from "./index.styles";

let defaultDescriptionTitle = 'How to use projects:'
let defaultDescriptionText = 'This tool gives you the ability to make different projects for your micro learning needs. Every project can house upto 12 Microskils and a documents folder wherein you can store aad onal macrolearning documents and reference matenal for your learning audience. You have full control on whether you want a documents folder or not. Every project must have atleast 1 Microskill.'

const CreateNewCompany = ({
  title,
  labels,
  placeholder = '',
  btnTxt,
  handleSubmit,
  inputValues,
  setInputValues,
  handleRepository,
  withValidity = true,
  withCheckBox = true,
  withToggle = false,
  handleToggleChange,
  descriptionTitle = defaultDescriptionTitle,
  descriptionText = defaultDescriptionText,
  toggleValue,   // Add this
  setToggleValue // Add this
}) => {
  const styles = useStyles();
  // const switchClasses = iosStyle();
  let newDate = new Date();
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();
  let year = newDate.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  let minDate = [year, month, day].join('-');
  // console.log('min_date = ' , minDate);

  const handleCompanyChange = (e) => {

    setInputValues({ ...inputValues, name: e.target.value });
  };

  const handleValidityChange = (e) => {
    setInputValues({ ...inputValues, validity: e.target.value });
  };

  const submitData = (e) => {
    e.preventDefault()
    let target = e.target.elements;
    let data = {
      name: target.CompanyName.value
    };

    if (withValidity) {
      let date = new Date(target.Validity.value); // some mock date
      let milliseconds = date.getTime();
      data.validity = milliseconds;
    }
    handleSubmit(data)
    // target.CompanyName.value = ""
    // target.Validity.value = ""
    // setInputValues({ name: "", validity: "" })
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.Left_div}>
        <Title titleText={title} infoIcon={true} />
        {/* <h2 className={styles.Title}>
            {title} <HelpIcon className={styles.Help_Icon} />
          </h2> */}
        <form onSubmit={submitData}>
          <div>
            <label htmlFor="CompanyName" className={styles.Label}>
              {labels.companyName}
            </label>
            <input
              className={styles.Input_Box}
              id="CompanyName"
              onChange={handleCompanyChange}
              name="CompanyName"
              value={
                inputValues.name
                  ? inputValues.name
                  : ''
              }
              type="text"
              minLength='2'
              maxLength='50'
              placeholder={placeholder}
              required
            />
          </div>
          {withValidity ?
            <div className={styles.datePickerBox}>
              <label htmlFor="Validity" className={styles.Label}>
                {labels.validity}
              </label>
              <input
                className={styles.Date_Input}
                id="Validity"
                onChange={handleValidityChange}
                name="Validity"
                min={minDate}
                value={
                  inputValues.validity
                    ? inputValues.validity
                    : ''
                }
                type="date"
                placeholder="dd-mm-yyyy"
                required
              />
            </div> : null}

    {/* Toggle Switch */}
  { withToggle  ?<div>
                <label htmlFor="toggleSwitch">Language Group:</label>
                <Switch
                    id="toggleSwitch"
                    checked={toggleValue}  // Bind to toggleValue
                    onChange={handleToggleChange}  // Update toggleValue on change
                />
            </div>
 : null}

          {withCheckBox ?
            <div className={styles.CheckBoxContainer}>
              <FormGroup>
                <FormControlLabel
                  className={styles.CheckBox}
                  control={
                    <Checkbox
                      onChange={handleRepository}
                    // checked={true}
                    />
                  }
                  label="Create Content Repository"
                />
              </FormGroup>
            </div> : null}
          <button className={styles.Save_Btn} type="Submit">
            {btnTxt}
          </button>
        </form>
      </div>

      <div className={styles.Right_div}>
        <div className={styles.Inside_Right_div}>
          <h1 className={styles.Right_div_title}>
            <ErrorOutlineIcon className={styles.Error_Icon} /> {descriptionTitle}
          </h1>
          <p className={styles.Right_div_textbox}>
            {descriptionText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreateNewCompany;
