import { makeStyles } from "@mui/styles";


const styles = makeStyles({
  full_Width: {
    width: '85%',
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      // margin: '0',

      '& .MuiTypography-root': {
        color: 'rgb(112, 112, 112,0.9)',
        // fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: "500",
      },
      '& .MuiButtonBase-root': {
        // padding: '0.4rem',
        // margin: '0 0.3rem'
      }
    }
  }
})

export default styles