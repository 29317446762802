import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 15px 50px;
  border-radius: 0px;
  background-color: #f4511e;
  color: white;
  border: transparent;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Montserrat';
  min-width: 200px;
  cursor: pointer;
  transition: ease box-shadow 0.4s;
  outline: 0;
  user-select: none;
  display: inline-block;
  text-transform: uppercase;

  :hover {
    box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.13);
  }

  :hover:active {
    transform: scale(0.99);
  }
  :disabled {
    cursor: not-allowed;
    background-color: #cccccc; /* Disabled background color */
    color: #666666; /* Disabled text color */
    box-shadow: none; /* Remove shadow for disabled state */
  }
`;

var FlatPrimaryButton = function (props) {
  let onClick = (e) => {
    if (props.hasOwnProperty('onClick')) {
      props.onClick(e);
    }
  };

  let className = '';

  if (props.hasOwnProperty('className')) {
    className = props.className;
  }

  let disabled = false;

  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }
  let style = {};

  if (props.hasOwnProperty('style')) {
    style = props.style;
  }
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={className}>
      {props.children}
    </StyledButton>
  );
};

export default FlatPrimaryButton;
