import draglistData from "../../draglist-data";
import { v4 as uuid } from 'uuid';


//services
import azureServices from "../../../../services/microsoft-azure";
import isEmpty from "../../../../utils/isEmpty";


const addMatchingSkillTags = async (question = '', skills = []) => {
    let skillList = skills.map(ele => ele.skill);
    let skillPrompt = `Provide the skill tag from the array ${JSON.stringify(skillList)} that best corresponds to the question '${question}' If none of the skills match the question, return null. Your response should only include the skill tag from the array or null.`
    const messages = [
        { role: "user", content: skillPrompt },
    ];
    let response = await azureServices.azureOpenAi(messages);
    let content = response?.choices[0]?.message?.content;
    console.log('MatchingSkillTags = ', content);
    return content;
}

// let prompt = `Please generate ${numberOfQuestions} multiple-choice questions based on the topic ${topic}. Ensure that the correct answer is marked as 'true' in the answers array. Provide the questions in an array of json following this format: [{"id": 0,"question": "Here goes the question 1","answers": [{"id": 0,"option": "Here goes the answer 1 for question 1","value": true},{"id": 1,"option": "Here goes the answer 2 for question 1","value": false},{"id": 2,"option": "Here goes the answer 3 for question 1","value": false},{"id": 3, "option": "Here goes the answer 4 for question 1", "value": false}] } ]`

const GAMETYPE_JSONS_FOR_PROMPTS = {
    MCQ: `[{"id": 0,"question": "Here goes the question 1 with maximum 110 characters","answers": [{"id": 0,"option": "Here goes the answer 1 for question 1 with maximum 60 characters","value": true},{"id": 1,"option": "Here goes the answer 2 for question 1 with maximum 60 characters","value": false},{"id": 2,"option": "Here goes the answer 3 for question 1 with maximum 60 characters","value": false},{"id": 3, "option": "Here goes the answer 4 for question 1 with maximum 60 characters", "value": false}] } ]`,
    TF: `[{ "id": 0,"context": "Here goes the context 1 with maximum 85 characters","question": "Here goes the question 1 with maximum 100 characters","answers": [{"id": 0,"option": "TRUE","value": true},{ "id": 1,"option": "FALSE", "value": false}] } ]`,
    MA: `[{ "id": 0, "question": "Here goes the question 1 with maximum 85 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 for question 1 with maximum 63 characters", "value": true }, { "id": 1, "option": "Here goes the answer 2 for question 1 with maximum 63 characters", "value": true }, { "id": 2, "option": "Here goes the answer 3 for question 1 with maximum 63 characters", "value": true }, { "id": 3, "option": "Here goes the answer 4 for question 1 with maximum 63 characters", "value": true }, { "id": 4, "option": "Here goes the answer 5 for question 1 with maximum 63 characters", "value": false }, { "id": 5, "option": "Here goes the answer 6 for question 1 with maximum 63 characters", "value": false }, { "id": 6, "option": "Here goes the answer 7 for question 1 with maximum 63 characters", "value": false }, { "id": 7, "option": "Here goes the answer 8 for question 1 with maximum 63 characters", "value": false }] }]`,
    MTRR: `[{ "id": 0, "context": "Here goes the context 1 with maximum 85 characters", "questionArray": [{ "id": 0, "question": "Here goes the question 1 of context 1 with maximum 66 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 of question 1 with maximum 45 characters", "value": true }] }, { "id": 1, "question": "Here goes the question 2 of context 1 with maximum 66 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 of question 2 with maximum 45 characters", "value": true }] }, { "id": 2, "question": "Here goes the question 3 of context 1 with maximum 66 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 of question 3 with maximum 45 characters",  "value": true }] }, { "id": 3, "question": "Here goes the question 4 of context 1 with maximum 66 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 of question 4 with maximum 45 characters",  "value": true }] }, { "id": 4, "question": "Here goes the question 5 of context 1 with maximum 66 characters", "answers": [{ "id": 0, "option": "Here goes the answer 1 of question 5 with maximum 45 characters", "value": true }] }] }]`
}

const getRegeneratePrompt = (listItem, selectedGame) => {
    let type = selectedGame?.type;
    let finalPrompt = '';

    let question = `Question: ${listItem.question} \n Answers:  ${JSON.stringify(listItem.answers)}`

    switch (type) {
        case 'MCQ':
            return finalPrompt = `Please rephrase and make this question more difficult '${question}'. Ensure that the correct answer is marked as 'true' in the answers array, also ensure that the character limit for questions is strictly under 110 characters, and for options, under 60 characters. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        // case 'TF':
        //     return finalPrompt = `Please generate ${maxQuestions} true-false questions based on the topic '${prompt}'. Ensure that the correct answer is marked as 'true' in the answers array, also ensure maximum character limit of context value should be 85 and maximum character limit of question should be 100. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        // case 'MA':
        //     return finalPrompt = `Please generate ${maxQuestions} multiple-answer questions based on the topic '${prompt}'. Ensure that minimum 4 correct answers are marked as 'true' in the answers array, also ensure maximum character limit of question should be 85 and maximum character limit of option should be 63. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        // case 'MTRR':
        //     return finalPrompt = `Please generate ${maxQuestions} match-the-right-response contexts based on the topic '${prompt}'. Ensure that there should be minimum ${mtrrMinQuestions} questions in questionArray. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        default:
            return finalPrompt;
    }
}

const getStitchedPrompt = (prompt = '', selectedGame, maxQuestions) => {
    let type = selectedGame?.type;
    console.log('prompt = ', prompt);
    let finalPrompt = prompt;
    const mtrrMinQuestions = 5
    if (type !== 'MCQ') return finalPrompt; //remove this
    switch (type) {
        case 'MCQ':
            return finalPrompt = `Please generate only ${maxQuestions} multiple-choice questions based on the topic '${prompt}'. Ensure that the correct answer is marked as 'true' in the answers array, also ensure that the character limit for questions is strictly under 110 characters, and for options, under 60 characters. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        case 'TF':
            return finalPrompt = `Please generate ${maxQuestions} true-false questions based on the topic '${prompt}'. Ensure that the correct answer is marked as 'true' in the answers array, also ensure maximum character limit of context value should be 85 and maximum character limit of question should be 100. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        case 'MA':
            return finalPrompt = `Please generate ${maxQuestions} multiple-answer questions based on the topic '${prompt}'. Ensure that minimum 4 correct answers are marked as 'true' in the answers array, also ensure maximum character limit of question should be 85 and maximum character limit of option should be 63. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        case 'MTRR':
            return finalPrompt = `Please generate ${maxQuestions} match-the-right-response contexts based on the topic '${prompt}'. Ensure that there should be minimum ${mtrrMinQuestions} questions in questionArray. Provide the questions in an array of json following this format: ${JSON.stringify(GAMETYPE_JSONS_FOR_PROMPTS[type])}`
        default:
            return finalPrompt;
    }

}

const getSkillId = (skill, skillList) => {
    if (skill === 'null' || !skill || !skill.length) return ''
    let data = skillList.find(s => s.skill == skill)
    return data?.id ? data?.id : ''
}

const checkIfAllAnswersAreEmpty = (answers = []) => {
    let isEmpty = true;
    for (let i = 0; i < answers.length; i++) {
        if (answers[i].option.length) {
            isEmpty = false
            break
        }
    }
    return isEmpty;
}

const checkIfMtrrQuestionsAnswersAreEmpty = (arr = []) => {
    let isEmpty = true;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].question.length || arr[i].answers[0].option.length) {
            isEmpty = false
            break
        }

    }
    return isEmpty;
}

const checkIfDefaultList = (selectedGame, list = []) => {
    let isDefault = false;
    if (list.length === 1) {
        let type = selectedGame?.type;

        switch (type) {
            case 'MCQ':
                if (isEmpty(list[0].question) && checkIfAllAnswersAreEmpty(list[0].answers)) {
                    isDefault = true;
                }
                break;
            case 'TF':
                if (isEmpty(list[0].question) && isEmpty(list[0].contextObj.context)) {
                    isDefault = true;
                }
                break;
            case 'MA':
                if (isEmpty(list[0].question) && checkIfAllAnswersAreEmpty(list[0].answers)) {
                    isDefault = true;
                }
                break;
            case 'MTRR':
                if (isEmpty(list[0].contextObj.context) && checkIfMtrrQuestionsAnswersAreEmpty(list[0].questionArr)) {
                    isDefault = true;
                }
                break;
            default:
                break;
        }
    }
    return isDefault
}

const getMcqList = async (selectedGame, genAiContent, skillList, list, isEdit = false) => {
    let tempList = [...list]
    if (checkIfDefaultList(selectedGame, list)) tempList = [];
    let stringified = JSON.stringify(draglistData[selectedGame.type][0]);
    let parsedData = JSON.parse(stringified);
    for (let i = 0; i < genAiContent.length; i++) {
        // let skillTag = await addMatchingSkillTags(genAiContent[i].question, skillList)
        let skillTag = ''
        let tempObj = {
            ...parsedData,
            uuid: uuid(),
            genAi: true,
            id: tempList.length,
            questionId: tempList.length,
            question: genAiContent[i].question,
            sequence: tempList.length + 1,
            skillId: getSkillId(skillTag, skillList)
        }
        let tempAnswers = [...genAiContent[i].answers];
        for (let j = tempAnswers.length; j < 4; j++) {
            tempAnswers.push({
                id: j,
                option: "",
                value: false,
                sequence: j + 1
            })
        }
        tempObj.answers = tempAnswers;
        tempList.push(tempObj)
    }
    return tempList;
}

const getTfList = async (selectedGame, genAiContent, skillList, list, isEdit = false) => {
    let tempList = [...list]
    if (checkIfDefaultList(selectedGame, list)) tempList = [];
    const isSoundsRight = selectedGame.designType === 'TF_SoundRight';
    let stringified;
    if (isSoundsRight) stringified = JSON.stringify(draglistData['TF_SoundRight'][0]);
    else stringified = JSON.stringify(draglistData[selectedGame.type][0]);
    let parsedData = JSON.parse(stringified);
    // console.log('parsedData  = ', parsedData);
    for (let i = 0; i < genAiContent.length; i++) {
        let skillTag = await addMatchingSkillTags(genAiContent[i].question, skillList)
        let tempObj = {
            ...parsedData,
            id: tempList.length,
            questionId: tempList.length,
            question: genAiContent[i].question,
            contextId: tempList.length,
            sequence: tempList.length + 1,
            skillId: getSkillId(skillTag, skillList)
        }
        if (!isSoundsRight) tempObj.contextObj = { id: tempList.length, type: 'TEXT', context: genAiContent[i].context }

        let tempAnswers = [...genAiContent[i].answers];
        for (let j = tempAnswers.length; j < 2; j++) {
            let answerObj = {
                id: j,
                option: "",
                value: true,
                sequence: j + 1
            }
            tempAnswers.push(answerObj)
        }
        tempObj.answers = tempAnswers;
        tempList.push(tempObj)
    }
    return tempList;
}

const getMaList = async (selectedGame, genAiContent, skillList, list, isEdit = false) => {
    let tempList = [...list]
    if (checkIfDefaultList(selectedGame, list)) tempList = [];
    const isRevealThePicture = selectedGame.designType === 'MA_RevealThePicture';
    let stringified;
    if (isRevealThePicture) stringified = JSON.stringify(draglistData['MA_RevealThePicture'][0]);
    else stringified = JSON.stringify(draglistData[selectedGame.type][0]);
    let parsedData = JSON.parse(stringified);
    // console.log('parsedData  = ', parsedData);
    for (let i = 0; i < genAiContent.length; i++) {
        let skillTag = await addMatchingSkillTags(genAiContent[i].question, skillList)
        let tempObj = {
            ...parsedData,
            id: tempList.length,
            questionId: tempList.length,
            question: genAiContent[i].question,
            sequence: tempList.length + 1,
            skillId: getSkillId(skillTag, skillList)
        }
        let tempAnswers = [...genAiContent[i].answers];
        for (let j = tempAnswers.length; j < 8; j++) {
            tempAnswers.push({
                id: j,
                option: "",
                value: false,
                sequence: j + 1
            })
        }
        tempObj.answers = tempAnswers;
        tempList.push(tempObj)
    }
    return tempList;
}

const getMtrrList = async (selectedGame, genAiContent, skillList, list, isEdit = false) => {
    let tempList = [...list]
    if (checkIfDefaultList(selectedGame, list)) tempList = [];

    const isLearnoCasino = selectedGame.designType === 'MTRR_LearnoCasino';
    let stringified;
    if (isLearnoCasino) stringified = JSON.stringify(draglistData['MTRR_LearnoCasino'][0]);
    else stringified = JSON.stringify(draglistData[selectedGame.type][0]);
    let parsedData = JSON.parse(stringified);
    // console.log('parsedData  = ', parsedData);
    for (let i = 0; i < genAiContent.length; i++) {
        let skillTag = await addMatchingSkillTags(genAiContent[i].context, skillList)
        let tempObj = {
            ...parsedData,
            id: tempList.length,
            contextId: tempList.length,
            contextObj: { id: tempList.length, type: 'TEXT', context: genAiContent[i].context },
            sequence: tempList.length + 1,
            skillId: getSkillId(skillTag, skillList)
        }
        let tempQuestions = [];
        const currentQuestionArr = genAiContent[i].questionArray;
        for (let j = 0; j < tempObj.questionArr.length; j++) {
            let questionObj = {
                ...tempObj.questionArr[j],
                id: j,
                contextId: tempList.length,
                skillId: tempObj.skillId,
                question: currentQuestionArr[j]?.question || "",
                answers: [{ id: 0, option: currentQuestionArr[j]?.answers[0]?.option || "", value: true }]
            }
            tempQuestions.push(questionObj)
        }

        tempObj.questionArr = tempQuestions;
        tempList.push(tempObj)
    }
    return tempList;
}

export default {
    getStitchedPrompt,
    getMcqList,
    getTfList,
    getMaList,
    getMtrrList,
    getRegeneratePrompt
}
