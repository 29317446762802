import React, { useEffect, useState } from 'react'

// mui
import { makeStyles } from "@mui/styles";
// import { Add } from '@material-ui/icons';
import AddIcon from '@mui/icons-material/Add';

//components
import CustomSelect from '../../../custom-styled/custom-select';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';

//services
import reportsServices from '../../../../services/reports';
import microskillServices from '../../../../services/microskill';

const ViaDemographic = ({
    setShowDwModal,
    selectedUsers = [],
    setSelectedUsers,
    userData,
    setUserData,
    selectedMicroskills,
    setHeadings,
    setLoadingUserData
}) => {
    const classes = useStyles();
    const query = useQuery();
    const companyId = query.get('c_id');
    const [demographicFilter, setDemographicFilter] = useState([]);
    const [allDemographicObjects, setAllDemographicObjects] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    const [selectedDemographicColumns, setSelectedDemographicColumns] = useState([]);

    const [mapping, setMapping] = useState([]);
    let mappingObject = commonUtil.objectify(mapping, "name");

    useEffect(() => {
        fetchDemographics(companyId);
    }, [])

    useEffect(() => {
        fetchMsAndDwUsers()
    }, [selectedMicroskills])

    const columnsDataObject = commonUtil.objectify(columnsData, 'id')

    const selectDemographicColumn = (item) => {
        setSelectedUsers([]);
        if (selectedDemographicColumns.includes(item.id)) {
            let filtered = selectedDemographicColumns.filter(ele => ele !== item.id);
            setSelectedDemographicColumns(filtered);
            const newDemographicFilter = demographicFilter.map(curr => {
                let temp = { ...curr };
                if (temp.id === item.id) temp.selected = [];
                return temp
            })
            setDemographicFilter(newDemographicFilter);
            fetchMsAndDwUsers(getSelectedDemographics(newDemographicFilter));
        } else {
            setSelectedDemographicColumns([...selectedDemographicColumns, item.id])
        }
    }

    const updateToDefaultDF = (distinctColumnValues) => {
        if (distinctColumnValues.length) {
            let tempColumnsData = []
            let tempDemographicFilters = [];
            distinctColumnValues.forEach(ele => {
                let tempSubmenu = [];
                ele.values.forEach(item => {
                    if (item) tempSubmenu.push({ id: item, name: item })
                });
                let tempObj = {
                    id: ele.columnName,
                    name: ele.columnName,
                    subMenu: tempSubmenu,
                    selected: [],
                    total: tempSubmenu.length
                };
                tempDemographicFilters.push(tempObj);
                tempColumnsData.push({
                    id: ele.columnName,
                    name: ele.columnName,
                });

            })
            setColumnsData(tempColumnsData);
            setDemographicFilter(tempDemographicFilters);
            let demographicObjects = commonUtil.objectify(tempDemographicFilters, "id");
            // console.log('tempDemographicFilters = ', tempDemographicFilters);
            // console.log('demographicObjects = ', demographicObjects);
            // console.log('tempColumnsData = ', tempColumnsData);
            setAllDemographicObjects(JSON.stringify(demographicObjects))

        }
    }

    const fetchDemographics = async (companyId) => {
        //  setLoading(true);
        const response = await reportsServices.getDemographics(companyId, true);
        if (response && response.status === 200) {
            let distinctColumnValues = response.data.data.distinctColumnValues;
            updateToDefaultDF(distinctColumnValues);
            let resData = response.data.data;
            if (resData?.mapping && !mapping.length) {
                let mapping = resData?.mapping;
                mapping.forEach(element => {
                    element.id = element.table_column;
                    element.name = element.excel_column;
                });
                setMapping(mapping);
            }
        }
    }

    const fetchMsAndDwUsers = async (demographicFilter = []) => {
        if (!selectedMicroskills.length) return;
        setLoadingUserData(true);
        let payload = {
            companyId,
            microskillIds: selectedMicroskills
        }
        if (demographicFilter.length) payload.userDemographics = demographicFilter;
        const response = await microskillServices.getMicroskillAndDemographicWiseUsers(payload);
        setLoadingUserData(false);
        console.log('response = ', response);
        if (response && response.status === 200) {
            let headings = commonUtil.getHeadings(response.data.data);
            if (headings.length) {
                headings = [{ title: "checkbox", key: "checkbox", isCheckbox: true }, ...headings];
                setHeadings(headings);
            }

            setUserData(response.data?.data || [])
        }
    }

    const _selectAllSubmenuDF = (currentList) => {
        setSelectedUsers([]);
        let isSelectAll = currentList.selected.length === currentList.subMenu.length;

        let tempDemographicFilter = demographicFilter.map((element) => {
            let tempElement = { ...element };
            if (element.id === currentList.id) {
                let newSelected = []
                if (!isSelectAll) {
                    newSelected = currentList.subMenu.map(ele => ele.id)
                }

                tempElement = { ...element, selected: newSelected };

            }
            return tempElement
        });
        setDemographicFilter(tempDemographicFilter);
        fetchMsAndDwUsers(getSelectedDemographics(tempDemographicFilter));
    }



    const handleSelectDemographic = (ele, item) => {
        setSelectedUsers([]);
        let tempDemographicFilter = demographicFilter.map((element) => {
            let tempElement = { ...element };
            if (element.id === ele.id) {
                let newSelected = element.selected;
                if (newSelected.includes(item.id)) {
                    let filtered = newSelected.filter(curr => curr !== item.id);
                    newSelected = filtered;
                } else {
                    newSelected = [...newSelected, item.id]
                }
                tempElement = { ...element, selected: newSelected };

            }
            return tempElement
        });
        setDemographicFilter(tempDemographicFilter);
        fetchMsAndDwUsers(getSelectedDemographics(tempDemographicFilter))
    }

    const getMappingDetails = (value) => mappingObject[value];

    const getSelectedDemographics = (demographicFilter) => {
        console.log('demographicFilter = ', demographicFilter);
        // get last demographic filter
        const creationDemographicFilter = demographicFilter[demographicFilter.length - 1];
        demographicFilter.pop();
        let selectedFilters = [];
        for (let i = 0; i < demographicFilter.length; i++) {
            if (demographicFilter[i].selected.length) {
                const columnName = getMappingDetails(demographicFilter[i].id)["table_column"];
                const excelColumn = getMappingDetails(demographicFilter[i].id)["excel_column"];
                let filterObj = { columnName: columnName, values: demographicFilter[i].selected, excelColumn: excelColumn }
                selectedFilters.push(filterObj);
            }
        }

        if (creationDemographicFilter.selected.length) {
            let filterObj = { columnName: 'createdOn', values: creationDemographicFilter.selected, excelColumn: 'createdOn' }
            selectedFilters.push(filterObj);
        }
        demographicFilter.push(creationDemographicFilter);
        return selectedFilters;
    }

    return (
        <div className={classes.main}>
            <div className={classes.selectMenusWrapper}>

                {
                    demographicFilter.map((ele, i) => {
                        return selectedDemographicColumns.includes(ele.id) ? (
                            <CustomSelect
                                defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: `Select ${ele.name}`, selected: ele.selected, dataObject: commonUtil.objectify(ele.subMenu, 'id') })}
                                autoClose={false}
                                withSearch={true}
                                withSelectAllOption={true}
                                listData={ele.subMenu}
                                selected={ele.selected}
                                handleSelect={(item) => handleSelectDemographic(ele, item)}
                                customSelectAll={true}
                                _selectAll={() => _selectAllSubmenuDF(ele)}
                            />
                        ) : null
                    })
                }
            </div>

            <div className={classes.addContentBtn}>
                <AddIcon /> Add Demographic
                <CustomSelect
                    hideBaseBtn={true}
                    defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Demographic', selected: selectedDemographicColumns, dataObject: columnsDataObject })}
                    withSelectAllOption={false}
                    listData={columnsData}
                    selected={selectedDemographicColumns}
                    handleSelect={selectDemographicColumn}
                />
            </div>
            <div
                className={classes.selectedUserWrapper}
                onClick={() => setShowDwModal(true)}
                style={{ right: selectedUsers.length < 10 ? '2rem' : '0rem' }}
            >
                <h1>{selectedUsers.length}</h1>
                <span>users selected</span>
            </div>
        </div>
    )
}

export default ViaDemographic




const useStyles = makeStyles(() => ({
    main: {
        position: 'relative'
    },
    selectMenusWrapper: {
        padding: '0.5rem 0',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        // flexDirection: 'row',
        // maxWidth: 'calc(600px + 2rem)',
        // flexWrap: 'wrap',
    },
    addContentBtn: {
        position: 'relative',
        color: "#F4511E",
        border: "1px solid #F4511E",
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '180px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
        '& > svg': {
            marginLeft: '0.6rem'

        },
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    selectedUserWrapper: {
        // border: '1px solid',
        minWidth: '10rem',
        position: 'absolute',
        right: '2rem',
        top: -14,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        '& > h1': {
            fontFamily: 'Montserrat',
            fontSize: '7rem',
            fontWeight: 1000,
            color: '#444444'
        },
        '& > span': {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            textDecoration: 'underline !important',
            position: 'absolute',
            bottom: 6,
            color: '#444444',
            fontWeight: 800,
        },
    }
}));