import commonUtil from "../utils/commonUtil";
import { authOpenaiInstance ,authAzureSearchServiceInstance} from "../utils/fetch";

//azure openai
const { OpenAIClient, AzureKeyCredential } = require("@azure/openai");


const baseUrl = process.env.REACT_APP_AZURE_OPENAI_BASE_URL;
const azureApiKey = process.env.REACT_APP_AZURE_OPENAI_KEY;
const searchEndPoint = process.env.REACT_APP_AZURE_SEARCH_ENDPOINT;
// const indexName = process.env.REACT_APP_AZURE_SEARCH_INDEX;//change to company id
const searchKey = process.env.REACT_APP_AZURE_SEARCH_KEY;
const deploymentName = process.env.REACT_APP_AZURE_DEPLOYMENT_NAME;
// const apiVersionIot = "2023-06-01-preview";//old
const apiVersionIot = "2024-05-01-preview";

// org let prompt = `From the latest chunks of data, please generate ${6} important points with detailed information about that point. Provide the points in an array of json following this format: [{"id": 0,"point": "Here goes the point 1", "description": "Here goes the description of point 1"}, {"id": 1,"point": "Here goes the point 2", "description": "Here goes the description of point 2"}], max length of points should be 60 and max length of description should be 200. And most importantly, just return the array in reponse.`


// let prompt = `Please generate some important points with detailed information about term insurance. Provide the points in an array of json following this format: [{"id": 0,"point": "Here goes the point 1", "description": "Here goes the description of point 1"}, {"id": 1,"point": "Here goes the point 2", "description": "Here goes the description of point 2"}], max length of points should be 60 and max length of description should be 200. And most importantly, just return the array in reponse.`

const format = [{ "id": 0, "point": "Here goes the point 1", "description": "Here goes the description of point 1" }, { "id": 1, "point": "Here goes the point 2", "description": "Here goes the description of point 2" }]

let prompt = `Generate ${6} key points along with detailed information about term insurance. Arrange the points in a JSON array adhering to this structure: ${JSON.stringify(format)}. Ensure that each point does not exceed 60 characters in length, and each description is limited to 200 characters. Crucially, your response should only include the array`

const defaultMessage = [{
    "role": "user",
    "content": prompt
}]


const createContentUsingCustomData = async ({ messages = [], indexName = null, inScope = true }) => {
    if (indexName) indexName = commonUtil.getCompanyIdInAzureFormat(indexName);

    const payload = {
        "data_sources": [
            {
                "type": "azure_search",
                "parameters": {
                    "endpoint": searchEndPoint,
                    "index_name": indexName,
                    "semantic_configuration": "default",
                    "query_type": "simple",
                    "fields_mapping": {},
                    "in_scope": inScope,
                    "role_information": "You are an AI assistant that helps people to create content from the document uploaded.",
                    "filter": null,
                    "strictness": 3,
                    "top_n_documents": 5,//5
                    "authentication": {
                        "type": "api_key",
                        "key": searchKey
                    }
                }
            }
        ],
        "messages": [
            {
                "role": "system",
                "content": "You are an AI assistant that helps people to create content based on a given topic from the document uploaded."
            },
            ...messages
        ],
        "temperature": 0,
        "top_p": 1,
        "max_tokens": 800,
        "stop": null,
        "stream": false
    }

    const url = `/openai/deployments/${deploymentName}/chat/completions?api-version=${apiVersionIot}`


    try {
        const resData = await authOpenaiInstance.post(url, payload)
        return resData
    } catch (err) {
        return err.response
    }
}


const runIndexer = async ({ indexName = null}) => {
    if (indexName) indexName = commonUtil.getCompanyIdInAzureFormat(indexName);

    const url = `/indexers/${indexName}-indexer/run?api-version=2020-06-30`


    try {
        const resData = await authAzureSearchServiceInstance.post(url)
        return resData
    } catch (err) {
        return err.response
    }
}


const azureOpenAi = async (messages) => {
    try {
        const client = new OpenAIClient(baseUrl, new AzureKeyCredential(azureApiKey));
        const result = await client.getChatCompletions(deploymentName, messages);
        return result
    } catch (err) {
        console.error("The sample encountered an error:", err);
        return err
    }
}


export default { createContentUsingCustomData, azureOpenAi,runIndexer }


