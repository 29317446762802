import React, { useEffect, useState } from 'react'

//mui
import { ButtonBase, Divider } from '@mui/material';
import { makeStyles } from "@mui/styles";

// Components
import { isEmpty } from 'lodash';
import CustomSelect from '../custom-styled/custom-select';
import CustomInput from '../form-question/components/custom-input';
import Label from '../game-based-assessment/components/label';

//services
import formServices from '../../services/form';
import challengesService from '../../services/challenges';

//utils
import commonUtil from '../../utils/commonUtil';
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';
import { GlobalStates } from '../../App';


const FormBasedChallenge = () => {

  const query = useQuery();
  const classes = useStyles();
  const router = useRouter()
  const companyId = query.get('c_id');
  const microskillId = query.get('ms_id')
  const sequence = query.get('seq');
  const queryFormId = query.get('formId')
  const queryChallengeName = query.get('challengeName')
  const challengeId = query.get('challenge_id')
  const editFlag = query.get('edit')


  // Custom Select States
  const [forms, setForms] = useState([]);
  const formsObject = commonUtil.objectify(forms, 'id')
  const [selectedForms, setSelectedForms] = useState([]);

  //Forms states
  const [formName, setFormName] = useState("")
  const [fromRoute, setFormRoute] = useState(false);
  const [formId, setFormId] = useState('')
  const [challengeName, setChallengeName] = useState('')



  //  console.log({editFlag});

  // for fecthing forms data to select
  const fetchForms = async (companyId, search) => {
    if (isEmpty(companyId)) return;
    const response = await formServices.fetchFormSearch(companyId, search, challengeId);
    if (response.status === 200 || response.status === 201) {
      let activeForms = response.data.data;
      setForms(activeForms);
    }
  }

  //setting forms from selected input
  const handleFormSelect = (item) => {
    console.log(item.id);
    setFormId(item.id);
    setSelectedForms([item.id]);
  }


  const createForm = async (data) => {
    const resp = await formServices.createForm(data);
    if (resp.status === 200 || resp.status === 201) {
      let respData = resp.data.data;
      setFormId(respData.id);
      setFormRoute(true);
    }
  }

  // Form Creating New Form
  const handleCreateNewForm = () => {
    let data = {}
    data["companyId"] = companyId;
    data["name"] = formName;
    data["questions"] = [];
    createForm(data);
  }

  const handleSubmit = async () => {

    if (editFlag === "true") {

      let payload = {
        companyId: companyId,
        challengeId: challengeId,
        name: challengeName,
        formId: formId,
        microskillId: microskillId,
        type: "FORM",
        gameType: "FORM",
        designType: "FORM",
        sequence: Number(sequence),
        maxScore: 0,
      }

      const responseData = await challengesService.updateChallenge(payload);
      if (responseData.status === 200 || responseData.status === 201) {
        router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=xx&&ms_id=${microskillId}`)
      }
      else {
        if (responseData.status === 500)
          GlobalStates.handleToast(true, "Internal Server error", 'Error');
      }

    }
    else {




      let payload = {
        companyId: companyId,
        name: challengeName,
        formId: formId,
        microskillId: microskillId,
        type: "FORM",
        gameType: "FORM",
        designType: "FORM",
        sequence: Number(sequence),
        maxScore: 0,
      }


      const responseData = await challengesService.createChallenge(payload);
      if (responseData.status === 200 || responseData.status === 201) {
        router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=xx&&ms_id=${microskillId}`);
      } else {
        if (responseData.status === 500) {
          GlobalStates.handleToast(true, 'Internal server error', 'error');
        }
      }
    }

  }

  const handleCancel = () => {
    router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=xx&&ms_id=${microskillId}`)
  }

  const handleChangeFormName = (e) => {
    setFormName(e.target.value)
  }

  const handleChangeChallengeName = (e) => {
    setChallengeName(e.target.value)
  }

  useEffect(() => {
    fetchForms(companyId);
    if (queryFormId) {
      setSelectedForms([queryFormId])
      setFormId(queryFormId)
    }
    if (queryChallengeName)
      setChallengeName(queryChallengeName)
  }, [])


  useEffect(() => {
    if (formId !== '')
      router.history.push(`/forms?c_id=${companyId}&formId=${formId}&ms_id=${microskillId}&seq=${sequence}`)
  }, [fromRoute])

  return (
    <>

      <div className={classes.mainContainer}>
        <div className={classes.inputContainers} style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            <CustomInput
              id="challengeName"
              defaultValue={challengeName}
              withLabel={true}
              handleChange={handleChangeChallengeName}
              labelText="Enter Challenge Name"
              placeholder="Enter Challenge Name"
              isEmpty={false}
            />
          </div>

          <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            <Label labelText="Select one of the available forms to continue" fs={'0.7rem'} />
            <CustomSelect
              defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Form', selected: selectedForms, dataObject: formsObject })}
              autoClose={true}
              withSearch={true}
              withSelectAllOption={false}
              listData={forms}
              selected={selectedForms}
              handleSelect={handleFormSelect}
              withClear={true}
              setSelected={setSelectedForms}
              width={'100%'}
            />
          </div>
          {
            selectedForms.length > 0 ? null
              :
              <>
                <div>
                  <Divider style={{ width: '100%', margin: '1rem 0', fontSize: '12px', color: 'gray' }} >OR</Divider>
                </div>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                  <CustomInput
                    id="FormName"
                    defaultValue={formName}
                    withLabel={true}
                    handleChange={handleChangeFormName}
                    labelText="Enter the form name to get started with creating a new one"
                    placeholder="Enter Form Name"
                    isEmpty={false}
                  />

                  <ButtonBase
                    className={`${classes.btn} ${formName.length !== 0 ? classes.btnFilled : classes.btnLowFilled}`}
                    disabled={formName.length === 0}
                    onClick={handleCreateNewForm}>
                    Create
                  </ButtonBase>
                </div>
              </>
          }

        </div>

        <p className={classes.para}>
          <strong style={{ color: "black" }}>Disclaimer:</strong> Demo Comp & it’s employees are solely responsible for the content published in the platform. InspireOne Technologies Pvt Ltd (referred henceforth as “Master-O”) shall not be held responsible or liable for the quality and / or quantity of the content uploaded, included but not limited to any errors, omissions, harmful or abusive content or any resulting loss or damages incurred by the Demo Comp on behalf of any content that is published on the Master-O platform.
        </p>

        <div className={classes.btnBox}>
          <ButtonBase
            className={`${classes.btn} ${challengeName.length !== 0 && selectedForms.length > 0 ? classes.btnFilled : classes.btnLowFilled}`}
            disabled={challengeName.length === 0 && selectedForms.length < 0}
            onClick={handleSubmit}
          >
            Save
          </ButtonBase>

          <ButtonBase
            className={`${classes.btn} ${classes.btnOutlined}`}
            onClick={handleCancel}
          >
            Cancel
          </ButtonBase>
        </div>




      </div>


    </>
  )
}

export default FormBasedChallenge





const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: '2rem 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainers: {
    width: 'fit-content',
  },
  Label: {
    display: "block",
    fontFamily: "Montserrat",
    color: "#484848",
    fontSize: "18px",
    fontWeight: "Bold",
    characterSpacing: "0",
    padding: "0.6rem 0"
  },
  Input_Box: {
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontWeight: "400",
    display: "block",
    padding: "0.5rem",
    margin: "0.5rem 0",
    border: "none",
    // boxShadow: "0 0 6px #DDE0E2",
    border: "1.4px solid #70707050",
    color: "#707070",
    fontSize: "0.8rem",
    width: "320px", marginBottom: "0.2rem"
  },
  Save_Btn: {
    backgroundColor: "#F4511E",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "Bold",
    characterSpacing: "0",
    lineSpacing: "82",
    padding: "0.7rem 2.8rem",
    border: "none",
    borderRadius: "2px",
    display: 'block',
    marginTop: '0.8rem',
    cursor: "pointer",
    "&:hover": {
      opacity: "0.9"
    }
  },
  para: {
    fontSize: "12px",
    maxWidth: "90ch",
    color: "gray",
    marginTop: '1rem'
  },
  btnBox: {
    position: 'absolute',
    bottom: '1rem',
    width: '75%',
    display: 'flex',
    gap: '2rem',
  },
  btn: {
    border: '2px solid #f4511e',
    height: '42px',
    width: '160px',
    borderRadius: '2px',
    fontSize: '0.7rem',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  btnFilled: {
    color: '#ffffff',
    backgroundColor: '#f4511e',
  },
  btnLowFilled: {
    color: '#ffffff',
    backgroundColor: '#f4511ec4',
    border: 'none'
  },
  btnOutlined: {
    color: '#f4511e',
    backgroundColor: '#ffffff'

  },
}))
