import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    card: {
      position: 'relative',
      width: '420px',
      maxWidth: '420px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      overflow: 'hidden',
      },
    aspect1: {
      aspectRatio: 3 / 4,
    },
    linkBox: {
      width: '100%',
      minHeight: '100%',
      aspectRatio: 4 / 3,
    },
    uploadContainer: {
      minHeight: '100%',
      width: '100%',
      maxWidth: 'calc(420px - 1rem)',
    },
    deleteIcon: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '1rem',
      right: '0.8rem',
      height: '2.2rem',
      width: '2.2rem',
      borderRadius: '50%',
      backgroundColor: 'rgb(112,112,112,0.5)',
      '& > img': {
        height: '1rem',
        width: '1rem',
        filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
      },
      '&:hover': {
        cursor: 'pointer',
        '& > img': {
          scale: 1.2,
          filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)',
        }
      }
    },
    iframe: {
      border: 'none',
      '&::-webkit-scrollbar': {
          width: '8px', // Set the scrollbar width
          height: '5px', // Set the scrollbar height
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#f4511e', // Thumb color
          borderRadius: '10px', // Rounded edges for the thumb
        },
        '&::-webkit-scrollbar-button': {
          display: 'none', // Removes the up/down or left/right arrow buttons
        },
    },
    wrapper: {
      width: '82%',
      border: '1.4px solid #ccc',
      marginTop: '0.9rem',
      borderRadius: '4px',
      padding: '0.4rem',
      '& > div': {
          border: 'none !important',
          fontSize: '0.7rem',
          fontWeight: 500,
          '& > div:first-child': {
              border: 'none !important',
              position: 'absolute',
              top: '-3rem',
              left: '-1rem',
              width: '118%'
          },
          '& > pre': {
              border: 'none !important',
              width: '100%',
              '& *': {
                  color: "rgb(112, 112, 112, 0.6)",
              },
              '& > div:first-child': {
                  fontSize: '0.7rem',
                  fontWeight: 500,
                  overflow: 'hidden',
                  padding: '0 !important',
                  '&::before': {
                      left: '0 !important',
                      fontSize: '0.7rem',
                      fontWeight: 500,
                      fontStyle: "normal",
                  },
              },
          },
      },
  },
}));

export default useStyles;