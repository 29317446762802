import constants from "../constants";
import { authFetch, authFetchAnalytics } from "../utils/fetch";

const REPORT_FLAG_TYPES = {
    EMAIL: 'email'
}

const fetchLoginReports = async (payload, { startIndex = 0, limit = 10 }) => {
    let url = `/reports/login?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchCompletionReports = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/completion`;
    if (payload.companyId === constants.IFL_ID) {
        if (payload.flag === 'excel' || payload.flag === 'email') {
            if ("reportFetchType" in payload) delete payload["reportFetchType"];
            if ("largeReport" in payload) delete payload["largeReport"];
            endPoint = `/reports/flat-completion`;
        }
    }
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const getDemographics = async (companyId, islaunch = false) => {
    let url = `/user/getcompWiseDemographics/${companyId}`;
    if (islaunch) {
        // send it params to get the data for launch
        url = `/user/getcompWiseDemographics/${companyId}?islaunch=true`;
    }
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
}

const getFilteredDemographics = async (payload) => {
    let url = `/user/demographicsFilter`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLearnerFeedbackReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/course-feedback`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchTrainerFeedbackReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/trainer-feedback`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchSkillGapReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/skill-gap`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchTqaReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/tqa`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}


const fetchLeaderboardReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/leader-board`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const downloadAssessmentReport = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/forms-report`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}


const fetchAttemptLevelReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/microskill-attempt-level-data`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        console.log(`ResData:${resData}`);
        return resData
    } catch (err) {
        console.log(`error:${err}`);
        return err.response
    }
}
const fetchMicroskillFeedbackReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/microskill-feedback-reports`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchActiveInactiveUserReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/login`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL || payload.flag === "user" ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchCompletionWithTimespentReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/completion`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchUserActivityReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/user-activity-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        // const resData = await authFetchAnalytics.post(url, payload)
        console.log(`resData:${resData}`);
        return resData
    } catch (err) {
        console.log(err);
        return err.response
    }
}

const fetchFirstTimeCompletionReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/first-time-completion-microskill`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLicenceReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/licence`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchSpotlightAttemptLevelReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/spotlight-attempt-level-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchDisabledMicroskillReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/disabled-microskill-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLearningJourneyCompletionReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/learning-journey-completion-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = payload.flag === REPORT_FLAG_TYPES.EMAIL ? await authFetchAnalytics.post(url, payload) : await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};

const fetchSavedCustomTemplates = async (companyId) => {
    let endPoint = `/customReports`;
    let url = `${endPoint}?companyId=${companyId}&sort=createdOn&sortDirection=desc`;
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
};

const deleteSavedCustomTemplate = async (data) => {
    let endPoint = `/customReports`;
    let url = `${endPoint}`;
    try {
        const resData = await authFetch.delete(url, { data });
        return resData
    } catch (err) {
        return err.response
    }
}

const sendReportActions = async (payload) => {
    let endPoint = `/customReports/reportActions`;
    let url = `${endPoint}`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const customReportSaveTemplate = async (payload) => {
    let url = `/customReports`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};
const generateReportTable = async (payload) => {
    let url = `/customReports/reportData`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};
const getSavedCustomTemplate = async (companyId, templateId) => {
    let endPoint = `/customReports/getSpecificCustomReport`;
    let url = `${endPoint}?id=${templateId}&companyId=${companyId}`;
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
};

const customReportUpdateTemplate = async (payload) => {
    let url = `/customReports`;
    try {
        const resData = await authFetch.put(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};

const customReportSchedule = async (payload) => {
    let url = `/customReports/createReportSchedular`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};

const customReportScheduleList = async (companyId) => {
    let url = `/customReports/customReportSchedular?companyId=${companyId}`;
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
};

// const customReportSpecificSchedular = async (companyId, templatedId) => {
//     let url = `/customReports/getSpecificReportSchedular?id=${templatedId}&companyId=${companyId}`;
//     try {
//         const resData = await authFetch.get(url);
//         return resData
//     } catch (err) {
//         return err.response
//     }
// };

const customReportScheduleUpdate = async (payload) => {
    let url = `/customReports/updateReportSchedular`;
    try {
        const resData = await authFetch.put(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};

const deleteScheduleReport = async (data) => {
    let endPoint = `/customReports/deleteReportSchedular`;
    let url = `${endPoint}`;
    try {
        const resData = await authFetch.delete(url, { data });
        return resData
    } catch (err) {
        return err.response
    }
}

export default {
    fetchLoginReports,
    fetchCompletionReports,
    fetchLearnerFeedbackReports,
    fetchTrainerFeedbackReports,
    downloadAssessmentReport,
    getDemographics,
    getFilteredDemographics,
    fetchSkillGapReports,
    fetchTqaReports,
    fetchLeaderboardReports,
    fetchAttemptLevelReport,
    fetchMicroskillFeedbackReport,
    fetchActiveInactiveUserReport,
    fetchCompletionWithTimespentReport,
    fetchUserActivityReport,
    fetchFirstTimeCompletionReport,
    fetchLicenceReport,
    fetchSpotlightAttemptLevelReport,
    fetchDisabledMicroskillReport,
    fetchLearningJourneyCompletionReport,
    fetchSavedCustomTemplates,
    deleteSavedCustomTemplate,
    sendReportActions,
    customReportSaveTemplate,
    generateReportTable,
    getSavedCustomTemplate,
    customReportUpdateTemplate,
    customReportSchedule,
    customReportScheduleList,
    // customReportSpecificSchedular,
    customReportScheduleUpdate,
    deleteScheduleReport,
}