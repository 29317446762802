import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '2rem auto',
        width: '80%',
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
    },
    tableRow: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
    },
    actionIcons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    iconButton: {
        cursor: 'pointer',
        background: '#f4511e',
        color: '#fff',
        height: '34px',
        width: '34px',
        '&:hover': {
            backgroundColor: '#f4511e'
        },
    },
    sortableHeader: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    heading: {
        fontSize: 32,
        marginBottom: 20,
    },
    templateName: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    btn: {
        width: '150px',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#fff",
        color: "#f4511e",
        fontFamily: "Montserrat",
        fontWeight: 'bold',
        fontSize: "12px",
        borderRadius: "0.2rem",
        cursor: "pointer",
        textTransform: 'capitalize',
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },

}));

export default useStyles;