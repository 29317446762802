import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
    searchBox: {
        border: "2.2px solid rgba(112, 112, 112, 0.3)",
        borderRadius: "4px",
        minHeight: "5rem",
        maxHeight: '20rem',
        minWidth: "7rem",
    },
    searchInputBox: {
        // backgroundColor: "rgb(242, 242, 242)",
        margin: '0.2rem',
        borderRadius: 4,
        border: '2px solid #88888850',
        padding: '0rem 1rem',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        "& input": {
            width: "95%",
            // backgroundColor: "rgb(242, 242, 242)",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            },
            "&::placeholder": {
                color:  "#88888880",
            },
        },
        "& i": {
            color: "#88888880"
        }
    },
    searchList: {
        height: "88%",
        width: "100%",
        maxHeight: '16rem',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            // 'scrollbar-width': 'thin'
            width: "7px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
            // boxShadow: 'inset 0 0 6px rgba(112, 112, 112, 0.5)',
            borderRadius: "4px",
            backgroundColor: '#f4511e'
        },
        // '&:hover': {
        //     "&::-webkit-scrollbar": {
        //         // 'scrollbar-width': 'thin'
        //         width: "7px",
        //     },
        //     "&::-webkit-scrollbar-track": {
        //         backgroundColor: "#fff",
        //     },
        //     "&::-webkit-scrollbar-thumb": {
        //         // boxShadow: 'inset 0 0 6px rgba(112, 112, 112, 0.5)',
        //         borderRadius: "4px",
        //         backgroundColor: '#f4511e'
        //     }
        // }


    },
    listItem: {
        padding: "0 0.5rem",
        height: '2.6rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '0.5rem',
        position: 'relative',
        "& span": {
            // paddingLeft: "0.3rem",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            color: "#484848",
        },
        '& > span:first-child': {
            padding: '0.3rem'
        },
        '& > span:nth-child(2)': {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: '1rem',
            maxHeight: '1rem',
            overflow: 'hidden',
        },
        // '& > span:nth-child(3)': {
        //     position: 'absolute',
        //     right: '0.8rem',
        //     '& > i': {
        //         fontSize: '1rem'
        //     }
        // },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9 !important'
        }
    },
    listItemIcon:{
        position: 'absolute',
        right: '0.8rem',
        '& > i': {
            fontSize: '1rem'
        }
    },
    selectAllLi: {
        padding: "0 0.5rem",
        height: '2.3rem',
        "& span": {
            paddingLeft: "0.3rem",
            color: "#F4511E",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    }
}))

export default useStyles