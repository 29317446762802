import React, { useState, useEffect, useContext } from 'react'
import { useRef } from 'react';

//mui
import { makeStyles } from "@mui/styles";
import { ButtonBase } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiCheckbox from "@mui/material/Checkbox";
import Menu from '@mui/material/Menu';

//components
import Label from '../../../custom-styled/label';
import CustomButton from '../../../custom-styled/button';
import CustomSelect from '../../../custom-styled/custom-select';
import Title from '../../../title';

//services
import microskillServices from '../../../../services/microskill';
import documentsServices from '../../../../services/documents';

//global-states
import { GlobalStates } from '../../../../App';

//utils
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';
import useQuery from '../../../../utils/useQuery';

const AzureOpenAi = () => {
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const microskillId = query.get("ms_id");
    const [globalStates] = useContext(GlobalStates)
    const [uploading, setUploading] = useState(false);
    const [localFiles, setLocalFiles] = useState([]);
    const [addMore, setAddMore] = useState(false);
    const [disableAll, setDisableAll] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState([]);

    const pdfMimeTypes = ["application/pdf", "application/x-pdf", "application/x-bzpdf", "application/x-gzpdf"];
    const docMimeTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-word.document.macroEnabled.12"];
    const allowedMimetypes = [...pdfMimeTypes, ...docMimeTypes];

    // useEffect(() => {
    //     fetchGenAiFiles()
    // }, [])

    // const fetchGenAiFiles = async () => {
    //     const response = await microskillServices.fetchGenAiFiles(companyId, microskillId);
    //     console.log('fetchGenAiFiles response = ', response);
        
    // }

    const handleFileUpload = (e) => {
        let files = e.target.files;
        console.log('files = ', files)
        if (addMore) {
            setAddMore(false);
            setLocalFiles((prev) => {
                let prevCopy = { ...prev };
                let len = prev.length;
                for (let i = 0; i < files.length; i++) {
                    prevCopy[len] = files[i];
                    len++
                }
                prevCopy.length = len;
                return prevCopy;
            });
            return
        }
        setLocalFiles(files);
    }

    const handleUpload = async () => {
        if (addMore) setAddMore(false);
        setUploading(true);
        setDisableAll(true);

        for (let i = 0; i < localFiles.length; i++) {
            const json = { companyId, microskillId, genAiFile: localFiles[i] }
            const payload = await commonUtil.jsonToFormData(json);
            const response = await microskillServices.uploadGenAiFile(payload);
            console.log('response = ', response);
            if (response.status !== 200) {
                setUploading(false);
                setDisableAll(false);
                globalStates.handleToast(true, 'Failed to upload file', 'error');
                return
            }
        }
        globalStates.handleToast(true, 'Content uploaded successfully!', 'success');
        setUploading(false);
        setDisableAll(false);
        setLocalFiles([]);
        setSelectedDocument([]);

    }

    const handleViewFiles = () => {

    }

    const getSelectedData = () => {
        const data = [];
        selectedDocument.forEach(ele => {
            data.push(ele.id)
        })
        if (Array.isArray(localFiles)) return data;

        for (let i = 0; i < localFiles.length; i++) {
            data.push(i)
        }

        return data
    }

    const getFilesListData = () => {
        const data = []
        selectedDocument.forEach(ele => {
            data.push({
                id: ele.id,
                name: ele?.name
            })
        })
        if (Array.isArray(localFiles)) return data;

        for (const key in localFiles) {
            if (!isNaN(parseInt(key))) {
                data.push({
                    id: parseInt(key),
                    name: localFiles[key]?.name
                })
            }

        }


        return data
    }

    const handleRemoveFiles = (item) => {
        return
    }

    const getFileCount = () => {
        let count = selectedDocument.length + localFiles.length;
        return count
    }

    return (
        <div className={classes.wrapper}>
            <Label labelText={'Content for AI Generation'} fs='12px' color='#484848' />
            {
                localFiles.length || selectedDocument.length ?
                    <>
                        <div className={classes.detailWrapper}>
                            <span>
                                <i className="fa-regular fa-file"></i>
                                {getFileCount()} {getFileCount() > 1 ? 'files' : 'file'} attached
                            </span>
                            <ButtonBase onClick={handleViewFiles} className={classes.viewFiles} key={getFileCount()}>
                                View files
                                <CustomSelect
                                    hideBaseBtn={true}
                                    defaultText={'View files'}
                                    withSearch={false}
                                    withSelectAllOption={false}
                                    listData={getFilesListData()}
                                    selected={getSelectedData()}
                                    handleSelect={handleRemoveFiles}
                                />
                            </ButtonBase>
                        </div>
                        <div className={classes.btnBox}>

                            {uploading ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                >
                                    UPLOADING...
                                </LoadingButton> :
                                <CustomButton
                                    variant='contained'
                                    btnText={'Upload'}
                                    bgcolor='#f4511e'
                                    borderColor='#f4511e'
                                    textColor='#fff'
                                    width='200px'
                                    fw={700}
                                    fs={10}
                                    isDisabled={disableAll}
                                    handleClick={handleUpload}
                                />
                            }
                            {
                                addMore ?
                                    <FileUpload flexDirection='row' handleFileUpload={handleFileUpload} documents={documents} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} /> :
                                    <CustomButton
                                        btnText={'Add more files'}
                                        bgcolor='#fff'
                                        borderColor='#f4511e'
                                        textColor='#f4511e'
                                        width='200px'
                                        fw={700}
                                        fs={10}
                                        handleClick={() => setAddMore(true)}
                                        isDisabled={disableAll}
                                    />
                            }

                        </div>
                    </>
                    : <FileUpload flexDirection='column' handleFileUpload={handleFileUpload} documents={documents} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} />
            }




        </div>
    )
}

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};

const FileUpload = ({ handleFileUpload, flexDirection = 'row', width = '22rem', height = 'auto' }) => {
    const classes = useStyles()
    // const acceptedFormats = ['.docx', '.doc', '.pptx', '.ppt', '.pdf'];
    const acceptedFormats = ['.pdf','.pptx', '.docx'];

    return (
        <div
            style={{
                flexDirection,
                width,
                height,
                padding: flexDirection === 'row' ? '0.2rem 0' : '1rem 0',
                gap: flexDirection === 'row' ? '1rem' : '0.6rem',
            }}
            className={`${classes.fileUploadWrapper}`}>
            <div className={classes.row}>
                <label className={classes.uploadIcon}>
                    <i className="fa-solid fa-cloud-arrow-up" style={{ fontSize: flexDirection === 'row' && '1.2rem', marginBottom: flexDirection === 'row' && 0 }}></i>
                    <input style={{ display: 'none' }} type='file' accept={acceptedFormats} onChange={handleFileUpload} multiple />
                </label>
                <h4>Upload reference files here</h4>
            </div>

        </div>
    )
}

export default AzureOpenAi




const useStyles = makeStyles(() => ({
    wrapper: {
        // border: '1px solid',
        margin: '1rem 0 0 0'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    fileUploadWrapper: {

        border: '2px dashed #CCCCCC',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '0.8rem',
        '& h4,h5,p': {
            fontSize: '0.6rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 700,
        },
        '& h5': {
            fontWeight: 500,
        },
    },
    baseBtn: {
        color: '#F4511E',
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '0.6rem',
        fontFamily: "Montserrat",
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    uploadIcon: {
        '& .fa-solid': {
            fontSize: '1.5rem',
            color: '#F4511E',
            cursor: 'pointer',
            marginBottom: '0.3rem',
            transition: '0.4s',
            '&:hover': {
                scale: 1.2
            }
        },

    },
    btnBox: {
        display: 'flex',
        marginTop: '1.5rem',
        gap: '3rem',
        '& > button': {
            borderRadius: '0.1rem'
        },
        '& > div': {
            marginTop: 0
        },
        '& .MuiLoadingButton-root': {
            width: 200,
            fontSize: 10,
            fontWeight: 700,
            fontFamily: 'Montserrat',
            backgroundColor: '#ccc',
            textTransform: 'unset'
        }

    },
    detailWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '1rem',
        '& > span': {
            fontSize: '0.8rem',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            '& > i': {
                marginRight: '0.5rem'
            }
        },
        '& > button': {
            color: '#f4511e',
            fontSize: '0.8rem',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            textDecoration: 'underline',
            '& > span': {
                width: '18px !important',
                height: '18px !important',
                marginRight: '0.8rem',
                color: '#f4511e'
            }
        }
    },
    viewFiles: {
        position: 'relative',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
    },

    //menu-styles
    documentMenu: {
        zIndex: 99999,
        '& > .MuiPaper-root': {
            // top: "17% !important",
            // left: "60% !important",
            // width: "500px",
            // height: "46px",

            '& > .MuiList-root': {
                // display: "flex",
                // padding: "6px",
                // height: "46px"
            }
        }
    },
    searchInputBox: {
        backgroundColor: "#fff",
        border: '1px solid rgb(112,112,112,0.4)',
        padding: '0rem 1rem',
        height: '2rem',
        width: '318px',
        display: 'flex',
        alignItems: 'center',
        margin: '0.5rem 0.3rem',
        borderRadius: '3px',
        "& input": {
            width: "95%",
            backgroundColor: "#fff",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            }
        },
        "& i": {
            color: "#888888"
        }
    },
    filterWrapper: {
        display: 'flex',
        height: '300px',
    },

    filterList: {
        height: '100%',
        // width: '30%',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            width: "7px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f9f9f9",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: '#f4511e'

        }
    },
    subMenuList: {
        backgroundColor: '#f9f9f9',
        width: '50%',
        '& > div': {
            "&:hover": {
                cursor: 'pointer',
                // backgroundColor: '#FFFFFF',
            }
        }
    },
    listItem: {
        position: 'relative',
        paddingLeft: "0.5rem",
        height: '2.6rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '0.5rem',
        "& span": {
            // paddingLeft: "0.3rem",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            color: "#484848",
        },
        '& > span:first-child': {
            padding: '0.3rem'
        },
        '& > span:nth-child(2)': {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: '1rem',
            maxHeight: '1rem',
            overflow: 'hidden'
        },
        '& > span:nth-child(3)': {
            position: 'absolute',
            right: '0.8rem',
            '& > i': {
                fontSize: '1rem'
            }
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9 !important'
        }
    },
    selectAllLi: {
        padding: "0 0.5rem",
        height: '2.3rem',
        "& span": {
            paddingLeft: "0.3rem",
            color: "#F4511E",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    filterBtnBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        position: 'relative',
        '& > .MuiButtonBase-root': {
            width: '150px',
            height: '38px',
            minWidth: '100px',
            padding: 0,
            fontWeight: 800,
            letterSpacing: 1
        },
        '& > .MuiButton-contained': {
            backgroundColor: '#f4511e',
            color: '#fff',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#f4511e',
                color: '#fff',
                borderColor: '#f4511e'
            }
        },
        '& > .MuiButton-outlined': {
            backgroundColor: '#fff',
            color: '#f4511e',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

            }
        },
        '& > h1': {
            position: "absolute",
            right: "3%",
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
        }
    }
}))

