import React from 'react'

//mui
import Button from '@mui/material/Button';

//components
import CustomButton from '../../../buttons';
import Label from '../../../game-based-assessment/components/label';
import CustomLottie from '../custom-lottie';

//constants
import constants from '../../../../constants';

// services
import usersServices from '../../../../services/microskill'

//styles
import useStyles from './index.styles';
import { useEffect } from 'react';
// import { padding } from '@mui/system';

import { PPT_IMPORTING_STATES } from "../../index"
import { Typography } from '@mui/material';


const CardTypeColumn = ({
  list,
  setList,
  setImportingList,
  showImportBtn,
  showCoverAndObjective = true,
  isObjectiveSkipped,
  coverAndObjective,
  selectedCard,
  setSelectedCard,
  handleSelectCard,
  selectedColumns,
  setSelectedColumns,
  setPreview,
  handleBulkUpload,
  defaultBgData,
  setLaBgImgData,
  defaultAudio,
  setLaAudioData,
  handlePptImport,
  pptImporting,
  pptUploadProgress
}) => {

  const classes = useStyles();

  useEffect(() => {
    // setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' })
  }, [])

  const handleImport = (e) => {
    if(pptImporting === PPT_IMPORTING_STATES.IN_PROGRESS) return;
    const files = e.target.files;
    console.log('handleImport = ', files);
    setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'uploadOrCreate' });
    handleBulkUpload(files);
  };

  const handleBack = () => {
    setLaBgImgData(defaultBgData);
    setLaAudioData(defaultAudio);
    setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
    setSelectedColumns({ ...selectedColumns, firstColumn: 'content' });
  }

  useEffect(() => {
    console.log('pptUploadProgress = ', pptUploadProgress);
    console.log('pptImporting = ', pptImporting);
  }, [pptUploadProgress, pptImporting])

  return (
    <>
      {showImportBtn ? <div className={classes.importBtnBox}>
        <Button variant="contained" component="label" onChange={handleImport} onFocus={(e) => console.log("import images focused")}>
          IMPORT CONTENT
          <input hidden accept="image/*" multiple type="file" />
        </Button>
      </div> : null}
      {showImportBtn ? <div className={classes.importBtnBox}>
        {/* <Button variant="contained" component="label" onChange={handlePptImport}>
          IMPORT POWERPOINT
          <input hidden accept='.ppt,.pptx' type="file" />
        </Button> */}

        <Button
          variant="contained"
          component="label"
          onChange={(e) => {
            if (pptImporting === PPT_IMPORTING_STATES.IN_PROGRESS) return
            handlePptImport(e);
          }}
        >
          {
            pptImporting === PPT_IMPORTING_STATES.IN_PROGRESS ? (
                pptUploadProgress < 100  ? `IMPORTING POWERPOINT ${pptUploadProgress}%` : `EXTRACTING POWERPOINT...`
            ) : (
              <>
                IMPORT POWERPOINT
                <input hidden accept='.ppt,.pptx' type="file" />
              </>
            )
          }
        </Button>
      </div > : null
      }
      <div className={classes.cardsContainer}
        style={{
          height: showImportBtn ? 'calc(69% - 4rem)' : 'calc(100% - 3.8rem)',
          paddingTop: showImportBtn ? '0rem' : '0.5rem'
        }}>

        {
          constants.LA_CARD_TYPE.map((row, i) => (
            (row.title === 'SECTION BREAK') ?
              <div key={i}>
                <Label labelText={'SECTION BREAK'} fs={'0.6rem'} />
                <div className={classes.sectionBreakBox}>
                  {
                    row.data.map((cardData, index) => (
                      <div className={classes.sectionBreakBtn} key={index}>
                        <CustomButton btnText={`SECTION BREAK ${index + 1}`} textColor={'#E3E3E3'} borderColor={'#fff'} bgcolor={'#fff'} />
                      </div>
                    ))
                  }

                </div>
              </div> :
              (
                (row.title === 'COVER & OBJECTIVE') ?
                  (
                    (showCoverAndObjective) ?
                      (<div key={i}>
                        <Label labelText={row.title} fs={'0.6rem'} />
                        <div className={classes.cardsBox} >
                          {
                            row.data.map((cardData, index) => (
                              // coverAndObjective.length === 1 ?

                              cardData.cardType === 'cover' ?
                                <div key={index} className={classes.createdAndDefaultChecked} >
                                  <img src={coverAndObjective[0].thumbImage} />
                                  <i className="fa-solid fa-circle-check"></i>
                                </div> : (
                                  isObjectiveSkipped ?
                                    <div className={classes.lottieBox} key={index} onClick={() => handleSelectCard(cardData, i, index)}>
                                      {((selectedCard.rowIndex === i) && (selectedCard.columnIndex === index)) ? <i className="fa-solid fa-circle-check"></i> : null}
                                      <CustomLottie animationData={cardData.animation} />
                                    </div> :
                                    <div className={classes.createdAndDefaultChecked} key={index}>
                                      <img src={coverAndObjective[1].thumbImage} />
                                      <i className="fa-solid fa-circle-check"></i>
                                    </div>
                                )


                            ))
                          }

                        </div>
                      </div>) : (null)

                  ) :
                  (<div key={i}>
                    <Label labelText={row.title} fs={'0.6rem'} />
                    <div className={classes.cardsBox} >
                      {
                        row.data.map((cardData, index) => (
                          <div className={classes.imageBox} key={index} onClick={() => handleSelectCard(cardData, i, index)}>
                            {((selectedCard.rowIndex === i) && (selectedCard.columnIndex === index)) ? <i className="fa-solid fa-circle-check"></i> : null}
                            <img src={cardData.image} alt='' />
                          </div>
                        ))
                      }
                      {/* {
                        row.data.map((cardData, index) => (
                          <div className={classes.lottieBox} key={index} onClick={() => handleSelectCard(cardData, i, index)}>
                            {((selectedCard.rowIndex === i) && (selectedCard.columnIndex === index)) ? <i className="fa-solid fa-circle-check"></i> : null}
                            <CustomLottie animationData={cardData.animation} />
                          </div>
                        ))
                      } */}

                    </div>
                  </div>)
              )

          ))
        }

      </div>
      {
        showImportBtn ?
          <div className={classes.btnBox}>
            <CustomButton handleClick={handleBack} btnText={'BACK'} variant={'contained'} textColor={'#fff'} bgcolor={'#f4511e'} />
          </div> : null
      }

    </>
  )
}

export default CardTypeColumn;
