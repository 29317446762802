import React from 'react'

//mui
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from "@mui/styles";


const GbaSkeleton = ({ maxQuestions }) => {
    let animation="wave";//"pulse","wave"
    const classes = useStyles();
    const questions = new Array(maxQuestions).fill(0)
    const responses = new Array(4).fill(0)
    return (
        <>
            {
                questions.map((item, j) => (
                    <div className={classes.wrapper} key={j}>
                        <Skeleton animation={animation} sx={{ height: 55 }} />
                        <div className={classes.listItem}>
                            <Skeleton animation={animation} sx={{ height: 55, }} />
                            <Skeleton animation={animation} variant="text" sx={{ fontSize: '1rem' }} width={150} />
                            <div className={classes.tagsWrapper}>
                                <Skeleton animation={animation} variant="rounded" width={140} height={35} sx={{ borderRadius: 25 }} />
                                <Skeleton animation={animation} variant="rounded" width={140} height={35} sx={{ borderRadius: 25 }} />
                            </div>
                            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={120}/> */}

                            {
                                responses.map((ele, i) => (
                                    <div className={classes.response} key={i}>
                                        <Skeleton animation={animation} sx={{ height: 55, width: '85%' }} />
                                        <div>
                                            <Skeleton animation={animation} variant="circular" width={20} height={17} />
                                            <Skeleton animation={animation} variant="text" sx={{ fontSize: '1rem', width: '100%', height: 25 }} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </>

    )
}

export default GbaSkeleton;



const useStyles = makeStyles(() => ({
    wrapper: {
        borderBottom: '1px solid rgb(184, 184, 184, 0.4)',
        marginBottom: '1.8rem',
        paddingBottom: '1rem',
        paddingTop: 0
    },
    listItem: {
        margin: '0 1rem',
        
    },
    tagsWrapper: {
        display: 'flex',
        gap: '1rem',
        margin: '1rem 0'
    },
    response: {
        display: 'flex',
        gap: '1rem',
        margin: '-10px 0',
        '& > div': {
            display: 'flex',
            gap: '0.5rem',
            width: '15%',
            alignItems: 'center'
        }
    }
}));
