import React, { useContext, useState, useEffect } from 'react';


//mui
import { Box, Button, Typography, Modal, TextField, Stack } from '@mui/material';

//styles
import useStyles from './index.style';

//components
import DemographicData from '../demographic-data';
import CustomDataPoint from '../custom-data-point';
import Label from '../../../custom-styled/label';
import CustomReportTable from '../custom-report-table';
import CustomSelect from "../../../custom-styled/custom-select";
import ControlledSwitches from '../../../custom-styled/switch-button';
import DatePicker from '../date-picker';

//services
import reportServices from '../../../../services/reports';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';
import isEmpty from '../../../../utils/isEmpty';

//context
import { GlobalStates } from '../../../../App';
// constant
import analyticsConstant from '../../constant/analytics.constant';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import useRouter from "../../../../utils/useRouter";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const dateRangeTypeData = [
    {
        id: "LAUNCHESON",
        name: "Completion wise date range",
    },
    {
        id: "COMPLETION",
        name: "Launch wise date range",
    }

]

const ReportBuilder = ({ setBackdrop }) => {
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const template_Id = query.get("template_Id");
    const router = useRouter();
    const [globalStates] = useContext(GlobalStates);
    const [templateName, setTemplateName] = useState('');
    const [demographicFilter, setDemographicFilter] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [open, setOpen] = useState(false);
    const [showGeneratedTable, setShowGeneratedTable] = useState(false);
    const [savedTemplates, setSavedTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [allDemographicData, setAllDemographicData] = useState([]);
    const [selectedDemographicColumns, setSelectedDemographicColumns] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    const [LaunchWise, setLaunchWise] = useState(true);
    const [dateRangeType, setDateRangeType] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isTemplateEdited, setIsTemplateEdited] = useState(false);
    const [microskillALignmentSelected, setMicroskillALignmentSelected] = useState(null);
    const [defaultDemographicData, setDefaultDemographicData] = useState([]);
    const [isRedirectViaReports, setIsRedirectViaReports] = useState(false);

    const today = dayjs();
    let minDate = dayjs(new Date('2022-01-01'));

    useEffect(() => {
        fetchSavedTemplated();
        if (!isEmpty(template_Id)) {
            setIsRedirectViaReports(true);
        }
    }, [])

    useEffect(() => {
        if (isRedirectViaReports && !isEmpty(allDemographicData)) {
            handleSelectTemplate(template_Id);
        }
    }, [allDemographicData, isRedirectViaReports])

    const fetchSavedTemplated = async () => {
        const response = await reportServices.fetchSavedCustomTemplates(companyId);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data.map(ele => ({ ...ele, name: ele.title }));
            setSavedTemplates(data);
        }
    }

    const validate = () => {
        let isValid = true;
        if (!selectedDataPoint.length) {
            globalStates.handleToast(true, 'Please select the data point', 'error')
            return isValid = false
        }
        for (let i = 0; i < selectedDataPoint.length; i++) {
            if (!selectedDataPoint[i].selectedValue.length) {
                globalStates.handleToast(true, `Please select the value for ${selectedDataPoint[i].name}`, 'error');
                return isValid = false
            }

        }
        return isValid
    }

    const handleOpenModal = () => {
        const valid = validate()
        if (!valid) return
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleSaveTemplate = async () => {
        if (!templateName.length) {
            globalStates.handleToast(true, 'Please enter the template name', 'error')
            return
        }
        let payload = getPayload()
        globalStates.handleProgressDialog(true, 'Please wait...');
        let response;
        if (!isEmpty(selectedTemplate)) {
            payload = { ...payload, id: selectedTemplate[0] }
            response = await reportServices.customReportUpdateTemplate(payload);
        } else {
            response = await reportServices.customReportSaveTemplate(payload);
        }
        globalStates.handleProgressDialog(false)
        if (response && (response.status === 201 || response.status === 200)) {
            fetchSavedTemplated();
            globalStates.handleToast(true, 'Template saved successfully!', 'success')
            if (isTemplateEdited) {
                setIsTemplateEdited(false);
            }
            handleClose();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    };

    const getMappingDetails = (value) => mappingObject[value];

    const getSelectedDemographics = () => {
        let selectedFilters = [];
        for (let i = 0; i < selectedDemographicColumns.length; i++) {
            const selectedfilterIndex = demographicFilter.findIndex((data) => data.id === selectedDemographicColumns[i])
            const selectedfilterData = demographicFilter[selectedfilterIndex]
            const columnName = getMappingDetails(selectedfilterData.id)["table_column"];
            const excelColumn = getMappingDetails(selectedfilterData.id)["excel_column"];
            let filterObj = { columnName: columnName, values: selectedfilterData.selected, excelColumn: excelColumn }
            selectedFilters.push(filterObj);
        }
        return selectedFilters;
    }

    const handleGenerateReport = () => {
        const valid = validate()
        if (!valid) return
        if (isTemplateEdited) {
            globalStates.handleToast(true, 'Please save template first', 'error')
            return
        }
        setShowGeneratedTable(true);
    };

    const getCustomDataPoints = () => {
        if (!Array.isArray(selectedDataPoint) || selectedDataPoint.length === 0) {
            return [];
        }

        return selectedDataPoint.map(ele => {
            const {
                id,
                name,
                selectedValue = [],
                uniqueId,
                group,
                isFilter,
                isValue
            } = ele;

            const key = uniqueId;
            const filter = appliedFilters[key];
            const value = isEmpty(selectedValue[0]) ? null : selectedValue[0];
            const resolvedName =
                id === "microskill-id-rows" ? "Microskill Name" : name;

            return {
                name: resolvedName,
                value,
                filter,
                group,
                isFilter,
                isValue,
            };
        });
    };

    const handleSelectTemplate = async (templateId) => {
        if ((!isEmpty(selectedDataPoint) && isEmpty(selectedTemplate)) || isTemplateEdited) {
            let confirm = window.confirm("All unsaved changes will be lost, do you wish to continue?");
            if (!confirm) return
        }
        (isTemplateEdited) && setIsTemplateEdited(false);
        setSelectedTemplate([templateId]);
        handleResetDateRange();
        const response = await reportServices.getSavedCustomTemplate(companyId, templateId);
        if (response.status === 200 || response.status === 201) {
            handleSetSelectedDataPoint(response.data.data.request.customPointData);
            handleSetSelectedDemographics(response.data.data.request.demographicData)
            if (!isEmpty(templateId)) {
                setTimeout(() => {
                    router.history.replace(`/analytics?c_id=${companyId}&&tab=20`)
                }, 1000);
            }

        }
    }

    const getId = (name) => {
        return analyticsConstant.customDataPointConstant.find(ele => ele.name === name).id;
    }

    const getFilterValue = (elementId, value) => {
        let filterValue;
        let selectedFilter;
        analyticsConstant.customDataPointConstant.forEach((ele) => {
            if (ele.id === elementId) {
                filterValue = ele.values;
                selectedFilter = ele.values.find((data) => data.id === value).filter
            }
        }
        )
        return { filterValue, selectedFilter }

    }

    const handleSetSelectedDataPoint = (customPoint) => {
        let tempFilters = {}
        const data = customPoint.map((ele) => {
            const id = getId(ele.name === "Microskill Name" ? "Microskill Name-rows" : ele.name);
            if (id === "microskill-id-columns" || id === "microskill-id-rows") {
                setMicroskillALignmentSelected(id)
            }
            const { filterValue, selectedFilter } = getFilterValue(id, ele.value);
            let uniqueId = uuid();
            tempFilters[uniqueId] = ele.filter;
            return (
                {
                    name: ele.name,
                    selectedValue: [ele.value],
                    group: ele.group,
                    currentFilter: selectedFilter,
                    id: id,
                    uniqueId,
                    values: filterValue,
                    isFilter: ele.isFilter,
                    isValue: ele.isValue,
                })
        })
        setSelectedDataPoint(() => {
            setAppliedFilters(tempFilters);
            return data;
        });
    }

    const handleSetSelectedDemographics = (demographicDataValue) => {
        const tempSelectedDemographicCols = demographicDataValue.map((ele) => ele.excelColumn)

        const tempSelectedDemographics = allDemographicData.map(ele => {
            let tempSubmenu = [];

            ele && ele.values.forEach(item => {
                if (item) tempSubmenu.push({ id: item, name: item })
            });
            return {
                id: ele.columnName,
                name: ele.columnName,
                subMenu: tempSubmenu,
                selected: !isEmpty(demographicDataValue.find((item => item.excelColumn === ele.columnName))) ? demographicDataValue.find((item => item.excelColumn === ele.columnName)).values : [],
                total: tempSubmenu.length,
            }
        })
        setDemographicFilter((prev) => {
            setSelectedDemographicColumns(tempSelectedDemographicCols);
            return tempSelectedDemographics;
        })
    }

    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            setFromDate(dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'));
            setToDate(dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS'));
        }
        setDateRange(newValue);

    }
    const handleResetDateRange = () => {
        setDateRange([null, null]);
    }

    const getPayload = () => {
        return {
            companyId,
            title: templateName,
            settings: 'string',
            request: {
                demographicData: getSelectedDemographics(),
                customPointData: getCustomDataPoints(),
                dateFilter: {
                    reportFetchType: LaunchWise ? 'LAUNCHESON' : 'COMPLETION',
                    fromDate,
                    toDate,
                }
            }

        }
    }

    const handleClearSelection = () => {
        setSelectedDataPoint([]);
        setAppliedFilters({});
        setDemographicFilter(defaultDemographicData);
        setSelectedDemographicColumns([]);
        setSelectedTemplate([]);
        setIsTemplateEdited(false);
    }

    const handleDateRangeType = (item) => {
        setDateRangeType([item.id]);
        if (item.id === "LAUNCHESON") {
            setLaunchWise(true)
        } else {
            setLaunchWise(false)
        }
    }

    const handleBackBtn = () => {
        setShowGeneratedTable(prev => !prev);
    }

    return (
        <>
            {!showGeneratedTable
                ?
                <div className={classes.reportBuilder}>
                    <div>
                        <h1 className={classes.heading}>Report Builder</h1>
                        <div className={classes.flexStyle}>
                            <div>
                                <div style={{ display: "flex" }}>
                                    <CustomSelect
                                        hideBaseBtn={true}
                                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Templates', selected: selectedTemplate, dataObject: commonUtil.objectify(savedTemplates, "id") })}
                                        withSelectAllOption={false}
                                        listData={savedTemplates}
                                        selected={selectedTemplate}
                                        handleSelect={(item) => handleSelectTemplate(item.id)}
                                        radio={true}
                                        autoClose={true}
                                        width={'250px'}
                                    />
                                    <div className={classes.dateRangeWrapper}>
                                        <DatePicker
                                            value={dateRange}
                                            maxDate={today}
                                            minDate={minDate}
                                            handleDateChange={handleDateChange}
                                            handleReset={handleResetDateRange}
                                            selectedShortcut={selectedShortcut}
                                            setSelectedShortcut={setSelectedShortcut}
                                        />
                                    </div>
                                    {
                                        (dateRange[0] && dateRange[1]) &&
                                        <CustomSelect
                                            hideBaseBtn={true}
                                            defaultText={commonUtil.getSelectLabel({
                                                type: 'single',
                                                defaultText: 'Select Date Range Type',
                                                selected: dateRangeType,
                                                dataObject: commonUtil.objectify(dateRangeTypeData, "id"),
                                            })}
                                            withSelectAllOption={false}
                                            listData={dateRangeTypeData}
                                            selected={dateRangeType}
                                            handleSelect={(item) => handleDateRangeType(item)}
                                            autoClose={true}
                                            width="230px"
                                            radio={true}
                                        />
                                    }
                                </div>

                            </div>
                            <div className={classes.btnBox}>

                                <Button title='Generate report' onClick={handleGenerateReport} className={`${classes.btn} ${classes.saveBtn}`}>
                                    Generate Report
                                </Button>

                                <Button
                                    title='Save Template'
                                    onClick={handleOpenModal}
                                    className={`${classes.btn} ${classes.saveBtn}`}>
                                    Save Template
                                </Button>
                                <Button
                                    title='Save Template'
                                    onClick={handleClearSelection}
                                    className={`${classes.btn} ${classes.clearBtn}`}>
                                    Clear Selection
                                </Button>
                            </div>
                        </div>
                    </div>


                    <div className={classes.headerContainer}>
                        <div className={classes.headerBox} style={{ width: '20%' }}>
                            <Label labelText={'Select Demographics'} fs='1rem' />
                            <DemographicData
                                demographicFilter={demographicFilter}
                                setDemographicFilter={setDemographicFilter}
                                mapping={mapping}
                                setMapping={setMapping}
                                setAllDemographicData={setAllDemographicData}
                                selectedDemographicColumns={selectedDemographicColumns}
                                setSelectedDemographicColumns={setSelectedDemographicColumns}
                                selectedTemplate={selectedTemplate}
                                isTemplateEdited={isTemplateEdited}
                                setIsTemplateEdited={setIsTemplateEdited}
                                setBackdrop={setBackdrop}
                                allDemographicData={allDemographicData}
                                setDefaultDemographicData={setDefaultDemographicData}
                            />
                        </div>

                        <div className={classes.headerBox} style={{ width: "80%" }}>
                            <Label labelText={'Select Data Points'} fs='1rem' />
                            <CustomDataPoint
                                appliedFilters={appliedFilters}
                                setAppliedFilters={setAppliedFilters}
                                selectedDataPoint={selectedDataPoint}
                                setSelectedDataPoint={setSelectedDataPoint}
                                selectedTemplate={selectedTemplate}
                                isTemplateEdited={isTemplateEdited}
                                setIsTemplateEdited={setIsTemplateEdited}
                                setMicroskillALignmentSelected={setMicroskillALignmentSelected}
                                microskillALignmentSelected={microskillALignmentSelected}
                            />
                        </div>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={style}>
                            <Label labelText={'Save Template'} fs='1rem' style={{ marginBottom: '10px' }} />

                            <Stack spacing={2} sx={{ margin: '1rem 0' }}>
                                <TextField
                                    label="Template Name"
                                    variant="outlined"
                                    fullWidth
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            // color: '#f4511e',
                                            '&.Mui-focused': {
                                                color: '#f4511e',
                                            },
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                        },
                                    }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveTemplate}
                                    style={{ backgroundColor: '#f4511e' }}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Box>
                    </Modal>
                </div>
                :
                <CustomReportTable
                    payload={getPayload()}
                    selectedTemplate={selectedTemplate}
                    handleBackBtn={handleBackBtn}
                />
            }
        </>
    );
};

export default ReportBuilder;