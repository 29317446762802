import { authActionTypes } from "./auth.types";

const INITIAL_STATE = {
    isLoading: false,
    error: null,
}
const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        default:
            return state;
    }
}

export default authReducer