import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

import Label from '../../../label';
import { useEffect } from 'react';




const CustomInput = ({
    id,
    defaultValue = '',
    isDisabled = false,
    handleChange,
    withLabel = false,
    labelText = 'Question:',
    maxLength = 500,
    limitText = '',
    isExceeded = false,
    isEmpty = false,
    type = 'text',
}) => {
    const classes = useStyles();
    // const disabledStyle = {
    //     '&:hover': {
    //         outline: 'none !important',
    //     },
    // }
    return (
        <>
            {withLabel ? <Label labelText={labelText} fs={'0.7rem'} /> : null}
            <div
                className={`${classes.customInputBox} ${isExceeded || isEmpty ? classes.error : ''} ${isDisabled ? classes.disabledStyle : ''}`}
            >
                <input
                    id={id}
                    style={{ width: '86%' }}
                    onChange={handleChange}
                    value={defaultValue}
                    maxLength={maxLength}
                    type={type}
                    disabled={isDisabled}
                />
                {/* {isExceeded ? */}
                <span style={{ backgroundColor: isDisabled ? "rgba(239, 239, 239, 0.3)" : "#fff" }}>
                    {limitText}
                </span>
                {/* : null} */}
            </div>
        </>
    )
}

export default CustomInput;

const useStyles = makeStyles(() => ({

    customInputBox: {
        border: '2px solid rgb(184, 184, 184, 0.3)',
        borderRadius: '3px',
        display: 'flex',
        fontSize: "0.7rem",
        fontWeight: "500",
        '& input': {
            color: 'rgb(112, 112, 112, 0.9)',
            padding: '0.5rem',
            fontSize: "0.8rem",
            // width: '86%',
            border: 'none',
            '&:focus': {
                outline: 'none',
            },
        },
        
        '&:focus-within': {
            // outline: '2px solid #4F0060',
            border: '2px solid #4F0060',
        },
        '&:hover': {
            // outline: '2px solid #4F0060',
            border: '2px solid #4F0060',
        },


        '& span': {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.28rem 0.5rem',
            width: '14%',
            minWidth: 'fit-content',
            fontWeight: 600,
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            color: '#EB3917',
        },
        // What corrective majors should you take in order to avoid conflict?
    },
    error: {
        // outline: '2px solid #EB3917 !important',
        border: '2px solid #EB3917 !important',
        '&:hover': {
            // outline: '2px solid #EB3917 !important',
            border: '2px solid #EB3917 !important',
        },
    },
    disabledStyle: {
        '&:hover': {
            outline: 'none !important',
        },
    }

}));