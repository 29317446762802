import React, { useEffect, useState } from 'react'
import Title from '../title';
import SearchBar from './components/search-bar';
import useStyles from './index.styles';
import { style } from '@mui/system';
import BulletinCard from '../bulletin-card';
import useQuery from '../../utils/useQuery';
import bulletinServices from '../../services/bulletin'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../custom-styled/loader';
import isEmpty from '../../utils/isEmpty';

const ManageBulletin = () => {

    const styles = useStyles();
    const query = useQuery();
    const history = useHistory()
    const company_id = query.get("c_id");
    let titleText = "All Bulletins"

    const [bulletinsData, setBulletinsData] = useState(null);
    const [loading, setLoading] = useState(true);


    const getAllBulletins = async () => {
        const response = await bulletinServices.fetchBulletins(company_id);
        // console.log(response.data.data[0].content.contents[0].content); // Image 
        // console.log(response.data.data[0].content.contents[1].content); // Title
        // console.log(response.data.data[0].content.contents[2].content); // Description
        setBulletinsData(response.data.data)
        setLoading(false);
    }

    const handleCreateBulletin = () => {
        history.push(`/bulletin?c_id=${company_id}`)
    }

    useEffect(() => {
        getAllBulletins()
    }, [])

    return (
        <>
            {loading ? <Loader /> : <div className={styles.mainContainer}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleColumn1}>
                        <div className={styles.dropdownBox}>
                            <Title titleText={titleText} infoIcon={true} />

                        </div>


                        <div className={styles.searchBarBox} >
                            {/* <SearchBar
                            //   allCategories={categoryWiseData.map(ele => {
                            //     let tempObj = { ...ele };
                            //     if ("Microskills" in tempObj) delete tempObj.Microskills;
                            //     return tempObj
                            //   })}
                            //   allCategoryWiseMs={categoryWiseData}
                            //   unCategorizedMs={unCategorizedMs}
                            //   handleEditMS={handleEditMS}
                            /> */}
                        </div>

                    </div>


                    <div className={styles.titleColumn2}>
                        <span className={styles.iconsBox}>
                            {/* <img src='/images/icons/grid.svg' alt='' title='Show List' /> */}
                            {/* <img className={styles.icon} src='/images/icons/grid.svg' alt='' title='Show Grid' /> */}
                        </span>
                    </div>
                </div>



                <div className={styles.list}>
                    <div className={styles.listItem}>


                        <div className={styles.cardsContainer}>
                            <div className={styles.createCard} onClick={handleCreateBulletin} >
                                <img src='/images/icons/add.svg' alt='' />
                                <h5>CREATE NEW BULLETIN</h5>
                            </div>

                            {
                                bulletinsData && bulletinsData.filter(element => !isEmpty(element.content)).map((element, index) => {
                                    return <BulletinCard key={index} index={index} getAllBulletins={getAllBulletins} data={element} companyId={company_id} />
                                })

                            }
                            {/* {
                                bulletinsData && bulletinsData.map((element, index) => {
                                    { console.log(index, element.title, element.id) }
                                })
                            } */}
                        </div>

                    </div>
                </div>


            </div>}
        </>
    )
}

export default ManageBulletin