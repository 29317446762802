import React, { useState, useEffect, useRef, useContext } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//common-styles
import commonStyles from './index.styles';
import UploadContainer from './upload-container';
import EditableTitle from '../rtf-title';
import EditableBody from '../rtf-body';

const ImageCard = ({
    customAspectRatio = true,
    titleBody,
    titleText,
    titleLimit,
    setTitleBody,
    setTitleText,
    setBodyText,
    laBgImgData,
    image,
    setImage,
    setImageFile,
    hanldeFileUpload
}) => {
    const classes = useStyles();
    const styles = commonStyles();
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    let capture = useRef();
    let titleRef = useRef(null);
    let bodyRef = useRef(null);

   
    const handeleTitleChange = (text) => {
        const editor = titleRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, title: text }))
        setTitleText(prev => prev = value)
    }

    const handleBodyChange = (text) => {
        const editor = bodyRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, body: text }))
        setBodyText(prev => prev = value)
    }




    const handleMouseOver = () => {
        setShowDeleteIcon(true);
    }
    const handleMouseLeave = () => {
        if (image) setShowDeleteIcon(false);
    }
    const handleRemoveImage = () => {
        setImageFile(null);
        setImage(null)
        setShowDeleteIcon(false);
    }
    let textColor = 'rgb(0, 0, 0, 0.5)';

    return (


        <>
            <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}

                onMouseLeave={handleMouseLeave}
            >
                <div className={classes.imgBox} >
                    {showDeleteIcon ?
                        <span className={classes.deleteIcon}>
                            <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Image" onClick={handleRemoveImage} />
                        </span>
                        : null}
                    {
                        image ?
                            <img src={image} alt='' onMouseOver={handleMouseOver} /> :
                            <UploadContainer handleChange={hanldeFileUpload} textColor={textColor} iconColor={laBgImgData?.bgImg ? '#fff' : '#f4511e'} />
                    }
                </div>
                <EditableTitle
                    ref={titleRef}
                    minHeight={'3rem'}
                    maxHeight={'6rem'}
                    handeleTitleChange={handeleTitleChange}
                    value={titleBody.title}
                    titleText={titleText}
                      titleLimit={titleLimit}
                />
                <EditableBody
                    ref={bodyRef}
                    minHeight={'10rem'}
                    maxHeight={'10rem'}
                    handleBodyChange={handleBodyChange}
                    value={titleBody.body}
                />


            </div>


        </>
    )

}

export default ImageCard;


const useStyles = makeStyles(() => ({
    imgBox: {
        // position: 'relative',
        borderRadius: '4px',
        minHeight: '50%',
        // height: '50%',
        width: '100%',
        maxWidth: 'calc(420px - 1rem)',
        aspectRatio: 4 / 3,
        '& > img': {
            borderRadius: '4px',
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        },
        '&:hover': {
            border: '1.6px dashed rgb(204, 204, 204, 0.5)',
        }
    },

    titleContainer: {
        // border : '1px solid',
        width: '100%',
        overflow: 'hidden',
        minHeight: '48px',
        margin: '0.8rem 0 !important',
        '& > div': {
            margin: '0 !important',
            maxHeight: '100%',
            height: '100%',
            '&:hover': {
                border: 'none',
            }
        },
        '&:hover': {
            border: '1px dashed rgb(204, 204, 204, 0.5)',
        }
    },
    deleteIcon: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0.5rem',
        right: '0.5rem',
        height: '2.2rem',
        width: '2.2rem',
        // padding: '0.2rem',
        borderRadius: '50%',
        backgroundColor: 'rgb(112,112,112,0.5)',
        '& > img': {
            transition: '0.7s',
            height: '1rem !important',
            width: '1rem !important',
            filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
        },
        '&:hover': {
            cursor: 'pointer !important',
            '& > img': {
                scale: 1.2,
                filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'

            }
        }
    }

}));