import React, { useEffect, useState } from "react";

// mui
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import MuiCheckbox from "@mui/material/Checkbox";

//components
import Label from "../../../custom-styled/label";
import CustomInput from '../../../custom-styled/custom-input'
import ChipStack from "../../../custom-styled/chip-stack";
import CustomButton from "../../../custom-styled/button";
import FileuploadInput from "../../../game-based-assessment/components/question-answer-component/components/fileupload-input";
import SnackbarContainer from "../../../snackbar";
import constants from "../../../../constants";
import RepositoryGroups from "../repository-groups";
import Disclaimer from "../../../disclaimer";

//services
import documentsServices from "../../../../services/documents";

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '75%',
    bgcolor: 'background.paper',
    // border: '2px solid red',
    boxShadow: 24,
    // border: 'none',
    borderRadius: '5px',
    outline: 'none',

}

const modalStyle = {
    zIndex: 9999,
}

const data = {
    FOLDER: {
        title: 'Add New Folder',
        nameLabel: 'Folder Name:',
        tagsLabel: 'Folder Groups:',
        statusLabel: 'Folder Status:',
        infoData: {
            title: 'How to add new folder:',
            description: 'Add a folder name and tags with comma separated values to make new folder and select its availability by shifting the values in the last dropdown that appears on this screen.'
        }
    },
    FILE: {
        title: 'Add New File',
        nameLabel: 'Browse File:',
        tagsLabel: 'File Groups:',
        statusLabel: 'File Status:',
        infoData: {
            title: 'Tips for adding new files:',
            description: [
                'File name cannot exceed more than 40 characters including spaces',
                `File size cannot exceed more than ${constants.REPOSITORY_MAX_FILE_SIZE_MB} MB.`,
                'Please add appropriate tags to your file so it can be searched'
            ]
        }
    },
    EDIT: {
        title: 'Edit Document',
        nameLabel: 'Name:',
        tagsLabel: 'Folder Tags:',
        statusLabel: 'Status:',
        infoData: {
            title: 'How to edit document:',
            description: 'Add name and tags with comma separated values to edit document and select its availability by shifting the values in the last dropdown that appears on this screen.'
        }
    }
}

const DocumentsModal = ({
    isModalOpen = false,
    type = 'FOLDER',
    withInfo = true,
    setIsModalOpen,
    isError = false,
    folderName,
    handleFolderName,
    fileName = 'No file chosen',
    handleFileChange,
    handleSave,
    isSaving = true,
    isToast = false,
    setIsToast,
    toastMessage = '',
    toastSeverity = '',
    editData,
    setEditData,
    status,
    setStatus,
    groups,
    selectedGroups,
    setSelectedGroups,
    setRepositoryGroups,
    isDownloadAllowed,
    setIsDownloadAllowed,
    isShareAllowed,
    setIsShareAllowed,
}) => {
    const classes = useStyles();
    const [chips, setChips] = useState([]);
    const [loadingRepoGroups, setLoadingRepoGroups] = useState(false);

    const withCheckbox = type === 'FILE' || (type === 'EDIT' && editData?.data?.type === 'FILE');

    useEffect(() => {
        if (isModalOpen && editData.edit) {
            console.log('editData = ', editData);
            fetchRepositoryGroups(editData.data.id);
        }
    }, [isModalOpen])

    useEffect(() => {
        let filteredChips = groups.filter(ele => selectedGroups.includes(ele.id));
        setChips(filteredChips)
    }, [selectedGroups])

    const fetchRepositoryGroups = async (repositoryId = null) => {
        setLoadingRepoGroups(true);
        const response = await documentsServices.fetchRepositoryGroups(repositoryId);
        setLoadingRepoGroups(false);
        if (response.status === 200 || response.status === 201) {
            const repoGroupsIds = response.data.data.map(ele => ele.Group.id);
            setRepositoryGroups(repoGroupsIds);
            setSelectedGroups(repoGroupsIds)
        }
    }

    const handleSelectGroup = (item) => {
        if (selectedGroups.includes(item.id)) {
            let filtered = selectedGroups.filter(ele => ele !== item.id);
            setSelectedGroups(filtered);
        } else {
            setSelectedGroups([...selectedGroups, item.id]);
        }
    };

    const handleRemoveGroup = (item) => {
        let filteredChips = chips.filter(ele => ele.id !== item.id);
        setChips(filteredChips);
        let filtered = selectedGroups.filter(ele => ele !== item.id);
        setSelectedGroups(filtered);
    }

    const handleClose = () => {
        setIsModalOpen(false);
        setEditData({ edit: false, data: null });
        setSelectedGroups([]);
        setRepositoryGroups([]);
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                console.log('backdrop clicked');
                return
            }}
            sx={modalStyle}
        >
            <Box sx={boxStyle}>
                <div className={classes.header}>
                    <Typography variant="body1" component="div"> {data[type].title} </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#f4511e',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.body}>
                    <div className={`${classes.column} ${classes.column1}`}>
                        <div className={classes.nameBox}>
                            <Label labelText={data[type].nameLabel} fs='0.8rem' />
                            {
                                type === 'FOLDER' || type === 'EDIT' ?
                                    <div className={classes.nameInput}>
                                        <CustomInput
                                            placeholder='Enter Folder Name'
                                            value={folderName}
                                            handleChange={handleFolderName}
                                            withLimitWarning={false}
                                            isEmpty={isError}
                                        />
                                    </div> :
                                    <div className={classes.fileInput}>
                                        <FileuploadInput
                                            fileName={fileName}
                                            accept={constants.LIBRARY_SUPPORTED_EXTENSIONS}
                                            handleChange={handleFileChange}
                                            multiple={true}
                                        />
                                    </div>

                            }
                        </div>
                        {
                            withCheckbox && 
                            <div className={classes.checkboxContainer}>
                                <div className={classes.checkbox}>
                                    <Checkbox
                                        id="download-file"
                                        handleClick={() => setIsDownloadAllowed(!isDownloadAllowed)}
                                        isChecked={isDownloadAllowed}
                                    />
                                    <Label labelText={"Allow Download"}/>
                                </div>
                                <div className={classes.checkbox}>
                                    <Checkbox
                                        id="share-file"
                                        handleClick={() => setIsShareAllowed(!isShareAllowed)}
                                        isChecked={isShareAllowed}
                                    />
                                    <Label labelText={"Allow Share"}/>
                                </div>
                            </div>
                        }
                        {
                            true &&
                            <div className={`${classes.tagsBox} ${classes.flexColumn}`}>
                                <Label labelText={data[type].tagsLabel} fs='0.8rem' />
                                <RepositoryGroups
                                    loading={loadingRepoGroups}
                                    editData={editData}
                                    groups={groups}
                                    selectedGroups={selectedGroups}
                                    setSelectedGroups={setSelectedGroups}
                                    chips={chips}
                                    setRepositoryGroups={setRepositoryGroups}
                                    handleSelectGroup={handleSelectGroup}
                                    handleRemoveGroup={handleRemoveGroup}
                                />
                            </div>
                        }

                        {false && <div className={`${classes.statusBox} ${classes.flexColumn}`}>
                            <Label labelText={data[type].statusLabel} fs='0.8rem' />
                            <div className={classes.selectBox}>
                                <select onChange={handleStatusChange} defaultValue={status}>
                                    <option value={'ACTIVE'}>ACTIVE</option>
                                    <option value={'INACTIVE'}>INACTIVE</option>
                                </select>
                            </div>
                        </div>}
                        <div className={classes.btnBox}>
                            {isSaving ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                >
                                    Saving...
                                </LoadingButton>
                                : <CustomButton
                                    handleClick={handleSave}
                                    variant='contained'
                                    textColor='#fff'
                                    bgcolor='#f4511e'
                                    fs="0.6rem"
                                    fw={700}
                                    ls={1}
                                    btnText='Save'
                                />}
                            <CustomButton
                                handleClick={handleClose}
                                variant='outlined'
                                textColor='#f4511e'
                                borderColor='#f4511e'
                                bgcolor='#fff'
                                fs="0.6rem"
                                fw={700}
                                ls={1}
                                btnText='Cancel'
                            />
                        </div>
                    </div>
                    {
                        withInfo ?
                            <div className={`${classes.column} ${classes.column2}`}>
                                <h1 className={classes.infoTitleBox}>
                                    <ErrorOutlineIcon className={classes.errorIcon} />
                                    {data[type].infoData.title}

                                </h1>
                                {
                                    type === 'FOLDER' || type === 'EDIT' ?
                                        <p className={classes.infoDescription} >
                                            {data[type].infoData.description}
                                        </p> :
                                        <ul className={`${classes.infoDescription} ${classes.list}`}>
                                            {
                                                data[type].infoData.description.map((ele, i) => (
                                                    <li key={i}>{ele}</li>
                                                ))
                                            }
                                        </ul>
                                }
                                <Disclaimer p="1rem 0" lh={2.5}/>

                            </div> : null
                    }
                </div>
                <SnackbarContainer
                    vertical={'bottom'}
                    horizontal={'center'}
                    open={isToast}
                    setOpen={setIsToast}
                    message={toastMessage}
                    severity={toastSeverity}
                />
            </Box>
        </Modal>
    )
}

export default DocumentsModal;

const useStyles = makeStyles(() => ({
    header: {
        height: '10%',
        borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            marginLeft: '2rem',
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "1.4rem",
            fontWeight: 700,
        },
    },
    body: {
        height: '90%',
        display: 'flex',
    },
    column: {
        height: '100%',
        width: '50%',
        padding: '1rem'
    },
    infoTitleBox: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Montserrat",
        color: "#4F0060",
        fontSize: "0.9rem",
        fontWeight: "Bold",
    },
    infoDescription: {
        color: "#707070",
        fontSize: "0.8rem",
        fontWeight: "400",
        lineHeight: "2rem",
        letterSpacing: '0.03rem'
    },
    errorIcon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginRight: "0.4rem"
    },
    list: {
        marginLeft: '1rem',
    },
    column1: {
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative'

    },
    column2: {
        // border: '1px solid blue',
        display: 'flex',
        gap: '0.8rem',
        flexDirection: 'column',
        justifyContent: 'center'
    },

    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    nameBox: {
        // border: '1px solid',
        // height: '18%',
        width: '80%',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',

    },
    nameInput: {
        height: '50% !important',
        '&:hover': {
            outline: '1px solid rgb(0,0,0)',
            borderRadius: '3px'
        }
    },
    fileInput: {
        height: '50% !important',
        '& > div': {
            margin: 0,
            '& > div:first-child': {
                //    border: '1px solid red',
                width: '80%'
            },
            '& > div:last-child': {
                // border: '1px solid green',
                width: '20%'
            }
        }
    },
    tagsBox: {
        // border: '1px solid',
        // height: '32%'
        height: '100%',
        maxHeight: '16rem',
        '& > div':{
            backgroundColor: '#fff',
            '& .MuiCircularProgress-root':{
                height: '2rem !important',
                width: '2rem !important'
            }
        }

    },
    chipStack: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem'
    },
    statusBox: {
        // border: '1px solid',
        position: 'relative',
        height: '18%',
        width: '80%',

    },
    selectBox: {
        height: '50% !important',
        '& select': {
            border: '1px solid rgb(184, 184, 184, 0.5)',
            borderRadius: '3px',
            width: '100%',
            height: '100%',
            padding: '0.5rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: "500",
        }
    },
    btnBox: {
        height: '10%',
        position: 'absolute',
        bottom: '2rem',
        width: 'calc(100% - 2rem)',
        display: 'flex',
        gap: '2rem',
        alignItems: 'flex-end',
        '& .MuiButtonBase-root': {
            borderRadius: 0,
            width: 200,
            height: '2.5rem',
            borderWidth: '2px',
            fontSize: '0.7rem',
            fontWeight: 800,
            letterSpacing: '1px',
            fontFamily: 'Montserrat',
        },
        '& .MuiButton-contained:hover': {
            backgroundColor: '#f4511e',
            borderColor: '#f4511e',
            color: '#fff',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
        },
        '& .MuiButton-outlined:hover': {
            backgroundColor: '#fff',
            borderColor: '#f4511e',
            color: '#f4511e',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
        },
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    checkbox: {
        '& span': {
            padding: '0',
        },
        '& label': {
            fontSize: '0.8rem',
            paddingLeft: '8px',
        }
    },
}));

const Checkbox = ({ id, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type="checkbox"
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};