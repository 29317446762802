import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainContainer: {
        height: 'calc(100% - 3.8rem)',
        padding: '1rem',
        position: 'relative'
    },
    row1: {
        display: 'flex',
        flexDirection: 'column',
        '& > button': {
            alignSelf: 'center',
            margin: '0.5rem 0'
        }
    },
    uploadProgressBox: {
        display: 'flex',
        alignItems: 'center',
        '& .fa-solid': {
            width: '6%',
            fontSize: '1.3rem',
            marginRight: '3%',
            color: '#444444',
        },
        '& > div': {
            width: '91%',
            '& > h6': {
                fontSize: '0.6rem',
                color: '#f3c50e',
                fontFamily: 'Montserrat',
                fontWeight: 600,
            }
        }
    },
    csvUploadBoxContainer: {
        border: '2px dashed #CCCCCC',
        borderRadius: '10px',
        margin: '1rem 0',
        height: '8rem'
    },
    csvUploadBox: {
        padding: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '8rem',
        '& h4,h5,a': {
            marginBottom: '0.2rem',
            fontSize: '0.6rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: "700",
        },
        '& h5': {
            margin: '0.3rem 0',
            fontWeight: "500",
        },
        '& > a': {
            margin: '0.3rem 0',
            color: '#F4511E',
            fontWeight: "600",
            '&:hover': {
                fontWeight: "700",
            }
        },

    },
    uploadIcon: {
        '& .fa-solid': {
            fontSize: '1.5rem',
            color: '#F4511E',
            cursor: 'pointer',
            marginBottom: '0.3rem',
            transition: '0.5s',
            '&:hover': {
                scale: 1.1
            }
        },

    },
    row2: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        margin: '1rem',
        marginTop: 0,
    },
    labelBox: {
        margin: '1rem 0'
    },
    warningNote: {
        // border: '1px solid red',
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "0.8rem",
        color: '#e30000',
        marginBottom: '1rem',
    },
    btnBox: {
        display: 'flex',
        gap: '2rem',
        '& .MuiButtonBase-root': {
            // marginRight: '2rem',
            width: '150px',
            fontFamily: "Montserrat",
            fontWeight: 800,
            lineHeight: 0,
            fontSize: "0.6rem",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '150px',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#F4511E",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        // marginRight: "2rem",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    clearBtn: {
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
        "&:hover": {
            border: "2px solid #F4511E",
            backgroundColor: "#FFFFFF",
        }
    },
    selectMenuWrapper: {
        minWidth: '1rem',
        margin: '0.5rem 0px',

        '& .MuiInputBase-root': {

            '& > svg': {
                fontSize: '1.5rem',
                color: '#f4511e',
                right: '0.5rem'
            },
            '& > fieldset': {
                borderColor: 'rgb(184, 184, 184, 0.5)',
            },

            '& .MuiSelect-select': {
                padding: '6px 8px',
            }
        }
    },

    selectRowsBox: {
        color: "rgb(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontSize: "0.8rem",
        fontWeight: 600,
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        '& .MuiFormControl-root': {
            minWidth: '1rem',
            margin: 0,
            '& .MuiInputBase-root': {
                lineHeight: 0,
                padding: 0,
                '& > svg': {
                    fontSize: '1.5rem',
                    color: '#f4511e',
                    right: '0.5rem'
                },
                '& > fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0)'
                },
                '& .MuiSelect-select': {
                    lineHeight: 0,
                    padding: '0.3rem',
                    display: 'flex',
                    width: '3rem',
                    alignItems: 'center',
                    color: '#f4511e',
                    fontFamily: "Montserrat",
                    fontSize: "0.8rem",
                    fontWeight: 600
                }
            }
        }
    },
    betaText: {
        color: "#f4511e",
        fontSize: "10px",
        fontWeight: 700,
        // animation: '$highlight 2s infinite ease',

    },
    '@keyframes highlight': {
        '0%': { color: '#f4511e' },
        '50%': { color: 'white' },
        '100%': { color: '#f4511e' },
    },


}));

export default useStyles;