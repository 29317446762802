import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiCheckbox from "@mui/material/Checkbox";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';


//components
import Title from '../../../title'
import Table from '../table';
import Label from '../../../custom-styled/label';
import MultipleFilter from '../multiple-filter';
import EmailReports from '../email-report';
import Loader from "../../../custom-styled/loader";

//utils
import isEmpty from '../../../../utils/isEmpty';
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';

//services
import reportsServices from '../../../../services/reports';
import microskillServices from '../../../../services/microskill';
import groupServices from '../../../../services/group';

//global-states
import { GlobalStates } from '../../../../App';
import commonStyles from '../../index.styles';

let defaultPagination = {
    limit: 1,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}



const defaultHeadings = [
    { title: "First Name", key: "First Name" },
    { title: "Last Name", key: "Last Name" },
    { title: "Business Group", key: "Business Group" },
    { title: "Challenges Launched & Active", key: "Challenges Launched & Active" },
    { title: "Completed In", key: "Completed In" },
    { title: "Completion Date", key: "Completion Date", isDate: true },
    { title: "Correct Attempts", key: "Correct Attempts" },
    { title: "Designation", key: "Designation" },
    { title: "Email", key: "Email" },
    { title: "Incomplete Attempts", key: "Incomplete Attempts" },
    { title: "Incorrect Attempts", key: "Incorrect Attempts" },
    { title: "Latest Login At", key: "Latest Login At", isDate: true },
    { title: "Learner Name", key: "Learner Name" },
    { title: "Mobile", key: "Mobile" },
    { title: "Microskill", key: "Microskill" },
    // { title: "Microskill ID", key: "Microskill ID" },
    { title: "Microskill Launched On", key: "Microskill Launched On", isDate: true },
    // { title: "Password", key: "Password" },
    { title: "Precision Percentage", key: "PrecisionPercentage" },
    { title: "Supervisor", key: "Supervisor" },
    { title: "Time Spent in Minutes", key: "Time Spent in Minutes" },
    { title: "Total Attempts", key: "Total Attempts" },
    { title: "Total Challenges", key: "Total Challenges" },
    { title: "User Launched on", key: "User Launched on", isDate: true },
    // { title: "UserId", key: "UserId" },
    { title: "Username", key: "Username" },
    { title: "Users Project", key: "Users Project" },
    { title: "Zone", key: "Zone" },
    { title: "Max Score", key: "maxScore" },
    { title: "Scored", key: "scored" },
];





let staticContentFilters = [
    { id: "groups", name: 'Groups', subMenu: [{ id: 1, name: 'Group 1' }, { id: 2, name: 'Group 2' }], selected: [] },
    {
        id: "microskills", name: 'Microskills', subMenu: [], selected: []
    },

]
let staticDemographicFilters = [
    {
        id: "username", name: 'Username', subMenu: [], selected: []
    }
]

// let demographics = [
//     {
//         id: 0, columnName: "userFunction", values: ['Assistant Manager'],
//     },
//     {
//         id: 1, columnName: "designation", values: ['Microskills'],
//     },
// ]

const LeaderboardReport = ({
    setBackdrop,
    mapping,
    demographicFilter,
    setDemographicFilter,
    _applyDemographicFilter,
    _selectAllSubmenuDF,
    _handleSubmenuItemDF,
    _resetDemographicFilter,
    handleDemographicSearch,
    contentFilter,
    setContentFilter,
    _selectAllSubmenuCF,
    _handleSubmenuItemCF,
    _applyContentFilter,
    _resetContentFilter,
    _handleContentSearch }) => {
    const styles = commonStyles();
    const classes = useStyles();
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const [active, setActive] = useState(null);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState(defaultPagination);
    const [tableLoader, setTableLoader] = useState(false);
    const [progress, setProgress] = useState(0);
    const [intervalCleared, setIntervalCleared] = useState(false);
    const today = dayjs();
    // const fromDate = dayjs(today.subtract(7, 'day')).format('YYYY-MM-DD HH:mm:ss.SSS');
    // const toDate = dayjs(today).format('YYYY-MM-DD HH:mm:ss.SSS');
    const [payload, setPayload] = useState({ companyId, flag: "filter" });
    const lastSevenDays = [today.subtract(7, 'day'), today];
    const [dateRange, setDateRange] = useState(lastSevenDays);
    const [limit, setLimit] = useState(25);
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [showGenerate, setShowGenerate] = useState(true);
    const [withDateRange, setWithDateRange] = useState(true);
    let minDate = dayjs(new Date('2022-01-01'));
    const intervalRef = useRef();

    useEffect(() => {
        setShowGenerate(true);
        setProgress(0);
    }, [payload, limit])

    // useEffect(() => {
    //     if (withDateRange) {
    //         let newPayload = { ...payload }
    //         newPayload["fromDate"] = dayjs(dateRange[0]).format('YYYY-MM-DD HH:mm:ss.SSS');
    //         newPayload["toDate"] = dayjs(dateRange[1]).format('YYYY-MM-DD HH:mm:ss.SSS');
    //         console.log('newPayload = ', newPayload);
    //         setPayload(newPayload);
    //     } else {
    //         let newPayload = { ...payload }
    //         if ("fromDate" in newPayload) delete newPayload["fromDate"];
    //         if ("toDate" in newPayload) delete newPayload["toDate"];
    //         console.log('newPayload = ', newPayload);
    //         setPayload(newPayload);
    //     }

    // }, [withDateRange])

    useEffect(() => {
        if (!showGenerate) {
            // fetchLeaderboardReports(payload, { startIndex, limit });
        }
    }, [showGenerate, startIndex])


    useEffect(() => {
        console.log('progress = ', progress);
        if (intervalCleared) {
            setProgress(0);
            setTableLoader(false);
            setIntervalCleared(false);
            // globalStates.handleToast(true, 'Completion reports generated successfully! ', 'success');
        }
    }, [intervalCleared])

    const updateProgress = () => {
        intervalRef.current = setInterval(() => {
            console.log('setInterval calling');
            setProgress((prevProgress) => {
                console.log('prevProgress in setInterval = ', prevProgress);
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(intervalRef.current);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);
    };

    const handleChangeLimit = (event) => {
        setStartIndex(0)
        setPageNo(1);
        setLimit(event.target.value);
    };

    const fetchLeaderboardReports = async (payload, options = {}) => {
        setTableLoader(true);
        // return
        const response = await reportsServices.fetchLeaderboardReports(payload, options);
        console.log('fetchTqaReports response = ', response);
        if (response && response.status === 200) {
            let resData = response.data.data;
            const headings = commonUtil.getHeadings(resData.data, 'dateOnly');
            console.log('Completion headings = ', headings);
            if (headings.length) setHeadings(headings);
            setTableData(resData.data);
            setPagination(resData.paginationOptions);
            setPageCount(resData.paginationOptions.pageCount);
            setTotalCount(resData.paginationOptions.totalCount);
            // let mapping = resData.mapping;
            // mapping.forEach(element => {
            //     element.id = element.table_column;
            //     element.name = element.excel_column;
            // });
            // console.log('mapping = ', mapping);
            // setMapping(mapping);
            updateProgress();
            // setTableLoader(false);
        } else {
            globalStates.handleToast(true, "Internal Server Error.", "error");
            return
        }
    }


    const handleBackClick = () => {
        if (showGenerate) return
        if (startIndex >= limit) {
            setStartIndex(startIndex - limit)
            setPageNo(pageNo - 1);
        }
    }

    const handleForwardClick = () => {
        if (showGenerate) return
        if (startIndex + limit < totalCount) {
            setStartIndex(startIndex + limit);
            setPageNo(pageNo + 1);
        }
    }

    const hideLicenseWarning = () => {
        setTimeout(() => {
            let datePicker = document.getElementsByClassName("MuiDateRangeCalendar-root");
            // console.log('datePicker = ', datePicker[0]);
            if (datePicker[0]) {
                const firstChildDiv = datePicker[0].querySelector("div:first-child");
                // console.log('firstChildDiv = ', firstChildDiv);
                firstChildDiv.style.zIndex = -100000;
            }
        }, 0)
    }

    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            let newPayload = {
                ...payload,
                companyId: companyId,
                flag: 'filter',
                fromDate: dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'),
                toDate: dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS')
            }
            console.log('newPayload = ', newPayload);

            setPayload(newPayload);
        }

        setDateRange(newValue);

    }

    const handleDownloadLeaderboardReport = async () => {
        console.log('handleDownloadLeaderboardReport');
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel",
        };
        console.log('downloadAsExcel payload = ', downloadAsExcelPayload);
        // return
        const response = await reportsServices.fetchLeaderboardReports(downloadAsExcelPayload, { startIndex: 0 });
        console.log('downloadAsExcel response = ', response);
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location || response.data.data.file;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Leaderboard reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const handleApplyContentFilter = () => {
        const { newPayload } = _applyContentFilter(payload, contentFilter);
        setPayload(newPayload);
    }

    const handleResetContentFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter",
        };
        if (newPayload.hasOwnProperty('microskillIds')) {
            delete newPayload['microskillIds'];
        }
        if (newPayload.hasOwnProperty('languages')) {
            delete newPayload['languages'];
        }
        console.log('handleResetContentFilter newPayload = ', newPayload);
        setPayload(newPayload);
        _resetContentFilter();
    }

    const getColumnName = (value) => {
        return mappingObject[value].table_column;
    }

    const getMappingDetails = (value) => mappingObject[value]


    const handleApplyDemographicFilter = () => {
        const { newDemographicFilterData, newPayload } = _applyDemographicFilter(demographicFilter, payload);
        setPayload(newPayload);
        setDemographicFilter(newDemographicFilterData);

        // let allDemographicData = JSON.parse(allDemographicObjects);
        // let selectedFilters = [];
        // let selectedOrders = [];
        // let tempFilter = [];
        // for (let i = 0; i < demographicFilter.length; i++) {
        //     if (demographicFilter[i].selected.length) {
        //         const columnName = getColumnName(demographicFilter[i].id);
        //         const mappingObj = getMappingDetails(demographicFilter[i].id);
        //         // console.log(mappingObj);
        //         let filterObj = {
        //             columnName: columnName,
        //             values: demographicFilter[i].selected,
        //             excelColumn: mappingObj.excel_column,
        //             isFilter: mappingObj.isFilter,
        //             order: mappingObj.order
        //         }
        //         selectedOrders.push(mappingObj.order);
        //         selectedFilters.push(filterObj);
        //     }
        //     let tempObj = { ...demographicFilter[i], subMenu: allDemographicData[demographicFilter[i].id].subMenu };
        //     tempFilter.push(tempObj);
        // }

        // //CHANGES MOVED TO DOWNLOAD REPORTS FUNCTION
        // let sortedMapping = [...mapping];
        // sortedMapping.sort((a, b) => {
        //     if (a.order < b.order) return -1
        //     else return 1
        // })
        // selectedOrders.sort((a, b) => b - a);
        // // console.log('selectedFilters before = ', selectedFilters);
        // // console.log('selectedOrders = ', selectedOrders);

        // let current = selectedOrders[0];
        // // console.log('current = ', current);
        // if (!isEmpty(current)) {
        //     for (let j = 0; j < sortedMapping.length; j++) {
        //         let isFilter = sortedMapping[j].isFilter;
        //         let order = sortedMapping[j].order;
        //         if (isFilter === 1 && order > current && sortedMapping[j].table_column !== "passwordHash") {
        //             if (!selectedOrders.includes(order)) {
        //                 let missingFilterObj = {
        //                     columnName: sortedMapping[j].table_column,
        //                     excelColumn: sortedMapping[j].excel_column,
        //                     isFilter: sortedMapping[j].isFilter,
        //                     order: sortedMapping[j].order,
        //                     values: [],
        //                 }
        //                 selectedFilters.push(missingFilterObj);
        //                 break;
        //             }
        //         }
        //     }
        // }


        // console.log('selectedFilters after = ', selectedFilters);
        // let newPayload = { ...payload, flag: "filter", demographics: selectedFilters };
        // console.log('newPayload = ', newPayload);
        // setPayload(newPayload);
        // setDemographicFilter(tempFilter);


    }

    const handleResetDemographicFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('demographics')) {
            delete newPayload['demographics'];
        }
        console.log('handleResetDemographicFilter newPayload = ', newPayload);
        setPayload(newPayload);
        _resetDemographicFilter();
        // updateToDefaultDF();
    }

    const handleEmailLeaderboardReport = async () => {
        // console.log('email id = ', emailId);
        // console.log('is valid emailId = ', commonUtil.isValidEmail(emailId));
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        let emailReportPayload = {
            ...payload,
            companyId: companyId,
            flag: "email",
            emailId: emailId
        };
        const response = await reportsServices.fetchLeaderboardReports(emailReportPayload, { startIndex: 0 });
        if (response.status === 200 || isEmpty(response)) {
            let message = `Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        }else if(response.status === 400){
            globalStates.handleToast(true, response.data.errors[0].message, "error");
            setSendingEmail(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }

    }

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <Title titleText={"Leaderboard Report"} />
                <div className={classes.actionIcons}>
                    <EmailReports
                        open={emailDialog}
                        setOpen={setEmailDialog}
                        loading={sendingEmail}
                        handleChange={(e) => setEmailId(e.target.value)}
                        handleClick={handleEmailLeaderboardReport} />
                    <img src='/images/analytics/download-icon.svg' alt='' title='Leaderboard Report' onClick={handleDownloadLeaderboardReport} />
                    {/* <i className="fa-solid fa-cloud-arrow-down" title='Download Completion Report' onClick={handleDownloadLeaderboardReport}></i> */}
                </div>
            </div>
            <div className={classes.tableBox}>
                <div className={classes.filtersContainer}>
                    <MultipleFilter
                        label={"Demographic Filters"}
                        title={"Select Demographics"}
                        filterList={demographicFilter}
                        setFilterList={setDemographicFilter}
                        handleSaveFilter={handleApplyDemographicFilter}
                        handleResetFilter={handleResetDemographicFilter}
                        handleSearch={handleDemographicSearch}
                        customhandleClick={_handleSubmenuItemDF}
                        customSelectAll={_selectAllSubmenuDF}
                    />
                    <MultipleFilter
                        label={"Content Filters"}
                        title={"Select Content"}
                        filterList={contentFilter}
                        setFilterList={setContentFilter}
                        // customhandleClick={handleSelectContent}
                        handleSaveFilter={handleApplyContentFilter}
                        handleResetFilter={handleResetContentFilter}
                        handleSearch={_handleContentSearch}
                        customSelectAll={_selectAllSubmenuCF}
                        customhandleClick={_handleSubmenuItemCF}
                    />

                    {/* <div className={classes.dateRangeWrapper}>
                        <Checkbox
                            id="with-date-range"
                            type="checkbox"
                            handleClick={() => setWithDateRange(!withDateRange)}
                            isChecked={withDateRange}
                        />
                        <Label labelText={"Date Range"} />
                        <div className={classes.datePickerBox}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateRangePicker']}>
                                    <DateRangePicker
                                        disabled={!withDateRange}
                                        onOpen={hideLicenseWarning}
                                        localeText={{ start: 'From', end: 'To' }}
                                        value={dateRange}
                                        maxDate={today}
                                        minDate={minDate}
                                        onChange={(newValue) => handleDateChange(newValue)}
                                        className={classes.datePicker}

                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div> */}
                </div>
                {/* <Table
                    loader={tableLoader}
                    showGenerate={showGenerate}
                    handleGenerate={() => setShowGenerate(false)}
                    progress={progress}
                    tableHeadings={headings}
                    tableData={tableData}
                    handleForwardClick={handleForwardClick}
                    handleBackClick={handleBackClick}
                    pageCount={pageCount}
                    pageNo={pageNo}
                    pagination={pagination}
                    rows={limit}
                    handleChangeNumberOfRows={handleChangeLimit}
                /> */}
            </div>

        </div>
    )

}

export default LeaderboardReport;


const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};


const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid',
    },
    loaderBox: {
        minHeight: 'calc(100vh - 6.4rem)',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
        '& > h1': {
            // marginTop: '2rem',
            fontFamily: 'Montserrat',
            color: "#f4511e",
            fontSize: "1.5rem",
            fontWeight: 700,
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        padding: '0.2rem 1rem',
        margin: '1.5rem 0',
        height: '3rem'
    },
    actionIcons: {
        position: 'absolute',
        right: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        '& img': {
            height: '1.9rem',
            transition: '.4s',
            '&:hover': {
                height: '2rem',
                cursor: 'pointer'
            }
        },

    },
    tableBox: {
        margin: '1rem',
        marginBottom: '2rem',
        boxShadow: "0px 0px 8px -4px rgba(0,0,0,0.5)",
        borderRadius: '6px'
    },
    filtersContainer: {
        // border: '1px solid red',
        position: 'relative',
        height: '3.8rem',
        gap: '1.3rem',
        display: 'flex',
        padding: '0 1rem',
        alignItems: "center"
    },
    demographicsFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 1rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    contentFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 1rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    filterIcon: {
        marginLeft: '0.8rem',
    },
    selectBoxWrapper: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: '#fff',
        borderRadius: '4px',
    },
    dateRangeWrapper: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        gap: '1rem',
        right: '1rem',
        height: '100%',
        "& > span": {
            marginRight: '-0.9rem'
        }
    },
    datePickerBox: {
        // border: '1px solid red',
        '& > div': {
            overflow: 'hidden !important',
            paddingTop: '0.4rem !important',
            height: '2.5rem !important',
            '& .MuiInputBase-root': {
                height: '2rem !important',
                width: '8rem !important',
                color: '#707070'

            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '0.9rem',
                fontWeight: 600
            }
        }
    },
}));

