import React, { useState, useEffect, forwardRef } from 'react'

//components
import QuestionAnswer from '../question-answer-component';

//utils
import commonUtil from '../../../../utils/commonUtil';

//validations
import validations from '../../../../validations/gba-validations';

//constants
import constants from '../../../../constants';

//styles
import useStyles from './index.styles';

const Listitem = (props) => {

  const { item, itemSelected, dragHandleProps, commonProps } = props;
  const classes = useStyles();
  //HERE IN commonProps WE CAN USE PROPS PASSED THROUGH DraggableList COMPONENT!
  const {
    isDisableAll,
    toggleCollapse,
    setToggleCollapse,
    isDragable,
    list,
    setList,
    type,
    setAllExpanded,
    selectedGame,
    skills,
    handleDelete,
    setSettings } = commonProps;
  const { onMouseDown, onTouchStart } = dragHandleProps;

  const listitemStatus = () => {
    const status = {
      error: "fa-solid fa-circle-exclamation",
      inProgress: "fa-regular fa-circle-dot",
      done: "fa-solid fa-circle-check"
    }

    return status[item.status];
  }


  const handleToggle = (e) => {
    if (e.target.id === '') return;
    const id = parseInt(e.target.id);
    const temp = [...toggleCollapse];
    if (toggleCollapse.includes(id)) {
      //TO CHECK VALIDATION OF CLOSED ELEMENT
      let tempList = [...list]
      for (let i = 0; i < tempList.length; i++) {
        if (tempList[i].id === id) {
          //HERE WE HAVE TO VALIDATE CLICKED LIST ITEM..
          let validated = validations.validateListitem(type, selectedGame.designType, tempList[i])
          tempList[i] = validated;
          setList(tempList);
          break
        }
      }

      //TO CLOSE ALREADY OPENED CONTEXT
      temp.splice(toggleCollapse.indexOf(id), 1)
      if (!temp.length) {
        setAllExpanded(true)
      }
      setToggleCollapse(temp)
      return
    }
    // {show:false,index:parseInt(e.target.id)}
    temp.push(id)
    if (temp.length === list.length) {
      setAllExpanded(false)
    }
    setToggleCollapse(temp)
    return
  }

  const getQuestionNumber = () => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === item.id) {
        return i + 1
      }
    }
  }

  const isVisible = (index) => {
    return toggleCollapse.includes(parseInt(index))
  }

  const enableDelete = () => {
    // return true
    return list.length > constants.GBA_VALIDATIONS[selectedGame.designType].minQuestionsRequired
  }

  return (
    <>
      <div
        key={item.id}
        id={item.id.toString()}
        className={`${classes.listItem}`}>
        <div
          key={item.id}
          id={item.id.toString()}
          className={`${classes.contextBox}`}
        >
          <i onClick={handleToggle}
            id={item.id.toString()}
            className={isVisible(item.id) ? "fa-sharp fa-solid fa-angle-up" : "fa-solid fa-angle-down"}>
          </i>


          <div style={{ cursor: 'pointer' }}>
            <div
              className={classes.context}
              onClick={handleToggle}
              id={item.id.toString()}
            >
              {!isVisible(item.id) ? <i className={listitemStatus()}></i> : null}
              {
                (type === "MCQ" || selectedGame.designType === 'TF_SoundRight') ?
                  `Question ${getQuestionNumber(item.id)}:` :
                  `Context ${getQuestionNumber(item.id)}`
              }
            </div>

            {
              (item['validations'].length) ?
                (
                  <>
                    {
                      (!isVisible(item.id)) ?
                        (<div
                          className={classes.warning}
                          onClick={handleToggle}
                          id={item.id.toString()}
                        >
                          <i className="fa-solid fa-circle-exclamation"></i> {commonUtil.getValidationErrors(item)}
                        </div>) : null
                    }
                  </>
                ) : null
            }

          </div>

          <img
            className={classes.deleteIcon}
            style={{ visibility: enableDelete() ? 'visible' : 'hidden' }}
            onClick={() => handleDelete(item)}
            src="/images/icons/delete-photo.svg"
            alt="Delete Button"
            title="Delete"
          />


          {
            isDragable ?
              <i className="fa-solid fa-grip-vertical"
                onMouseDown={(e) => {//IS CALLED WHEN DRAG STARTS
                  if (!isDragable) return
                  document.body.style.overflow = "hidden";
                  onMouseDown(e);
                }}
                onMouseUp={() => { //IS CALLED WHEN DRAG ENDS
                  if (!isDragable) return
                  document.body.style.overflow = "visible";
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  if (!isDragable) return
                  document.body.style.overflow = "hidden";
                  onTouchStart(e);
                }}
                onTouchEnd={(e) => {
                  if (!isDragable) return
                  document.body.style.overflow = "visible";
                }}
              ></i> : null
          }
        </div>
        {
          isVisible(item.id) ?
            (
              <QuestionAnswer
                listitem={item}
                isDisableAll={isDisableAll}
                getQuestionNumber={getQuestionNumber}
                type={type}
                designType={selectedGame.designType}
                tags={skills}
                itemId={item.id}
                list={list}
                setList={setList}
                setSettings={setSettings}
              />
            ) : (null)
        }
      </div>
    </>
  );
};
export default Listitem;