import React from 'react'
import commonStyles from './index.styles';
import EditableTitle from '../../../../learning-aid/components/rtf-title';
import SuggestedOrUpload from '../../../../learning-aid/components/suggested-or-upload';
import { makeStyles } from "@mui/styles";

const BulletinUploadCard = ({ customAspectRatio = true, }) => {


    const classes = useStyles();
    const styles = commonStyles();

        return (
            <>
                <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`}
                //  ref={capture}
                    style={{
                        //   backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundBlendMode: 'multiply',
                        backgroundColor: 'rgb(0,0,0,0.5)',
                    }}>

                    {/* <div className={classes.titleContainer}>
          <EditableTitle
            ref={titleRef}
            handeleTitleChange={handeleTitleChange}
            fs={'2.6rem'}
            fw={800}
            align={'center'}
            textColor={'#fff'}
            placeholderColor={'rgb(255, 255, 255, 0.5)'}
          />
        </div> */}
                    <EditableTitle
                        //   ref={titleRef}
                        textColor={'#fff'}
                        minHeight={'3rem'}
                        maxHeight={'25rem'}
                        //   handeleTitleChange={handeleTitleChange}
                        //   value={titleBody.title}
                        //   handleTitleClick={handleTitleClick}
                        //   showTitleToolbar={toolbar.title}
                        placeholderColor={'rgb(255, 255, 255, 0.5)'}
                    />
                    {
                        <div className={classes.suggestionBox}>
                            <SuggestedOrUpload
                                title={'Background Image:'}
                            //   handleChange={onImageChange}
                            //   suggestedImages={suggestedImages}
                            //   handleClick={handleSelectSuggested}
                            />
                        </div>}

                </div>

            </>
        )
    }

export default BulletinUploadCard


const useStyles = makeStyles(() => ({
    row: {
      height: '100%',
      width: '100%',
      display: 'flex'
    },
    colum1: {
      // border: '1px solid red',
      width: '90%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // position: 'relative'
    },
    colum2: {
      // border: '1px solid red',
      width: '10%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    aspect1: {
      aspectRatio: 3 / 4,
    },
    aspect2: {
      aspectRatio: 9 / 16,
    },
    cardBox: {
      // border: '1px solid',
      // position: 'relative',
      height: '88%',
      display: 'flex',
      justifyContent: 'center',
  
    },
    card: {
      // width: '43%',
      position: 'relative',
      height: '100%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // padding: '1rem 1.5rem',
  
      // margin: '0 auto'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      display: 'none',//hiding logo in phase 1 launch
      // border: '1px solid black',
      height: '2.5rem',
      width: '40%',
      position: 'absolute',
      top: '3%'
    },
    logo: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    titleContainer: {
      width: '92%',
      maxHeight: '21%',
      margin: '0.5rem 0 !important',
      overflow: 'hidden',
      position: 'absolute',
      top: '12%',
      '& > div': {
        margin: '0 !important',
        marginTop: '0 !important',
        lineHeight: '1.2 !important',
        maxHeight: '100%',
        height: '100%',
        '&:focus': {
          outline: 'none'
        },
        '&:hover': {
          border: 'none',
        }
      },
      '&:hover': {
        border: '1px dashed rgb(204, 204, 204, 0.5)',
      }
    },
    btnBox: {
      height: '12%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiButtonBase-root': {
        borderRadius: '3px',
        height: '2.8rem',
        width: '11% !important',
        '&:hover': {
          borderColor: '#f4511e',
          backgroundColor: '#fff',
          color: '#f4511e'
        }
      }
    },
    imgBox: {
      borderRadius: '4px',
      height: '52%',
      width: '92%',
      position: 'absolute',
      top: '35%',
      '& > img': {
        borderRadius: '4px',
        width: '100%',
        aspectRatio: 4 / 3
      },
      '&:hover': {
        border: '1.6px dashed rgb(204, 204, 204, 0.5)',
      }
    },
  
    cardDetails: {
      // border: '1px solid',
      position: 'absolute',
      top: '88%',
      width: '92%',
      height: '8%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        '& label': {
          fontWeight: 900,
        },
      },
      '& img': {
        height: '2rem',
        width: '2rem'
      },
  
    },
  
    colum3Row1: {
      height: '92%'
    },
    colum3Row2: {
      height: '8%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '1rem',
      '& .MuiButtonBase-root': {
        width: '50%',
        '&:hover': {
          borderColor: '#f4511e',
          backgroundColor: '#fff',
          color: '#f4511e'
        }
      }
    },
    aspectRatioButtons: {
      // border: '1px solid',
      height: '10%'
    },
    suggestionBox: {
      backgroundColor: '#fff',
      // border: '1px solid',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      // width : '10rem',
      position: 'absolute',
      top: '11.9rem',
      right: '-10.2rem',
      // height: '17rem', //changing while hiding suggested images
      height: '7.8rem',
      width: '10.2rem',
      padding: '0.5rem',
      paddingLeft: '0.3rem',
      paddingRight: '1.5rem'
    }
  
  }));
  